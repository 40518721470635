import React, { Component } from "react";
import _ from "lodash";
import { Checkbox, Radio, RadioGroup } from "react-icheck";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
class CorporateIdentificationUnderstandingOfMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyUnderstandingFinancialMarket: "moderate",
      coyUnderstandingFinancialMarketOther: "",
      coyActiveOnFinancialMarket: "l1year",
      coyActiveOnFinancialMarketOther: "",
      coyAssetsManagedBefore: "bymyself",
      coyAssetsManagedBeforeOther: "",
      coyFinancialProductsInvested: [],
      coyFinancialProductsInvestedOther: "",
      coyInvestmentDecisions: "weekly",
      coyInvestmentDecisionsOther: "",
      coyFinancialProductsInvestedOtherChecked: false,
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.saveUnderstandingDetails = this.saveUnderstandingDetails.bind(this);
    this.changeFinancial = this.changeFinancial.bind(this);
    this.changeFinancialProductOther =
      this.changeFinancialProductOther.bind(this);
    this.checkFincialProductIfSelected =
      this.checkFincialProductIfSelected.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_UNDERSTANDING_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    try {
      const api = new Api();
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;

        let setStateVariables = [
          "coyUnderstandingFinancialMarket",
          "coyUnderstandingFinancialMarketOther",
          "coyActiveOnFinancialMarket",
          "coyActiveOnFinancialMarketOther",
          "coyAssetsManagedBefore",
          "coyAssetsManagedBeforeOther",
          "coyFinancialProductsInvested",
          "coyFinancialProductsInvestedOther",
          "coyInvestmentDecisions",
          "coyInvestmentDecisionsOther",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });

          if (
            !_.isUndefined(
              idnetificationData.coyFinancialProductsInvestedOther
            ) &&
            idnetificationData.coyFinancialProductsInvestedOther !== ""
          ) {
            stateObj["coyFinancialProductsInvestedOtherChecked"] = true;
          }
        }
        this.setState(stateObj);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  changeFinancial(e, checked, value) {
    let { coyFinancialProductsInvested } = this.state;
    if (checked) {
      coyFinancialProductsInvested.push(value);
      this.setState({
        coyFinancialProductsInvested: coyFinancialProductsInvested,
      });
    } else {
      var removeFinancialProductsInvested = {};
      removeFinancialProductsInvested = _.remove(
        coyFinancialProductsInvested,
        function (n) {
          return n !== value;
        }
      );
      this.setState({
        coyFinancialProductsInvested: removeFinancialProductsInvested,
      });
    }
  }

  changeFinancialProductOther(e, checked) {
    let stateObj = {
      coyFinancialProductsInvestedOtherChecked: checked,
    };
    if (!checked) {
      stateObj.coyFinancialProductsInvestedOther = "";
    }
    this.setState(stateObj);
  }

  checkFincialProductIfSelected(fieldName) {
    let { coyFinancialProductsInvested } = this.state;
    let checked =
      !_.isUndefined(coyFinancialProductsInvested) &&
      coyFinancialProductsInvested.indexOf(fieldName) > -1
        ? true
        : false;
    return checked;
  }

  async saveUnderstandingDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyUnderstandingFinancialMarket,
      coyUnderstandingFinancialMarketOther,
      coyActiveOnFinancialMarket,
      coyActiveOnFinancialMarketOther,
      coyAssetsManagedBefore,
      coyAssetsManagedBeforeOther,
      coyFinancialProductsInvested,
      coyFinancialProductsInvestedOther,
      coyInvestmentDecisions,
      coyInvestmentDecisionsOther,
    } = this.state;

    if (
      coyUnderstandingFinancialMarket !== "" ||
      coyActiveOnFinancialMarket !== "" ||
      coyAssetsManagedBefore !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/corporate_identification/save/understanding_details", {
          coyUnderstandingFinancialMarket: coyUnderstandingFinancialMarket,
          coyUnderstandingFinancialMarketOther:
            coyUnderstandingFinancialMarketOther,
          coyActiveOnFinancialMarket: coyActiveOnFinancialMarket,
          coyActiveOnFinancialMarketOther: coyActiveOnFinancialMarketOther,
          coyAssetsManagedBefore: coyAssetsManagedBefore,
          coyAssetsManagedBeforeOther: coyAssetsManagedBeforeOther,
          coyFinancialProductsInvested: coyFinancialProductsInvested,
          coyFinancialProductsInvestedOther: coyFinancialProductsInvestedOther,
          coyInvestmentDecisions: coyInvestmentDecisions,
          coyInvestmentDecisionsOther: coyInvestmentDecisionsOther,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/corporate_identification/submit");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      coyUnderstandingFinancialMarket,
      coyUnderstandingFinancialMarketOther,
      coyActiveOnFinancialMarket,
      coyActiveOnFinancialMarketOther,
      coyAssetsManagedBefore,
      coyAssetsManagedBeforeOther,
      coyFinancialProductsInvestedOtherChecked,
      coyInvestmentDecisions,
      coyFinancialProductsInvestedOther,
      buttonLoading,
    } = this.state;

    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h5 className="text-center color-white identification-header">About the company’s experience and understanding of financial markets and instruments</h5> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            How is the company’s knowledge and understanding of
                            financial markets and products?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyUnderstandingFinancialMarket"
                              className="understanding-split-radio-container"
                              value={coyUnderstandingFinancialMarket}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyUnderstandingFinancialMarket"
                                )
                              }
                            >
                              <Radio
                                value="poor"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET[
                                    "POOR"
                                  ]
                                }
                              />
                              <Radio
                                value="moderate"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET[
                                    "MODERATE"
                                  ]
                                }
                              />
                              <Radio
                                value="good"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET[
                                    "GOOD"
                                  ]
                                }
                              />
                              <Radio
                                value="excellent"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET[
                                    "EXCELLENT"
                                  ]
                                }
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET[
                                    "OTHER"
                                  ]
                                }
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyUnderstandingFinancialMarket === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Other
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="coyUnderstandingFinancialMarketOther"
                              value={coyUnderstandingFinancialMarketOther}
                              onChange={this.onchange}
                              placeholder="Other"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            For how long has the company been active on the
                            financial markets / investing in financial products?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyActiveOnFinancialMarket"
                              className="understanding-split-radio-container"
                              value={coyActiveOnFinancialMarket}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyActiveOnFinancialMarket"
                                )
                              }
                            >
                              <Radio
                                value="l1year"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "L1YEAR"
                                  ]
                                }
                              />
                              <Radio
                                value="1to2years"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "1TO2YEARS"
                                  ]
                                }
                              />
                              <Radio
                                value="3to5years"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "3TO5YEARS"
                                  ]
                                }
                              />
                              <Radio
                                value="m5years"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "M5YEARS"
                                  ]
                                }
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "OTHER"
                                  ]
                                }
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyActiveOnFinancialMarket === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Other
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="coyActiveOnFinancialMarketOther"
                              value={coyActiveOnFinancialMarketOther}
                              onChange={this.onchange}
                              placeholder="Other"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            How have the company’s assets been managed before?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyAssetsManagedBefore"
                              className="wealth-split-radio-container"
                              value={coyAssetsManagedBefore}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyAssetsManagedBefore"
                                )
                              }
                            >
                              <Radio
                                value="bymyself"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "BYMYSELF"
                                  ]
                                }
                              />
                              <Radio
                                value="discretionarymanagement"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "DISCRETIONARYMANAGEMENT"
                                  ]
                                }
                              />
                              <Radio
                                value="withadviceroffinancialadvisor"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "WITHADVICEROFFINANCIALADVISOR"
                                  ]
                                }
                              />
                              <Radio
                                value="nondiscretionarymanagement"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "NONDISCRETIONARYMANAGEMENT"
                                  ]
                                }
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "OTHER"
                                  ]
                                }
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyAssetsManagedBefore === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Other
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="coyAssetsManagedBeforeOther"
                              value={coyAssetsManagedBeforeOther}
                              onChange={this.onchange}
                              placeholder="Please Specify"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            In which financial products has the company already
                            invested on a regular basis?{" "}
                          </label>
                          <div className="financial-products-invested">
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "SHARES"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "shares"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "shares")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "BONDS"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "bonds"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "bonds")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "COLLECTIVEINVESTMENTFUND"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "collectiveinvestmentfund"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "collectiveinvestmentfund"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "SUKUK"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "sukuk"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "sukuk")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "STRUCTUREDPRODUCTS"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "structuredproducts"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "structuredproducts"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "DERIVATIVES"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "derivatives"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "derivatives")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "INSURANCEINVESTMENTS"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "insuranceinvestments"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "insuranceinvestments"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "COMMODITIES"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "commodities"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "commodities")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "CURRENCIES"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "currencies"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "currencies")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "HEDGEFUND"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "hedgefund"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "hedgefund")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "PRIVATEEQUITY"
                                ]
                              }
                              checked={this.checkFincialProductIfSelected(
                                "privateequity"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "privateequity"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "OTHER"
                                ]
                              }
                              onChange={this.changeFinancialProductOther}
                              value="other"
                              checked={coyFinancialProductsInvestedOtherChecked}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyFinancialProductsInvestedOtherChecked && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Other
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.onchange}
                              placeholder="Please Specify"
                              name="coyFinancialProductsInvestedOther"
                              value={coyFinancialProductsInvestedOther}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            How often does the company take investments
                            decisions (buy/sell)?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyInvestmentDecisions"
                              className="understanding-split-radio-container"
                              value={coyInvestmentDecisions}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyInvestmentDecisions"
                                )
                              }
                            >
                              <Radio
                                value="seldom"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "SELDOM"
                                  ]
                                }
                              />
                              <Radio
                                value="monthly"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "MONTHLY"
                                  ]
                                }
                              />
                              <Radio
                                value="weekly"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "WEEKLY"
                                  ]
                                }
                              />
                              <Radio
                                value="daily"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "DAILY"
                                  ]
                                }
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "OTHER"
                                  ]
                                }
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyInvestmentDecisions === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Other
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="coyInvestmentDecisions"
                              value={coyInvestmentDecisions}
                              onChange={this.onchange}
                              placeholder="Please Specify"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_identification/wealth"
                className="color-white"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-2"
                type="button"
                onClick={this.saveUnderstandingDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateIdentificationUnderstandingOfMarket;
