import React from "react";
import Footer from "./Footer";
import CustomHeader from "./CustomHeader";
import { ToastContainer } from "react-toastify";
class Custombasepages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { currentLocation } = this.props;
    return (
      <div className="custom-wrapper">
        <ToastContainer hideProgressBar={true} />
        <div className="custom-background-color">
          <CustomHeader
            authUserInfo={this.props.authUserInfo}
            isKycPage={true}
          />
          <div className="custom-bg-overlay"></div>
          <div
            className={`all-wrapper menu-side with-pattern ${
              currentLocation === "emd" ? "full-width-container" : ""
            }`}
          >
            <div className="custom-content-container">
              {this.props.children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Custombasepages;
