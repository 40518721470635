import React from "react";
import Api from "../../services/api";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";

class SubscribeEmailVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let verificationCode = this.props.match.params.id;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    try {
      const api = new Api();
      const response = await api
        .setToken(verificationCode)
        .get("user/subscribe_verification");
      if (response.code === 200) {
        toast.success(response.message);
      }
    } catch (e) {
      toast.error(messageConstants.SOMETHING_WENT_WRONG);
    }
    this.props.history.push("/");
  }

  render() {
    return <div className="register-container"></div>;
  }
}
export default SubscribeEmailVerify;
