import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import UserDashboardMenu from "./UserDashboardMenu";
import UserDashboardTopBar from "./UserDashboardTopBar";
import CustomHeader from "./CustomHeader";
import Footer from "./Footer";

class Base extends Component {
  constructor(props) {
    super(props);
    document.body.classList.add("menu-position-side");
    document.body.classList.add("menu-side-left");
    document.body.classList.add("full-screen");
    document.body.classList.add("with-content-panel");
    document.body.classList.add("user-dashboard-panel");
  }

  render() {
    const { currentLocation } = this.props;
    return (
      <div className="all-wrapper with-side-panel solid-bg-all user-dashboard-container">
        <ToastContainer hideProgressBar={true} />
        <div>
          <div className="user-dashboard-header-bg-color header-fixed-menu">
            <CustomHeader
              authUserInfo={this.props.authUserInfo}
              isKycPage={false}
              baseTokenChanged={this.props.baseTokenChanged}
              {...this.props}
            />
          </div>
          <div className="layout-w center-content">
            <UserDashboardMenu authUserInfo={this.props.authUserInfo} />
            <div
              className={`content-w user-dashboard-content ${currentLocation === "portfolio"
                ? "portfolio-page-container"
                : ""
                }`}
            >
              <UserDashboardTopBar authUserInfo={this.props.authUserInfo} />
              {this.props.children}
              <div className="clearfix"></div>
              <div className="base-footer">
                <Footer color="black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Base;
