import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
/* import { Link } from "react-router-dom"; */
import _ from "lodash";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";
// import browseFileImg from "../../assets/img/browse_file.png?v1";

class ListingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingDetails: {},
      kycDocumentTypeListTypes: "",
      listingFiles: "",
      objectIdRef: "",
    };
  }

  async componentDidMount() {
    const api = new Api();
    document.title =
      messageConstants.LISTING_DETAIL_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    let listingId = this.props.match.params.id;
    await this.props.checkEmdSelected();
    if (!_.isUndefined(listingId)) {
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("user/listing/details", { listingId: listingId });
        if (response.code === 200) {
          this.setState(
            {
              listingDetails: response.data.listingDetail,
              documentTypeListTypes: response.data.documentTypeListTypes,
              listingFiles: response.data.listingFiles,
              objectIdRef: response.data.objectIdRef,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
    this.props.pageProgress("remove");
  }

  render() {
    const { listingDetails, documentTypeListTypes, listingFiles, objectIdRef } =
      this.state;
    // let displayDocumentTypes = '';
    let displayDocumentTypesModified = "";
    var date =
      listingDetails && listingDetails.createdOnUTC
        ? moment(listingDetails.createdOnUTC).format("DD/MM/YYYY")
        : "";
    // if (!_.isUndefined(documentTypeListTypes) && !_.isEmpty(documentTypeListTypes)) {
    //     displayDocumentTypes = documentTypeListTypes.map((parentDocumentType, mainIndex) =>
    //         <div>
    //             {objectIdRef[parentDocumentType._id] &&
    //                 <div className="element-box">
    //                     <h5 className={(parentDocumentType.children.length > 1 ? 'text-center' : 'd-none hide')}><span className="document-type-header">{parentDocumentType.label}</span></h5>
    //                     <div className="row">
    //                         <div className="col-md-12">
    //                             {
    //                                 parentDocumentType.children.map((documentType, index) =>
    //                                     <div className={(parentDocumentType.children.length > 1 ? 'mt-4' : '')}>
    //                                         <h6 className="element-header"><span>{documentType.label}</span></h6>
    //                                         <div className="row">
    //                                             {
    //                                                 objectIdRef[documentType._id] && !_.isEmpty(objectIdRef[documentType._id]) && objectIdRef[documentType._id].map((image, imageIndex) =>
    //                                                     <div className="col-md-6 mt-4">

    //                                                         {(_.includes(listingFiles[image], 'png') || _.includes(listingFiles[image], 'jpg') || _.includes(listingFiles[image], 'jpeg') || _.includes(listingFiles[image], 'bmp')) &&
    //                                                             <a rel="noopener noreferrer" href={listingFiles[image]} target="_blank">
    //                                                                 <img src={listingFiles[image]} height={350} alt={documentType.label} />
    //                                                             </a>
    //                                                         }

    //                                                         {(!_.includes(listingFiles[image], 'png') && !_.includes(listingFiles[image], 'jpg') && !_.includes(listingFiles[image], 'jpeg') && !_.includes(listingFiles[image], 'bmp')) &&
    //                                                             <a rel="noopener noreferrer" href={listingFiles[image]} target="_blank">
    //                                                                 <img src={browseFileImg} height={200} alt={documentType.label} />
    //                                                             </a>
    //                                                         }
    //                                                     </div>
    //                                                 )
    //                                             }
    //                                         </div>
    //                                     </div>
    //                                 )
    //                             }
    //                         </div>
    //                     </div>
    //                 </div>
    //             }
    //         </div>
    //     );
    // }

    if (
      !_.isUndefined(documentTypeListTypes) &&
      !_.isEmpty(documentTypeListTypes)
    ) {
      displayDocumentTypesModified = documentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div className="col-md-6">
            {objectIdRef[parentDocumentType._id] && (
              <div className="mt-2 text-default text-default-color">
                {parentDocumentType.label} :{" "}
                <a
                  href={
                    listingFiles[
                      objectIdRef[parentDocumentType.children[0]._id][0]
                    ]
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </div>
            )}
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span>General Details</span>
                    </div>
                  </h6>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Daily Production (MCF) :{" "}
                        {listingDetails.dailyProductionMcf}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Estimated Power Capacity (kWh) :{" "}
                        {listingDetails.estimatedPoweCapacity}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Oil total Proved Reserves :{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Oil total Probable Reserves :{" "}
                        {listingDetails.oilTotalProbableReserves}{" "}
                        {listingDetails.oilTotalProbableReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Oil total Proved Reserves :{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Gas total Proved Reserves :{" "}
                        {listingDetails.gasTotalProvedReserves}{" "}
                        {listingDetails.gasTotalProvedReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Gas total Probable Reserves :{" "}
                        {listingDetails.gasTotalProbableReserves}{" "}
                        {listingDetails.gasTotalProbableReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Gas total Possible Reserves :{" "}
                        {listingDetails.gasTotalPossibleReserves}{" "}
                        {listingDetails.gasTotalPossibleReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Location :{" "}
                        {listingDetails &&
                        listingDetails.location &&
                        listingDetails.location !== ""
                          ? listingDetails.location
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Latitude / Longitude :{" "}
                        {listingDetails.latitude ? listingDetails.latitude : ""}{" "}
                        {listingDetails.longitude
                          ? listingDetails.longitude
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        Created Date : {date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="element-box mt-4">
                <div>
                  <h6 className="element-header">
                    <span>Campaign Documents</span>
                  </h6>
                  <div className="row">{displayDocumentTypesModified}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ListingDetails;
