import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  tokenReceivingAddress,
  tokenSymbol,
  contractAddress,
  contractAbiUrl,
  web3Network,
} from "../../services/api-config";
import PayMetmask from "../PaymetamaskButton";

class TokenPaymentModal extends Component {
  render() {
    const {
      tokenPaymentModalOpen,
      onCloseModal,
      totalTokenPayment,
      handleMetaMaskError,
      handleMetaMaskSuccess,
      userReceivingEthAddress,
      walletAddressCopied,
    } = this.props;
    return (
      <Modal
        open={tokenPaymentModalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container token-payment-modal-container",
        }}
      >
        <div className="onboarding-modal token-payment-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content tokenpayment-content-modal">
                    <h4 className="onboarding-title">
                      Send {tokenSymbol} to custody account
                    </h4>
                    {!userReceivingEthAddress && (
                      <div className="token-address-text">
                        Please whitelist your ETH address from my account
                        section to send {tokenSymbol}..
                      </div>
                    )}
                    {userReceivingEthAddress && (
                      <div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className="display-block">
                                Your whitelisted {tokenSymbol} wallet address{" "}
                                {userReceivingEthAddress}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="mt-3"></div>
                        <PayMetmask
                          text="Send"
                          web3Network={web3Network}
                          fromAddress={userReceivingEthAddress}
                          toAddress={tokenReceivingAddress}
                          amount={totalTokenPayment}
                          amountWalletCurrencySymbol={tokenSymbol}
                          successCallback={(txHash) =>
                            handleMetaMaskSuccess(txHash)
                          }
                          errorCallback={(e) => handleMetaMaskError(e)}
                          logoAnimated={true}
                          contractAddress={contractAddress}
                          contractAbiUrl={contractAbiUrl}
                        />
                        <div className="mt-3"></div>
                        <hr />
                        <div className="token-address-text">
                          <span className="normalfontweight">
                            Send {tokenSymbol} manually to custody address
                          </span>{" "}
                          {tokenReceivingAddress}
                          <span>
                            <CopyToClipboard
                              text={tokenReceivingAddress}
                              onCopy={() => walletAddressCopied()}
                            >
                              <button
                                className="btn btn-primary btn-sm permian-button exchange-wallet-copy-address"
                                type="button"
                              >
                                Copy
                              </button>
                            </CopyToClipboard>
                          </span>
                        </div>
                        <div className="clearfix"></div>
                        <span className="mt-3 sendtokennote">
                          Note: You will not be able to cancel partially
                          executed mandates.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default TokenPaymentModal;
