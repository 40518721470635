import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tokenSymbol } from "../../services/api-config";

class CurrencyAddressModal extends Component {
  render() {
    const {
      currencyTransactionModalOpen,
      onCloseModal,
      currencyType,
      walletAddressCopied,
    } = this.props;
    const walletAddress = this.props[currencyType + "walletAddress"];
    const walletBalance = this.props[currencyType + "walletBalance"];

    return (
      <Modal
        open={currencyTransactionModalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "exchange-currency-modal-container instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">{currencyType} Wallet</h4>
                    <div className="onboarding-text">
                      <div>
                        Send {currencyType} to this address to trade on{" "}
                        {tokenSymbol}.
                        <h5 className="mt-2">
                          <span className="pull-left">{walletAddress}</span>
                          <span>
                            <CopyToClipboard
                              text={walletAddress}
                              onCopy={() => walletAddressCopied()}
                            >
                              <button
                                className="btn btn-primary btn-sm permian-button exchange-wallet-copy-address"
                                type="button"
                              >
                                Copy
                              </button>
                            </CopyToClipboard>
                          </span>
                        </h5>
                      </div>
                      <div>
                        Your current balance is {walletBalance} {currencyType}.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default CurrencyAddressModal;
