import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import Select from "react-select";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import validators from "../../validators";
class IndividualIdentificationPersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      previousName: "",
      otherName: "",
      gender: "m",
      dob: "",
      placeOfBirth: "",
      birthCountryValue: "",
      birthCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      citizenshipCountry1Value: "",
      citizenshipCountry1Name: messageConstants.LABEL_SELECT_COUNTRY,
      citizenshipCountry2Value: "",
      citizenshipCountry2Name: messageConstants.LABEL_SELECT_COUNTRY,
      citizenshipCountry3Value: "",
      citizenshipCountry3Name: messageConstants.LABEL_SELECT_COUNTRY,
      citizenshipCountry4Value: "",
      citizenshipCountry4Name: messageConstants.LABEL_SELECT_COUNTRY,
      countryLegalDomicile1Value: "",
      countryLegalDomicile1Name: messageConstants.LABEL_SELECT_COUNTRY,
      countryLegalDomicile2Value: "",
      countryLegalDomicile2Name: messageConstants.LABEL_SELECT_COUNTRY,
      countryFiscalResidence1Value: "",
      countryFiscalResidence1Name: messageConstants.LABEL_SELECT_COUNTRY,
      countryFiscalResidence2Value: "",
      countryFiscalResidence2Name: messageConstants.LABEL_SELECT_COUNTRY,
      countriesList: [
        { value: "", label: messageConstants.LABEL_SELECT_COUNTRY },
      ],
      maritialStatus: "s",
      spouseName: "",
      nationalityOption1: "",
      nationalityOption2: "",
      nationalityOption3: "",
      nationalityOption4: "",
      displayMoreNationality: false,
      displayMoreCountryOfCitizenship: false,
      buttonLoading: false,
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleMaritialStatusChange =
      this.handleMaritialStatusChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.displayMoreToggle = this.displayMoreToggle.bind(this);
    this.submitIndividualPersonalDetails =
      this.submitIndividualPersonalDetails.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_PERSONAL_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    let authenticationToken = this.props.authToken;
    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "fullName",
          "previousName",
          "otherName",
          "gender",
          "dob",
          "placeOfBirth",
          "maritialStatus",
          "spouseName",
          "nationalityOption1",
          "nationalityOption2",
          "nationalityOption3",
          "nationalityOption4",
        ];

        let setStateCountryVariables = [
          "birthCountry",
          "citizenshipCountry1",
          "citizenshipCountry2",
          "citizenshipCountry3",
          "citizenshipCountry4",
          "countryLegalDomicile1",
          "countryLegalDomicile2",
          "countryFiscalResidence1",
          "countryFiscalResidence2",
        ];

        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (key === "dob") {
                value = moment(value);
              }
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });

          if (
            (!_.isUndefined(idnetificationData.nationalityOption3) &&
              idnetificationData.nationalityOption3 !== "") ||
            (!_.isUndefined(idnetificationData.nationalityOption4) &&
              idnetificationData.nationalityOption4 !== "")
          ) {
            stateObj["displayMoreNationality"] = true;
          }

          if (
            (!_.isUndefined(idnetificationData.citizenshipCountry3) &&
              idnetificationData.citizenshipCountry3 !== "") ||
            (!_.isUndefined(idnetificationData.citizenshipCountry4) &&
              idnetificationData.citizenshipCountry4 !== "")
          ) {
            stateObj["displayMoreCountryOfCitizenship"] = true;
          }
        }
        this.setState(stateObj);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleGenderChange(event, value) {
    this.setState({
      gender: value,
    });
  }

  handleMaritialStatusChange(event, value) {
    this.setState({
      maritialStatus: value,
    });
  }

  handleDobChange(date) {
    this.setState({
      dob: date,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    // let countryId = fieldName + 'CountryId';
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    // stateobj[countryId] = selectedOption.value;
    this.setState(stateobj);
  }

  displayMoreToggle(stateName = "") {
    let newValue = !this.state[stateName];
    this.setState({
      [stateName]: newValue,
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "placeOfBirth"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async submitIndividualPersonalDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      fullName,
      previousName,
      otherName,
      gender,
      dob,
      placeOfBirth,
      birthCountryValue,
      maritialStatus,
      spouseName,
      nationalityOption1,
      nationalityOption2,
      nationalityOption3,
      nationalityOption4,
      citizenshipCountry1Value,
      citizenshipCountry2Value,
      citizenshipCountry3Value,
      citizenshipCountry4Value,
      countryLegalDomicile1Value,
      countryLegalDomicile2Value,
      countryFiscalResidence1Value,
      countryFiscalResidence2Value,
    } = this.state;

    if (
      fullName !== "" &&
      gender !== "" &&
      dob !== "" &&
      placeOfBirth !== "" &&
      (nationalityOption1 !== "" || nationalityOption2 !== "")
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/personal_details", {
          fullName: fullName,
          previousName: previousName,
          otherName: otherName,
          gender: gender,
          dob: dob,
          placeOfBirth: placeOfBirth,
          birthCountryValue: birthCountryValue,
          maritialStatus: maritialStatus,
          spouseName: spouseName,
          nationalityOption1: nationalityOption1,
          nationalityOption2: nationalityOption2,
          nationalityOption3: nationalityOption3,
          nationalityOption4: nationalityOption4,
          citizenshipCountry1Value: citizenshipCountry1Value,
          citizenshipCountry2Value: citizenshipCountry2Value,
          citizenshipCountry3Value: citizenshipCountry3Value,
          citizenshipCountry4Value: citizenshipCountry4Value,
          countryLegalDomicile1Value: countryLegalDomicile1Value,
          countryLegalDomicile2Value: countryLegalDomicile2Value,
          countryFiscalResidence1Value: countryFiscalResidence1Value,
          countryFiscalResidence2Value: countryFiscalResidence2Value,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/individual_identification/contact_details");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      fullName,
      previousName,
      otherName,
      gender,
      dob,
      placeOfBirth,
      countriesList,
      maritialStatus,
      spouseName,
      nationalityOption1,
      nationalityOption2,
      nationalityOption3,
      nationalityOption4,
      displayMoreNationality,
      displayMoreCountryOfCitizenship,
      birthCountryValue,
      birthCountryName,
      citizenshipCountry1Value,
      citizenshipCountry1Name,
      citizenshipCountry2Value,
      citizenshipCountry2Name,
      citizenshipCountry3Value,
      citizenshipCountry3Name,
      citizenshipCountry4Value,
      citizenshipCountry4Name,
      countryLegalDomicile1Value,
      countryLegalDomicile1Name,
      countryLegalDomicile2Name,
      countryLegalDomicile2Value,
      countryFiscalResidence1Name,
      countryFiscalResidence1Value,
      countryFiscalResidence2Name,
      countryFiscalResidence2Value,
      buttonLoading,
    } = this.state;

    let showNationalityMoreText =
      displayMoreNationality === false ? "Add" : "Hide";
    let showCitizenshipMoreText =
      displayMoreCountryOfCitizenship === false ? "Add" : "Hide";

    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h2 className="color-white text-center identification-header">
            Client Identification Form Individual Client / Beneficial Owner
          </h2>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {" "}
                            Full Name<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={fullName}
                            onChange={this.onchange}
                            placeholder="As on passport"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Previous Name </label>
                          <input
                            className="form-control"
                            type="text"
                            name="previousName"
                            id="previousName"
                            value={previousName}
                            onChange={this.onchange}
                            placeholder="Previous Name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Other Name </label>
                          <input
                            className="form-control"
                            type="text"
                            name="otherName"
                            id="otherName"
                            value={otherName}
                            onChange={this.onchange}
                            placeholder="Alias"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <div className="gender-container">
                            <RadioGroup
                              name="gender"
                              value={gender}
                              onChange={this.handleGenderChange}
                            >
                              <Radio
                                value="m"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Male"
                                name="gender"
                              />
                              <Radio
                                value="f"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Female"
                                name="gender"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group dob-container">
                          <label>
                            Date Of Birth
                            <span className="required-sign">*</span>
                          </label>
                          <Datetime
                            dateFormat="DD-MM-YYYY"
                            onChange={this.handleDobChange}
                            timeFormat={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: "dd-mm-yyyy" }}
                            utc={true}
                            value={dob}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {" "}
                            Place of birth
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="placeOfBirth"
                            id="placeOfBirth"
                            value={placeOfBirth}
                            onChange={this.onchange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Birth Country </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="birthCountryId"
                            id="country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "birthCountry"
                              )
                            }
                            value={{
                              label: birthCountryName,
                              value: birthCountryValue,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Marital status</label>
                          <div className="gender-container">
                            <RadioGroup
                              name="maritialStatus"
                              value={maritialStatus}
                              onChange={this.handleMaritialStatusChange}
                            >
                              <Radio
                                value="s"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Single"
                                name="maritialStatus"
                              />
                              <Radio
                                value="m"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Married"
                                name="maritialStatus"
                              />
                              <Radio
                                value="d"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Divorced"
                                name="maritialStatus"
                              />
                              <Radio
                                value="w"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Widower"
                                name="maritialStatus"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {maritialStatus === "m" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label> Spouse Name </label>
                            <input
                              className="form-control"
                              type="text"
                              name="spouseName"
                              id="spouseName"
                              value={spouseName}
                              onChange={this.onchange}
                              placeholder="Spouse Name"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          {" "}
                          Nationality<span className="required-sign">
                            *
                          </span>{" "}
                          <a
                            href="Javascript:void(0)"
                            className="add-more-nationality color-white pl-2 text-underline"
                            onClick={() =>
                              this.displayMoreToggle("displayMoreNationality")
                            }
                          >
                            {showNationalityMoreText} More
                          </a>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="nationalityOption1"
                            id="nationalityOption1"
                            value={nationalityOption1}
                            onChange={this.onchange}
                            placeholder="Nationality"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="nationalityOption2"
                            id="nationalityOption2"
                            value={nationalityOption2}
                            onChange={this.onchange}
                            placeholder="Nationality"
                          />
                        </div>
                      </div>
                    </div>

                    {displayMoreNationality === true && (
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="nationalityOption3"
                              id="nationalityOption3"
                              value={nationalityOption3}
                              onChange={this.onchange}
                              placeholder="Nationality"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="nationalityOption4"
                              id="nationalityOption4"
                              value={nationalityOption4}
                              onChange={this.onchange}
                              placeholder="Nationality"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Country of Citizenship/Residency{" "}
                          <a
                            href="Javascript:void(0)"
                            className="add-more-nationality color-white pl-2 text-underline"
                            onClick={() =>
                              this.displayMoreToggle(
                                "displayMoreCountryOfCitizenship"
                              )
                            }
                          >
                            {showCitizenshipMoreText} More
                          </a>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="citizenshipCountry1"
                            id="citizenshipCountry1"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "citizenshipCountry1"
                              )
                            }
                            value={{
                              label: citizenshipCountry1Name,
                              value: citizenshipCountry1Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="citizenshipCountry2"
                            id="citizenshipCountry2"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "citizenshipCountry2"
                              )
                            }
                            value={{
                              label: citizenshipCountry2Name,
                              value: citizenshipCountry2Value,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {displayMoreCountryOfCitizenship === true && (
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <Select
                              className="country-select2"
                              classNamePrefix="cntry"
                              options={countriesList}
                              placeholder="Select Country"
                              name="citizenshipCountry3"
                              id="citizenshipCountry3"
                              onChange={(selectedOption) =>
                                this.handleCountryChange(
                                  selectedOption,
                                  "citizenshipCountry3"
                                )
                              }
                              value={{
                                label: citizenshipCountry3Name,
                                value: citizenshipCountry3Value,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Select
                              className="country-select2"
                              classNamePrefix="cntry"
                              options={countriesList}
                              placeholder="Select Country"
                              name="citizenshipCountry4"
                              id="citizenshipCountry4"
                              onChange={(selectedOption) =>
                                this.handleCountryChange(
                                  selectedOption,
                                  "citizenshipCountry4"
                                )
                              }
                              value={{
                                label: citizenshipCountry4Name,
                                value: citizenshipCountry4Value,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Country of legal domicile (Only if different from
                          above)
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="countryLegalDomicile1"
                            id="countryLegalDomicile1"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "countryLegalDomicile1"
                              )
                            }
                            value={{
                              label: countryLegalDomicile1Name,
                              value: countryLegalDomicile1Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="countryLegalDomicile2"
                            id="countryLegalDomicile2"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "countryLegalDomicile2"
                              )
                            }
                            value={{
                              label: countryLegalDomicile2Name,
                              value: countryLegalDomicile2Value,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Country of fiscal residence (Only if different from
                          above)
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="countryFiscalResidence1"
                            id="countryFiscalResidence1"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "countryFiscalResidence1"
                              )
                            }
                            value={{
                              label: countryFiscalResidence1Name,
                              value: countryFiscalResidence1Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="countryFiscalResidence2"
                            id="countryFiscalResidence2"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "countryFiscalResidence2"
                              )
                            }
                            value={{
                              label: countryFiscalResidence2Name,
                              value: countryFiscalResidence2Value,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link to="/my_account" className="color-white">
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Cancel
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.submitIndividualPersonalDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationPersonalDetails;
