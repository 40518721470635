/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
} from "../../utils/Util";
import * as am4core from "@amcharts/amcharts4/core";
import axios from "axios";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as messageConstants from "../../utils/Messages";
import moment from "moment";
import { fetchTokenDetails } from "../../utils/Util";
import { API_ROOT } from "../../services/api-config";

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myXPRBalance: "",
      myXPRUsdValue: "",
      myTransactions: "",
      totalTransaction: "",
      sizePerPage: 10,
      page: 1,
      transactionLoading: true,
      popoverOpen: false,
      netPresentValueInformation: "Company's net present value in USD",
      tokenSymbol: this.props.activeTokenName || "",
      netPresentValue: "",
      tokensUnderEmd: false,
      emdSelected: false,
      dummyTransactions: [
        {
          txnId: "No transactions found",
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    const { activeTokenName } = this.props;
    if (prevProps.activeTokenName !== activeTokenName) {
      this.setActiveToken(activeTokenName);
    }
  }

  setActiveToken(tokenSymbol = "") {
    if (tokenSymbol) {
      this.setState({ tokenSymbol }, () => {
        this.getTokenDetails();
      });
    }
  }

  prepareGraphData() {
    const { tokenSymbol, tokensUnderEmd } = this.state;
    let { listedTokenForecastDetails } = this.state;
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    if (!tokensUnderEmd) {
      listedTokenForecastDetails = {};
    }
    if (listedTokenForecastDetails) {
      const chartDataArray = [];
      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit0Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary0Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 0`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit0Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary0Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary0Value,
        });
      }
      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit1Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary1Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 1`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit1Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary1Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary1Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit2Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary2Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 2`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit2Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary2Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary2Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit3Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary3Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 3`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit3Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary3Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary3Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit4Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary4Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 4`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit4Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary4Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary4Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit5Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary5Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 5`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit5Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary5Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary5Value,
        });
      }

      chart.data = chartDataArray;

      let categoryAxis1 = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis1.dataFields.category = "year";
      categoryAxis1.renderer.minGridDistance = 10;

      var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = `${listedTokenForecastDetails.yAxisMainTitle}`;

      var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.title.text = listedTokenForecastDetails.tokenPriceYaxisTitle;
      valueAxis2.renderer.opposite = true;
      valueAxis2.renderer.grid.template.disabled = true;

      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = "oilReserves";
      series1.dataFields.categoryX = "year";
      series1.yAxis = valueAxis1;
      series1.name = listedTokenForecastDetails.primaryUnitTitle;

      series1.tooltipText = "{name}\n[bold font-size: 14]{valueY}[/]";
      series1.tooltip.label.fontSize = "12";
      series1.fill = chart.colors.getIndex(0);
      series1.strokeWidth = 0;
      series1.clustered = false;
      series1.columns.template.width = am4core.percent(12);

      var series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "energyReserve";
      series2.dataFields.categoryX = "year";
      series2.yAxis = valueAxis1;
      series2.name = listedTokenForecastDetails.secondaryUnitTitle;

      series2.tooltipText = "{name}\n[bold font-size: 14]{valueY}";
      series2.tooltip.label.fontSize = "12";
      series2.fill = chart.colors.getIndex(0).lighten(0.5);
      series2.columns.template.width = am4core.percent(24);

      series2.strokeWidth = 0;
      series2.clustered = false;
      series2.toBack();

      var series3 = chart.series.push(new am4charts.LineSeries());
      series3.id = "g3";
      series3.dataFields.valueY = "tokenPrice";
      series3.dataFields.categoryX = "year";
      series3.name =
        listedTokenForecastDetails.priceUnitTitle || `${tokenSymbol} Price`;
      series3.strokeWidth = 2;
      series3.tensionX = 0.7;
      series3.yAxis = valueAxis2;
      series3.tooltipText = "{name}\n[bold font-size: 14]{valueY}[/]";
      series3.tooltip.label.fontSize = "12";

      var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
      bullet3.circle.radius = 3;
      bullet3.circle.strokeWidth = 2;
      bullet3.circle.fill = am4core.color("#fff");
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.fullWidthLineX = true;
      chart.cursor.xAxis = categoryAxis1;
      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fill = am4core.color("#000");
      chart.cursor.lineX.fillOpacity = 0.1;
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      this.chart = chart;
    }
  }

  async componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { activeTokenName } = this.props;
    if (!activeTokenName) {
      this.setActiveToken(activeTokenName);
      await this.props.getListedTokens();
    }

    await this.getTokenDetails();
    await this.setXprUnitPrice();
    document.title =
      messageConstants.DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    await this.props.checkEmdSelected();
    this.setState({
      emdSelected: await this.props.checkEmdSelected(),
    });
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
  }

  async getTokenDetails() {
    const { tokenSymbol } = this.state;
    if (tokenSymbol) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      let stateObj = {};
      const response = await api
        .setToken(authenticationToken)
        .get("user/listed/tokens", { tokenSymbol });
      if (response) {
        const responseData = response.data["tokens"]
          ? response.data["tokens"][0]
          : "";

        if (responseData) {
          stateObj.tokensUnderEmd = true;
          const tokenPrice = parseFloat(responseData.listedTokenPrice || 0);
          let listedTokenFloating = responseData.listedTokenFloating;
          listedTokenFloating = niceNumberDecimalDisplay(
            listedTokenFloating,
            0
          );
          let authorizedToken = responseData.listedTokenAuthorized;
          authorizedToken = parseFloat(authorizedToken);
          let authorizedTokenValue = parseFloat(authorizedToken);
          authorizedTokenValue = authorizedTokenValue * tokenPrice;
          authorizedTokenValue = niceNumberDecimalDisplay(
            authorizedTokenValue,
            2
          );
          let floatingToken = responseData.listedTokenFloating;
          floatingToken = parseFloat(floatingToken);
          let floatingTokenValue = parseFloat(floatingToken);
          floatingTokenValue = floatingTokenValue * tokenPrice;
          floatingTokenValue = niceNumberDecimalDisplay(floatingTokenValue, 2);
          let unissuedToken = responseData.listedTokenUnIssued;
          unissuedToken = parseFloat(unissuedToken);
          let unissuedTokenValue = parseFloat(unissuedToken);
          unissuedTokenValue = unissuedTokenValue * tokenPrice;
          unissuedTokenValue = niceNumberDecimalDisplay(unissuedTokenValue, 2);
          let outstandingToken = responseData.listedTokenOutstanding;
          outstandingToken = parseFloat(outstandingToken);
          const listedTokenIssuedFromContract = await fetchTokenDetails(
            responseData.listedTokenContractAddress
          );
          let listedTokenIssued = listedTokenIssuedFromContract && listedTokenIssuedFromContract > 0
            ? listedTokenIssuedFromContract
            : responseData.listedTokenIssued;
          listedTokenIssued = parseFloat(listedTokenIssued) || 0;
          let listedTokenIssuedValue = listedTokenIssued * tokenPrice;
          let outstandingTokenValue = parseFloat(outstandingToken);
          outstandingTokenValue = outstandingTokenValue * tokenPrice;
          outstandingTokenValue = niceNumberDecimalDisplay(
            outstandingTokenValue,
            2
          );
          authorizedToken = niceNumberDecimalDisplay(authorizedToken, 0);
          floatingToken = niceNumberDecimalDisplay(floatingToken, 0);
          unissuedToken = niceNumberDecimalDisplay(unissuedToken, 0);
          outstandingToken = niceNumberDecimalDisplay(outstandingToken, 0);
          let listedTokenTotalOfferings =
            responseData.listedTokenTotalOfferings;
          listedTokenTotalOfferings = parseFloat(
            listedTokenTotalOfferings || 0
          );
          let listedTokenTotalOfferingsValue =
            parseFloat(listedTokenTotalOfferings) * tokenPrice;
          listedTokenTotalOfferings = niceNumberDecimalDisplay(
            listedTokenTotalOfferings,
            2
          );
          listedTokenTotalOfferingsValue = niceNumberDecimalDisplay(
            listedTokenTotalOfferingsValue,
            2
          );
          let listedTokenOutstandingTokens =
            responseData.listedTokenOutstandingTokens;
          listedTokenOutstandingTokens = parseFloat(
            listedTokenOutstandingTokens || 0
          );
          let listedTokenOutstandingTokensValue =
            parseFloat(listedTokenOutstandingTokens) * tokenPrice;
          listedTokenOutstandingTokensValue = niceNumberDecimalDisplay(
            listedTokenOutstandingTokensValue,
            2
          );
          listedTokenOutstandingTokens = niceNumberDecimalDisplay(
            listedTokenOutstandingTokens,
            2
          );
          let enterpriceValueDetails =
            (listedTokenIssued +
              parseFloat(responseData.listedTokenOutstandingTokens)) *
            tokenPrice;

          enterpriceValueDetails = niceNumberDecimalDisplay(
            enterpriceValueDetails,
            2
          );

          listedTokenIssuedValue = niceNumberDecimalDisplay(
            listedTokenIssuedValue,
            2
          );

          const enterpriceValue = `$${enterpriceValueDetails}`;

          stateObj = {
            tokensUnderEmd: true,
            tokenPrice,
            listedTokenFloating,
            authorizedToken,
            authorizedTokenValue,
            floatingToken,
            floatingTokenValue,
            unissuedToken,
            unissuedTokenValue,
            outstandingToken,
            outstandingTokenValue,
            listedTokenForecastDetails: responseData.listedTokenForecastDetails,
            listedTokenTotalAmountRaised: niceNumberDecimalDisplay(
              responseData.listedTokenTotalAmountRaised,
              2
            ),
            listedTokenTotalOfferings,
            listedTokenTotalOfferingsValue,
            listedTokenIssued,
            listedTokenIssuedValue,
            listedTokenOutstandingTokens,
            listedTokenOutstandingTokensValue,
            enterpriceValue,
            netPresentValue:
              responseData && responseData.listedTokenNetPresentValue
                ? niceNumberDecimalDisplay(
                    responseData.listedTokenNetPresentValue,
                    0
                  )
                : "",
          };
        } else {
          stateObj.tokensUnderEmd = false;
        }
        this.setState(stateObj, () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
          this.prepareGraphData();
          if (stateObj.tokensUnderEmd) {
            this.getTokenBalanceDetails();
          }
        });
      }
    } else {
      const tempState = { tokensUnderEmd: false };
      this.setState(tempState, () => {
        this.prepareGraphData();
      });
    }
  }

  async getTokenBalanceDetails() {
    let stateObj = {};
    try {
      const { tokenPrice, tokenSymbol, dummyTransactions, page, sizePerPage } =
        this.state;
      if (tokenSymbol) {
        const api = new Api();
        let authenticationToken = this.props.authToken;
        const response = await api
          .setToken(authenticationToken)
          .get("user/token/balance", { tokenSymbol, page, sizePerPage });
        if (
          response.code === 200 &&
          response.data &&
          !!response.data.transactions
        ) {
          const responseData = response.data;
          let tokenUsdValue = responseData.tokenBalance
            ? parseFloat(responseData.tokenBalance) * parseFloat(tokenPrice)
            : 0;
          if (tokenUsdValue) {
            tokenUsdValue = niceNumberDecimalDisplay(tokenUsdValue, 2);
          }
          stateObj = {
            tokenBalance: responseData.tokenBalance
              ? niceNumberDecimalDisplay(responseData.tokenBalance, 2)
              : 0,
            myTransactions: !_.isEmpty(
              responseData.transactions.tokenTransactions
            )
              ? responseData.transactions.tokenTransactions
              : dummyTransactions,
            tokenUsdValue,
            totalTransaction: responseData.transactions.number,
            transactionLoading: false,
          };
          this.setState(stateObj);
        }
      }
    } catch (e) {
      this.setState({
        tokenBalance: 0,
        myTransactions: this.state.dummyTransactions,
        tokenUsdValue: 0,
        totalTransaction: 0,
        transactionLoading: false,
      });
      console.log(e);
    }
  }

  async setXprUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }

  render() {
    const {
      transactionLoading,
      netPresentValue,
      netPresentValueInformation,
      tokenSymbol,
      authorizedToken,
      authorizedTokenValue,
      unissuedToken,
      unissuedTokenValue,
      outstandingToken,
      outstandingTokenValue,
      floatingToken,
      floatingTokenValue,
      tokensUnderEmd,
      dummyTransactions,
      listedTokenAmountRaised,
      listedTokenTotalOfferings,
      listedTokenTotalOfferingsValue,
      listedTokenIssued,
      listedTokenIssuedValue,
      listedTokenOutstandingTokens,
      listedTokenOutstandingTokensValue,
      listedTokenTotalAmountRaised,
      tokenPrice,
      enterpriceValue,
      btcUnitUsdPrice,
    } = this.state;

    let {
      myTransactions,
      listedTokenForecastDetails,
      tokenUsdValue,
      tokenBalance,
      totalTransaction,
    } = this.state;
    if (!tokensUnderEmd) {
      myTransactions = dummyTransactions;
      tokenUsdValue = "0";
      tokenBalance = "0";
      totalTransaction = 0;
    }
    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="row">
                <div className="col-sm-6 mgtp-sm-3">
                  <div className="p-3 dark-blue-theme-color buy-xpr-header-block investor-emd-box">
                    <span>
                      Enterprise Value :
                      <span className="pl-2">{enterpriceValue || "0"}</span>{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 mgtp-sm-3">
                  <div className="p-3 dark-blue-theme-color buy-xpr-header-block investor-emd-box">
                    <span style={{ marginLeft: "10px" }}>
                      Amount Raised : {listedTokenTotalAmountRaised || "0"}
                    </span>
                  </div>
                </div>
              </div>

              <div className={`row mt-4  investor-emd-box`}>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className={`dark-blue-theme-color`}>
                    <div className="text-center dashboard-statistics-container card-padding">
                      <h5 className="dashboard-header">Offering</h5>
                      <div className="barrel-display">
                        {!tokensUnderEmd ? (
                          <div>0</div>
                        ) : (
                          <div className="color-black">
                            {`${listedTokenTotalOfferings} ${tokenSymbol}`}
                          </div>
                        )}
                      </div>
                      <div className="worth-highlighter">
                        {!tokensUnderEmd ? (
                          <div>0 USD</div>
                        ) : (
                          <div>{`$ ${listedTokenTotalOfferingsValue} USD`}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mgtp-sm-4">
                  <div className={`dark-blue-theme-color`}>
                    <div className="text-center dashboard-statistics-container card-padding">
                      <h5 className="dashboard-header">Issued</h5>
                      <div className="barrel-display">
                        {!tokensUnderEmd ? (
                          <div>0</div>
                        ) : (
                          <div className="color-black">
                            {listedTokenIssued} {tokenSymbol}
                          </div>
                        )}
                      </div>
                      <div className="worth-highlighter">
                        {!tokensUnderEmd ? (
                          <div>0 USD</div>
                        ) : (
                          <div>{`$ ${listedTokenIssuedValue} USD`}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mgtp-sm-4 mgtp-md-4">
                  <div className={`dark-blue-theme-color`}>
                    <div className="text-center dashboard-statistics-container card-padding">
                      <h5 className="dashboard-header">Outstanding</h5>
                      <div className="barrel-display">
                        {!tokensUnderEmd ? (
                          <div>0</div>
                        ) : (
                          <div className="color-black">
                            {`${listedTokenOutstandingTokens} ${tokenSymbol}`}
                          </div>
                        )}
                      </div>
                      <div className="worth-highlighter">
                        {!tokensUnderEmd ? (
                          <div>0 USD</div>
                        ) : (
                          <div>
                            {`$ ${listedTokenOutstandingTokensValue} USD`}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mgtp-sm-4 mgtp-md-4">
                  <div className={`dark-blue-theme-color`}>
                    <div className="text-center dashboard-statistics-container card-padding">
                      <h5 className="dashboard-header">Token Price</h5>
                      <div className="barrel-display">
                        {!tokensUnderEmd ? (
                          <div>0 {tokenSymbol}</div>
                        ) : (
                          <div>$ {tokenPrice.toFixed(2)}</div>
                        )}
                      </div>
                      <div className="worth-highlighter">
                        {!tokensUnderEmd ? (
                          <div>0 USD</div>
                        ) : (
                          <div>
                            {niceNumberDecimalDisplay(
                              tokenPrice / btcUnitUsdPrice,
                              8
                            )}{" "}
                            BTC
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className={`card-inner-padding dark-blue-theme-color`}>
                    <h6 style={{ fontSize: "20px" }}>My Investment</h6>
                    <hr className="bg-white" />
                    <div className="row">
                      <div className="col-md-3 user-statistics-container">
                        <div className="mt-2">
                          <div className="investment-card-title">
                            Total Investment
                          </div>
                          <h4 className="investment-card-value">
                            $ {tokenUsdValue}
                          </h4>
                        </div>
                        <div className="mt-4">
                          <div className="investment-card-title">
                            Total {tokenSymbol}
                          </div>
                          <h4 className="investment-card-value">
                            {tokenSymbol} {tokenBalance}
                          </h4>
                        </div>
                      </div>

                      <div className="col-md-9">
                        <div className="table-responsive table-striped pl-4">
                          <table className="table table-lightborder dashboard-my-investment-list">
                            <thead>
                              <tr>
                                <th className="text-left">TXN Id</th>
                                <th className="text-right">Transaction Date</th>
                                <th className="text-right">Amount Sent</th>
                                <th className="text-right">
                                  {tokenSymbol} Tokens
                                </th>
                                <th className="text-right">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(myTransactions) &&
                                myTransactions.map((row, index) => {
                                  let label = "Completed";
                                  let className = "badge-success";
                                  if (row.tokenSentStatus === false) {
                                    label = "Pending";
                                    className = "badge-warning";
                                  }
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div
                                          style={{
                                            width: "180px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {row.txnId}
                                          {row.txDetailURL === "" && (
                                            <span>{row.txHash || ""}</span>
                                          )}
                                        </div>
                                      </td>
                                      <td className="text-right">
                                        {row.CreatedOnUTC &&
                                          moment(row.CreatedOnUTC).format(
                                            "YYYY-MM-DD HH:mm"
                                          )}
                                      </td>
                                      <td className="text-right">
                                        <span
                                          className="custom-tooltip"
                                          tooltip-title={tooltipNumberDisplay(
                                            row.amountRecieve,
                                            row.transactionType
                                          )}
                                        >
                                          {row.amountRecieve &&
                                            `$ ${niceNumberDecimalDisplay(
                                              row.amountRecieve,
                                              2
                                            )}`}
                                        </span>
                                      </td>
                                      <td className="text-right">
                                        <span
                                          className="custom-tooltip"
                                          tooltip-title={tooltipNumberDisplay(
                                            row.coins,
                                            tokenSymbol
                                          )}
                                        >
                                          {row.coins &&
                                            niceNumberDecimalDisplay(
                                              row.coins,
                                              2
                                            )}
                                        </span>
                                      </td>
                                      <td className="text-right">
                                        {row.amountRecieve && (
                                          <span
                                            className={"badge " + className}
                                          >
                                            {label}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        {totalTransaction > 5 && (
                          <div className="pull-right pr-1">
                            <Link
                              to={{
                                pathname: "/my_investment",
                                state: tokenSymbol,
                              }}
                              className="color-white"
                            >
                              <button className="btn account-button pull-right">
                                View All
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {listedTokenForecastDetails && (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <div className={`card-inner-padding dark-blue-theme-color`}>
                      <h6 className="xpr-chart-header">
                        {tokenSymbol}{" "}
                        {listedTokenForecastDetails
                          ? listedTokenForecastDetails.forecastTitle
                          : "Forecast"}
                      </h6>
                      <hr className="bg-white" />
                      <div className="mt-2">
                        <div
                          id="chartdiv"
                          style={{ width: "100%", height: "500px" }}
                        ></div>
                        {listedTokenForecastDetails &&
                          listedTokenForecastDetails.disclaimer && (
                            <div className="disclaimer-text">
                              {listedTokenForecastDetails.disclaimer}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;
