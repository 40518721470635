import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";
import Select from "react-select";

class CorporateFatcaCrsIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyMailingAddressSame: "y",
      buttonLoading: false,
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveIdentificationDetails = this.saveIdentificationDetails.bind(this);
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  async componentWillMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.CORPORATE_FATCA_CRS_IDENTIFICATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    let authenticationToken = this.props.authToken;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/company/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "coyInvestorName",
          "coyRegisteredBuildingName",
          "coyRegisteredStreet",
          "coyRegisteredCity",
          "coyRegisteredState",
          "coyRegisteredPostalCode",
          "coyMailingAddressSame",
          "coyMailingAddressBuildingName",
          "coyMailingAddressStreet",
          "coyMailingAddressCity",
          "coyMailingAddressState",
          "coyMailingAddesssPostalCode",
        ];
        let setStateCountryVariables = [
          "coyIncorporationCountry",
          "coyRegisteredCountry",
          "coyMailingAddressCountry",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  async saveIdentificationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyInvestorName,
      coyIncorporationCountryValue,
      coyRegisteredBuildingName,
      coyRegisteredStreet,
      coyRegisteredCity,
      coyRegisteredState,
      coyRegisteredCountryValue,
      coyRegisteredPostalCode,
      coyMailingAddressSame,
      coyMailingAddressBuildingName,
      coyMailingAddressStreet,
      coyMailingAddressCity,
      coyMailingAddressState,
      coyMailingAddressCountryValue,
      coyMailingAddesssPostalCode,
    } = this.state;

    if (
      coyInvestorName !== "" &&
      coyIncorporationCountryValue !== "" &&
      coyRegisteredBuildingName !== "" &&
      coyRegisteredStreet !== "" &&
      coyRegisteredState !== "" &&
      coyRegisteredCountryValue !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/company_fatca_crs/save/identification_details", {
          coyInvestorName: coyInvestorName,
          coyIncorporationCountryValue: coyIncorporationCountryValue,
          coyRegisteredBuildingName: coyRegisteredBuildingName,
          coyRegisteredStreet: coyRegisteredStreet,
          coyRegisteredCity: coyRegisteredCity,
          coyRegisteredState: coyRegisteredState,
          coyRegisteredCountryValue: coyRegisteredCountryValue,
          coyRegisteredPostalCode: coyRegisteredPostalCode,
          coyMailingAddressSame: coyMailingAddressSame,
          coyMailingAddressBuildingName: coyMailingAddressBuildingName,
          coyMailingAddressStreet: coyMailingAddressStreet,
          coyMailingAddressCity: coyMailingAddressCity,
          coyMailingAddressState: coyMailingAddressState,
          coyMailingAddressCountryValue: coyMailingAddressCountryValue,
          coyMailingAddesssPostalCode: coyMailingAddesssPostalCode,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/corporate_fatca_crs/declaration");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    let {
      countriesList,
      coyMailingAddressSame,
      coyIncorporationCountryName,
      coyIncorporationCountryValue,
      coyRegisteredCountryName,
      coyRegisteredCountryValue,
      coyMailingAddressCountryValue,
      coyMailingAddressCountryName,
      buttonLoading,
      coyInvestorName,
      coyRegisteredBuildingName,
      coyRegisteredStreet,
      coyRegisteredCity,
      coyRegisteredState,
      coyRegisteredPostalCode,
      coyMailingAddressBuildingName,
      coyMailingAddressStreet,
      coyMailingAddressCity,
      coyMailingAddressState,
      coyMailingAddesssPostalCode,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h4 className="text-center color-white identification-header">Section 1:  Subscriber Identification</h4> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Subscriber / Investor Name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Investor Name"
                            name="coyInvestorName"
                            onChange={this.onChange}
                            value={coyInvestorName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Country of Incorporation or Organization
                            <span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyIncorporationCountry"
                              )
                            }
                            value={{
                              label: coyIncorporationCountryName,
                              value: coyIncorporationCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-xl text-default-color">
                          Current registered address
                        </h6>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Building name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="permanentAddressBuildingName"
                            placeholder="Building name"
                            name="coyRegisteredBuildingName"
                            onChange={this.onChange}
                            value={coyRegisteredBuildingName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Street<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyRegisteredStreet"
                            id="coyRegisteredStreet"
                            placeholder="Street"
                            onChange={this.onChange}
                            value={coyRegisteredStreet}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            City / Town
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Street"
                            name="coyRegisteredCity"
                            onChange={this.onChange}
                            value={coyRegisteredCity}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            State<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="coyRegisteredState"
                            placeholder="Place"
                            name="coyRegisteredState"
                            onChange={this.onChange}
                            value={coyRegisteredState}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Country<span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyRegisteredCountry"
                              )
                            }
                            value={{
                              label: coyRegisteredCountryName,
                              value: coyRegisteredCountryValue,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Postal Code<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Place"
                            name="coyRegisteredPostalCode"
                            onChange={this.onChange}
                            value={coyRegisteredPostalCode}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-large text-default-color">
                          Mailing address
                        </h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label className="text-default text-default-color">
                          {" "}
                          Same as registered address?{" "}
                        </label>

                        <RadioGroup
                          className="understanding-split-radio-container"
                          value={coyMailingAddressSame}
                          onChange={(e, value) =>
                            this.handleRadioChange(
                              e,
                              value,
                              "coyMailingAddressSame"
                            )
                          }
                        >
                          <Radio
                            value="y"
                            radioClass="iradio_square-blue pr-2"
                            increaseArea="20%"
                            label=" Yes"
                          />
                          <Radio
                            value="n"
                            radioClass="iradio_square-blue pr-2"
                            increaseArea="20%"
                            label=" No"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    {coyMailingAddressSame === "n" && (
                      <div>
                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default text-default-color">
                                Building name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Building name"
                                name="coyMailingAddressBuildingName"
                                onChange={this.onChange}
                                value={coyMailingAddressBuildingName}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default text-default-color">
                                Street
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Street"
                                name="coyMailingAddressStreet"
                                onChange={this.onChange}
                                value={coyMailingAddressStreet}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default text-default-color">
                                City / Town
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Street"
                                name="coyMailingAddressCity"
                                onChange={this.onChange}
                                value={coyMailingAddressCity}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default text-default-color">
                                State
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                id="permanentAddressPlace"
                                placeholder="Place"
                                name="coyMailingAddressState"
                                onChange={this.onChange}
                                value={coyMailingAddressState}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default text-default-color">
                                Country
                              </label>
                              <Select
                                className="country-select2"
                                classNamePrefix="cntry"
                                options={countriesList}
                                placeholder="Select Country"
                                onChange={(selectedOption) =>
                                  this.handleCountryChange(
                                    selectedOption,
                                    "coyMailingAddressCountry"
                                  )
                                }
                                value={{
                                  label: coyMailingAddressCountryName,
                                  value: coyMailingAddressCountryValue,
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default text-default-color">
                                Postal Code
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Place"
                                name="coyMailingAddesssPostalCode"
                                onChange={this.onChange}
                                value={coyMailingAddesssPostalCode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/informations"
                className="color-white text-underline"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-3"
                type="button"
                onClick={this.saveIdentificationDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaCrsIdentification;
