// let recaptchaSiteKey = '6LdTt5IUAAAAAHr8LILfR5h6e6PDmsqfxYHHed4K';
// let googleMapKeyValue = 'AIzaSyC7DsgoUfC1dO2Heqyl5X36-ARrlYk5i3U';
let tokenReceivingAddressValue = "0x8345a32fed4ddf798386a7C80C921c92cAC88259";
let contractAddressValue = "0xC02f22EF822EBAC7A74122Aa45EE9f1899E46Fe5";
let contractAbiUrlValue = "https://api-rinkeby.etherscan.io/api";
let web3NetworkValue = 4;
let txnLinkValue = "https://rinkeby.etherscan.io/tx/";
let btcExplorerLinkValue = "https://www.blockchain.com/btc/tx";

let backendHost = "http://localhost:3000";
// let backendHost = "https://api.permianchain.com";
let recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
// let recaptchaSiteKey = '6LetRlwUAAAAABwsXjJSlJIZftu0uVVPcICfp7e2'
let googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";

if (process.env.REACT_APP_NODE_ENV === "production") {
  backendHost = "https://masterapi.permianchain.com";
  recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
  tokenReceivingAddressValue = "";
  contractAddressValue = "0x271a01dc2e37fdc4cd7e9d1548bd61c6ab3e90e0";
  contractAbiUrlValue = "https://api.etherscan.io/api";
  web3NetworkValue = 1;
  txnLinkValue = "https://etherscan.io/tx/";
  googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
} else if (process.env.REACT_APP_NODE_ENV === "test") {
  backendHost = "https://api.permianchain.com";
  recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
  tokenReceivingAddressValue = "0x8345a32fed4ddf798386a7C80C921c92cAC88259";
  contractAddressValue = "0xC02f22EF822EBAC7A74122Aa45EE9f1899E46Fe5";
  contractAbiUrlValue = "https://api-rinkeby.etherscan.io/api";
  web3NetworkValue = 4;
  googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
}

export const API_ROOT = `${backendHost}`;
export const RECAPTCHASITEKEY = `${recaptchaSiteKey}`;
export const tokenReceivingAddress = tokenReceivingAddressValue;
export const tokenSymbol = "NRT";
export const contractAddress = contractAddressValue;
export const contractAbiUrl = contractAbiUrlValue;
export const web3Network = web3NetworkValue;
export const txnLink = txnLinkValue;
export const btcExplorerLink = btcExplorerLinkValue;
export const GOOGLE_MAP_KEY = googleMapKeyValue;
export const CurrencyExchangeApiUrl =
  "https://free.currconv.com/api/v7/convert";
export const CurrencyExchangeApiKey = "3a7b6ad853b2e0d441d8";
