import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import * as messageConstants from "../../utils/Messages";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

class KycUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentTypeId: "",
      kycFile: "",
      selfieWithKycFile: "",
      buttonLoading: false,
      apiError: "",
      userRole: "",
      recaptchaResponse: "",
      kycDocumentNumber: "",
      displayNote: false,
      kycFiles: "",
      objectIdRef: "",
      filesRequiredAr: [],
      allowKycSubmit: false,
      bigFileAr: [],
    };
    this.uploadInput = [];
    this.uploadImageArray = {};
    this.uploadMultipleImageArray = {};
    this.userRequiredUploadFileCounter = 0;
    this.userUploadedFileCounter = 0;
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_UPLOAD_FILES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    var body = document.body;
    body.classList.remove("full-screen");
    body.classList.remove("with-content-panel");
    body.classList.remove("user-dashboard-panel");
    try {
      const api = new Api();
      let _this = this;
      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", {
          emdId,
          getKycPhoto: true,
          getKycDocumentTypes: true,
        });
      if (userResponse.code === 200 && userResponse.data) {
        this.setState({ userRole: userResponse.data.role });
        if (
          userResponse.data.kycStatus === "u" ||
          userResponse.data.kycStatus === "a"
        ) {
          let redirectUrl =
            userResponse.data.kycStatus === "u"
              ? `/kyc_status/pending/${emdId}`
              : `/kyc_status/approved/${emdId}`;
          this.props.history.push(redirectUrl);
        } else {
          let stateObj = {};
          let userKycImages = userResponse.data.userKycDetailPhotos;

          if (userResponse.data.termsOfEngagementDataAdded) {
            stateObj["termsOfEngagementDataAdded"] =
              userResponse.data.termsOfEngagementDataAdded;
          }

          if (!_.isEmpty(userKycImages)) {
            _.forEach(userKycImages, function (value, key) {
              let keyName = "display" + key + "Image";
              let textKey = "kyc" + key + "TextDisplay";
              stateObj[keyName] = value;
              stateObj[textKey] = false;
            });
          }

          if (
            !_.isUndefined(userResponse.data.displayNote) &&
            userResponse.data.displayNote === true
          ) {
            stateObj.displayNote = true;
          }

          if (
            !_.isUndefined(userResponse.data.kycDocumentTypeList) &&
            !_.isUndefined(userResponse.data.kycDocumentNumber)
          ) {
            stateObj.kycDocumentTypeList =
              userResponse.data.kycDocumentTypeList;
            stateObj.kycDocumentNumber = userResponse.data.kycDocumentNumber;
            stateObj.kycFiles = userResponse.data.kycFiles;
            stateObj.objectIdRef = userResponse.data.objectIdRef;
          }

          if (!_.isEmpty(userResponse.data.kycDocumentTypeList)) {
            _.forEach(
              userResponse.data.kycDocumentTypeList,
              function (docValue, docKey) {
                if (!_.isEmpty(docValue.children)) {
                  if (
                    _this.state.userRole === "ci" &&
                    docValue.children.length > 1
                  ) {
                    _.forEach(
                      docValue.children,
                      function (childValue, childKey) {
                        if (childValue.required === true) {
                          _this.userRequiredUploadFileCounter += 1;
                        }
                      }
                    );
                  }
                  if (_this.state.userRole === "i") {
                    _.forEach(
                      docValue.children,
                      function (childValue, childKey) {
                        if (childValue.required === true) {
                          _this.userRequiredUploadFileCounter += 1;
                        }
                      }
                    );
                  }
                }
              }
            );
          }

          if (!_.isEmpty(userResponse.data.objectIdRef)) {
            let keyFiles = userResponse.data.kycFiles;
            _.forEach(userResponse.data.objectIdRef, function (value, key) {
              stateObj[key + "StateFiles"] = [];
              if (!_.isEmpty(value)) {
                _.forEach(value, function (value1, key1) {
                  let temp = {};
                  temp.source = keyFiles[value1];
                  temp.options = { type: "local", load: false };
                  stateObj[key + "StateFiles"].push(temp);
                });
              }
            });
            stateObj["allowKycSubmit"] = true;
            this.userUploadedFileCounter = _.size(
              userResponse.data.objectIdRef
            );
          }
          if (!_.isUndefined(stateObj)) {
            this.setState(stateObj);
          }
        }
      } else {
        toast.error(userResponse.message);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }

    window.onbeforeunload = function () {
      this.onUnload();
      return "";
    }.bind(this);
  }

  onUnload = (event) => {
    alert("page Refreshed");
  };

  saveKycDocuments = (event) => {
    const { allowKycSubmit } = this.state;
    let _this = this;
    let uploadedFileSize =
      _.size(this.uploadImageArray) + _.size(this.uploadMultipleImageArray);
    if (uploadedFileSize === 0) {
      this.props.history.push("/kyc/suitability/analysis");
    } else if (
      uploadedFileSize > 0 &&
      uploadedFileSize < 5000000 &&
      (uploadedFileSize >= _this.userRequiredUploadFileCounter ||
        _this.userRequiredUploadFileCounter <= _this.userUploadedFileCounter)
    ) {
      this.setState({
        buttonLoading: true,
      });
      const data = new FormData();
      const { emdId } = this.props.match.params;
      data.append("emdId", emdId);
      if (!_.isUndefined(this.uploadImageArray)) {
        _.forEach(this.uploadImageArray, function (imageValue) {
          data.append(imageValue.name, imageValue.file);
          data.append(
            imageValue.name + "_kycDocumentId",
            imageValue.documentTypeId
          );
        });
      }

      if (!_.isUndefined(this.uploadMultipleImageArray)) {
        _.forEach(this.uploadMultipleImageArray, function (imageValue) {
          if (!_.isEmpty(imageValue.files)) {
            _.forEach(imageValue.files, function (image) {
              let size = image.file.size;
              let type = image.file.type;
              let lastModified = image.file.lastModified;
              if (
                size > 0 &&
                !_.isUndefined(type) &&
                !_.isUndefined(lastModified) &&
                lastModified !== "" &&
                lastModified !== null
              ) {
                data.append(imageValue.name + "[]", image.file);
              } else {
                data.append(
                  imageValue.name + "_existed_files[]",
                  image.file.name
                );
              }
            });
            data.append(
              imageValue.name + "_kycDocumentId",
              imageValue.documentTypeId
            );
          }
        });
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: this.props.authToken,
        },
      };
      axios
        .post(API_ROOT + "/user/emd/saveKycDocuments", data, config)
        .then(function (response) {
          if (!_.isUndefined(response)) {
            _this.setState({
              buttonLoading: false,
            });
            if (response.data.code === 200) {
              toast.success(response.data.message);
              _this.props.history.push(`/kyc/suitability/analysis/${emdId}`);
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch(function (error) {
          _this.setState({
            buttonLoading: false,
          });
          toast.error(messageConstants.ERROR_MSG_VALID_FILES);
        });
    } else {
      if (
        allowKycSubmit &&
        _this.userUploadedFileCounter >= _this.userRequiredUploadFileCounter
      ) {
        this.props.history.push("/kyc_submit");
      } else {
        let message = messageConstants.ERROR_MSG_REQUIRED_FILES;
        _this.setState({
          buttonLoading: false,
        });
        toast.error(message);
      }
    }
  };

  fileUpdated = (
    isMultiple = false,
    documentTypeName,
    documentTypeId,
    fileItems,
    documentRequired
  ) => {
    if (!_.isEmpty(fileItems)) {
      let { allowKycSubmit } = this.state;
      if (isMultiple === true) {
        var temp = {};
        temp.documentTypeId = documentTypeId;
        temp.files = fileItems;
        temp.name = documentTypeName;
        this.uploadMultipleImageArray[documentTypeName] = temp;
        if (allowKycSubmit) {
          this.userUploadedFileCounter += 1;
        }
      } else {
        let size = fileItems[0].file.size;
        let type = fileItems[0].file.type;
        let name = fileItems[0].file.name;
        let lastModified = fileItems[0].file.lastModified;
        if (
          size > 0 &&
          size < 5000000 &&
          !_.isUndefined(type) &&
          !_.isUndefined(lastModified) &&
          lastModified !== "" &&
          lastModified !== null
        ) {
          var uploadTemp = {};
          uploadTemp.documentTypeId = documentTypeId;
          uploadTemp.file = fileItems[0].file;
          uploadTemp.name = documentTypeName;
          this.uploadImageArray[documentTypeName] = uploadTemp;
          if (allowKycSubmit) {
            this.userUploadedFileCounter += 1;
          }
        }
      }
    }
  };

  fileRemoved = () => {
    let { allowKycSubmit } = this.state;
    if (allowKycSubmit) {
      this.userUploadedFileCounter -= 1;
    }
  };

  render() {
    const {
      kycDocumentTypeList,
      displayNote,
      userRole,
      termsOfEngagementDataAdded,
    } = this.state;
    let { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    let displayDocumentTypes = "";
    let _this = this;
    if (!_.isEmpty(kycDocumentTypeList)) {
      displayDocumentTypes = kycDocumentTypeList.map(
        (parentDocumentType, mainIndex) => (
          <div className="col-md-12 card-horizontal-padding" key={mainIndex}>
            <div className="mt-4">
              <h5
                className={
                  parentDocumentType.children.length > 1 ? "" : "d-none hide"
                }
              >
                {parentDocumentType.label}
              </h5>
              <div
                className={
                  parentDocumentType.children.length <= 1 && userRole === "ci"
                    ? "d-none hide"
                    : ""
                }
              >
                {parentDocumentType.children.map((documentType, index) => (
                  <div className="mt-4" key={index}>
                    <div className="custom-element-box">
                      <div className="element-box">
                        <h5 className="form-header">
                          {documentType.label}{" "}
                          <span
                            className={
                              "required-field " +
                              (documentType.required === true
                                ? ""
                                : "d-none hide")
                            }
                          >
                            *
                          </span>
                        </h5>
                        <div className="dropzoneIdContainer">
                          <div className="row">
                            <div className="col-md-7">
                              <FilePond
                                ref={(ref) =>
                                  (this.uploadInput[documentType._id] = ref)
                                }
                                files={
                                  this.state[documentType._id + "StateFiles"]
                                }
                                allowMultiple={documentType.multiple || false}
                                allowFileTypeValidation={true}
                                allowFileSizeValidation={true}
                                allowImagePreview={false}
                                maxFileSize="5MB"
                                credits={false}
                                acceptedFileTypes={[
                                  "image/*",
                                  "application/pdf",
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]}
                                instantUpload={false}
                                imagePreviewHeight={150}
                                server={{
                                  load: (
                                    source,
                                    load,
                                    error,
                                    progress,
                                    abort,
                                    headers
                                  ) => {
                                    load();
                                  },
                                }}
                                onremovefile={(file) => {
                                  _this.fileRemoved();
                                }}
                                onupdatefiles={(fileItems) => {
                                  if (
                                    !_.isEmpty(fileItems) &&
                                    fileItems[0].fileSize < 5000000
                                  ) {
                                    if (
                                      ["pdf", "docx"].includes(
                                        fileItems[0].fileExtension
                                      )
                                    ) {
                                      _this.fileUpdated(
                                        documentType.multiple,
                                        documentType.name,
                                        documentType._id,
                                        fileItems,
                                        documentType.required
                                      );
                                    } else if (
                                      [
                                        "jpeg",
                                        "jpg",
                                        "gif",
                                        "tiff",
                                        "psd",
                                        "eps",
                                        "ai",
                                        "indd",
                                        "raw",
                                        "png",
                                      ].includes(fileItems[0].fileExtension) &&
                                      fileItems[0].fileSize < 500000
                                    ) {
                                      _this.fileUpdated(
                                        documentType.multiple,
                                        documentType.name,
                                        documentType._id,
                                        fileItems,
                                        documentType.required
                                      );
                                    } else if (
                                      [
                                        "jpeg",
                                        "jpg",
                                        "gif",
                                        "tiff",
                                        "psd",
                                        "eps",
                                        "ai",
                                        "indd",
                                        "raw",
                                        "png",
                                      ].includes(fileItems[0].fileExtension) &&
                                      fileItems[0].fileSize > 500000
                                    ) {
                                      toast.error(
                                        "Please make sure image size is under 500kb"
                                      );
                                      fileItems[0].abortLoad();
                                    }
                                  } else if (
                                    !_.isEmpty(fileItems) &&
                                    fileItems[0].fileSize > 5000000
                                  ) {
                                    toast.error(
                                      "Please make sure document size is under 5mb"
                                    );
                                    fileItems[0].abortLoad();
                                  }
                                }}
                              />
                            </div>
                            <div className="col-md-5 mgtp-sm-3">
                              {documentType.name === "passport" && (
                                <ul className="text-small text-default-color">
                                  <li className="mt-1">
                                    Identification documents must be certified
                                    by your lawyer, notary public, chartered
                                    accountant or corporate secretary.
                                  </li>
                                  <li className="mt-1">
                                    Certified identification copies should be in
                                    good condition and clearly visible.
                                  </li>
                                  <li className="mt-1">
                                    Make sure that there is no light glare on
                                    the copies in case of image file.
                                  </li>
                                </ul>
                              )}
                              {documentType.name !== "passport" &&
                                documentType.name !== "address_proof" && (
                                  <ul className="text-small text-default-color">
                                    <li className="mt-1">
                                      {documentType.label} should be in good
                                      condition and clearly visible.
                                    </li>
                                    <li className="mt-1">
                                      Make sure that there is no light glare on
                                      the {documentType.label} in case of Image
                                      file.
                                    </li>
                                    <li className="mt-1">
                                      Source of wealth could be your last three
                                      months bank statements, last three months
                                      portfolio statements, salary certificate
                                      or evidence of salary
                                    </li>
                                  </ul>
                                )}

                              {documentType.name === "address_proof" && (
                                <ul className="text-small text-default-color">
                                  <li className="mt-1">
                                    {documentType.label} should be in good
                                    condition and clearly visible.
                                  </li>
                                  <li className="mt-1">
                                    Make sure that there is no light glare on
                                    the {documentType.label} in case of Image
                                    file.
                                  </li>
                                  <li className="mt-1">
                                    A utility bill should be no more than 3
                                    months old.
                                  </li>
                                </ul>
                              )}

                              {documentType.name !== "passport" &&
                                documentType.name !== "address_proof" &&
                                documentType.name !== "bank_proof" && (
                                  <ul className="text-small text-default-color">
                                    <li className="mt-1">
                                      {documentType.label} should be in good
                                      condition and clearly visible.
                                    </li>
                                    <li className="mt-1">
                                      Make sure that there is no light glare on
                                      the {documentType.label} in case of Image
                                      file.
                                    </li>

                                    {documentType.notehelpText && (
                                      <li className="mt-1">
                                        {documentType.notehelpText}
                                      </li>
                                    )}
                                  </ul>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      );
    }
    const { emdId } = this.props.match.params;
    return (
      <div className="kyc-container kyc-document-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row ml-1 mr-1">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <ul className="progressbar d-flex p-0 justify-content-center">
                  <li>Basic Details</li>
                  <li className="active">Upload</li>
                  <li>Suitability Analysis</li>
                  <li>Relationship Disclosure</li>
                  {termsOfEngagementDataAdded && <li>Terms Of Engagement</li>}
                </ul>
              </div>
              <div className="col-md-2"></div>
            </div>
            {displayNote && (
              <div className="mt-3 d-none hide">
                <h6>
                  Note : All documents requested need to be signed/stamped by a
                  registered lawyer, auditor. Documents must either be in the
                  English language or officially translated.
                </h6>
              </div>
            )}
            <div className="row">{displayDocumentTypes}</div>
            <div className="row card-inner-padding">
              <div className="col-md-12 text-right d-flex justify-content-end">
                <Link className="btn cancel-button" to="/dashboard">
                  Do it later
                </Link>
                <Link to={`/kyc/${emdId}`}>
                  <button className="btn update-button" type="button">
                    Previous
                  </button>
                </Link>
                <button
                  className="btn update-button ml-0"
                  type="submit"
                  onClick={this.saveKycDocuments}
                  disabled={buttonDisabled || this.state.disableButton}
                >
                  Next
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white ml-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default KycUpload;
