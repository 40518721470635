import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import auth from "../../utils/auth";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import progressIcon from "../../assets/img/review.svg";
class KycStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      note: "",
    };
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_STATUS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    // console.log(date);

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    try {
      const { emdId } = this.props.match.params;
      let authenticationToken = this.props.authToken;
      const api = new Api();
      const userResponse = await api
        .setToken(authenticationToken)
        .create("/emd/userKycDetails", {emdId});
      // console.log(userResponse.data)
      if (userResponse.code === 200) {
        if (userResponse.data.isKycVerified === true) {
          this.props.history.push(`/kyc_status/approved/${emdId}`);
        }
        if (userResponse.data.kycStatus === "r") {
          this.props.history.push(`/kyc_status/rejected/${emdId}`);
        }
        if (
          !_.isUndefined(userResponse.data.userKycDetail.noteHistory) &&
          !_.isEmpty(userResponse.data.userKycDetail.noteHistory) &&
          userResponse.data.userKycDetail.noteHistory !== "" &&
          userResponse.data.userKycDetail.noteHistory !== null
        ) {
          this.setState({
            note: userResponse.data.userKycDetail.noteHistory,
          });
        }
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  render() {
    const { note } = this.state;
    let dateArr = new Array();
    let kycStatus = this.props.match.params.status;
    let buttonDisplay = false;
    let icon =
      kycStatus === "approved"
        ? "fa fa-check"
        : kycStatus === "rejected"
        ? "fa fa-times bg-red"
        : "fa fa-tasks";
    let heading =
      kycStatus === "approved"
        ? "Verified"
        : kycStatus === "rejected"
        ? "Not Verified"
        : "Under Review";
    let description =
      kycStatus === "approved"
        ? messageConstants.KYC_DOCUMENTS_VERIFIED_SUCCESS
        : kycStatus === "rejected"
        ? messageConstants.KYC_DOCUMENTS_REJECTED
        : messageConstants.KYC_DOCUMENTS_UNDER_REVIEW;

    let buttonLabel = "";
    let redirectLink = "";
    if (kycStatus === "approved" || kycStatus === "rejected") {
      buttonDisplay = true;
      buttonLabel =
        kycStatus === "approved" ? "Continue to Dashboard" : "Submit KYC";
      redirectLink = kycStatus === "approved" ? "/dashboard" : "/kyc";
    }

    if (kycStatus === "approved") {
      let userInfo = {};
      let authUserInfo = this.props.authUserInfo;
      userInfo.fullName = authUserInfo.fullName;
      userInfo.userDisplayName = authUserInfo.userDisplayName;
      userInfo.email = authUserInfo.email;
      userInfo.isKycVerified = true;
      userInfo.appovedScreenViwed = authUserInfo.appovedScreenViwed;
      auth.setUserInfo(userInfo);
    }

    return (
      <div className="kyc-status-container text-center">
        <h1 className="kyc-header">KYC</h1>
        <div className="kyc-content">
          <h2 className="mt-5">
            <img src={progressIcon} alt="progress" />
          </h2>
          <h3 className="text-xl kycstatus-heading">{heading}</h3>
          <div className="row">
            <div className="col-md-12">
              <h6 className="kycstatus-description text-default">
                {description}
              </h6>
              {note !== null &&
                !_.isEmpty(note) &&
                !_.isUndefined(note) &&
                note !== "" &&
                note.map((note) => {
                  {
                    dateArr = note.date.split("T");
                  }
                  return (
                    <div>
                      <h6 className="mt-4">Note : {note.note}</h6>
                      <h6 className="mt-4">Note : {dateArr[0]}</h6>
                    </div>
                  );
                })}
            </div>
          </div>

          {buttonDisplay === true && (
            <div className="row mt-5">
              <div className="col-md-12">
                <Link to={redirectLink}>
                  <button className="btn kyc-status-button">
                    {buttonLabel}
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default KycStatus;
