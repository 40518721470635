import React, { Component } from "react";
import { Link } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import { Checkbox } from "react-icheck";
import { toast } from "react-toastify";
import auth from "../../utils/auth";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
class IndividualIdentificationSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundsInvestUponAdviceAccepted: false,
      informationCorrectAccepted: false,
      moreAssetsAccepted: false,
      signature: "",
      buttonLoading: false,
    };
    this.clearSignature = this.clearSignature.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.submitIndividualIdentification =
      this.submitIndividualIdentification.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_SUBMIT_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
  }

  componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  changeCheckbox(e, checked, fieldName) {
    this.setState({
      [fieldName]: checked,
    });
  }

  async submitIndividualIdentification(event) {
    event.preventDefault();
    let _this = this;
    let authenticationToken = this.props.authToken;
    let signature = _this.signaturePad.toDataURL();
    let signatureEmpty = _this.signaturePad.isEmpty();
    const {
      fundsInvestUponAdviceAccepted,
      informationCorrectAccepted,
      moreAssetsAccepted,
    } = this.state;
    if (
      fundsInvestUponAdviceAccepted &&
      informationCorrectAccepted &&
      moreAssetsAccepted &&
      !signatureEmpty
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/submit", {
          fundsInvestUponAdviceAccepted: fundsInvestUponAdviceAccepted,
          informationCorrectAccepted: informationCorrectAccepted,
          moreAssetsAccepted: moreAssetsAccepted,
          signature: signature,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });

        if (apiResponse.code === 200) {
          /* Update status */
          let authUserInfo = this.props.authUserInfo;
          authUserInfo.identificationDataFilled = true;
          auth.setUserInfo(authUserInfo);
          /* Update status */

          toast.success(apiResponse.message);
          this.props.history.push("/dashboard");
        } else {
          toast.error(apiResponse.message);
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
      }
    } else {
      let msg = signatureEmpty
        ? messageConstants.ERROR_MSG_SIGNATURE
        : messageConstants.PLEASE_ACCEPT_DECLARATION;
      toast.error(msg);
    }
  }

  render() {
    const { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h2 className="text-center color-white identification-header">
            Submit Details
          </h2>
          <div className="kyc-container mt-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <h5 className="color-white">Data protection statement</h5>
                    <p>
                      In order to provide you with products and services we need
                      to collect, use, share and store personal and financial
                      information about you. This includes the information
                      requested on this form and information obtained from third
                      parties.
                    </p>

                    <p>
                      The information requested may be used to assist us in
                      providing the service you are applying for, to advise you
                      of other products and services, to confirm, update and
                      enhance records and to establish your identity. The data
                      that we collect may be shared / transferred to / or stored
                      / or processed at, our offices in other countries outside
                      the UAE.
                    </p>
                    <h5 className="color-white">Declaration</h5>
                    <p>
                      <Checkbox
                        checkboxClass="icheckbox_square-blue"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeCheckbox(
                            e,
                            checked,
                            "fundsInvestUponAdviceAccepted"
                          )
                        }
                      />
                      <span className="pl-2">
                        I confirm that all the assets / funds to be invested
                        upon advice / arrangements by you have been lawfully
                        acquired and are not derived from, either directly or
                        indirectly, or otherwise connected with any criminal or
                        unlawful activity. I we declare to act on my own behalf.
                      </span>
                    </p>

                    <p>
                      <Checkbox
                        checkboxClass="icheckbox_square-blue"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeCheckbox(
                            e,
                            checked,
                            "informationCorrectAccepted"
                          )
                        }
                      />
                      <span className="pl-2">
                        I declare that the above mentioned information is true
                        and correct to the best of our knowledge and it has been
                        made in good faith. I accept that you will rely on this
                        information to fulfil all of its regulatory
                        requirements.
                      </span>
                    </p>

                    <p>
                      <Checkbox
                        checkboxClass="icheckbox_square-blue"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeCheckbox(e, checked, "moreAssetsAccepted")
                        }
                      />
                      <span className="pl-2">
                        I confirm that I have net assets of more than 500,000
                        USD and/or sufficient experience and understanding of
                        relevant financial markets, products or transactions and
                        any associated risks. Therefore I accept my
                        classification as Professional Client.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <h5 className="color-white">
                      Signature{" "}
                      <span
                        className="color-white clear-sign-action ml-2 text-underline"
                        onClick={() => this.clearSignature()}
                      >
                        Clear
                      </span>
                    </h5>
                    <div className="signature-pad-container">
                      <SignaturePad
                        ref={(ref) => (this.signaturePad = ref)}
                        height={150}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <Link
                  to="/individual_identification/understanding_of_market"
                  className="color-white"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.submitIndividualIdentification}
                  disabled={buttonDisabled}
                >
                  Submit{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationSubmit;
