import React from "react";
import { Link } from "react-router-dom";
import { Marker, InfoWindow } from "react-google-maps";

class MarkerWithInfoWindow extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.onToggleOpen = this.onToggleOpen.bind(this);
  }

  onToggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
    render() {
        return (<Marker
            position={this.props.position}
            onClick={this.onToggleOpen}>
            {this.state.isOpen && <InfoWindow onCloseClick={this.onToggleOpen}>
                <div>
                    <h5>
                        <span>{this.props.content}</span>
                        <Link to={`/campaign_detail/${this.props.markerId}`} className="ml-2">Details</Link>
                    </h5>
                    
                </div>

            </InfoWindow>}
        </Marker>)
    }
}

export default MarkerWithInfoWindow;
