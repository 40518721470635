import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class IndividualFatcaCrsDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specifiedUsPerson: "y",
      usTinNo: "",
      buttonLoading: false,
    };
    this.saveDeclarationDetails = this.saveDeclarationDetails.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    document.title =
      messageConstants.INDIVIDUAL_FATCA_CRS_DECLARATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    const api = new Api();
    let authenticationToken = this.props.authToken;

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/individual/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = ["specifiedUsPerson", "usTinNo"];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async saveDeclarationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    let processFlag = true;
    const { specifiedUsPerson, usTinNo } = this.state;

    if (specifiedUsPerson !== "") {
      this.setState({
        buttonLoading: true,
      });

      if (specifiedUsPerson === "y" && usTinNo === "") {
        processFlag = false;
      }

      if (processFlag) {
        const api = new Api();
        const apiResponse = await api
          .setToken(authenticationToken)
          .create("user/individual_fatca_crs/save/declaration", {
            specifiedUsPerson: specifiedUsPerson,
            usTinNo: usTinNo,
          });
        if (apiResponse) {
          this.setState({
            buttonLoading: false,
          });
          if (apiResponse.code === 200) {
            this.props.history.push("/individual_fatca_crs/tax_declaration");
            toast.success(apiResponse.message);
          } else {
            toast.error(apiResponse.message);
          }
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
        toast.error("Please enter valid U.S. TIN no.");
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    const { specifiedUsPerson, usTinNo, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h4 className="text-center color-white identification-header">
            Section 2: FATCA Declaration of U.S. Citizenship or U.S. Residence
            for Tax purposes
          </h4>

          <div className="kyc-container mt-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <h5 className="color-white">
                      Please tick either (a) or (b) and complete as appropriate.
                    </h5>

                    <RadioGroup
                      className="understanding-split-radio-container"
                      name="specifiedUsPerson"
                      value={specifiedUsPerson}
                      onChange={(e, value) =>
                        this.handleRadioChange(e, value, "specifiedUsPerson")
                      }
                    >
                      <Radio
                        value="y"
                        radioClass="iradio_square-blue pr-2"
                        increaseArea="20%"
                        label={
                          " (a) " +
                          messageConstants
                            .LABEL_INDIVIDUAL_FATCA_CRS_DECLARATION_US_PERSON[
                            "Y"
                          ]
                        }
                      />
                      <Radio
                        value="n"
                        radioClass="iradio_square-blue pr-2"
                        increaseArea="20%"
                        label={
                          " (b) " +
                          messageConstants
                            .LABEL_INDIVIDUAL_FATCA_CRS_DECLARATION_US_PERSON[
                            "N"
                          ]
                        }
                      />
                    </RadioGroup>

                    {specifiedUsPerson === "y" && (
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <label>U.S. TIN</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="usTinNo"
                            id="usTinNo"
                            value={usTinNo}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Link
                  to="/individual_fatca_crs/identification"
                  className="color-white text-underline"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveDeclarationDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualFatcaCrsDeclaration;
