import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class CorporateIdentificationBusinessIntentions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyLearnedAboutOurService: "own",
      coyReferralByName: "",
      coyAssetAmountExpectedToServiced: "",
      coyAssetPercentageExpectedToServiced: "",
      coyExpectedInOutTurnoverFlow: "l500000USD",
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.saveBusinessIntensionDetails =
      this.saveBusinessIntensionDetails.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_BUSINESS_INTENSIONS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    let authenticationToken = this.props.authToken;
    try {
      const api = new Api();
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/company/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "coyLearnedAboutOurService",
          "coyReferralByName",
          "coyAssetAmountExpectedToServiced",
          "coyAssetPercentageExpectedToServiced",
          "coyExpectedInOutTurnoverFlow",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async saveBusinessIntensionDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyLearnedAboutOurService,
      coyReferralByName,
      coyAssetAmountExpectedToServiced,
      coyAssetPercentageExpectedToServiced,
      coyExpectedInOutTurnoverFlow,
    } = this.state;

    if (
      coyLearnedAboutOurService !== "" &&
      coyAssetAmountExpectedToServiced !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/corporate_identification/save/business_intensions", {
          coyLearnedAboutOurService: coyLearnedAboutOurService,
          coyReferralByName: coyReferralByName,
          coyAssetAmountExpectedToServiced: coyAssetAmountExpectedToServiced,
          coyAssetPercentageExpectedToServiced:
            coyAssetPercentageExpectedToServiced,
          coyExpectedInOutTurnoverFlow: coyExpectedInOutTurnoverFlow,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/corporate_identification/wealth");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  handleRadioChange(event, value, fieldName) {
    this.setState({
      [fieldName]: value,
    });
  }

  render() {
    let {
      coyLearnedAboutOurService,
      coyReferralByName,
      coyAssetAmountExpectedToServiced,
      coyAssetPercentageExpectedToServiced,
      coyExpectedInOutTurnoverFlow,
      buttonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            How did you learn about us and our services?
                          </label>
                          <div className="our-service-container">
                            <div className="row">
                              <div className="col-md-12">
                                <RadioGroup
                                  name="coyLearnedAboutOurService"
                                  className="split-radio-container"
                                  value={coyLearnedAboutOurService}
                                  onChange={(event, value) =>
                                    this.handleRadioChange(
                                      event,
                                      value,
                                      "coyLearnedAboutOurService"
                                    )
                                  }
                                >
                                  <Radio
                                    value="oe"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE[
                                        "OE"
                                      ]
                                    }
                                  />

                                  <Radio
                                    value="own"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE[
                                        "OWN"
                                      ]
                                    }
                                  />
                                  <br />

                                  <Radio
                                    value="r"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE[
                                        "R"
                                      ]
                                    }
                                  />

                                  <Radio
                                    value="roub"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE[
                                        "ROUB"
                                      ]
                                    }
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          </div>
                        </div>

                        {coyLearnedAboutOurService !== "own" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-default-color text-default">
                                  Name
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="coyReferralByName"
                                  onChange={this.onchange}
                                  placeholder="Name"
                                  value={coyReferralByName}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label className="text-default-color text-default">
                          Which amount of assets do you expect to be serviced by
                          us?<span className="required-sign">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyAssetAmountExpectedToServiced"
                            id="coyAssetAmountExpectedToServiced"
                            onChange={this.onchange}
                            placeholder="Assets in USD"
                            value={coyAssetAmountExpectedToServiced}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyAssetPercentageExpectedToServiced"
                            id="coyAssetPercentageExpectedToServiced"
                            onChange={this.onchange}
                            placeholder="% of total net wealth"
                            value={coyAssetPercentageExpectedToServiced}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            What is the expected annual in/out flow turnover of
                            assets?
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyExpectedInOutTurnoverFlow"
                              value={coyExpectedInOutTurnoverFlow}
                              onChange={(event, value) =>
                                this.handleRadioChange(
                                  event,
                                  value,
                                  "coyExpectedInOutTurnoverFlow"
                                )
                              }
                            >
                              <Radio
                                value="l500000USD"
                                radioClass="iradio_square-blue"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_EXPECTED_IN_OUT_FLOW[
                                    "L500000USD"
                                  ]
                                }
                              />

                              <Radio
                                value="500000to1000000USD"
                                radioClass="iradio_square-blue ml-3"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_EXPECTED_IN_OUT_FLOW[
                                    "500000TO1000000USD"
                                  ]
                                }
                              />

                              <Radio
                                value="m1000000USD"
                                radioClass="iradio_square-blue ml-3"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_EXPECTED_IN_OUT_FLOW[
                                    "M1000000USD"
                                  ]
                                }
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_identification/contact_details"
                className="color-white"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-2"
                type="button"
                onClick={this.saveBusinessIntensionDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateIdentificationBusinessIntentions;
