import React, { Component } from "react";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import moment from "moment";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
class MyInvestment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenSymbol: this.props.activeTokenName || "",
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 20,
      filters: "",
    };
  }
  getTokenBalanceDetails = async () => {
    const { tokenSymbol,page, sizePerPage } = this.state;
    if (tokenSymbol) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/balance", { tokenSymbol,page, sizePerPage });
      if (response.code===200 && response.data && !!response.data.transactions) {
        const responseData = response.data;
        this.setState({ 
          renderFlag: true,
          data: responseData.transactions.tokenTransactions,
          totalSize: responseData.transactions.number,
        })
      }
    }
  }
  async componentDidMount() {
    this.setState({tokenSymbol:this.props.location.state});
    document.title =
    messageConstants.MY_INVESTMENT_PAGE_TITLE +
    messageConstants.PAGE_TITLE_SEPERATOR +
    messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    this.getTokenBalanceDetails();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getTokenBalanceDetails();
      });
    } else {
        this.setState({ filters: filters }, () => {
          this.getTokenBalanceDetails();
        });
        return true;
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag,totalSize } = this.state;
    const { activeTokenName:tokenSymbol } = this.props;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "txnId",
        text: "Txn Id",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left text-default">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "CreatedOnUTC",
        text: "Transaction Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left text-default">
              <div> {moment(cell).format("YYYY-MM-DD HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Amount Sent",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amountRecieve,
                  row.transactionType
                )}
              >
                {`$ ${niceNumberDecimalDisplay(cell, 2)}`}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "coins",
        text: `Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-default">
              {niceNumberDecimalDisplay(cell, 2)}{" "}
              {tokenSymbol}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.tokenSentStatus === true) {
            label = "Completed";
            className = "badge-success";
          } else {
            label = "Pending";
            className = "badge-warning";
          }
          return (
            <div>
              <div className="text-left text-default">
                <div className="d-inline-block">
                  <span className={"badge "+className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    return (
      <div className="adminDashboardContainer transactioncontainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <h6 style={{ fontSize: "20px" }}>My Investment</h6>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data || []}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MyInvestment;
