import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class CorporateFatcaCrsEntityClassification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyCrsEntityFinancialInsitution: "",
      coyEntityNonFinancialInstitution: "",
      coyControllingPerson: "",
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.saveEntityClassificationDetails =
      this.saveEntityClassificationDetails.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    let authenticationToken = this.props.authToken;
    const api = new Api();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/company/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "coyCrsEntityFinancialInsitution",
          "coyEntityNonFinancialInstitution",
          "coyControllingPerson",
        ];

        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleRadioChange(event, value, name) {
    this.setState({
      coyCrsEntityFinancialInsitution: "",
      coyEntityNonFinancialInstitution: "",
      [name]: value,
    });
  }

  async saveEntityClassificationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyCrsEntityFinancialInsitution,
      coyControllingPerson,
      coyEntityNonFinancialInstitution,
    } = this.state;

    if (
      coyCrsEntityFinancialInsitution !== "" ||
      coyEntityNonFinancialInstitution !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/company_fatca_crs/save/entity_classification", {
          coyCrsEntityFinancialInsitution: coyCrsEntityFinancialInsitution,
          coyControllingPerson: coyControllingPerson,
          coyEntityNonFinancialInstitution: coyEntityNonFinancialInstitution,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/corporate_fatca_crs/declarations_and_understanding"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    const {
      coyCrsEntityFinancialInsitution,
      coyControllingPerson,
      coyEntityNonFinancialInstitution,
      buttonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h4 className="text-center color-white identification-header">Section 5: Entity’s CRS Classification</h4> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <p className="text-large text-default-color">
                      Note : The information provided in this section is for
                      CRS. Please note an entity’s CRS classification may differ
                      from its FATCA classification in Section 3.
                    </p>
                    <p className="mt-2 text-default text-default-color">
                      For more information please see the CRS Standard and
                      associated commentary:&nbsp;
                      <a
                        href="http://www.oecd.org/tax/automatic-exchange/common-reporting-standard/common-reporting-standard-and-related-commentaries/#d.en.345314"
                        target="_blank"
                        className="text-underline"
                        rel="noopener noreferrer"
                      >
                        http://www.oecd.org/tax/automatic-exchange/common-reporting-standard/common-reporting-standard-and-related-commentaries/#d.en.345314
                      </a>
                    </p>

                    <div className="form-group">
                      <label className="text-default text-default-color">
                        If the Subscriber is a Financial Institution, please
                        tick one of the below categories
                      </label>
                      <RadioGroup
                        className="radio-half-width-container"
                        value={coyCrsEntityFinancialInsitution}
                        onChange={(e, value) =>
                          this.handleRadioChange(
                            e,
                            value,
                            "coyCrsEntityFinancialInsitution"
                          )
                        }
                      >
                        <Radio
                          value="undercrs"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_FINANCIAL_INSTITUTION[
                              "UNDERCRS"
                            ]
                          }
                        />
                        <Radio
                          value="otherfinancialinstitution"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_FINANCIAL_INSTITUTION[
                              "OTHERFINANCIALINSTITUTION"
                            ]
                          }
                        />
                      </RadioGroup>
                      <div className="mt-2">
                        <span className="text-default text-default-color">
                          (If this box is ticked, we will request the name of
                          any Controlling Person(s) of the Entity and for
                          complete separate individual self-certification forms
                          for each of your Controlling Persons**)
                        </span>
                      </div>
                    </div>

                    {coyCrsEntityFinancialInsitution !== "" && (
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label className="text-default text-default-color">
                            Controlling person name:{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Controlling person name"
                            name="coyControllingPerson"
                            onChange={this.onChange}
                            value={coyControllingPerson}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group mt-3">
                      <label className="text-default text-default-color">
                        {" "}
                        If the Subscriber is a Non-Financial Institution, please
                        tick one of the below categories.
                      </label>
                      <RadioGroup
                        value={coyEntityNonFinancialInstitution}
                        name="coyEntityNonFinancialInstitution"
                        onChange={(e, value) =>
                          this.handleRadioChange(
                            e,
                            value,
                            "coyEntityNonFinancialInstitution"
                          )
                        }
                      >
                        <Radio
                          value="corportaion"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION[
                              "CORPORATION"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="government"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION[
                              "GOVERNMENT"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="internationalorganization"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION[
                              "INTERNATIONALORGANIZATION"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="other"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION[
                              "OTHER"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="passive"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION[
                              "PASSIVE"
                            ]
                          }
                        />
                      </RadioGroup>
                      <div className="mt-2">
                        <span className="text-default text-default-color">
                          (If this box is ticked, you will be requested to
                          complete a separate Individual Self-Certification Form
                          for each of your Controlling Persons**)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <h4 className="text-xl text-default-color">
                      **Controlling Persons:
                    </h4>
                    <p className="mt-2 text-default text-default-color">
                      NOTE: Please note that each Controlling Person must
                      complete a Separate Individual Self-Certification form. If
                      there are no natural person(s) who exercise control of the
                      Subscriber then the Controlling Person will be the natural
                      person(s) who hold the position of senior managing
                      official of the Subscriber.
                    </p>
                    <p className="mt-2 text-default text-default-color">
                      For further information on Identification requirements
                      under CRS for Controlling Persons, see the Commentary to
                      Section VIII of the CRS Standard:&nbsp;
                      <a
                        href="http://www.oecd.org/tax/automatic-exchange/common-reporting-standard/common-reporting-standard-and-related-commentaries/#d.en.345314"
                        target="_blank"
                        className="color-white text-underline"
                        rel="noopener noreferrer"
                      >
                        http://www.oecd.org/tax/automatic-exchange/common-reporting-standard/common-reporting-standard-and-related-commentaries/#d.en.345314
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/tax_declaration"
                className="color-white text-underline"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-3"
                type="button"
                onClick={this.saveEntityClassificationDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaCrsEntityClassification;
