import React, { Component } from "react";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import { toast } from "react-toastify";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
const api = new Api();

class PortfolioListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
      ordersRenderFlag: false,
      ordersButtonLoading: false,
      ordersPage: 1,
      ordersSizePerPage: 10,
      ordersFilters: "",
      verifierModalOpen: false,
      onCloseModal: false,
      myTokensList: "",
      data: [],
      totalSize: 0,
      ordersTotalSize: 0,
    };
  }

  async componentDidMount() {
    document.title =
      messageConstants.PORTFOLIOLISTVIEW_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    this.getMyTokenList();
    this.getRecords();
    this.getOrdersRecords();
  }

  async getRecords() {
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    const authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/portfolio", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.tokens,
          totalSize: _.size(response.data.tokens),
        });
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }

      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  handleOrdersTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.ordersSizePerPage !== sizePerPage ||
      this.state.ordersPage !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getOrdersRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ ordersFilters: filters }, () => {
          this.getOrdersRecords();
        });
        return true;
      }
    }
  };

  async getOrdersRecords() {
    const { ordersSizePerPage, ordersPage } = this.state;
    let { ordersFilters } = this.state;
    let authenticationToken = this.props.authToken;
    ordersFilters = JSON.stringify(ordersFilters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/exchange/transaction/list", {
          sizePerPage: ordersSizePerPage,
          page: ordersPage,
          filters: ordersFilters,
        });
      if (response.code === 200) {
        this.setState({
          ordersRenderFlag: true,
          ordersTotalSize: response.data.totalTransactions,
        });
      }
    } catch (e) {
      this.setState({
        ordersRenderFlag: true,
        ordersTotalSize: 0,
      });
    }
  }


  getMyTokenList = async () => {
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/tokens/list", {});
      if (response.code === 200) {
        this.setState({
          myTokensList: response.data.tokens,
        });
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleEventsChange = () => {
    this.setState({
      selectedToken: "5ca4fd67b764ac12245b4626",
    });
  }

  addVerifierRequest = async () => {
    let authenticationToken = this.props.authToken;
    const { withdrawAmount, selectedToken } = this.state;
    if (!_.isUndefined(withdrawAmount) && withdrawAmount > 0) {
      this.setState({
        requestButtonLoading: true,
      });
      const response = await api
        .setToken(authenticationToken)
        .create("user/token/withdrawal/request", {
          withdrawAmount,
          selectedToken,
        });
      if (response) {
        this.setState({
          requestButtonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          amount: "",
          userNote: "",
        });
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg =
        withdrawAmount <= 0 || withdrawAmount === ""
          ? "Please enter valid withdrawal amount."
          : "Please enter valid details for required fields.";
      toast.error(msg);
    }
  }

  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  showVerifierModal = () => {
    this.setState({
      verifierModalOpen: true,
    });
  }

  onCloseModal = () => {
    this.setState({
      verifierModalOpen: false,
    });
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
    } = this.state;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "issuerId",
        text: "Token Name",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>
                {cell.listedTokenName} ({cell.listedTokenSymbol})
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Token Price",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amountRecieve,
                  row.transactionType
                )}
              >
                ${row.issuerId && niceNumberDecimalDisplay(row.issuerId.listedTokenPrice,2)}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "totalTokenSent",
        text: `Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {niceNumberDecimalDisplay(cell, 2)}{" "}
              {row.issuerId.listedTokenSymbol}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "usdAmount",
        text: "Total Amount",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let usdAmount =
            parseFloat(row.totalTokenSent) * parseFloat(row.issuerId.listedTokenPrice);
          return <div>${niceNumberDecimalDisplay(usdAmount, 2)}</div>;
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    return (
      <div className="row">
        <div className="col-sm-12 mt-4">
          <div className="element-wrapper ">
            <div className="element-box">
              <h6 className="element-header">Portfolio Details</h6>
              <div>
                {renderFlag === true && (
                  <RemoteAll
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PortfolioListView;
