import React, { Component } from "react";
class UserDashboardTopBar extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {};
  }

  render() {
    return (
      <div className="top-bar color-scheme-transparent admin-top-bar"></div>
    );
  }
}
export default UserDashboardTopBar;
