import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import Api from "../../services/api";
import auth from "../../utils/auth";
import Basepages from "./Basepages";
import Landingpage from "./Landingpage";
import EmailVerifyBasePages from "./EmailVerifyBasePages";
import Base from "./Base";
import ExchangeBasePage from "./ExchangeBasePage";
import Custombasepages from "./Custombasepages";
import Signin from "../Signin/Signin";
import Home from "../Home/Home";
import Signup from "../Signup/Signup";
import EmailVerify from "../EmailVerify/EmailVerify";
import SubscribeEmailVerify from "../EmailVerify/SubscribeEmailVerify";
import Forgot from "../Forgot/Forgot";
import ResetPassword from "../ResetPassword/ResetPassword";
import Dashboard from "../Dashboard/Dashboard";
import MyInvestment from "../MyInvestment/MyInvestment";
import Logout from "../Logout/Logout";
import Kyc from "../Kyc/Kyc";
import KycSuitabilityAnalysis from "../KycSuitabilityAnalysis/KycSuitabilityAnalysis";
import KycUpload from "../KycUpload/KycUpload";
import KycSubmit from "../KycSubmit/KycSubmit";
import KycStatus from "../KycStatus/KycStatus";
import KycAgreement from "../KycAgreement/KycAgreement";
import KycEngagementTerms from "../KycEngagementTerms/KycEngagementTerms";
// import SubscribeNrt from "../SubscribeNrt/SubscribeNrt";
import SubscribeSecurityToken from "../SubscribeSecurityToken/SubscribeSecurityToken";
import Resources from "../Resources/Resources";
import MyAccount from "../MyAccount/MyAccount";
import Transactions from "../Transactions/Transactions";
import PortfolioListView from "../Portfolio/PortfolioListView";
import TokenHolders from "../TokenHolders/TokenHolders";
import Listings from "../Listings/Listings";
import MapCampaigns from "../Listings/MapCampaigns";
import ListingDetails from "../Listings/ListingDetails";
import ListingProgressDetails from "../Listings/ListingProgressDetails";
import ListingCategoryProgressDetails from "../Listings/ListingCategoryProgressDetails";
import Terms from "../Terms/Terms";
import Exchange from "../Exchange/Exchange";
import Withdrawals from "../Withdrawals/Withdrawals";
import IndividualIdentificationPersonalDetails from "../IndividualIdentification/PersonalDetails";
import IndividualIdentificationContactDetails from "../IndividualIdentification/ContactDetails";
import IndividualIdentificationProfessionalDetails from "../IndividualIdentification/ProfessionalDetails";
import IndividualIdentificationBusinessIntentions from "../IndividualIdentification/BusinessIntentions";
import IndividualIdentificationWealth from "../IndividualIdentification/Wealth";
import IndividualIdentificationUnderstandingOfMarket from "../IndividualIdentification/UnderstandingOfMarket";
import IndividualIdentificationSubmit from "../IndividualIdentification/Submit";

import IndividualFatcaCrsInformations from "../IndividualFatcaCrs/Informations";
import IndividualFatcaCrsIdentification from "../IndividualFatcaCrs/Identification";
import IndividualFatcaCrsDeclaration from "../IndividualFatcaCrs/Declaration";
import IndividualFatcaTaxDeclaration from "../IndividualFatcaCrs/TaxDeclaration";
import IndividualFatcaControllingPerson from "../IndividualFatcaCrs/ControllingPerson";
import IndividualFatcaDeclarationAndUndertakings from "../IndividualFatcaCrs/DeclarationAndUnderstandings";

import CorporateIdentificationCompanyDetails from "../CorporateIdentification/CompanyDetails";
import CorporateIdentificationContactDetails from "../CorporateIdentification/ContactDetails";
import CorporateIdentificationBusinessIntentions from "../CorporateIdentification/BusinessIntentions";
import CorporateIdentificationWealth from "../CorporateIdentification/Wealth";
import CorporateIdentificationUnderstandingOfMarket from "../CorporateIdentification/UnderstandingOfMarket";
import CorporateIdentificationSubmit from "../CorporateIdentification/Submit";

import CorporateFatcaCrsInformations from "../CorporateFatcaCrs/Informations";
import CorporateFatcaCrsIdentification from "../CorporateFatcaCrs/Identification";
import CorporateFatcaCrsDeclaration from "../CorporateFatcaCrs/Declaration";
import CorporateFatcaCrsClassification from "../CorporateFatcaCrs/Classification";
import CorporateFatcaTaxDeclaration from "../CorporateFatcaCrs/TaxDeclaration";
import CorporateFatcaCrsEntityClassification from "../CorporateFatcaCrs/EntityClassification";
import CorporateFatcaDeclarationAndUndertakings from "../CorporateFatcaCrs/DeclarationAndUnderstandings";
import EmdList from "../EmdList/EmdList";
import Issuers from "../Issuers/Issuers";
import Portfolio from "../Portfolio/Portfolio";

let currentLocation = "";
let loginCheckInterval;

const PrivateRoute = ({ component: Component, ...rest }) => {
  let messageLoginPage = "",
    messageClass = "";
  const authToken = auth.getToken();
  if (authToken === null) {
    messageLoginPage = "LOGIN_REQUIRED";
    messageClass = "warning";
  }
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          !_.isNull(authToken) ? (
            <Component {...rest} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: {
                  from: props.location,
                  message: messageLoginPage,
                  messageClass,
                },
              }}
            />
          )
        }
      />
    </div>
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const authToken = auth.getToken();
  //const authUserInfo = auth.getUserInfo();
  return (
    <Route
      {...rest}
      render={(props) =>
        _.isNull(authToken) ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTokenName: "",
      windowWidth: 0,
      windowHeight: 0,
      isMobileSized: false,
    };
    this.logout = this.logout.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.checkKycVerified = this.checkKycVerified.bind(this);
    this.checkEmdSelected = this.checkEmdSelected.bind(this);
    this.pageProgress = this.pageProgress.bind(this);
  }
  baseTokenChanged = (tokenName = "") => {
    this.setState(
      {
        activeTokenName: tokenName,
      },

      () => {
        if (currentLocation === "subscribe") {
          this.props.history.push("/subscribe/" + tokenName);
        } else if (currentLocation === "token_holders") {
          this.props.history.push("/token_holders/" + tokenName);
        }
      }
    );
  };
  logout(msg, message_type) {
    auth.clearToken();
    auth.clearUserInfo();
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
    this.props.history.push({
      pathname: "/signin",
    });
  }
  checkLogin() {
    const authToken = auth.getToken();
    if (!_.isNull(authToken)) {
      loginCheckInterval = setInterval(() => {
        const updatedToken = auth.getToken();
        if (_.isNull(updatedToken)) {
          this.logout("TOKEN_EXPIRED", "warning");
        }
      }, 1500);
    }
    return "";
  }

  pageProgress(action) {
    var body = document.body;
    if (action === "remove") {
      body.classList.add("page-loaded");
    } else if (action === "force_remove") {
      setTimeout(() => {
        body.classList.add("page-loaded");
      }, 5000);
    } else {
      body.classList.remove("page-loaded");
    }
  }

  async checkEmdSelected() {
    const authToken = auth.getToken();
    let params = {};
    if (!_.isUndefined(authToken) && authToken !== "" && authToken !== null) {
      const api = new Api();
      const response = await api
        .setToken(authToken)
        .create("user/getUserDetails", params);
      if (response.data.isEmdSelected === false) {
        this.props.history.push("/emd");
        return false;
      }
      let userInfo = {};
      userInfo.fullName = response.data.fullName;
      userInfo.userDisplayName = response.data.fullName;
      userInfo.email = response.data.email;
      userInfo.isKycVerified = response.data.isKycVerified;
      userInfo.role = response.data.role;
      userInfo.identificationDataFilled =
        response.data.identificationDataFilled;
      userInfo.fatcaCrsDataFilled = response.data.fatcaCrsDataFilled;
      userInfo.emdName =
        response.data && response.data.emdName ? response.data.emdName : "";
      userInfo.emdLogo =
        response.data && response.data.emdLogo ? response.data.emdLogo : "";
      userInfo.dealerNames =
        response.data && response.data.dealerList
          ? response.data.dealerList.map(dealer => dealer.companyName)
          : [];
      auth.setUserInfo(userInfo);
      return true;
    } else {
      this.props.history.push("/signin");
    }
  }

  async checkKycVerified(flag = false) {
    const authToken = auth.getToken();
    let params = {};
    let response = { status: true };
    if (!!authToken) {
      const api = new Api();
      if (flag === true) {
        params.twoFA = true;
      }
      const userDetailsResponse = await api
        .setToken(authToken)
        .create("user/getUserDetails", params);
      if (!_.isUndefined(userDetailsResponse)) {
        if (
          !userDetailsResponse.data.userKycDetail &&
          userDetailsResponse.data.isKycVerified === false
        ) {
          response.status = false;
          response.redirectUrl = "/emd";
        } else if (
          userDetailsResponse.data.kycStatus === "u" ||
          userDetailsResponse.data.kycStatus === "r"
        ) {
          response.status = false;
          response.redirectUrl =
            userDetailsResponse.data.kycStatus === "u"
              ? "/kyc_status/pending"
              : "/kyc_status/rejected";
        } else {
          response.userDetailsResponse = userDetailsResponse;
        }
      }
    } else {
      // this.props.history.push("/signin");
    }
    return response;
  }

  async componentDidMount() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];
    await this.checkLogin();
    this.getListedTokens();
  }
  componentWillUnmount() {
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
    /*window.removeEventListener("resize", this.updateWindowDimensions);*/
  }
  updateWindowDimensions = () => {
    const windowHeight = window.innerHeigh;
    const windowWidth = window.innerWidth;
    const isMobileSized = window.innerWidth < 700 ? true : false;
    this.setState({ windowWidth, windowHeight, isMobileSized });
  };

  getListedTokens = async () => {
    const api = new Api();
    const authenticationToken = auth.getToken();
    const response = await api
      .setToken(authenticationToken)
      .get("user/listed/tokens");
    if (response) {
      this.setState({
        activeTokenName:
          response.data && response.data.tokens && response.data.tokens[0]
            ? response.data.tokens[0].listedTokenSymbol
            : "",
        listedTokens: response.data.tokens,
      });
    }
  }
  renderAuthenticationRoute() {
    return (
      <Basepages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <Route
          path="/terms_conditions"
          render={() => (
            <Terms
              {...this.props}
              {...this.state}
              pageProgress={this.pageProgress}
            />
          )}
        />

        <PublicRoute
          {...this.state}
          path="/signin"
          exact={true}
          component={Signin}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/signup"
          component={Signup}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/forgot"
          component={Forgot}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/reset_password/:id"
          component={ResetPassword}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/generate_password/:id"
          component={ResetPassword}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
      </Basepages>
    );
  }

  renderEmailVerifyRoute() {
    return (
      <EmailVerifyBasePages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <PublicRoute
          {...this.state}
          path="/email_verification/:id"
          component={EmailVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/subscribe_email_verification/:id"
          component={SubscribeEmailVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
      </EmailVerifyBasePages>
    );
  }

  renderAuthenitcatedRoute() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    return (
      <Base
        logout={this.logout}
        currentLocation={currentLocation}
        {...this.state}
        {...this.props}
        authUserInfo={authUserInfo}
        authToken={authToken}
        baseTokenChanged={this.baseTokenChanged}
      >
        <Switch>
          <PrivateRoute
            {...this.state}
            path="/dashboard"
            component={Dashboard}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
            getListedTokens={this.getListedTokens}
          />
          <PrivateRoute
            {...this.state}
            path="/my_investment"
            component={MyInvestment}
            activeTokenName={this.state.activeTokenName}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          {/*<PrivateRoute
            {...this.state}
            path="/subscribe"
            component={SubscribeNrt}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />*/}
          <PrivateRoute
            {...this.state}
            path="/subscribe/:id"
            component={SubscribeSecurityToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/my_account"
            component={MyAccount}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/transactions"
            component={Transactions}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/portfolio_list_view"
            component={PortfolioListView}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />

          <PrivateRoute
            {...this.state}
            path="/token_holders/:id"
            component={TokenHolders}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/campaigns/map/:id"
            component={MapCampaigns}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/campaigns/:id"
            component={Listings}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/issuers"
            component={Issuers}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/income"
            component={Withdrawals}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/campaign_detail/:id"
            component={ListingDetails}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/progress_reports/:id/category/:categoryid"
            component={ListingCategoryProgressDetails}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/progress_reports/:id"
            component={ListingProgressDetails}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/resources"
            component={Resources}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/logout"
            component={Logout}
            authToken={authToken}
            logout={this.logout}
            authUserInfo={authUserInfo}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/personal_details"
            component={IndividualIdentificationPersonalDetails}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/contact_details"
            component={IndividualIdentificationContactDetails}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/professional_details"
            component={IndividualIdentificationProfessionalDetails}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/business_intentions"
            component={IndividualIdentificationBusinessIntentions}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/wealth"
            component={IndividualIdentificationWealth}
            checkEmdSelected={this.checkEmdSelected}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/understanding_of_market"
            component={IndividualIdentificationUnderstandingOfMarket}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_identification/submit"
            component={IndividualIdentificationSubmit}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_fatca_crs/informations"
            component={IndividualFatcaCrsInformations}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_fatca_crs/identification"
            component={IndividualFatcaCrsIdentification}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_fatca_crs/declaration"
            component={IndividualFatcaCrsDeclaration}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_fatca_crs/tax_declaration"
            component={IndividualFatcaTaxDeclaration}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_fatca_crs/controlling_person"
            component={IndividualFatcaControllingPerson}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/individual_fatca_crs/declaration_and_undertakings"
            component={IndividualFatcaDeclarationAndUndertakings}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_identification/company_details"
            component={CorporateIdentificationCompanyDetails}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_identification/contact_details"
            component={CorporateIdentificationContactDetails}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_identification/business_intentions"
            component={CorporateIdentificationBusinessIntentions}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_identification/wealth"
            component={CorporateIdentificationWealth}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_identification/understanding_of_market"
            component={CorporateIdentificationUnderstandingOfMarket}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_identification/submit"
            component={CorporateIdentificationSubmit}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/informations"
            component={CorporateFatcaCrsInformations}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/identification"
            component={CorporateFatcaCrsIdentification}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/declaration"
            component={CorporateFatcaCrsDeclaration}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/classification"
            component={CorporateFatcaCrsClassification}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/tax_declaration"
            component={CorporateFatcaTaxDeclaration}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/entity_crs_classification"
            component={CorporateFatcaCrsEntityClassification}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/corporate_fatca_crs/declarations_and_understanding"
            component={CorporateFatcaDeclarationAndUndertakings}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/portfolio"
            component={Portfolio}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            checkEmdSelected={this.checkEmdSelected}
            pageProgress={this.pageProgress}
          />
        </Switch>
      </Base>
    );
  }

  renderExchangeRoute() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    return (
      <ExchangeBasePage
        logout={this.logout}
        currentLocation={currentLocation}
        {...this.state}
        authUserInfo={authUserInfo}
      >
        <Switch>
          <PrivateRoute
            {...this.state}
            path="/exchange"
            component={Exchange}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
        </Switch>
      </ExchangeBasePage>
    );
  }

  renderCustomRoute() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    return (
      <Base
        logout={this.logout}
        currentLocation={currentLocation}
        {...this.state}
        authUserInfo={authUserInfo}
      >
        <Switch>
          <PrivateRoute
            {...this.state}
            exact
            path="/kyc/relationship/disclosure/:emdId"
            component={KycAgreement}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/kyc/engagement/terms"
            component={KycEngagementTerms}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/kyc/suitability/analysis/:emdId"
            component={KycSuitabilityAnalysis}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/kyc/upload/:emdId"
            component={KycUpload}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/kyc/:emdId"
            component={Kyc}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />

          <PrivateRoute
            {...this.state}
            path="/kyc_submit"
            component={KycSubmit}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/kyc_status/:status/:emdId"
            component={KycStatus}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/emd"
            component={EmdList}
            authUserInfo={authUserInfo}
            authToken={authToken}
            checkKycVerified={this.checkKycVerified}
            pageProgress={this.pageProgress}
            checkEmdSelected={this.checkEmdSelected}
            rowList={3}
          />
        </Switch>
      </Base>
    );
  }

  renderHomeRoute() {
    // const authToken = auth.getToken();
    // const authUserInfo = auth.getUserInfo();
    return (
      <Landingpage
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <PublicRoute
          {...this.state}
          path="/landing"
          exact={true}
          component={Home}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
          pageProgress={this.pageProgress}
        />
      </Landingpage>
    );
  }
  renderMainPage() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    if (_.isNull(authToken) || _.isUndefined(authToken)) {
      return (
        <Basepages
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <Signin
                {...this.props}
                {...this.state}
                urlpath={currentLocation}
                checkLogin={this.checkLogin}
                pageProgress={this.pageProgress}
              />
            )}
          />
        </Basepages>
      );
    } else {
      return (
        <Base
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
          baseTokenChanged={this.baseTokenChanged}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <Dashboard
                {...this.props}
                {...this.state}
                pageProgress={this.pageProgress}
                checkKycVerified={this.checkKycVerified}
                authToken={authToken}
                authUserInfo={authUserInfo}
                getListedTokens={this.getListedTokens}
                checkEmdSelected={this.checkEmdSelected}
              />
            )}
          >
            <Redirect to="/dashboard" />
          </Route>
        </Base>
      );
    }
  }
  render() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];
    const path = [
      "signin",
      "signup",
      "forgot",
      "reset_password",
      "generate_password",
      "terms_conditions",
    ];
    const emailVerify = ["email_verification", "subscribe_email_verification"];
    const homeRoutes = ["privacy", "landing"];
    const customRoutes = [
      "kyc",
      "upload",
      "kyc_submit",
      "kyc_status",
      "emd",
      "agreement",
      "analysis",
    ];
    const exchange = ["exchange"];
    if (!currentLocation) {
      return <div>{this.renderMainPage()}</div>;
      // TO-DO hide exchange page until later approval -- Xiao 2022-03-31
      // } else if (!!currentLocation && exchange.indexOf(currentLocation) > -1) {
      //   return <div>{this.renderExchangeRoute()}</div>;
    } else if (currentLocation === "admin") {
      const authToken = auth.getToken();
      if (!authToken) {
        toast.error("Invalid URL.");
        this.props.history.push("/signin");
      } else {
        this.props.history.push("/dashboard");
      }
      return "<div></div>";
    } else if (!!currentLocation && homeRoutes.indexOf(currentLocation) > -1) {
      return <div>{this.renderHomeRoute()}</div>;
    } else if (
      !!currentLocation &&
      customRoutes.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderCustomRoute(currentLocation)}</div>;
    } else if (!!currentLocation && emailVerify.indexOf(currentLocation) > -1) {
      return <div>{this.renderEmailVerifyRoute()}</div>;
    } else if (!!currentLocation && path.indexOf(currentLocation) > -1) {
      return <div>{this.renderAuthenticationRoute()}</div>;
    } else {
      return <div>{this.renderAuthenitcatedRoute()}</div>;
    }
  }
}
export default withRouter(Layout);
