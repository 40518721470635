import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import validators from "../../validators";
class IndividualIdentificationContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesList: [
        { value: "", label: messageConstants.LABEL_SELECT_COUNTRY },
      ],
      permanentAddressBuildingName: "",
      permanentAddressStreet: "",
      permanentAddressStreetNo: "",
      permanentAddressPlace: "",
      permanentAddressCountryValue: "",
      permanentAddressCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      mailingAddressBuildingName: "",
      mailingAddressPoBox: "",
      mailingAddressStreet: "",
      mailingAddressStreetNo: "",
      mailingAddressPlace: "",
      mailingAddressCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      mailingAddressCountryValue: "",
      contactDetailsTelephone1: "",
      contactDetailsTelephone2: "",
      contactDetailsFax1: "",
      contactDetailsFax2: "",
      contactDetailsEmail1: "",
      contactDetailsEmail2: "",
      buttonLoading: false,
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.saveContactDetails = this.saveContactDetails.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_CONTACT_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    let authenticationToken = this.props.authToken;
    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "permanentAddressBuildingName",
          "permanentAddressStreet",
          "permanentAddressStreetNo",
          "permanentAddressPlace",
          "mailingAddressBuildingName",
          "mailingAddressPoBox",
          "mailingAddressStreet",
          "mailingAddressStreetNo",
          "mailingAddressPlace",
          "contactDetailsTelephone1",
          "contactDetailsTelephone2",
          "contactDetailsFax1",
          "contactDetailsFax2",
          "contactDetailsEmail1",
          "contactDetailsEmail2",
        ];
        let setStateCountryVariables = [
          "permanentAddressCountry",
          "mailingAddressCountry",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  isFormValid() {
    let status = true;
    const validationFields = ["permanentAddressBuildingName"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async saveContactDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      permanentAddressBuildingName,
      permanentAddressStreet,
      permanentAddressStreetNo,
      permanentAddressPlace,
      permanentAddressCountryValue,
      mailingAddressBuildingName,
      mailingAddressPoBox,
      mailingAddressStreet,
      mailingAddressStreetNo,
      mailingAddressPlace,
      mailingAddressCountryValue,
      contactDetailsTelephone1,
      contactDetailsTelephone2,
      contactDetailsFax1,
      contactDetailsFax2,
      contactDetailsEmail1,
      contactDetailsEmail2,
    } = this.state;

    const isFormValid = this.isFormValid();
    if (
      isFormValid &&
      permanentAddressBuildingName !== "" &&
      permanentAddressStreet !== "" &&
      permanentAddressPlace !== "" &&
      permanentAddressCountryValue !== "" &&
      (contactDetailsTelephone1 !== "" || contactDetailsTelephone2 !== "") &&
      (contactDetailsEmail1 !== "" || contactDetailsEmail2 !== "")
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/contact_details", {
          permanentAddressBuildingName: permanentAddressBuildingName,
          permanentAddressStreet: permanentAddressStreet,
          permanentAddressStreetNo: permanentAddressStreetNo,
          permanentAddressPlace: permanentAddressPlace,
          permanentAddressCountry: permanentAddressCountryValue,
          mailingAddressBuildingName: mailingAddressBuildingName,
          mailingAddressPoBox: mailingAddressPoBox,
          mailingAddressStreet: mailingAddressStreet,
          mailingAddressStreetNo: mailingAddressStreetNo,
          mailingAddressPlace: mailingAddressPlace,
          mailingAddressCountry: mailingAddressCountryValue,
          contactDetailsTelephone1: contactDetailsTelephone1,
          contactDetailsTelephone2: contactDetailsTelephone2,
          contactDetailsFax1: contactDetailsFax1,
          contactDetailsFax2: contactDetailsFax2,
          contactDetailsEmail1: contactDetailsEmail1,
          contactDetailsEmail2: contactDetailsEmail2,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/individual_identification/professional_details"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      countriesList,
      permanentAddressBuildingName,
      permanentAddressStreet,
      permanentAddressPlace,
      permanentAddressCountryName,
      permanentAddressCountryValue,
      mailingAddressBuildingName,
      mailingAddressPoBox,
      mailingAddressStreetNo,
      mailingAddressStreet,
      mailingAddressPlace,
      mailingAddressCountryName,
      mailingAddressCountryValue,
      contactDetailsFax1,
      contactDetailsFax2,
      contactDetailsTelephone1,
      contactDetailsTelephone2,
      contactDetailsEmail1,
      contactDetailsEmail2,
      permanentAddressStreetNo,
      buttonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h2 className="color-white text-center identification-header">
            Contact Details
          </h2>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">
                          Permanent Address Details
                        </h6>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Building name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanentAddressBuildingName"
                            id="permanentAddressBuildingName"
                            value={permanentAddressBuildingName}
                            onChange={this.onchange}
                            placeholder="Building name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Street<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanentAddressStreet"
                            id="permanentAddressStreet"
                            value={permanentAddressStreet}
                            onChange={this.onchange}
                            placeholder="Street"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Street No</label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanentAddressStreetNo"
                            id="permanentAddressStreetNo"
                            value={permanentAddressStreetNo}
                            onChange={this.onchange}
                            placeholder="Street"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Place<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="permanentAddressPlace"
                            id="permanentAddressPlace"
                            value={permanentAddressPlace}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Country<span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "permanentAddressCountry"
                              )
                            }
                            value={{
                              label: permanentAddressCountryName,
                              value: permanentAddressCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">Mailing address</h6>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Building name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="mailingAddressBuildingName"
                            id="mailingAddressBuildingName"
                            value={mailingAddressBuildingName}
                            onChange={this.onchange}
                            placeholder="Building name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>PO Box</label>
                          <input
                            className="form-control"
                            type="text"
                            name="mailingAddressPoBox"
                            id="mailingAddressPoBox"
                            value={mailingAddressPoBox}
                            onChange={this.onchange}
                            placeholder="PO Box"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Street</label>
                          <input
                            className="form-control"
                            type="text"
                            name="mailingAddressStreet"
                            id="mailingAddressStreet"
                            value={mailingAddressStreet}
                            onChange={this.onchange}
                            placeholder="Street"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Street No</label>
                          <input
                            className="form-control"
                            type="text"
                            name="mailingAddressStreetNo"
                            id="mailingAddressStreetNo"
                            value={mailingAddressStreetNo}
                            onChange={this.onchange}
                            placeholder="Street No"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Place</label>
                          <input
                            className="form-control"
                            type="text"
                            name="mailingAddressPlace"
                            id="mailingAddressPlace"
                            value={mailingAddressPlace}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "mailingAddressCountry"
                              )
                            }
                            value={{
                              label: mailingAddressCountryName,
                              value: mailingAddressCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">Contact Details</h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Telephone<span className="required-sign">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="contactDetailsTelephone1"
                            id="contactDetailsTelephone1"
                            value={contactDetailsTelephone1}
                            onChange={this.onchange}
                            placeholder="Telephone No"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="contactDetailsTelephone2"
                            id="contactDetailsTelephone2"
                            value={contactDetailsTelephone2}
                            onChange={this.onchange}
                            placeholder="Telephone No"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>Fax</label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="contactDetailsFax1"
                            id="contactDetailsFax1"
                            value={contactDetailsFax1}
                            onChange={this.onchange}
                            placeholder="Fax 1"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="contactDetailsFax2"
                            id="contactDetailsFax2"
                            value={contactDetailsFax2}
                            onChange={this.onchange}
                            placeholder="Fax 2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Email<span className="required-sign">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="contactDetailsEmail1"
                            id="contactDetailsEmail1"
                            value={contactDetailsEmail1}
                            onChange={this.onchange}
                            placeholder="Email 1"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="contactDetailsEmail2"
                            id="contactDetailsEmail2"
                            value={contactDetailsEmail2}
                            onChange={this.onchange}
                            placeholder="Email 2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link
                  to="/individual_identification/personal_details"
                  className="color-white"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveContactDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationContactDetails;
