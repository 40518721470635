import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { withGoogleMap, withScriptjs, GoogleMap } from "react-google-maps";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { GOOGLE_MAP_KEY } from "../../services/api-config";
import MarkerWithInfoWindow from "./MarkerWithInfoWindow";
import Switch from "react-switch";

class MapCampaigns extends Component {
  constructor() {
    super();
    this.state = {
      campaignDetails: [],
      checked: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.CAMPAIGNS_MAP_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    this.getRecords();
  }

  handleChange(checked) {
    let _this = this;
    let issuerId = this.props.match.params.id;
    this.setState(
      {
        checked,
      },
      () => {
        setTimeout(function () {
          _this.props.history.push(`/campaigns/${issuerId}`);
        }, 300);
      }
    );
  }

  async getRecords() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    let issuerId = this.props.match.params.id;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/campaigns/map/locations", { issuerId: issuerId });
      if (response.code === 200) {
        this.setState({
          campaignDetails: response.data.campaigns,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        campaignDetails: [],
      });
    }
  }

  render() {
    const { campaignDetails } = this.state;
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          zoom={3}
          center={{ lat: 20.5937, lng: 78.9629 }}
          ref={(ref) => {
            this.mapRef = ref;
          }}
        >
          {campaignDetails &&
            campaignDetails.map((details,index) => (
              <MarkerWithInfoWindow
                key={index}
                position={details.marker}
                content={details.location}
                markerId={details.markerId}
              />
            ))}
        </GoogleMap>
      ))
    );
    let map;
    map = (
      <div className="h-100 minheightmap">
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );

    return (
      <div className="adminDashboardContainer campaignmaps">
        <span className="listview-campaigns">
          List View{" "}
          <Switch
            className="top3"
            onChange={this.handleChange}
            checked={this.state.checked}
            height={18}
            width={45}
          />
        </span>
        {map}
      </div>
    );
  }
}
export default MapCampaigns;
