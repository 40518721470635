import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class IndividualIdentificationWealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesList: [{ value: "", label: "Select Country" }],
      estimatedGlobalNetWealth: "500000to1000000USD",
      estimatedGlobalNetWealthOther: "",
      builtGlobalWealth: "professionalbusinessactivities",
      builtGlobalWealthOther: "",
      annualNetIncome: "100000to250000USD",
      regularIncome: "salaryorpension",
      regularIncomeOther: "",
      regularNetIncomeCountry1Value: "",
      regularNetIncomeCountry1Name: messageConstants.LABEL_SELECT_COUNTRY,
      regularNetIncomeCountry2Value: "",
      regularNetIncomeCountry2Name: messageConstants.LABEL_SELECT_COUNTRY,
      regularNetIncomeCountry3Value: "",
      regularNetIncomeCountry3Name: messageConstants.LABEL_SELECT_COUNTRY,
      regularNetIncomeCountry4Value: "",
      regularNetIncomeCountry4Name: messageConstants.LABEL_SELECT_COUNTRY,
      displayMoreCountry: false,
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.displayMoreToggle = this.displayMoreToggle.bind(this);
    this.saveWealthDetails = this.saveWealthDetails.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_WEALTH_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    let authenticationToken = this.props.authToken;
    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "estimatedGlobalNetWealth",
          "estimatedGlobalNetWealthOther",
          "builtGlobalWealth",
          "builtGlobalWealthOther",
          "annualNetIncome",
          "regularIncome",
          "regularIncomeOther",
        ];
        let setStateCountryVariables = [
          "regularNetIncomeCountry1",
          "regularNetIncomeCountry2",
          "regularNetIncomeCountry3",
          "regularNetIncomeCountry4",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
          if (
            !_.isUndefined(idnetificationData.regularNetIncomeCountry3) ||
            !_.isUndefined(idnetificationData.regularNetIncomeCountry4)
          ) {
            stateObj["displayMoreCountry"] = true;
          }
        }
        this.setState(stateObj);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  async saveWealthDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      estimatedGlobalNetWealth,
      estimatedGlobalNetWealthOther,
      builtGlobalWealth,
      builtGlobalWealthOther,
      annualNetIncome,
      regularIncome,
      regularIncomeOther,
      regularNetIncomeCountry1Value,
      regularNetIncomeCountry2Value,
      regularNetIncomeCountry3Value,
      regularNetIncomeCountry4Value,
    } = this.state;

    if (
      estimatedGlobalNetWealth !== "" ||
      builtGlobalWealth !== "" ||
      annualNetIncome !== "" ||
      regularIncome !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/wealth", {
          estimatedGlobalNetWealth: estimatedGlobalNetWealth,
          estimatedGlobalNetWealthOther: estimatedGlobalNetWealthOther,
          builtGlobalWealth: builtGlobalWealth,
          builtGlobalWealthOther: builtGlobalWealthOther,
          annualNetIncome: annualNetIncome,
          regularIncome: regularIncome,
          regularIncomeOther: regularIncomeOther,
          regularNetIncomeCountry1: regularNetIncomeCountry1Value,
          regularNetIncomeCountry2: regularNetIncomeCountry2Value,
          regularNetIncomeCountry3: regularNetIncomeCountry3Value,
          regularNetIncomeCountry4: regularNetIncomeCountry4Value,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/individual_identification/understanding_of_market"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  displayMoreToggle(stateName = "") {
    let { displayMoreCountry } = this.state;
    this.setState({
      displayMoreCountry: !displayMoreCountry,
    });
  }

  render() {
    let {
      countriesList,
      estimatedGlobalNetWealth,
      builtGlobalWealth,
      annualNetIncome,
      regularIncome,
      displayMoreCountry,
      buttonLoading,
      regularNetIncomeCountry1Value,
      regularNetIncomeCountry1Name,
      regularNetIncomeCountry2Value,
      regularNetIncomeCountry2Name,
      regularNetIncomeCountry3Value,
      regularNetIncomeCountry3Name,
      regularNetIncomeCountry4Value,
      regularNetIncomeCountry4Name,
      builtGlobalWealthOther,
      regularIncomeOther,
      estimatedGlobalNetWealthOther,
    } = this.state;

    let showCountryMoreText = displayMoreCountry === false ? "Add" : "Hide";
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h2 className="text-center color-white identification-header">
            About your wealth and income
          </h2>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            What is your estimated global net wealth?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="estimatedGlobalNetWealth"
                              className="wealth-split-radio-container"
                              value={estimatedGlobalNetWealth}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "estimatedGlobalNetWealth"
                                )
                              }
                            >
                              <Radio
                                value="l500000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "L500000USD"
                                  ]
                                }
                                name="estimatedGlobalNetWealth"
                              />

                              <Radio
                                value="500000to1000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "500000TO1000000USD"
                                  ]
                                }
                                name="estimatedGlobalNetWealth"
                              />

                              <Radio
                                value="1000000to5000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "1000000TO5000000USD"
                                  ]
                                }
                                name="estimatedGlobalNetWealth"
                              />

                              <Radio
                                value="5000000to10000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "5000000TO10000000USD"
                                  ]
                                }
                                name="estimatedGlobalNetWealth"
                              />
                              <br />

                              <Radio
                                value="m10000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "M10000000USD"
                                  ]
                                }
                                name="estimatedGlobalNetWealth"
                              />

                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "OTHER"
                                  ]
                                }
                                name="estimatedGlobalNetWealth"
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {estimatedGlobalNetWealth === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Other</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="estimatedGlobalNetWealthOther"
                                  id="estimatedGlobalNetWealthOther"
                                  onChange={this.onchange}
                                  placeholder="Other"
                                  value={estimatedGlobalNetWealthOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            How have you built up your global net wealth?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="builtGlobalWealth"
                              className="wealth-split-radio-container"
                              value={builtGlobalWealth}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "builtGlobalWealth"
                                )
                              }
                            >
                              <Radio
                                value="professionalbusinessactivities"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "PROFESSIONALBUSINESSACTIVITIES"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />

                              <Radio
                                value="inheritancegifts"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "INHERITANCEGIFTS"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />

                              <Radio
                                value="pensionorstockoptionpayout"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "PENSIONORSTOCKOPTIONPAYOUT"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />

                              <Radio
                                value="realestatetransactions"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "REALESTATETRANSACTIONS"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />
                              <br />

                              <Radio
                                value="managingpersonalinvestments"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "MANAGINGPERSONALINVESTMENTS"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />

                              <Radio
                                value="saleofownbusiness"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "SALEOFOWNBUSINESS"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />

                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                                    "OTHER"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {builtGlobalWealth === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Other</label>
                            <input
                              className="form-control"
                              type="text"
                              name="builtGlobalWealthOther"
                              id="builtGlobalWealthOther"
                              onChange={this.onchange}
                              placeholder="Other"
                              value={builtGlobalWealthOther}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            What is your annual regular net income?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="annualNetIncome"
                              className="wealth-split-radio-container"
                              value={annualNetIncome}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "annualNetIncome"
                                )
                              }
                            >
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                                    "UPTO100000USD"
                                  ]
                                }
                                name="annualNetIncome"
                                value="upto100000USD"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                                    "100000TO250000USD"
                                  ]
                                }
                                name="annualNetIncome"
                                value="100000to250000USD"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                                    "250000TO500000USD"
                                  ]
                                }
                                name="annualNetIncome"
                                value="250000to500000USD"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                                    "500000TO1000000USD"
                                  ]
                                }
                                name="annualNetIncome"
                                value="500000to1000000USD"
                              />
                              <br />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                                    "M1000000USD"
                                  ]
                                }
                                name="annualNetIncome"
                                value="m1000000USD"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            How do you make your regular net income?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="regularIncome"
                              className="wealth-split-radio-container"
                              value={regularIncome}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "regularIncome"
                                )
                              }
                            >
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_HOW_YOU_MAKE_REGULAR_NET_INCOME[
                                    "SALARYORPENSION"
                                  ]
                                }
                                name="regularIncome"
                                value="salaryorpension"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_HOW_YOU_MAKE_REGULAR_NET_INCOME[
                                    "INCOMEFROMINVESTMENTS"
                                  ]
                                }
                                name="regularIncome"
                                value="incomefrominvestments"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_HOW_YOU_MAKE_REGULAR_NET_INCOME[
                                    "BUSINESSINCOME"
                                  ]
                                }
                                name="regularIncome"
                                value="businessincome"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_HOW_YOU_MAKE_REGULAR_NET_INCOME[
                                    "RENTALINCOME"
                                  ]
                                }
                                name="regularIncome"
                                value="rentalincome"
                              />
                              <br />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label=" Other"
                                name="regularIncome"
                                value="other"
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {regularIncome === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Other</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="regularIncomeOther"
                                  onChange={this.onchange}
                                  placeholder="Other"
                                  value={regularIncomeOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">
                          In which countries do you make your regular net
                          income?{" "}
                          <a
                            href="Javascript:void(0)"
                            className="color-white pl-2 text-underline"
                            onClick={() => this.displayMoreToggle()}
                          >
                            {showCountryMoreText} More
                          </a>
                        </h6>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-6">
                        <Select
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countriesList}
                          placeholder="Select Country"
                          onChange={(selectedOption) =>
                            this.handleCountryChange(
                              selectedOption,
                              "regularNetIncomeCountry1"
                            )
                          }
                          value={{
                            label: regularNetIncomeCountry1Name,
                            value: regularNetIncomeCountry1Value,
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countriesList}
                          placeholder="Select Country"
                          onChange={(selectedOption) =>
                            this.handleCountryChange(
                              selectedOption,
                              "regularNetIncomeCountry2"
                            )
                          }
                          value={{
                            label: regularNetIncomeCountry2Name,
                            value: regularNetIncomeCountry2Value,
                          }}
                        />
                      </div>
                    </div>

                    {displayMoreCountry === true && (
                      <div className="row  mt-4">
                        <div className="col-md-6">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "regularNetIncomeCountry3"
                              )
                            }
                            value={{
                              label: regularNetIncomeCountry3Name,
                              value: regularNetIncomeCountry3Value,
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "regularNetIncomeCountry4"
                              )
                            }
                            value={{
                              label: regularNetIncomeCountry4Name,
                              value: regularNetIncomeCountry4Value,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link
                  to="/individual_identification/business_intentions"
                  className="color-white"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveWealthDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationWealth;
