import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Link } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import EditProfileModal from "./EditProfileModal";
import OtpModal from "./OtpModal";
import { confirmAlert } from "react-confirm-alert";
import "icheck/skins/all.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import auth from "../../utils/auth";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      phoneNumber: "",
      email: "",
      countryName: "",
      countryIdValue: null,
      changePasswordModalOpen: false,
      editProfileModalOpen: false,
      otpModalOpen: false,
      updateEthAddressButtonLoading: false,
      changePasswordButtonLoading: false,
      editProfileButtonLoading: false,
      toTpUri: "",
      twoFAFormattedKey: "",
      twoFAStatus: false,
      twoFAcode: "",
      receivingEthAddress: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      currentKycStatus: "u",
      role: "",
      identificationDataFilled: false,
      fatcaCrsDataFilled: false,
      newMandateMail: false,
      mandateStatusMail: false,
      matchingMandateMail: false,
      orderExecutedMail: false,
      updateNotificationButtonLoading: false,
      countriesList: [{ value: this.countryIdValue, label: this.countryName }],
    };
    this.optionRef = React.createRef();
  }

  copySecretKey = () => {
    const msg = "Secret Key successfully copied!";
    toast.success(msg);
  };
  async componentWillMount() {
    document.title =
      messageConstants.MY_ACCOUNT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    const authToken = auth.getToken();
    const api = new Api();
    const userDetailsResponse = await api
      .setToken(authToken)
      .create("user/getUserDetails", { twoFA: true });
    let stateSet = {};
    let userResponse = userDetailsResponse;
    if (!_.isUndefined(userResponse.data.twoFAFormattedKey)) {
      stateSet.twoFAFormattedKey = userResponse.data.twoFAFormattedKey;
    }
    if (!_.isUndefined(userResponse.data.toTpUri)) {
      stateSet.toTpUri = userResponse.data.toTpUri;
    }
    if (!_.isUndefined(userResponse.data.twoFAFormattedKey)) {
      stateSet.twoFAStatus = userResponse.data.twoFAStatus;
    }

    if (!_.isUndefined(userResponse.data.identificationDataFilled)) {
      stateSet.identificationDataFilled =
        userResponse.data.identificationDataFilled;
    }

    if (!_.isUndefined(userResponse.data.fatcaCrsDataFilled)) {
      stateSet.fatcaCrsDataFilled = userResponse.data.fatcaCrsDataFilled;
    }

    if (!_.isUndefined(userResponse.data.role)) {
      stateSet.role = userResponse.data.role;
    }

    if (!_.isUndefined(userResponse.data.newMandateMail)) {
      stateSet.newMandateMail = userResponse.data.newMandateMail;
    }

    if (!_.isUndefined(userResponse.data.mandateStatusMail)) {
      stateSet.mandateStatusMail = userResponse.data.mandateStatusMail;
    }

    if (!_.isUndefined(userResponse.data.matchingMandateMail)) {
      stateSet.matchingMandateMail = userResponse.data.matchingMandateMail;
    }

    if (!_.isUndefined(userResponse.data.orderExecutedMail)) {
      stateSet.orderExecutedMail = userResponse.data.orderExecutedMail;
    }

    stateSet.twoFAStatus = userResponse.data.twoFAStatus;
    stateSet.receivingEthAddress = userResponse.data.receivingEthAddress;

    stateSet.dispalySecretKey = userResponse.data.dispalySecretKey;

    stateSet.fullName = userResponse.data.fullName;
    stateSet.email = userResponse.data.email;
    stateSet.countryName = userResponse.data.country;

    if (!_.isUndefined(userResponse.data.phoneNumber)) {
      stateSet.phoneNumber = userResponse.data.phoneNumber;
    }

    if (
      userResponse.data.userKycDetail &&
      userResponse.data.userKycDetail.status
    ) {
      stateSet.currentKycStatus = userResponse.data.userKycDetail.status;
    } else if (
      !userResponse.data.userKycDetail &&
      userResponse.data.isKycVerified
    ) {
      stateSet.currentKycStatus = userResponse.data.kycStatus;
    }

    if (!_.isEmpty(stateSet)) {
      this.setState(stateSet);
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }

    try {
      const response = await api.get("user/getCountries");
      let countriesList = [];
      if (response.code === 200) {
        if (response.data.countries) {
          response.data.countries.forEach((country) => {
            let obj = { value: country._id, label: country.name };
            countriesList.push(obj);
          });
          if (countriesList) {
            this.setState({
              countriesList: countriesList
            });
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  updateRejectKycStatus = async (userId, type) => {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("user/kyc_status/update/reject");
    if (response && response.code === 200) {
      this.props.history.push("/kyc");
    } else {
      toast.error(response.message);
    }
  };

  updateNotificationSettings = async () => {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const {
      newMandateMail,
      mandateStatusMail,
      matchingMandateMail,
      orderExecutedMail,
    } = this.state;

    this.setState({
      updateNotificationButtonLoading: true,
    });

    const response = await api
      .setToken(authenticationToken)
      .create("user/notification/settings/update", {
        newMandateMail,
        mandateStatusMail,
        matchingMandateMail,
        orderExecutedMail,
      });
    this.setState({
      updateNotificationButtonLoading: false,
    });

    if (response && response.code === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  updateKyc = () => {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Cancel",
      },
      {
        label: "Yes",
        onClick: () => _self.updateRejectKycStatus(),
      },
    ];
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure want to resubmit KYC?",
      buttons: buttons,
    });
  };

  twoFaAuthenticationChange = async (type = "enable") => {
    const { twoFAcode } = this.state;
    const authenticationToken = this.props.authToken;
    const authUserInfo = this.props.authUserInfo;
    const twoFAStatus = type === "disable" ? false : true;
    if (!!authUserInfo.email && !!twoFAcode) {
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/verify2FA", {
          email: authUserInfo.email,
          code: twoFAcode,
          status: twoFAStatus,
        });
      if (response.code === 200) {
        this.setState({
          twoFAcode: "",
          twoFAStatus: response.data.twoFAStatus,
          toTpUri: response.data.toTpUri,
        });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_TWO_FA_CODE);
    }
  };

  updateReceivingEthAddress = async () => {
    const { receivingEthAddress } = this.state;
    const authenticationToken = this.props.authToken;
    if (!!receivingEthAddress) {
      this.setState({
        updateEthAddressButtonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/add/whitelisted/address", {
          ethAddress: receivingEthAddress,
        });
      this.setState({
        updateEthAddressButtonLoading: false,
      });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS);
    }
  };

  updatePassword = async () => {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    const authenticationToken = this.props.authToken;
    if (!!oldPassword && !!newPassword && newPassword === confirmNewPassword) {
      this.setState({ changePasswordButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/changePassword", {
          oldPassword: oldPassword,
          newPassword: newPassword,
        });
      this.setState({ changePasswordButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onChangePasswordCloseModal();
        setTimeout(() => {
          auth.clearToken();
          auth.clearUserInfo();
        }, 1100);
      } else {
        toast.error(response.message);
      }
    } else {
      let msg =
        newPassword !== confirmNewPassword
          ? "Password and confirm password did not matched."
          : messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  };

  updateProfile = async () => {
    const { fullName, phoneNumber, countryName } = this.state;
    const authenticationToken = this.props.authToken;
    if (!!fullName && !!phoneNumber && !!countryName) {
      this.setState({ editProfileButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/profile/edit", {
          fullName: fullName,
          phoneNumber: phoneNumber,
          countryName
        });
      this.setState({ editProfileButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onEditProfileCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  };

  importWallet = async () => {
    const authenticationToken = this.props.authToken;
    this.setState({ importWalletLoading: true });
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .create("user/wallet/import");
    this.setState({ importWalletLoading: false, otpModalOpen: true });
    if (response.code === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }

  sendOtp = async () => {
    const authenticationToken = this.props.authToken;
    const { otpCode } = this.state;
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .create("user/wallet/otp/verify", {
        otpCode,
      });

    if (response.code === 200) {
      this.setState({
        whilelistedWalletAddressSecretKey: response.data,
      });
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onNumberChange = (value) => {
    this.setState({ phoneNumber: value });
  }

  openChangePasswordModal = () => {
    this.setState({ changePasswordModalOpen: true });
  }

  onChangePasswordCloseModal = () => {
    this.setState({ changePasswordModalOpen: false });
  }

  openEditProfileModal = () => {
    this.setState({ editProfileModalOpen: true });
  }

  onEditProfileCloseModal = () => {
    this.setState({ editProfileModalOpen: false });
  }

  openOtpModal = () => {
    this.setState({ otpModalOpen: true });
  }

  onMenuOpen = () => {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  };

  handleCountryChange = (selectedOption) => {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
    });
  }

  closeOtpModal = () => {
    this.setState({ otpModalOpen: false });
  }

  handleRadioChange = (event, value, name) => {
    const radioValue = JSON.parse(value);
    this.setState({
      [name]: radioValue,
    });
  }

  render() {
    let {
      toTpUri,
      twoFAStatus,
      twoFAFormattedKey,
      twoFAcode,
      receivingEthAddress,
      updateEthAddressButtonLoading,
      fullName,
      email,
      phoneNumber,
      currentKycStatus,
      identificationDataFilled,
      role,
      fatcaCrsDataFilled,
      dispalySecretKey,
      importWalletLoading,
      countryName
    } = this.state;

    let qrCodeImg = "";
    let twofaButtonClassName = "";
    if (!twoFAStatus && toTpUri !== "") {
      qrCodeImg =
        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=" +
        toTpUri;
    }
    let twofaLabel = "Disabled";
    let twofaClassName = "badge-danger";
    let buttonLabel = "Enable 2FA";
    let buttonType = "enable";
    if (twoFAStatus === true) {
      twofaLabel = "Enabled";
      twofaClassName = "badge-success";
      buttonLabel = "Disable 2FA";
      buttonType = "disable";
      twofaButtonClassName = "btn-danger";
    }

    let kycFontClass,
      kycLabel,
      kycDescription = "";
    switch (currentKycStatus) {
      case "u":
        kycFontClass = "fa-eye";
        kycLabel = "Under Review";
        kycDescription =
          "Your KYC is under review. We will notify you once it approves.";
        break;

      case "p":
        kycFontClass = "fa-info";
        kycLabel = "Pending";
        kycDescription =
          "Your KYC is process in pending. Kindly complete KYC to access all features of panel";
        break;

      case "r":
        kycFontClass = "fa-times";
        kycLabel = "Rejected";
        kycDescription = "Your KYC is rejected. Please submit it again.";
        break;

      case "a":
        kycFontClass = "fa-check";
        kycLabel = "Approved";
        kycDescription =
          "Your KYC is already verified. You can update if there any changes.";
        break;

      default:
        break;
    }

    let updateEthAddressButtonDisabled =
      updateEthAddressButtonLoading === true ? true : false;
    let fatcaRedirectLink =
      role === "i"
        ? "/individual_fatca_crs/informations"
        : "/corporate_fatca_crs/informations";
    let identificationRedirect =
      role === "i"
        ? "/individual_identification/personal_details"
        : "/corporate_identification/company_details";

    return (
      <div className="my-account-container">
        <div className="content-i">
          <div className="content-box">
            {(identificationDataFilled === false ||
              fatcaCrsDataFilled === false) && (
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <div
                      className="p-3 dark-blue-theme-color 
                    my-account-error-msg text-default"
                    >
                      <span className="pr-2">
                        <i className="fa fa-times myaccount-error-icon"></i>
                      </span>{" "}
                      Please fill
                      {identificationDataFilled === false && (
                        <Link to={identificationRedirect}>
                          <strong className="text-default-color">
                            {" "}
                            Identification details
                          </strong>
                        </Link>
                      )}
                      {identificationDataFilled === false &&
                        fatcaCrsDataFilled === false && <span> and </span>}
                      {fatcaCrsDataFilled === false && (
                        <Link to={fatcaRedirectLink}>
                          <strong className="text-default-color">
                            {" "}
                            FATCA & CRS forms
                          </strong>
                        </Link>
                      )}{" "}
                      to access full features of the PermianChain Investor
                      dashboard.
                    </div>
                  </div>
                </div>
              )}
            <div className="row row-eq-height">
              <div className="col-sm-12 col-md-12 col-lg-6 my-account-details">
                <div className="dark-blue-theme-color my-account-header-block card-inner-padding h-100 myaccount-card">
                  <div>
                    <h5>
                      <span>Account Information</span>
                      <button
                        className="btn account-button pull-right ml-2 btn-small"
                        style={{ padding: "0px 12px" }}
                        onClick={() => this.openEditProfileModal()}
                      >
                        Edit
                      </button>
                    </h5>
                    <div className="mt-1 text-default text-default-color">
                      Name : <span className="pl-1">{fullName}</span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Email : <span className="pl-1">{email}</span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Phone :{" "}
                      <span className="pl-1">
                        {phoneNumber !== "" ? phoneNumber : "N/A"}
                      </span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Country : <span className="pl-0">{countryName}</span>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn account-button btn-large"
                      onClick={() => this.openChangePasswordModal()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 my-account-details">
                <div className="dark-blue-theme-color my-account-header-block card-inner-padding h-100 myaccount-card">
                  <div>
                    <h5>
                      <span>Whitelisted wallet address</span>
                    </h5>
                    <div className="mt-3 text-default text-default-color">
                      In order to subscribe and receive digital securities
                      please add your ETH / ERC-20 compatible wallet address.
                    </div>
                    <div className="mt-3">
                      <input
                        className="form-control receiving-eth-address"
                        placeholder={`Receiving wallet address`}
                        type="text"
                        name="receivingEthAddress"
                        id="receivingEthAddress"
                        value={receivingEthAddress}
                        onChange={this.onchange}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn account-button btn-large"
                      onClick={this.updateReceivingEthAddress}
                      disabled={updateEthAddressButtonDisabled}
                    >
                      Update{" "}
                      {updateEthAddressButtonLoading && (
                        <i className="fa-spin fa fa-spinner ml-1" />
                      )}
                    </button>

                    {dispalySecretKey && (
                      <button
                        className="btn account-button btn-large ml-2"
                        onClick={this.importWallet}
                      >
                        Import Wallet
                        {importWalletLoading && (
                          <i className="fa-spin fa fa-spinner ml-1" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mgtp-lg-4">
              <div className="col-lg-12 col-sm-12 ">
                <div className="dark-blue-theme-color my-account-header-block card-inner-padding two-factor-block">
                  <h5>
                    <span>2 Factor Authentication</span>
                    <span className="pull-right">
                      <span style={{ fontSize: "14px" }}>Current Status</span>{" "}
                      <label className={"badge " + twofaClassName}>
                        {twofaLabel}
                      </label>
                    </span>
                  </h5>
                  <div className="mt-3 two-factor-heading-header text-default text-default-color">
                    Two-factor authentication is a method for protection your
                    web account. When it is activated you need to enter not only
                    your password, but also a special code.
                  </div>
                  <div className="row mt-3 flex-sm-nowrap justify-content-center">
                    {qrCodeImg !== "" && (
                      <div className="ml-2">
                        <img alt="" src={qrCodeImg} height="170" />
                      </div>
                    )}
                    <div className="ml-2 two-factor-desc-text">
                      {!twoFAStatus && (
                        <div className="ml-2">
                          <div className="text-default text-default-color mobile-text-small mgtp-sm-3">
                            Open up your 2FA mobile app and scan the following
                            QR barcode. If your 2FA mobile app does not support
                            QR barcodes, enter in the following number:
                          </div>
                          <h5 className="mt-4 text-center text-large mobile-text-default">
                            {twoFAFormattedKey}
                          </h5>
                        </div>
                      )}
                      <div className="mt-4 text-center">
                        <label className="text-default text-default-color">
                          2FA Code
                        </label>
                        <input
                          className="form-control two-fa-code-input"
                          type="text"
                          name="twoFAcode"
                          id="twoFAcode"
                          placeholder="Enter 2FA code"
                          onChange={this.onchange}
                          value={twoFAcode}
                        />
                        <button
                          className={
                            "btn twofa-submit-button pl-3 " +
                            twofaButtonClassName
                          }
                          onClick={() =>
                            this.twoFaAuthenticationChange(buttonType)
                          }
                        >
                          {buttonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordModal
          {...this.props}
          {...this.state}
          onInputValueChange={this.onchange}
          isFormValid={this.isFormValid}
          displayValidationErrors={this.displayValidationErrors}
          onCloseModal={this.onChangePasswordCloseModal}
          updatePassword={this.updatePassword}
        />
        <EditProfileModal
          optionRef={this.optionRef}
          {...this.props}
          {...this.state}
          onCloseModal={this.onEditProfileCloseModal}
          onInputValueChange={this.onchange}
          onNumberChange={this.onNumberChange}
          updateProfile={this.updateProfile}
          handleCountryChange={this.handleCountryChange}
          onMenuOpen={this.onMenuOpen}
        />

        <OtpModal
          {...this.props}
          {...this.state}
          copySecretKey={this.copySecretKey}
          onCloseModal={this.closeOtpModal}
          onInputValueChange={this.onchange}
          sendOtp={this.sendOtp}
        />
      </div>
    );
  }
}
export default MyAccount;
