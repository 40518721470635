import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import { Radio, RadioGroup } from "react-icheck";
import { toast } from "react-toastify";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class IndividualFatcaCrsIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDate: "",
      mailingAddressSame: "y",
      birthCountryName: "Select Country",
      residentialCountryName: "Select Country",
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.saveIdentificationDetails = this.saveIdentificationDetails.bind(this);
    this.dateValidation = this.dateValidation.bind(this);
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  handleDateChange(date) {
    this.setState({
      birthDate: date,
    });
  }

  dateValidation = (currentDate) => {
    var bday = Datetime.moment().subtract(6570, "days");
    return currentDate.isBefore(bday);
  };

  async componentWillMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.INDIVIDUAL_FATCA_CRS_IDENTIFICATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    let authenticationToken = this.props.authToken;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/individual/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "investorName",
          "birthCity",
          "birthDate",
          "residentialBuildingName",
          "residentialStreet",
          "residentialCity",
          "residentialState",
          "mailingAddressSame",
          "mailingAddressBuildingName",
          "mailingAddressStreet",
          "mailingAddressCity",
          "mailingAddressState",
          "mailingAddesssPostalCode",
          "residentialPostalCode",
        ];
        let setStateCountryVariables = [
          "birthCountry",
          "residentialCountry",
          "mailingAddressCountry",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                if (key === "birthDate") {
                  stateObj[key] = moment(value);
                } else {
                  stateObj[key] = value;
                }
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async saveIdentificationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      investorName,
      birthCity,
      birthCountryValue,
      birthDate,
      residentialBuildingName,
      residentialStreet,
      residentialCity,
      residentialState,
      residentialCountryValue,
      residentialPostalCode,
      mailingAddressSame,
      mailingAddressBuildingName,
      mailingAddressStreet,
      mailingAddressCity,
      mailingAddressState,
      mailingAddressCountryValue,
      mailingAddesssPostalCode,
    } = this.state;

    var aDate = moment(birthDate);
    if (!aDate.isValid()) {
      toast.error("Please select valid birthdate");
    } else {
      if (
        investorName !== "" &&
        birthCity !== "" &&
        birthCountryValue !== "" &&
        birthDate !== "" &&
        residentialBuildingName !== "" &&
        residentialStreet !== "" &&
        residentialState !== "" &&
        residentialCountryValue !== "" &&
        residentialPostalCode !== ""
      ) {
        this.setState({
          buttonLoading: true,
        });
        const api = new Api();
        const apiResponse = await api
          .setToken(authenticationToken)
          .create("user/individual_fatca_crs/save/identification", {
            investorName: investorName,
            birthCity: birthCity,
            birthCountryValue: birthCountryValue,
            birthDate: birthDate,
            residentialBuildingName: residentialBuildingName,
            residentialStreet: residentialStreet,
            residentialCity: residentialCity,
            residentialState: residentialState,
            residentialCountryValue: residentialCountryValue,
            residentialPostalCode: residentialPostalCode,
            mailingAddressSame: mailingAddressSame,
            mailingAddressBuildingName: mailingAddressBuildingName,
            mailingAddressStreet: mailingAddressStreet,
            mailingAddressCity: mailingAddressCity,
            mailingAddressState: mailingAddressState,
            mailingAddressCountryValue: mailingAddressCountryValue,
            mailingAddesssPostalCode: mailingAddesssPostalCode,
          });
        if (apiResponse) {
          this.setState({
            buttonLoading: false,
          });
          if (apiResponse.code === 200) {
            this.props.history.push("/individual_fatca_crs/declaration");
            toast.success(apiResponse.message);
          } else {
            toast.error(apiResponse.message);
          }
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
        toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      }
    }
  }

  render() {
    let {
      countriesList,
      investorName,
      birthCity,
      birthCountryName,
      birthCountryValue,
      birthDate,
      residentialBuildingName,
      residentialStreet,
      residentialCity,
      residentialState,
      residentialCountryName,
      residentialCountryValue,
      residentialPostalCode,
      mailingAddressSame,
      mailingAddressBuildingName,
      mailingAddressStreet,
      mailingAddressCity,
      mailingAddressState,
      mailingAddressCountryName,
      mailingAddressCountryValue,
      mailingAddesssPostalCode,
      buttonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    var viewDate = Datetime.moment()
      .subtract(6570, "days")
      .format("DD-MM-YYYY");

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h4 className="text-center color-white identification-header">
            Section 1: Subscriber Identification
          </h4>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Subscriber / Investor Name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Investor Name"
                            name="investorName"
                            onChange={this.onChange}
                            value={investorName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Town or City of Birth
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="City of Birth"
                            name="birthCity"
                            onChange={this.onChange}
                            value={birthCity}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Country of Birth
                            <span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "birthCountry"
                              )
                            }
                            value={{
                              label: birthCountryName,
                              value: birthCountryValue,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Date of Birth
                            <span className="required-sign">*</span>
                          </label>
                          <Datetime
                            dateFormat="DD-MM-YYYY"
                            timeFormat={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: "dd-mm-yyyy" }}
                            utc={true}
                            onChange={this.handleDateChange}
                            value={birthDate}
                            isValidDate={this.dateValidation}
                            viewDate={viewDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">
                          Current Residential Address
                        </h6>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Building name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="residentialBuildingName"
                            id="residentialBuildingName"
                            placeholder="Building name"
                            onChange={this.onChange}
                            value={residentialBuildingName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Street<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="residentialStreet"
                            id="residentialStreet"
                            placeholder="Street"
                            onChange={this.onChange}
                            value={residentialStreet}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>City / Town</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Street"
                            name="residentialCity"
                            onChange={this.onChange}
                            value={residentialCity}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            State<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="residentialState"
                            id="residentialState"
                            placeholder="Place"
                            onChange={this.onChange}
                            value={residentialState}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Country<span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "residentialCountry"
                              )
                            }
                            value={{
                              label: residentialCountryName,
                              value: residentialCountryValue,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Postal Code<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Place"
                            name="residentialPostalCode"
                            value={residentialPostalCode}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">Mailing address</h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label> Same as residential address? </label>

                        <RadioGroup
                          className="understanding-split-radio-container"
                          value={mailingAddressSame}
                          onChange={(e, value) =>
                            this.handleRadioChange(
                              e,
                              value,
                              "mailingAddressSame"
                            )
                          }
                          name="mailingAddressSame"
                        >
                          <Radio
                            value="y"
                            radioClass="iradio_square-blue pr-2"
                            increaseArea="20%"
                            label=" Yes"
                          />
                          <Radio
                            value="n"
                            radioClass="iradio_square-blue pr-2"
                            increaseArea="20%"
                            label=" No"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    {mailingAddressSame === "n" && (
                      <div>
                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Building name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="mailingAddressBuildingName"
                                id="mailingAddressBuildingName"
                                placeholder="Building name"
                                value={mailingAddressBuildingName}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Street</label>
                              <input
                                className="form-control"
                                type="text"
                                name="mailingAddressStreet"
                                id="mailingAddressStreet"
                                placeholder="Street"
                                value={mailingAddressStreet}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>City / Town</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Street"
                                name="mailingAddressCity"
                                value={mailingAddressCity}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>State</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Place"
                                name="mailingAddressState"
                                id="mailingAddressState"
                                value={mailingAddressState}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Country</label>
                              <Select
                                className="country-select2"
                                classNamePrefix="cntry"
                                options={countriesList}
                                placeholder="Select Country"
                                onChange={(selectedOption) =>
                                  this.handleCountryChange(
                                    selectedOption,
                                    "mailingAddressCountry"
                                  )
                                }
                                value={{
                                  label: mailingAddressCountryName,
                                  value: mailingAddressCountryValue,
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Postal Code</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Place"
                                name="mailingAddesssPostalCode"
                                value={mailingAddesssPostalCode}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Link
                  to="/individual_fatca_crs/informations"
                  className="color-white text-underline"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveIdentificationDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualFatcaCrsIdentification;
