import React, { Component } from "react";
import _ from "lodash";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";

class CorporateFatcaCrsInformations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    document.title =
      messageConstants.CORPORATE_FATCA_CRS_INFORMATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  render() {
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <h5 className="text-xl text-default-color">
                      Instructions for completion
                    </h5>
                    <p className="text-default text-default-color">
                      We are obliged under the Foreign Account Tax Compliance
                      Act (“FATCA”), related intergovernmental agreements
                      (“IGAs”) and regulations based on the OECD Common
                      Reporting Standard (“CRS”) to collect certain information
                      about each investor’s tax arrangements. Please complete
                      the sections below as directed and provide any additional
                      information that is requested. Please note that in certain
                      circumstances we may be legally obliged to share this
                      information, and other financial information with respect
                      to an investor’s interests in the Company with relevant
                      tax authorities. This form is intended to request
                      information only where such request is not prohibited by
                      local law.
                    </p>

                    <p className="mt-2 text-default text-default-color">
                      If you have any questions about this form or defining the
                      investor’s tax residency status, please refer to the OECD
                      CRS Portal or speak to a tax adviser.
                    </p>

                    <p className="mt-2 text-default text-default-color">
                      For further information on FATCA or CRS please refer to
                      the US Department of the Treasury’s website at:{" "}
                      <a
                        className="text-underline overflow-wrap"
                        href="http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA
                      </a>{" "}
                      or in the case of CRS only the following link to the OECD
                      CRS Information Portal at:{" "}
                      <a
                        className="text-underline overflow-wrap"
                        href="http://www.oecd.org/tax/automatic-exchange/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://www.oecd.org/tax/automatic-exchange/
                      </a>
                      .
                    </p>

                    <p className="mt-2 text-default text-default-color">
                      If any of the information below about the investor’s tax
                      residence or FATCA/CRS classification changes in the
                      future, please advise of these changes promptly.
                    </p>

                    <p className="mt-2 text-default text-default-color">
                      Subscribers that are individuals should not complete this
                      form and should complete the form entitled “Individual
                      (Controlling Person’s) Self-Certification for FATCA and
                      CRS”.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/identification"
                className="color-white text-underline"
              >
                <button className="btn update-button ml-3" type="button">
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaCrsInformations;
