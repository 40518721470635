import React, { Component } from "react";
import _ from "lodash";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PortfolioListView from "./PortfolioListView";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { niceNumberDisplay, niceNumberDecimalDisplay } from "../../utils/Util";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myTransactions: [],
      myPortfolioAmount: 0
    };
  }
  prepareLineGraphData() {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("linechartdiv", am4charts.XYChart);

    chart.data = [
      {
        date: "1 dec 2019",
        value: 111473,
      },
      {
        date: "1 Jan 2020",
        value: 121473,
      },
      {
        date: "1 Feb 2020",
        value: 101473,
      },
      {
        date: "1 Mar 2020",
        value: 41473,
      },
      {
        date: "1 Apr 2020",
        value: 40473,
      },
      {
        date: "1 May 2020",
        value: 161473,
      },
    ];

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.tooltipText = "{value}";
    // series.id = "g3";
    // series.dataFields.valueY = "tokenPrice";
    // series.dataFields.categoryX = "year";
    series.name = `${""} Price`;
    series.strokeWidth = 2;
    series.tensionX = 0.7;
    series.yAxis = valueAxis;
    series.tooltipText = "[bold font-size: 20]${valueY}[/]";

    var bullet3 = series.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.radius = 3;
    bullet3.circle.strokeWidth = 2;
    bullet3.circle.fill = am4core.color("#fff");
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#000");
    chart.cursor.lineX.fillOpacity = 0.1;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    this.chart = chart;
  }
  prepareGraphData() {
    // Create chart instance
    let chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        coin: "BRX",
        price: 15001.9,
      },
      {
        coin: "XPR",
        price: 30001.9,
      },
      {
        coin: "ABC",
        price: 20001.1,
      },
      {
        coin: "DEF",
        price: 16500.8,
      },
      {
        coin: "LLK",
        price: 13009.9,
      },
      {
        coin: "Other",
        price: 12800.3,
      },
    ];

    // Set inner radius
    chart.innerRadius = am4core.percent(60);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "price";
    pieSeries.dataFields.category = "coin";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.tooltipText = "${price}";

    var label = chart.seriesContainer.createChild(am4core.Label);
    label.text = ``;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 30;
    pieSeries.labels.template.disabled = true;
    // pieSeries.ticks.template.disabled = true;
    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
  }
  async componentDidMount() {
    this.prepareGraphData();
    this.prepareLineGraphData();
    this.getTokenDetails();
    this.getPortfolioAmount();

    document.title =
      messageConstants.PORTFOLIO_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.props.pageProgress("remove");
  }

  async getTokenDetails() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("user/portfolio");

    if (response) {
      const responseData = response.data;
      let stateObj = {
        myTransactions: responseData.tokens,
      };
      if (stateObj) {
        this.setState(stateObj);
      }
    }
  }

  async getPortfolioAmount() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("user/portfolio/amount");

    if (response) {
      const responseData = response.data;
      let stateObj = {
        myPortfolioAmount: responseData,
      };
      if (stateObj) {
        this.setState(stateObj);
      }
    }
  }

  render() {
    const {
      myTransactions,
      myPortfolioAmount,
    } = this.state;
    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-md-8">
                <div className="element-box card-padding h-100 d-flex justify-content-center align-items-center">
                  <div className="element-balances justify-content-center mobile-full-width">
                    <div className="balance balance-v2">
                      <div className="balance-title">Your portfolio Value</div>
                      <div className="balance-value">
                        <span className="d-xxl-none">
                          ${myPortfolioAmount ? niceNumberDecimalDisplay(myPortfolioAmount,2) : 0}
                        </span>
                        <span className="d-none d-xxl-inline-block">
                          ${myPortfolioAmount ? niceNumberDecimalDisplay(myPortfolioAmount,2) : 0}
                        </span>
                        <span className="trending trending-down-basic hide d-none">
                          <span title="12% down from last month">%12</span>
                          <i className="os-icon os-icon-arrow-2-down"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row col-sm-12 col-lg-5 col-md-5 col-xl-4 col-xxl-3 ml-xs-2 ml-sm-2 ml-md-4 hide d-none">
                    <div className="element-balances justify-content-between mobile-full-width">
                      <div className="balance balance-v2">
                        <div className="balance-title">
                          Your last month portfolio Value
                        </div>
                        <div className="balance-value">
                          <span className="d-xxl-none">$72,245</span>
                          <span className="d-none d-xxl-inline-block">
                            $171,473
                          </span>
                          <span className="trending trending-down-basic">
                            <span title="12% down from last month">%12</span>
                            <i className="os-icon os-icon-arrow-2-down"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mgtp-sm-3 h-100">
                <div className="element-box card-padding card-max-height">
                  {!_.isUndefined(myTransactions) &&
                    myTransactions !== "" &&
                    myTransactions !== null &&
                    myTransactions.map((row, index) => {
                      return (
                        <a
                          className="el-tablo centered trend-in-corner"
                          href={void 0}
                          key={index}
                        >
                          <div className="label">
                            {row.issuerId ? row.issuerId.listedTokenName : ""} (
                            {row.issuerId ? row.issuerId.listedTokenSymbol : ""}
                            )
                          </div>
                          <div className="value">
                            {niceNumberDecimalDisplay(row.totalTokenSent, 2)}
                          </div>
                          <div className="trending trending-up">
                            <span>
                              $
                              {niceNumberDisplay(row.issuerId.listedTokenPrice)}
                            </span>
                            <i className="os-icon os-icon-arrow-up6"></i>
                          </div>
                          <div className="token">
                            $
                            {niceNumberDecimalDisplay(
                              parseFloat(row.totalTokenSent) *
                                parseFloat(row.issuerId.listedTokenPrice),
                              2
                            )}
                          </div>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="row mt-4 mb-3 hide d-none">
              <div className="col-sm-6 col-lg-6 col-xxl-6  ">
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    height: "470px",
                  }}
                >
                  <div className="element-wrapper compact">
                    <div className="element-box-tp">
                      <h6 className="element-box-header">Portfolio Summary</h6>
                      <div className="el-chart-w">
                        <div className="chartjs-size-monitor">
                          <div className="chartjs-size-monitor-expand">
                            <div className=""></div>
                          </div>
                          <div className="chartjs-size-monitor-shrink">
                            <div className=""></div>
                          </div>
                        </div>
                        <div
                          id="linechartdiv"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mt-xs-4 col-lg-6 col-xxl-6 ">
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    height: "470px",
                  }}
                >
                  <h6 className="element-box-header text-center">
                    Portfolio Distribution
                  </h6>
                  <div className="mt-2">
                    <div
                      id="chartdiv"
                      style={{ width: "100%", height: "400px" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="floated-chat-w">
              <div className="floated-chat-i">
                <div className="chat-close">
                  <i className="os-icon os-icon-close"></i>
                </div>
                <div className="chat-head">
                  <div className="user-w with-status status-green">
                    <div className="user-avatar-w">
                      <div className="user-avatar">
                        <img alt="" src="img/avatar1.jpg" />
                      </div>
                    </div>
                    <div className="user-name">
                      <h6 className="user-title">John Mayers</h6>
                      <div className="user-role">Account Manager</div>
                    </div>
                  </div>
                </div>
                <div
                  className="chat-messages ps ps--theme_default"
                  data-ps-id="6c913fa7-33a5-d2c4-1513-aae7f8707b98"
                >
                  <div className="message">
                    <div className="message-content">
                      Hi, how can I help you?
                    </div>
                  </div>
                  <div className="date-break">Mon 10:20am</div>
                  <div className="message">
                    <div className="message-content">
                      Hi, my name is Mike, I will be happy to assist you
                    </div>
                  </div>
                  <div className="message self">
                    <div className="message-content">
                      Hi, I tried ordering this product and it keeps showing me
                      error code.
                    </div>
                  </div>
                  <div
                    className="ps__scrollbar-x-rail"
                    style={{ left: "0px", bottom: "0px" }}
                  >
                    <div
                      className="ps__scrollbar-x"
                      tabIndex="0"
                      style={{ left: "0px", width: "0px" }}
                    ></div>
                  </div>
                  <div
                    className="ps__scrollbar-y-rail"
                    style={{ top: "0px", right: "0px" }}
                  >
                    <div
                      className="ps__scrollbar-y"
                      tabIndex="0"
                      style={{ top: "0px", height: "0px" }}
                    ></div>
                  </div>
                </div>
                <div className="chat-controls">
                  <input
                    className="message-input"
                    placeholder="Type your message here..."
                  />
                  <div className="chat-extra">
                    <a href="#">
                      <span className="extra-tooltip">Attach Document</span>
                      <i className="os-icon os-icon-documents-07" />
                    </a>
                    <a href="#">
                      <span className="extra-tooltip">Insert Photo</span>
                      <i className="os-icon os-icon-others-29" />
                    </a>
                    <a href="#">
                      <span className="extra-tooltip">Upload Video</span>
                      <i className="os-icon os-icon-ui-51" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <PortfolioListView 
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Portfolio;
