import React from "react";
import { ToastContainer } from "react-toastify";

class Landingpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="landing-page-wrapper">
        <ToastContainer hideProgressBar={true} />
        {this.props.children}
      </div>
    );
  }
}
export default Landingpage;
