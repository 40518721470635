import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
class CorporateIdentificationContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesList: [{ value: "", label: "Select Country" }],
      coyOperationOtherCountry1Name: messageConstants.LABEL_SELECT_COUNTRY,
      coyOperationOtherCountry1Value: "",
      coyOperationOtherCountry2Name: messageConstants.LABEL_SELECT_COUNTRY,
      coyOperationOtherCountry2Value: "",
      coyOperationOtherCountry3Name: messageConstants.LABEL_SELECT_COUNTRY,
      coyOperationOtherCountry3Value: "",
      coyOperationOtherCountry4Name: messageConstants.LABEL_SELECT_COUNTRY,
      coyOperationOtherCountry4Value: "",
      coyPermanentAddressCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      coyPermanentAddressCountryValue: "",
      coyTradingAddressCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      coyTradingAddressCountryValue: "",
      coyMailingAddressCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      coyMailingAddressCountryValue: "",
      coyPermanentAddressBuildingName: "",
      coyPermanentAddressStreet: "",
      coyPermanentAddressStreetNo: "",
      coyPermanentAddressPlace: "",
      coyTradingAddressBuildingName: "",
      coyTradingAddressStreet: "",
      coyTradingAddressStreetNo: "",
      coyTradingAddressPlace: "",
      coyMailingAddressBuildingName: "",
      coyMailingAddressPoBox: "",
      coyMailingAddressStreet: "",
      coyMailingAddressPlace: "",
      mailingAddressCountryValue: "",
      coyContactDetailsTelephone1: "",
      contactDetailsTelephone2: "",
      coyContactDetailsFax1: "",
      coyContactDetailsFax2: "",
      coyContactDetailsEmail1: "",
      coyContactDetailsEmail2: "",
      coyWebsite: "",
      displayMoreCountry: false,
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.saveContactDetails = this.saveContactDetails.bind(this);
  }

  async componentWillMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const api = new Api();
    const response = await api.get("user/getCountries");
    let authenticationToken = this.props.authToken;
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/company/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let identificationData = identificationResponse.data;
        let setStateVariables = [
          "coyPermanentAddressBuildingName",
          "coyPermanentAddressStreet",
          "coyPermanentAddressStreetNo",
          "coyPermanentAddressPlace",
          "coyTradingAddressBuildingName",
          "coyTradingAddressStreet",
          "coyTradingAddressStreetNo",
          "coyTradingAddressPlace",
          "coyMailingAddressBuildingName",
          "coyMailingAddressPoBox",
          "coyMailingAddressStreet",
          "coyMailingAddressStreetNo",
          "coyMailingAddressPlace",
          "coyContactDetailsTelephone1",
          "coyContactDetailsTelephone2",
          "coyContactDetailsFax1",
          "coyContactDetailsFax2",
          "coyContactDetailsEmail1",
          "coyContactDetailsEmail2",
          "coyWebsite",
        ];
        let setStateCountryVariables = [
          "coyPermanentAddressCountry",
          "coyTradingAddressCountry",
          "coyOperationOtherCountry1",
          "coyOperationOtherCountry2",
          "coyOperationOtherCountry3",
          "coyOperationOtherCountry4",
          "coyMailingAddressCountry",
        ];
        if (!_.isUndefined(identificationData)) {
          _.forEach(identificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });

          if (
            (!_.isUndefined(identificationData.coyOperationOtherCountry3) &&
              identificationData.coyOperationOtherCountry3 !== "") ||
            (!_.isUndefined(identificationData.coyOperationOtherCountry4) &&
              identificationData.coyOperationOtherCountry4 !== "")
          ) {
            stateObj["displayMoreCountry"] = true;
          }
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleDobChange(date) {
    this.setState({
      dob: date,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  displayMoreToggle(stateName = "") {
    let { displayMoreCountry } = this.state;
    this.setState({
      displayMoreCountry: !displayMoreCountry,
    });
  }

  async saveContactDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyOperationOtherCountry1Value,
      coyOperationOtherCountry2Value,
      coyOperationOtherCountry3Value,
      coyOperationOtherCountry4Value,
      coyPermanentAddressCountryValue,
      coyTradingAddressCountryValue,
      coyPermanentAddressBuildingName,
      coyPermanentAddressStreet,
      coyPermanentAddressStreetNo,
      coyPermanentAddressPlace,
      coyTradingAddressBuildingName,
      coyTradingAddressStreet,
      coyTradingAddressStreetNo,
      coyTradingAddressPlace,
      coyMailingAddressBuildingName,
      coyMailingAddressPoBox,
      coyMailingAddressStreet,
      coyMailingAddressStreetNo,
      coyMailingAddressPlace,
      coyMailingAddressCountryValue,
      coyContactDetailsTelephone1,
      coyContactDetailsTelephone2,
      coyContactDetailsFax1,
      coyContactDetailsFax2,
      coyContactDetailsEmail1,
      coyContactDetailsEmail2,
      coyWebsite,
    } = this.state;

    // const isFormValid = this.isFormValid();
    if (
      coyPermanentAddressBuildingName !== "" &&
      coyPermanentAddressStreet !== "" &&
      coyPermanentAddressPlace !== "" &&
      coyPermanentAddressCountryValue !== "" &&
      (coyContactDetailsTelephone1 !== "" ||
        coyContactDetailsTelephone2 !== "") &&
      (coyContactDetailsEmail1 !== "" || coyContactDetailsEmail2 !== "")
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/corporate_identification/save/contact_details", {
          coyOperationOtherCountry1Value: coyOperationOtherCountry1Value,
          coyOperationOtherCountry2Value: coyOperationOtherCountry2Value,
          coyOperationOtherCountry3Value: coyOperationOtherCountry3Value,
          coyOperationOtherCountry4Value: coyOperationOtherCountry4Value,
          coyPermanentAddressCountryValue: coyPermanentAddressCountryValue,
          coyTradingAddressCountryValue: coyTradingAddressCountryValue,
          coyPermanentAddressBuildingName: coyPermanentAddressBuildingName,
          coyPermanentAddressStreet: coyPermanentAddressStreet,
          coyPermanentAddressStreetNo: coyPermanentAddressStreetNo,
          coyPermanentAddressPlace: coyPermanentAddressPlace,
          coyTradingAddressBuildingName: coyTradingAddressBuildingName,
          coyTradingAddressStreet: coyTradingAddressStreet,
          coyTradingAddressStreetNo: coyTradingAddressStreetNo,
          coyTradingAddressPlace: coyTradingAddressPlace,
          coyMailingAddressBuildingName: coyMailingAddressBuildingName,
          coyMailingAddressPoBox: coyMailingAddressPoBox,
          coyMailingAddressStreet: coyMailingAddressStreet,
          coyMailingAddressStreetNo: coyMailingAddressStreetNo,
          coyMailingAddressPlace: coyMailingAddressPlace,
          coyMailingAddressCountryValue: coyMailingAddressCountryValue,
          coyContactDetailsTelephone1: coyContactDetailsTelephone1,
          coyContactDetailsTelephone2: coyContactDetailsTelephone2,
          coyContactDetailsFax1: coyContactDetailsFax1,
          coyContactDetailsFax2: coyContactDetailsFax2,
          coyContactDetailsEmail1: coyContactDetailsEmail1,
          coyContactDetailsEmail2: coyContactDetailsEmail2,
          coyWebsite: coyWebsite,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/corporate_identification/business_intentions"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      coyPermanentAddressBuildingName,
      countriesList,
      displayMoreCountry,
      coyTradingAddressStreet,
      coyTradingAddressCountryName,
      coyTradingAddressCountryValue,
      coyPermanentAddressStreet,
      coyPermanentAddressStreetNo,
      coyPermanentAddressPlace,
      coyPermanentAddressCountryValue,
      coyTradingAddressBuildingName,
      coyTradingAddressStreetNo,
      coyTradingAddressPlace,
      coyOperationOtherCountry1Value,
      coyOperationOtherCountry2Value,
      coyOperationOtherCountry3Value,
      coyOperationOtherCountry4Value,
      coyMailingAddressBuildingName,
      coyMailingAddressPoBox,
      coyMailingAddressStreet,
      coyMailingAddressPlace,
      coyContactDetailsTelephone1,
      coyContactDetailsTelephone2,
      coyContactDetailsFax1,
      coyContactDetailsFax2,
      coyContactDetailsEmail1,
      coyContactDetailsEmail2,
      coyWebsite,
      coyPermanentAddressCountryName,
      coyOperationOtherCountry1Name,
      coyOperationOtherCountry2Name,
      coyOperationOtherCountry3Name,
      coyOperationOtherCountry4Name,
      buttonLoading,
      coyMailingAddressCountryName,
      coyMailingAddressCountryValue,
    } = this.state;
    let showCountryMoreText = displayMoreCountry === false ? "Add" : "Hide";
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h2 className="color-white text-center identification-header">Contact Details</h2> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-xl">
                          Registered address
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Building name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyPermanentAddressBuildingName"
                            id="coyPermanentAddressBuildingName"
                            value={coyPermanentAddressBuildingName}
                            onChange={this.onchange}
                            placeholder="Building name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Street<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyPermanentAddressStreet"
                            id="coyPermanentAddressStreet"
                            value={coyPermanentAddressStreet}
                            onChange={this.onchange}
                            placeholder="Street"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Street No
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyPermanentAddressStreetNo"
                            id="coyPermanentAddressStreetNo"
                            value={coyPermanentAddressStreetNo}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Place<span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyPermanentAddressPlace"
                            id="coyPermanentAddressPlace"
                            value={coyPermanentAddressPlace}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Country<span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyPermanentAddressCountry"
                              )
                            }
                            value={{
                              label: coyPermanentAddressCountryName,
                              value: coyPermanentAddressCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-xl">
                          Trading address
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Building name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyTradingAddressBuildingName"
                            id="coyTradingAddressBuildingName"
                            value={coyTradingAddressBuildingName}
                            onChange={this.onchange}
                            placeholder="Building name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Street
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyTradingAddressStreet"
                            id="coyTradingAddressStreet"
                            value={coyTradingAddressStreet}
                            onChange={this.onchange}
                            placeholder="Street"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Street No
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyTradingAddressStreetNo"
                            id="coyTradingAddressStreetNo"
                            value={coyTradingAddressStreetNo}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Place
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyTradingAddressPlace"
                            id="coyTradingAddressPlace"
                            value={coyTradingAddressPlace}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Country
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyTradingAddressCountry"
                              )
                            }
                            value={{
                              label: coyTradingAddressCountryName,
                              value: coyTradingAddressCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row ">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-xl">
                          Other countries of operations{" "}
                          <a
                            href="#/"
                            className="color-white pl-2 text-underline"
                            onClick={() => this.displayMoreToggle()}
                          >
                            {showCountryMoreText} More
                          </a>
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <Select
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countriesList}
                          onChange={(selectedOption) =>
                            this.handleCountryChange(
                              selectedOption,
                              "coyOperationOtherCountry1"
                            )
                          }
                          value={{
                            label: coyOperationOtherCountry1Name,
                            value: coyOperationOtherCountry1Value,
                          }}
                        />
                      </div>
                      <div className="col-md-6 country-top-margin">
                        <Select
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countriesList}
                          onChange={(selectedOption) =>
                            this.handleCountryChange(
                              selectedOption,
                              "coyOperationOtherCountry2"
                            )
                          }
                          value={{
                            label: coyOperationOtherCountry2Name,
                            value: coyOperationOtherCountry2Value,
                          }}
                        />
                      </div>
                    </div>
                    {displayMoreCountry === true && (
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyOperationOtherCountry3"
                              )
                            }
                            value={{
                              label: coyOperationOtherCountry3Name,
                              value: coyOperationOtherCountry3Value,
                            }}
                          />
                        </div>
                        <div className="col-md-6 country-top-margin">
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyOperationOtherCountry4"
                              )
                            }
                            value={{
                              label: coyOperationOtherCountry4Name,
                              value: coyOperationOtherCountry4Value,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-xl">
                          Mailing address
                        </h6>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Building name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyMailingAddressBuildingName"
                            id="coyMailingAddressBuildingName"
                            value={coyMailingAddressBuildingName}
                            onChange={this.onchange}
                            placeholder="Building name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            PO Box
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyMailingAddressPoBox"
                            id="coyMailingAddressPoBox"
                            value={coyMailingAddressPoBox}
                            onChange={this.onchange}
                            placeholder="PO Box"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Street
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyMailingAddressStreet"
                            id="coyMailingAddressStreet"
                            value={coyMailingAddressStreet}
                            onChange={this.onchange}
                            placeholder="Street"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Place
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyMailingAddressPlace"
                            id="coyMailingAddressPlace"
                            value={coyMailingAddressPlace}
                            onChange={this.onchange}
                            placeholder="Place"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Country
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyMailingAddressCountry"
                              )
                            }
                            value={{
                              label: coyMailingAddressCountryName,
                              value: coyMailingAddressCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-xl">
                          Contact Details
                        </h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label className="ttext-default-color text-default">
                          Telephone<span className="required-sign">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyContactDetailsTelephone1"
                            id="coyContactDetailsTelephone1"
                            value={coyContactDetailsTelephone1}
                            onChange={this.onchange}
                            placeholder="Telephone No"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyContactDetailsTelephone2"
                            id="coyContactDetailsTelephone2"
                            value={coyContactDetailsTelephone2}
                            onChange={this.onchange}
                            placeholder="Telephone No"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label className="text-default-color text-default">
                          Fax
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyContactDetailsFax1"
                            id="coyContactDetailsFax1"
                            value={coyContactDetailsFax1}
                            onChange={this.onchange}
                            placeholder="Fax 1"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyContactDetailsFax2"
                            id="coyContactDetailsFax2"
                            value={coyContactDetailsFax2}
                            onChange={this.onchange}
                            placeholder="Fax 2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label className="text-default-color text-default">
                          Email<span className="required-sign">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyContactDetailsEmail1"
                            id="coyContactDetailsEmail1"
                            value={coyContactDetailsEmail1}
                            onChange={this.onchange}
                            placeholder="Email Address"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyContactDetailsEmail2"
                            id="coyContactDetailsEmail2"
                            value={coyContactDetailsEmail2}
                            onChange={this.onchange}
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Website
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyWebsite"
                            id="coyWebsite"
                            onChange={this.onchange}
                            placeholder="Website"
                            value={coyWebsite}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_identification/company_details"
                className="color-white"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-2"
                type="button"
                onClick={this.saveContactDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateIdentificationContactDetails;
