import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import Avatar from "react-avatar";
import axios from "axios";
import investorLogoImg from "../../assets/img/investor-w.png";
import exchangeLogoImg from "../../assets/img/exchange-w.png";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import { API_ROOT } from "../../services/api-config";

class CustomHeader extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {
      listedTokens: [],
      toggleStatus: false,
      ethUnitUsdPrice: 0,
      btcUnitUsdPrice: 0,
      isTokenSelectorToggleOn: false,
    };
    this.tokenNames = {
      BRX: "Brox Token",
      XPR: "Demo Token 1",
      NRT: "Demo Token 2",
    };
  }

  componentDidMount() {
    this.setXprUnitPrice();
    const { listedTokens } = this.props;

    if (listedTokens) {
      this.prepareTokensData(listedTokens);
    }
  }

  dropDownClick = () => {
    this.setState({ toggleStatus: !this.state.toggleStatus });
  };
  async setXprUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { listedTokens } = this.props;
    if (prevProps.listedTokens !== listedTokens) {
      this.prepareTokensData(listedTokens);
    }
  }

  async prepareTokensData(listedTokens = "") {
    if (listedTokens) {
      let listedFinalTokens = [];
      let btcPriceUsd = "";
      const geminiPricingResponse = await axios.get(
        `${API_ROOT}/geminiPricing.json`
      );
      if (geminiPricingResponse && geminiPricingResponse.data) {
        btcPriceUsd = geminiPricingResponse.data.btc;
      }
      let priceUsd = btcPriceUsd;
      if (priceUsd > 0) {
        _.forEach(listedTokens, function (value, key) {
          const tokenUnitPrice =
            parseFloat(value.listedTokenPrice ? value.listedTokenPrice : 0) /
            parseFloat(priceUsd);
          value.tokenUnitPrice = tokenUnitPrice.toString();
          listedFinalTokens.push(value);
        });
      }
      if (listedFinalTokens) {
        this.setState({ listedTokens: listedFinalTokens });
      }
    }
  }

  toggleTokenSelector = () => {
    this.setState(prevState => ({
      isTokenSelectorToggleOn: !prevState.isTokenSelectorToggleOn
    }));
  }

  render() {
    let { authUserInfo, isExchange, isKycPage, activeTokenName } = this.props;
    const { listedTokens, toggleStatus, ethUnitUsdPrice, btcUnitUsdPrice } =
      this.state;
    let imagePath =
      isExchange && isExchange === true ? exchangeLogoImg : investorLogoImg;
    const customHaderImage =
      authUserInfo && authUserInfo.emdLogo ? authUserInfo.emdLogo : imagePath;
    let userDisplayName =
      !_.isUndefined(authUserInfo) &&
        authUserInfo !== null &&
        authUserInfo !== ""
        ? authUserInfo.userDisplayName
        : "";
    let headerClass =
      isExchange && isExchange === true
        ? "top-bar exchange-top-bar"
        : "top-bar custom-top-bar";

    let listedTokensHtml = "";
    const actvieTokenDetails = _.find(listedTokens, [
      "listedTokenSymbol",
      activeTokenName,
    ]);
    if (!_.isUndefined(listedTokens) && !_.isEmpty(listedTokens)) {
      listedTokensHtml = listedTokens.map((tokenDetails, index) => (
        <div
          className={`fancy-selector-option ${activeTokenName === "BRX" ? "active" : ""
            }`}
          key={index}
        >
          <div
            className="fs-main-info token-container d-flex justify-content-between align-items-center"
            onClick={() => {
              this.props.baseTokenChanged(tokenDetails.listedTokenSymbol);
              this.dropDownClick();
            }}
          >
            <div className="d-flex">
              <div>
                <Avatar
                  value={tokenDetails.listedTokenSymbol.charAt(0)}
                  round={true}
                  size={60}
                  style={{ fontWeight: "bold" }}
                  textSizeRatio={2}
                  color="#59ADDE"
                />
              </div>
              <div className="comp-name ml-2 d-flex justify-content-center flex-column">
                <div>
                  {_.truncate(tokenDetails.listedTokenCompanyName, {
                    length: 30,
                  })}
                </div>
                <div className="token-text">
                  {`(${tokenDetails.listedTokenSymbol})`}
                </div>
              </div>
            </div>
            <div>
              {typeof tokenDetails.tokenUnitPrice === "string" && (
                <div className="fs-sub d-flex justify-content-between flex-column">
                  <div className="token-text">
                    {tokenDetails.listedTokenPrice} USD
                  </div>
                  <div className="token-text">
                    {tokenDetails.tokenUnitPrice.substring(0, 8)} BTC
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ));
    }

    return (
      <div>
        <div
          className={`${headerClass} ${!isExchange && !isKycPage ? "padding-10" : ""
            }`}
        >
          <div>
            <Link to="/">
              <img
                className="customLogo"
                src={`${customHaderImage}`}
                height={70}
                alt="Investor"
              />
            </Link>
          </div>

          {!isKycPage && (
            <span className="text-white">
              ETH/USD : ${niceNumberDecimalDisplay(ethUnitUsdPrice, 2)} |
              BTC/USD : ${niceNumberDecimalDisplay(btcUnitUsdPrice, 2)}{" "}
            </span>
          )}

          {!isKycPage && (
            <div
              className={`top-bar color-scheme-transparent admin-top-bar ${!isExchange && !isKycPage ? "token-picker-widget" : ""
                }`}
            >
              {listedTokensHtml && (
                <div className={`fancy-selector-w ${this.state.isTokenSelectorToggleOn ? 'opened' : ''}`}>
                  <div className="fancy-selector-current">
                    <div className="fs-img">
                      <Avatar
                        value={activeTokenName}
                        round={true}
                        size={50}
                        textSizeRatio={4}
                        color="#59ADDE"
                      />
                    </div>
                    <div className="fs-main-info">
                      <div className="fs-name">
                        <span>
                          {actvieTokenDetails &&
                            actvieTokenDetails.listedTokenCompanyName
                            ? actvieTokenDetails.listedTokenCompanyName
                            : ""}
                        </span>
                        <strong>({activeTokenName})</strong>
                      </div>
                      <div className="fs-sub">
                        <span>My Holdings: </span>
                        <span>
                          {actvieTokenDetails ? niceNumberDecimalDisplay(
                            actvieTokenDetails.tokenBalance,
                            2
                          ) : 0}{" "}
                          |
                        </span>
                        <strong>
                          $
                          {actvieTokenDetails
                            ? niceNumberDecimalDisplay(
                              actvieTokenDetails.myAssetValue,
                              2
                            )
                            : 0}
                        </strong>
                      </div>
                    </div>
                    <div className="fs-selector-trigger dropdown-toggler" onClick={this.toggleTokenSelector}>
                      <i className="os-icon os-icon-arrow-down4"></i>
                    </div>
                  </div>
                  <div className="fancy-selector-options fancy-shadow">
                    {listedTokensHtml}
                  </div>
                </div>
              )}
            </div>
          )}

          {(isExchange || isKycPage) && (
            <div
              className={`top-menu-controls ${!isExchange && !isKycPage ? "mb-40" : ""
                }`}
            >
              {((isExchange && isExchange === true) ||
                (isKycPage && isKycPage === true)) && (
                  <div className="custom-username">
                    Welcome, {userDisplayName}
                  </div>
                )}

              {((isExchange && isExchange === true) ||
                (isKycPage && isKycPage === true)) && (
                  <div className="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
                    <i className="os-icon os-icon-user-male-circle menu-vertical-align-set"></i>
                    <div className="os-dropdown">
                      <ul>
                        <li>
                          <Link to="/my_account">
                            <i className="os-icon os-icon-ui-49"></i>
                            <span>Profile Settings</span>
                          </Link>
                        </li>
                        {isExchange && isExchange === true && (
                          <li>
                            <Link to="/withdrawals">
                              <i className="fa fa-money"></i>
                              <span>Withdrawals</span>
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link to="/logout">
                            <i className="fa fa-power-off"></i>
                            <span>Logout</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default CustomHeader;
