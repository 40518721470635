import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class CorporateIdentificationWealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyEstimatedGlobalNetWealth: "l500000USD",
      coyEstimatedGlobalNetWealthOther: "",
      coyBuiltGlobalWealth: "business_income",
      coyBuiltGlobalWealthOther: "",
      coyInvestmentVehicle: "n",
      coyVehiclesCapitalSource: "professional_income",
      coyVehiclesCapitalSourceOther: "",
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.displayMoreToggle = this.displayMoreToggle.bind(this);
    this.saveWealthDetails = this.saveWealthDetails.bind(this);
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async componentWillMount() {
    let authenticationToken = this.props.authToken;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/company/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "coyEstimatedGlobalNetWealth",
          "coyEstimatedGlobalNetWealthOther",
          "coyBuiltGlobalWealth",
          "coyBuiltGlobalWealthOther",
          "coyInvestmentVehicle",
          "coyVehiclesCapitalSource",
          "coyVehiclesCapitalSourceOther",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  displayMoreToggle(stateName = "") {
    let { displayMoreCountry } = this.state;
    this.setState({
      displayMoreCountry: !displayMoreCountry,
    });
  }

  async saveWealthDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyEstimatedGlobalNetWealth,
      coyEstimatedGlobalNetWealthOther,
      coyBuiltGlobalWealth,
      coyBuiltGlobalWealthOther,
      coyInvestmentVehicle,
      coyVehiclesCapitalSource,
      coyVehiclesCapitalSourceOther,
    } = this.state;

    if (coyEstimatedGlobalNetWealth !== "" && coyBuiltGlobalWealth !== "") {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/corporate_identification/save/wealth_details", {
          coyEstimatedGlobalNetWealth: coyEstimatedGlobalNetWealth,
          coyEstimatedGlobalNetWealthOther: coyEstimatedGlobalNetWealthOther,
          coyBuiltGlobalWealth: coyBuiltGlobalWealth,
          coyBuiltGlobalWealthOther: coyBuiltGlobalWealthOther,
          coyInvestmentVehicle: coyInvestmentVehicle,
          coyVehiclesCapitalSource: coyVehiclesCapitalSource,
          coyVehiclesCapitalSourceOther: coyVehiclesCapitalSourceOther,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/corporate_identification/understanding_of_market"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      coyEstimatedGlobalNetWealth,
      coyBuiltGlobalWealth,
      coyInvestmentVehicle,
      coyBuiltGlobalWealthOther,
      coyVehiclesCapitalSource,
      coyVehiclesCapitalSourceOther,
      coyEstimatedGlobalNetWealthOther,
      buttonLoading,
    } = this.state;

    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            What is the estimated global net wealth of your
                            company?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyEstimatedGlobalNetWealth"
                              className="wealth-split-radio-container"
                              value={coyEstimatedGlobalNetWealth}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyEstimatedGlobalNetWealth"
                                )
                              }
                            >
                              <Radio
                                value="l500000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH[
                                    "L500000USD"
                                  ]
                                }
                              />
                              <Radio
                                value="500000to1000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH[
                                    "500000TO1000000USD"
                                  ]
                                }
                              />
                              <Radio
                                value="1000000to5000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "1000000TO5000000USD"
                                  ]
                                }
                              />
                              <Radio
                                value="5000000to10000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "5000000TO10000000USD"
                                  ]
                                }
                              />
                              <br />
                              <Radio
                                value="m10000000USD"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "M10000000USD"
                                  ]
                                }
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                                    "OTHER"
                                  ]
                                }
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {coyEstimatedGlobalNetWealth === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-default-color text-default">
                                  Other
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="coyEstimatedGlobalNetWealthOther"
                                  onChange={this.onchange}
                                  placeholder="Other"
                                  value={coyEstimatedGlobalNetWealthOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            How has this been built up?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyBuiltGlobalWealth"
                              className="wealth-split-radio-container"
                              value={coyBuiltGlobalWealth}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyBuiltGlobalWealth"
                                )
                              }
                            >
                              <Radio
                                value="business_income"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[
                                    "BUSINESS_INCOME"
                                  ]
                                }
                              />

                              <Radio
                                value="income_from_investment"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[
                                    "INCOME_FROM_INVESTMENT"
                                  ]
                                }
                              />

                              <Radio
                                value="shareholder_equity"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[
                                    "SHAREHOLDER_EQUITY"
                                  ]
                                }
                              />

                              <Radio
                                value="private_placement"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[
                                    "PRIVATE_PLACEMENT"
                                  ]
                                }
                              />
                              <br />

                              <Radio
                                value="sale_of_investments"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[
                                    "SALE_OF_INVESTMENT"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />

                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP[
                                    "OTHER"
                                  ]
                                }
                                name="builtGlobalWealth"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyBuiltGlobalWealth === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Other
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="coyBuiltGlobalWealthOther"
                              value={coyBuiltGlobalWealthOther}
                              onChange={this.onchange}
                              placeholder="Other"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            Is your Company a Personal Investment Vehicle?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyInvestmentVehicle"
                              className="wealth-split-radio-container"
                              value={coyInvestmentVehicle}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyInvestmentVehicle"
                                )
                              }
                            >
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label=" Yes"
                                value="y"
                              />
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label=" No"
                                value="n"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            What is the source of the Vehicle’s capital?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="coyVehiclesCapitalSource"
                              className="wealth-split-radio-container"
                              value={coyVehiclesCapitalSource}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "coyVehiclesCapitalSource"
                                )
                              }
                            >
                              <Radio
                                value="professional_income"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[
                                    "PROFESSIONAL_INCOME"
                                  ]
                                }
                              />

                              <Radio
                                value="sale_from_investment"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[
                                    "SALE_FROM_INVESTMENT"
                                  ]
                                }
                              />

                              <Radio
                                value="real_estate_transaction"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[
                                    "REAL_ESTATE_TRANSACTION"
                                  ]
                                }
                              />

                              <Radio
                                value="gift_inheritance"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[
                                    "GIFT_INHERITANCE"
                                  ]
                                }
                              />
                              <br />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[
                                    "INCOME_FROM_INVESTMENT"
                                  ]
                                }
                                name="regularIncome"
                                value="income_from_investment"
                              />

                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE[
                                    "OTHER"
                                  ]
                                }
                                name="regularIncome"
                                value="other"
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {coyVehiclesCapitalSource === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="text-default-color text-default">
                                  Other
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={this.onchange}
                                  placeholder="Other"
                                  name="coyVehiclesCapitalSourceOther"
                                  value={coyVehiclesCapitalSourceOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_identification/business_intentions"
                className="color-white"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-2"
                type="button"
                onClick={this.saveWealthDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateIdentificationWealth;
