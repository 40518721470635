import React, { Component } from "react";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import _ from "lodash";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
class PaymentRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      paymentRequestModalOpen,
      onCloseModal,
      onInputValueChange,
      saveUSDPaymentRequest,
      paymentRequestButtonLoading,
      transactionAmount,
      transactionNote,
      fileUpdated,
    } = this.props;
    let buttonDisabled = paymentRequestButtonLoading === true ? true : false;

    return (
      <Modal
        open={paymentRequestModalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">
                      Make USD Deposit Request
                    </h4>
                    <div className="onboarding-text">
                      Generate USD pay request by filling below required
                      details.
                    </div>
                    <form>
                      <div className="form-group">
                        <label htmlFor="transactionAmount">
                          Transaction Amount
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="transactionAmount"
                          placeholder="Enter transaction amount"
                          type="text"
                          value={transactionAmount}
                          name="transactionAmount"
                          onChange={onInputValueChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="transactionNote">Note</label>
                        <textarea
                          className="form-control"
                          placeholder="Enter Note"
                          name="transactionNote"
                          id="transactionNote"
                          value={transactionNote}
                          onChange={onInputValueChange}
                        ></textarea>
                      </div>

                      <label> Payment Proof File</label>
                      <div className="resource-continaer payment-proof-container dropzoneIdContainer">
                        <div className="row">
                          <div className="col-md-12">
                            <FilePond
                              allowMultiple={false}
                              allowFileTypeValidation={true}
                              acceptedFileTypes={[
                                "image/*",
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              ]}
                              instantUpload={false}
                              onupdatefiles={(fileItems) => {
                                if (
                                  !_.isEmpty(fileItems) &&
                                  fileItems[0].fileSize < 5000000
                                ) {
                                  if (
                                    fileItems[0].fileExtension === "pdf" ||
                                    fileItems[0].fileExtension === "docx"
                                  ) {
                                    fileUpdated(fileItems);
                                  } else if (
                                    (fileItems[0].fileExtension == "jpeg" ||
                                      fileItems[0].fileExtension == "jpg" ||
                                      fileItems[0].fileExtension == "gif" ||
                                      fileItems[0].fileExtension == "tiff" ||
                                      fileItems[0].fileExtension == "psd" ||
                                      fileItems[0].fileExtension == "eps" ||
                                      fileItems[0].fileExtension == "ai" ||
                                      fileItems[0].fileExtension == "indd" ||
                                      fileItems[0].fileExtension == "raw" ||
                                      fileItems[0].fileExtension == "png") &&
                                    fileItems[0].fileSize < 500000
                                  ) {
                                    fileUpdated(fileItems);
                                  } else if (
                                    (fileItems[0].fileExtension == "jpeg" ||
                                      fileItems[0].fileExtension == "jpg" ||
                                      fileItems[0].fileExtension == "gif" ||
                                      fileItems[0].fileExtension == "tiff" ||
                                      fileItems[0].fileExtension == "psd" ||
                                      fileItems[0].fileExtension == "eps" ||
                                      fileItems[0].fileExtension == "ai" ||
                                      fileItems[0].fileExtension == "indd" ||
                                      fileItems[0].fileExtension == "raw" ||
                                      fileItems[0].fileExtension == "png") &&
                                    fileItems[0].fileSize > 500000
                                  ) {
                                    toast.error(
                                      "Please make sure image size is under 500kb"
                                    );
                                  }
                                } else if (
                                  !_.isEmpty(fileItems) &&
                                  fileItems[0].fileSize > 5000000
                                ) {
                                  toast.error(
                                    "Please make sure document size is under 5mb"
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary permian-button exchange-usd-submit-btn"
                          type="button"
                          onClick={saveUSDPaymentRequest}
                          disabled={buttonDisabled}
                        >
                          {" "}
                          Submit{" "}
                          {paymentRequestButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default PaymentRequestModal;
