import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import { toast } from "react-toastify";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { tokenSymbol } from "../../services/api-config";

class Withdrawals extends Component {
  constructor(props) {
    super(props);
    const typeOptions = [
      { value: "XPR", label: tokenSymbol },
      { value: "USD", label: "USD" },
    ];
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
      requestModalOpen: false,
      detailModalOpen: false,
      amount: "",
      requestButtonLoading: false,
      typeOptions,
      typeValue: null,
      typeName: "Select Type",
      totalExchangeUSDBalance: "",
      totalTokens: "",
      requestDetails: [],
    };
    this.onchange = this.onchange.bind(this);
    this.onRequestCloseModal = this.onRequestCloseModal.bind(this);
    this.savePaymentRequest = this.savePaymentRequest.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.onDetailsCloseModal = this.onDetailsCloseModal.bind(this);
    this.openDetailsModal = this.openDetailsModal.bind(this);
  }

  async componentDidMount() {
    this.getWalletBalance();
    document.title =
      messageConstants.WITHDRAWAL_REQUESTS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.props.pageProgress("force_remove");
  }

  async getWalletBalance() {
    const { typeOptions } = this.state;
    let stateObject = {};
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const userAddressesResponse = await api
      .setToken(authenticationToken)
      .get("user/currency/list");
    if (userAddressesResponse.code === 200) {
      stateObject.totalExchangeUSDBalance =
        userAddressesResponse.data.totalExchangeUSDBalance;
      stateObject.totalTokens = userAddressesResponse.data.tokens;
      const depositeAddresses = userAddressesResponse.data.depositeAddresses;
      if (!_.isEmpty(depositeAddresses)) {
        _.forEach(depositeAddresses, function (value, key) {
          var temp = {
            label: value.addressType,
            value: value.addressType,
          };
          typeOptions.push(temp);
        });
        stateObject.depositeAddresses = depositeAddresses;
        if (typeOptions && !_.isEmpty(typeOptions)) {
          stateObject.typeOptions = typeOptions;
        }
      }
      if (!_.isEmpty(stateObject)) {
        this.setState(stateObject);
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onRequestCloseModal() {
    this.setState({ requestModalOpen: false });
  }

  openRequesModal() {
    this.setState({ requestModalOpen: true });
  }

  onDetailsCloseModal() {
    this.setState({
      detailModalOpen: false,
      requestDetails: [],
    });
  }

  openDetailsModal(details) {
    this.setState({
      detailModalOpen: true,
      requestDetails: details,
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/currency/transactions", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.withdrawalRequests,
          totalSize: response.data.withdrawalRequestNumber,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTypeChange(selectedOption) {
    this.setState({
      typeValue: selectedOption.value,
      typeName: selectedOption.label,
    });
  }

  async savePaymentRequest() {
    let authenticationToken = this.props.authToken;
    const { amount, userNote, typeValue } = this.state;
    if (!_.isUndefined(amount) && amount > 0) {
      this.setState({
        requestButtonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/create/withdrawal/request", {
          amount,
          userNote: userNote,
          currencyType: typeValue,
        });
      if (response) {
        this.setState({
          requestButtonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          amount: "",
          userNote: "",
        });
        toast.success(response.message);
        this.onRequestCloseModal();
        this.getRecords();
        this.getWalletBalance();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg =
        amount <= 0 || amount === ""
          ? "Please enter valid withdrawal amount."
          : "Please enter valid details for required fields.";
      toast.error(msg);
    }
  }

  render() {
    let myWalletCurrencyBalance = "";
    const { depositeAddresses } = this.state;
    const _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "txnId",
        text: "#ID",
      },
      {
        headerClasses: "text-bold",
        dataField: "amount",
        text: "amount",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var amount = cell !== "" && cell !== null ? cell : "N/A";
          return (
            <div>
              {amount}{" "}
              {row.currencyType === "XPR" ? tokenSymbol : row.currencyType}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "status",
        formatter: function (cell) {
          let labelClassName =
            cell === "p"
              ? "badge-warning"
              : cell === "a"
              ? "badge-primary"
              : "badge-danger";
          let label =
            cell === "p" ? "Pending" : cell === "a" ? "Executed" : "Rejected";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Created Date",
        formatter: function (cell) {
          var date =
            cell && cell !== "" && cell !== null
              ? moment(cell).format("DD/MM/YYYY HH:mm")
              : "N/A";
          return <div>{date}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "updatedOnUTC",
        text: "Executed Date",
        formatter: function (cell) {
          var date =
            cell && cell !== "" && cell !== null
              ? moment(cell).format("DD/MM/YYYY HH:mm")
              : "N/A";
          return <div>{date}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "_id",
        text: "Details",
        formatter: function (cell, row) {
          return (
            <button
              className="btn btn-xs btn-sm btn-primary"
              onClick={() => _this.openDetailsModal(row)}
            >
              View Details
            </button>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    if (depositeAddresses && !_.isEmpty(depositeAddresses)) {
      myWalletCurrencyBalance = depositeAddresses.map(
        (depositValue, mainIndex) => (
          <div className="col-2">
            <div className="el-tablo highlight">
              <div>{depositValue.addressType}</div>
              <div className="value">
                {depositValue.walletBalance > 0
                  ? depositValue.walletBalance
                  : 0}{" "}
                {depositValue.addressType}
              </div>
            </div>
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper wallet-statistics-element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header">Income</h6>
                    <div className="project-box">
                      <div className="project-info">
                        <div className="row align-items-center">
                          <div className="col-sm-12">
                            <div className="row">
                              <div className="col-lg-2 col-sm-4 col-xs-6">
                                <div className="el-tablo">
                                  <div className="title">BTC Balance</div>
                                  <div className="value">0 BTC</div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-sm-4 col-xs-6">
                                <div className="el-tablo">
                                  <div className="title">ETH Balance</div>
                                  <div className="value">0 ETH</div>
                                </div>
                              </div>
                              {/* <div className="col-2">
                                <div className="el-tablo highlight">
                                  <div>USDC Balance</div>
                                  <div className="value">0 USDC</div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="element-box">
                <h5 className="form-header">
                  <span>
                    <span>Income Statement</span>
                  </span>
                </h5>
                <div className="mt-2">
                  <span
                    className="alert alert-defult text-center"
                    style={{ display: "inherit" }}
                  >
                    Income statement records not found.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Withdrawals;
