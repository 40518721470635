import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import dashboardIcon from "../../assets/img/sidebar-icons/dashboard.svg?v1";
import dealerIcon from "../../assets/img/sidebar-icons/dealer.svg?v1";
import logoutIcon from "../../assets/img/sidebar-icons/logout.svg?v1";
import issuersIcon from "../../assets/img/sidebar-icons/issuers.svg?v1";
import resourcesIcon from "../../assets/img/sidebar-icons/resources.svg?v1";
import statementIcon from "../../assets/img/sidebar-icons/statement.svg?v1";
import subscriptionIcon from "../../assets/img/sidebar-icons/subscriptions.svg?v1";
import portfolioIcon from "../../assets/img/sidebar-icons/portfolio.svg?v1";
class UserDashboardMenu extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {};
    this.menu = React.createRef();
    this.windowResizeHandler = this.windowResizeHandler.bind(this);
  }

  activeRoute = (routeName) => {
    return window.location.pathname.indexOf(routeName) > -1
      ? "menu_active"
      : "cursor-pointer";
  };

  toggleMenu = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive });
    this.state.isMenuActive
      ? (this.menu.current.style.display = "none")
      : (this.menu.current.style.display = "block");
  };

  closeMenu = () => {
    if (window.innerWidth < 768) {
      this.menu.current.style.display = "none";
    }
  };

  windowResizeHandler = () => {
    const menu = this.menu;
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768 && menu) {
        this.menu.current.style.display = "block";
      }
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.windowResizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResizeHandler);
  }

  render() {
    let { authUserInfo } = this.props;
    let userDisplayName =
      authUserInfo &&
        authUserInfo.userDisplayName
        ? authUserInfo.userDisplayName
        : "";
    return (
      <div className="menu-container">
        <div className="menu-mobile menu-activated-on-click color-scheme-dark">
          <div className="mm-logo-buttons-w">
            <div className="mm-buttons">
              <div className="mobile-menu-trigger" onClick={this.toggleMenu}>
                <div className="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>
          <div className="menu-and-user" ref={this.menu}>
            <div className="logged-user-w">
              <div className="logged-user-info-w">
                <div className="logged-user-name">
                  Welcome {userDisplayName}
                </div>
              </div>
            </div>

            <ul className="main-menu">
              <li
                className={this.activeRoute("dashboard")}
                onClick={this.closeMenu}
              >
                <Link to="/dashboard" className="mobile-menu-link">
                  <div className="icon-w" title="Dashboard">
                    {/* <div className="os-icon os-icon-layout"></div> */}
                    <img
                      src={dashboardIcon}
                      alt="dashboard"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("my_account")}
                onClick={this.closeMenu}
              >
                <Link to="/my_account" className="mobile-menu-link">
                  <div className="icon-w" title="My Account">
                    <div
                      className="fa fa-user"
                      style={{ color: "#395cbe" }}
                    ></div>
                  </div>
                  <span>My Account</span>
                </Link>
              </li>

              <li
                className={this.activeRoute("portfolio")}
                onClick={this.closeMenu}
              >
                <Link to="/portfolio" className="mobile-menu-link">
                  <div className="icon-w" title="Portfolio">
                    <img
                      src={portfolioIcon}
                      alt="portfolio"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Portfolio</span>
                </Link>
              </li>

              <li
                className={this.activeRoute("transactions")}
                onClick={this.closeMenu}
              >
                <Link to="/transactions" className="mobile-menu-link">
                  <div className="icon-w">
                    <img
                      src={subscriptionIcon}
                      alt="transactions"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Subscriptions</span>
                </Link>
              </li>

              <li
                className={this.activeRoute("income")}
                onClick={this.closeMenu}
              >
                <Link to="/income" className="mobile-menu-link">
                  <div className="icon-w">
                    <img
                      src={statementIcon}
                      alt="income"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Income</span>
                </Link>
              </li>

              {/* <li className="has-sub-menu">
                <a href="javascript:void(0);">
                  <div className="icon-w" title="Statement">
                    <img src={statementIcon} alt="statement" height="22" width="22" />
                  </div>
                  <span>Statement</span>
                </a>
                <ul className="sub-menu">
                  <li className={this.activeRoute('portfolio')}>
                    <Link to="/portfolio" className="mobile-menu-link">Portfolio</Link>
                  </li>
                  <li className={this.activeRoute('transactions')}>
                    <Link to="/transactions" className="mobile-menu-link">Subscriptions</Link>
                  </li>
                  <li className={this.activeRoute('income')}>
                    <Link to="/income" className="mobile-menu-link">Income</Link>
                  </li>
                </ul>
              </li> */}

              {/*<li>
                <Link to="/exchange" target="_blank">
                  <div className="icon-w">
                    <div className="fa fa-exchange"></div>
                  </div>
                  <span>Exchange</span>
                </Link>
              </li>*/}

              {/* <li>
                <Link to="/transactions" target="_blank">
                  <div className="icon-w">
                    <div className="fa fa-history"></div>
                  </div>
                  <span>Transactions</span>
                </Link>
              </li> */}

              {/*<li>
                <Link to="/token_holders" className="mobile-menu-link">
                  <div className="icon-w">
                    <div className="fa fa-money"></div>
                  </div>
                  <span>Token Holders</span>
                </Link>
              </li>*/}

              <li
                className={this.activeRoute("issuers")}
                onClick={this.closeMenu}
              >
                <Link to="/issuers" className="mobile-menu-link">
                  <div className="icon-w" title="Issuers">
                    {/* <div className="fa fa-list"></div> */}
                    <img
                      src={issuersIcon}
                      alt="issuers"
                      height="22"
                      width="22"
                    />
                  </div>
                  <span>Issuers</span>
                </Link>
              </li>

              <li
                className={this.activeRoute("resources")}
                onClick={this.closeMenu}
              >
                <Link to="/resources" className="mobile-menu-link">
                  <div className="icon-w" title="Resources">
                    {/* <div className="fa fa-file"></div> */}
                    <img
                      src={resourcesIcon}
                      alt="resource"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Resources</span>
                </Link>
              </li>

              <li>
                <Link to="/logout" className="mobile-menu-link">
                  <div className="icon-w" title="Logout">
                    {/* <div className="fa fa-sign-out"></div> */}
                    <img src={logoutIcon} alt="logout" height="20" width="20" />
                  </div>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="menu-w menu-activated-on-click color-scheme-light color-style-transparent menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link user-dashboard-menu">
          <div className="logged-user-w avatar-inline">
            <div className="logged-user-i">
              <div className="logged-user-info-w emdcontainer">
                <div className="logged-user-name font-size-12">
                  Welcome {userDisplayName}
                </div>
              </div>
              <div className="logged-user-toggler-arrow">
                <div className="os-icon os-icon-chevron-down"></div>
              </div>
              <div className="logged-user-menu color-style-bright">
                <div className="logged-user-avatar-info display-block">
                  <div className="logged-user-info-w menu-padding-set">
                    <div className="logged-user-name">{userDisplayName}</div>
                    <div className="logged-user-role">Investor</div>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="os-icon os-icon-wallet-loaded"></i>
                </div>
                <ul>
                  <li>
                    <Link to="/my_account">
                      {/* <i className="os-icon os-icon-user-male-circle2"></i> */}
                      <i className="fa fa-user color-white"></i>
                      <label className="color-white">My Account</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout">
                      <i className="os-icon os-icon-signs-11"></i>
                      <label className="color-white">Logout</label>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="menu-and-user">
            <ul className="main-menu">
              <li className={this.activeRoute("dashboard")}>
                <Link to="/dashboard">
                  <div className="icon-w">
                    {/* <div className="os-icon os-icon-layout"></div> */}
                    <img
                      src={dashboardIcon}
                      alt="dashboard"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className={this.activeRoute("portfolio")}>
                <Link to="/portfolio">
                  <div className="icon-w">
                    <img
                      src={portfolioIcon}
                      alt="portfolio"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Portfolio</span>
                </Link>
              </li>

              <li className={this.activeRoute("transactions")}>
                <Link to="/transactions">
                  <div className="icon-w">
                    <img
                      src={subscriptionIcon}
                      alt="transactions"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Subscriptions</span>
                </Link>
              </li>

              <li className={this.activeRoute("income")}>
                <Link to="/income">
                  <div className="icon-w">
                    <img
                      src={statementIcon}
                      alt="income"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Income</span>
                </Link>
              </li>

              {/* <li className="has-sub-menu">
                <a href="true">
                  <div className="icon-w">
                    <img src={statementIcon} alt="statement" height="20" width="20" />
                  </div>
                  <span>Statement</span>
                </a>
                <ul className="sub-menu">
                  <li className={this.activeRoute('portfolio')}>
                    <Link to="/portfolio" className="mobile-menu-link">Portfolio</Link>
                  </li>
                  <li className={this.activeRoute('transactions')}>
                    <Link to="/transactions" className="mobile-menu-link">Subscriptions</Link>
                  </li>
                  <li className={this.activeRoute('income')}>
                    <Link to="/income" className="mobile-menu-link">Income</Link>
                  </li>
                </ul>
              </li> */}
              <li className={this.activeRoute("emd")}>
                <Link to="/emd">
                  <div className="icon-w">
                    <img
                      src={dealerIcon}
                      alt="Manage Dealer"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Manage Dealer</span>
                </Link>
              </li>
              <li className={this.activeRoute("issuers")}>
                <Link to="/issuers">
                  <div className="icon-w">
                    <img
                      src={issuersIcon}
                      alt="issuers"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Issuers</span>
                </Link>
              </li>
              <li className={this.activeRoute("resources")}>
                <Link to="/resources">
                  <div className="icon-w">
                    {/* <div className="fa fa-file"></div> */}
                    <img
                      src={resourcesIcon}
                      alt="resource"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Resources</span>
                </Link>
              </li>
              {/* <li className="selected cursor-pointer">
              <Link to="/logout">
                <div className="icon-w">
                  <div className="fa fa-power-off"></div>
                </div>
                <span>Logout</span>
              </Link>
          </li> */}
            </ul>
          </div>

          {/* <div
            class="element-box-tp mr-3 ml-3"
            style={{ position: "absolute", bottom: 100 }}
          >
            <div class="todo-list">
              <a class="todo-item" href="#">
                <div class="ti-info">
                  <div class="ti-header">Change EMD</div>
                  <div class="ti-sub-header">
                    You can change EMD by click here.
                  </div>
                </div>
                <div class="ti-icon">
                  <i class="os-icon os-icon-arrow-right7"></i>
                </div>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default UserDashboardMenu;
