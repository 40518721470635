import React, { Component } from "react";
import _ from "lodash";
import { Checkbox, Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class IndividualIdentificationUnderstandingOfMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      understandingFinancialMarket: "moderate",
      activeOnFinancialMarket: "l1year",
      activeOnFinancialMarketOther: "",
      assetsManagedBefore: "bymyself",
      assetsManagedBeforeOther: "",
      financialProductsInvested: [],
      financialProductsInvestedOther: "",
      investmentDecisions: "weekly",
      investmentDecisionsOther: "",
      financialProductsInvestedOtherChecked: false,
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.saveUnderstandingDetails = this.saveUnderstandingDetails.bind(this);
    this.changeFinancial = this.changeFinancial.bind(this);
    this.changeFinancialProductOther =
      this.changeFinancialProductOther.bind(this);
    this.checkFincialProductIfSelected =
      this.checkFincialProductIfSelected.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_UNDERSTANDING_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    try {
      const api = new Api();
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "understandingFinancialMarket",
          "understandingFinancialMarketOther",
          "activeOnFinancialMarket",
          "activeOnFinancialMarketOther",
          "assetsManagedBefore",
          "assetsManagedBeforeOther",
          "financialProductsInvested",
          "financialProductsInvestedOther",
          "investmentDecisions",
          "investmentDecisionsOther",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });

          if (
            !_.isUndefined(idnetificationData.financialProductsInvestedOther) &&
            idnetificationData.financialProductsInvestedOther !== ""
          ) {
            stateObj["financialProductsInvestedOtherChecked"] = true;
          }
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  changeFinancial(e, checked, value) {
    let { financialProductsInvested } = this.state;
    if (checked) {
      financialProductsInvested.push(value);
      this.setState({
        financialProductsInvested: financialProductsInvested,
      });
    } else {
      var removeFinancialProductsInvested = {};
      removeFinancialProductsInvested = _.remove(
        financialProductsInvested,
        function (n) {
          return n !== value;
        }
      );
      this.setState({
        financialProductsInvested: removeFinancialProductsInvested,
      });
    }
  }

  changeFinancialProductOther(e, checked) {
    let stateObj = {
      financialProductsInvestedOtherChecked: checked,
    };
    if (!checked) {
      stateObj.financialProductsInvestedOther = "";
    }
    this.setState(stateObj);
  }

  checkFincialProductIfSelected(fieldName) {
    let { financialProductsInvested } = this.state;
    let checked =
      !_.isUndefined(financialProductsInvested) &&
      financialProductsInvested.indexOf(fieldName) > -1
        ? true
        : false;
    return checked;
  }

  async saveUnderstandingDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      understandingFinancialMarket,
      understandingFinancialMarketOther,
      activeOnFinancialMarket,
      activeOnFinancialMarketOther,
      assetsManagedBefore,
      assetsManagedBeforeOther,
      financialProductsInvested,
      financialProductsInvestedOther,
      investmentDecisions,
      investmentDecisionsOther,
    } = this.state;

    if (
      understandingFinancialMarket !== "" ||
      activeOnFinancialMarket !== "" ||
      assetsManagedBefore !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/understanding", {
          understandingFinancialMarket: understandingFinancialMarket,
          understandingFinancialMarketOther: understandingFinancialMarketOther,
          activeOnFinancialMarket: activeOnFinancialMarket,
          activeOnFinancialMarketOther: activeOnFinancialMarketOther,
          assetsManagedBefore: assetsManagedBefore,
          assetsManagedBeforeOther: assetsManagedBeforeOther,
          financialProductsInvested: financialProductsInvested,
          financialProductsInvestedOther: financialProductsInvestedOther,
          investmentDecisions: investmentDecisions,
          investmentDecisionsOther: investmentDecisionsOther,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/individual_identification/submit");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      } else {
        this.setState({
          buttonLoading: false,
        });
      }
    }
  }

  render() {
    let {
      understandingFinancialMarket,
      activeOnFinancialMarket,
      assetsManagedBefore,
      investmentDecisions,
      financialProductsInvestedOtherChecked,
      understandingFinancialMarketOther,
      activeOnFinancialMarketOther,
      financialProductsInvestedOther,
      assetsManagedBeforeOther,
      investmentDecisionsOther,
      buttonLoading,
    } = this.state;

    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h5 className="text-center color-white identification-header">
            Your experience and understanding of financial markets and
            instruments
          </h5>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            How is your knowledge and understanding of financial
                            markets and products?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="understandingFinancialMarket"
                              className="understanding-split-radio-container"
                              value={understandingFinancialMarket}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "understandingFinancialMarket"
                                )
                              }
                            >
                              <Radio
                                value="excellent"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET[
                                    "EXCELLENT"
                                  ]
                                }
                                name="understandingFinancialMarket"
                              />
                              <Radio
                                value="good"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET[
                                    "GOOD"
                                  ]
                                }
                                name="understandingFinancialMarket"
                              />
                              <Radio
                                value="moderate"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET[
                                    "MODERATE"
                                  ]
                                }
                                name="understandingFinancialMarket"
                              />
                              <Radio
                                value="poor"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET[
                                    "POOR"
                                  ]
                                }
                                name="understandingFinancialMarket"
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET[
                                    "OTHER"
                                  ]
                                }
                                name="understandingFinancialMarket"
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {understandingFinancialMarket === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Other</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="understandingFinancialMarketOther"
                                  id="understandingFinancialMarketOther"
                                  onChange={this.onchange}
                                  placeholder="Other"
                                  value={understandingFinancialMarketOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            For how long have you been active on the financial
                            markets / investing in financial products?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="activeOnFinancialMarket"
                              className="understanding-split-radio-container"
                              value={activeOnFinancialMarket}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "activeOnFinancialMarket"
                                )
                              }
                            >
                              <Radio
                                value="l1year"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "L1YEAR"
                                  ]
                                }
                                name="activeOnFinancialMarket"
                              />

                              <Radio
                                value="1to2years"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "1TO2YEARS"
                                  ]
                                }
                                name="activeOnFinancialMarket"
                              />

                              <Radio
                                value="3to5years"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "3TO5YEARS"
                                  ]
                                }
                                name="activeOnFinancialMarket"
                              />

                              <Radio
                                value="m5years"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "M5YEARS"
                                  ]
                                }
                                name="activeOnFinancialMarket"
                              />

                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                                    "OTHER"
                                  ]
                                }
                                name="activeOnFinancialMarket"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {activeOnFinancialMarket === "other" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Other</label>
                            <input
                              className="form-control"
                              type="text"
                              name="activeOnFinancialMarketOther"
                              id="activeOnFinancialMarketOther"
                              onChange={this.onchange}
                              placeholder="Other"
                              value={activeOnFinancialMarketOther}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            How have your assets been managed before?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="assetsManagedBefore"
                              className="understanding2-split-radio-container"
                              value={assetsManagedBefore}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "assetsManagedBefore"
                                )
                              }
                            >
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "WITHADVICEROFFINANCIALADVISOR"
                                  ]
                                }
                                name="assetsManagedBefore"
                                value="withadviceroffinancialadvisor"
                              />
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "NONDISCRETIONARYMANAGEMENT"
                                  ]
                                }
                                name="assetsManagedBefore"
                                value="nondiscretionarymanagement"
                              />
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "DISCRETIONARYMANAGEMENT"
                                  ]
                                }
                                name="assetsManagedBefore"
                                value="discretionarymanagement"
                              />
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "BYMYSELF"
                                  ]
                                }
                                name="assetsManagedBefore"
                                value="bymyself"
                              />
                              <Radio
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                                    "OTHER"
                                  ]
                                }
                                name="assetsManagedBefore"
                                value="other"
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {assetsManagedBefore === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Other</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={this.onchange}
                                  placeholder="Please Specify"
                                  name="assetsManagedBeforeOther"
                                  value={assetsManagedBeforeOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            In which financial products have you already
                            invested on a regular basis?{" "}
                          </label>
                          <div className="financial-products-invested">
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "SHARES"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "shares"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "shares")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "BONDS"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "bonds"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "bonds")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "COLLECTIVEINVESTMENTFUND"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "collectiveinvestmentfund"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "collectiveinvestmentfund"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "SUKUK"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "sukuk"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "sukuk")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "STRUCTUREDPRODUCTS"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "structuredproducts"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "structuredproducts"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "DERIVATIVES"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "derivatives"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "derivatives")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "INSURANCEINVESTMENTS"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "insuranceinvestments"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "insuranceinvestments"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "COMMODITIES"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "commodities"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "commodities")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "CURRENCIES"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "currencies"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "currencies")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "HEDGEFUND"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "hedgefund"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(e, checked, "hedgefund")
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "PRIVATEEQUITY"
                                ]
                              }
                              name="financialProductsInvested[]"
                              checked={this.checkFincialProductIfSelected(
                                "privateequity"
                              )}
                              onChange={(e, checked) =>
                                this.changeFinancial(
                                  e,
                                  checked,
                                  "privateequity"
                                )
                              }
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
                                  "OTHER"
                                ]
                              }
                              onChange={this.changeFinancialProductOther}
                              value="other"
                              checked={financialProductsInvestedOtherChecked}
                            />
                          </div>
                        </div>

                        {financialProductsInvestedOtherChecked && (
                          <div className="form-group">
                            <label>Other</label>
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.onchange}
                              placeholder="Please Specify"
                              name="financialProductsInvestedOther"
                              value={financialProductsInvestedOther}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            How often do you take investments decisions
                            (buy/sell)?{" "}
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="investmentDecisions"
                              className="understanding-split-radio-container"
                              value={investmentDecisions}
                              onChange={(e, value) =>
                                this.handleRadioChange(
                                  e,
                                  value,
                                  "investmentDecisions"
                                )
                              }
                            >
                              <Radio
                                value="seldom"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "SELDOM"
                                  ]
                                }
                                name="investmentDecisions"
                              />
                              <Radio
                                value="monthly"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "MONTHLY"
                                  ]
                                }
                                name="investmentDecisions"
                              />
                              <Radio
                                value="weekly"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "WEEKLY"
                                  ]
                                }
                                name="investmentDecisions"
                              />
                              <Radio
                                value="daily"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "DAILY"
                                  ]
                                }
                                name="investmentDecisions"
                              />
                              <Radio
                                value="other"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS[
                                    "OTHER"
                                  ]
                                }
                                name="investmentDecisions"
                              />
                            </RadioGroup>
                          </div>
                        </div>

                        {investmentDecisions === "other" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Other</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="investmentDecisionsOther"
                                  id="investmentDecisionsOther"
                                  onChange={this.onchange}
                                  placeholder="Please Specify"
                                  value={investmentDecisionsOther}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link
                  to="/individual_identification/wealth"
                  className="color-white"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>
                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveUnderstandingDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationUnderstandingOfMarket;
