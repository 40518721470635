import React, { Component } from "react";
import moment from "moment";
class Footer extends Component {
  render() {
    let { isExchange, color } = this.props;
    var year = moment(new Date()).format("YYYY");
    let footerClass =
      isExchange && isExchange === true
        ? "copyright-text text-center mt-3 footer exchange-footer"
        : "copyright-text text-center";
    return (
      <div className={footerClass} style={{ color: color }}>
        Copyright © {year} PermianChain Technologies, Inc.
      </div>
    );
  }
}
export default Footer;
