import React, { Component } from "react";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import moment from "moment";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  ethAddressDisplay,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";

class Tabs extends React.Component {
  state = {
    activeTab: "Subscriptions",
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          if (child.props.label === this.state.activeTab)
            content = child.props.children;
        })}
        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
        />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button) => {
        return (
          <button
            className={button === activeTab ? "active" : ""}
            onClick={() => changeTab(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 20,
      filters: "",
      ordersRenderFlag: false,
      ordersButtonLoading: false,
      ordersPage: 1,
      ordersSizePerPage: 10,
      ordersFilters: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleOrdersTableChange = this.handleOrdersTableChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.TRANSACTIONS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    this.getRecords();
    this.getOrdersRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/transaction/history", {
          sizePerPage: sizePerPage,
          page: page,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.transactions.tokenTransactions,
          totalSize: response.data.transactions.number,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }

      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  handleOrdersTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.ordersSizePerPage !== sizePerPage ||
      this.state.ordersPage !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getOrdersRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ ordersFilters: filters }, () => {
          this.getOrdersRecords();
        });
        return true;
      }
    }
  };

  async getOrdersRecords() {
    const api = new Api();
    const { ordersSizePerPage, ordersPage } = this.state;
    let { ordersFilters } = this.state;
    let authenticationToken = this.props.authToken;
    ordersFilters = JSON.stringify(ordersFilters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/exchange/transaction/list", {
          sizePerPage: ordersSizePerPage,
          page: ordersPage,
          filters: ordersFilters,
        });
      if (response.code === 200) {
        this.setState({
          ordersRenderFlag: true,
          ordersTotalSize: response.data.totalTransactions,
        });
      }
    } catch (e) {
      this.setState({
        ordersRenderFlag: true,
        ordersTotalSize: 0,
      });
    }
  }

  render() {
    const { data, sizePerPage, page, renderFlag,totalSize } = this.state;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "txnId",
        text: "Txn Id",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left text-default">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "issuerId",
        text: "Token Symbol",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left text-default">
              <div>{cell && cell.listedTokenSymbol}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "CreatedOnUTC",
        text: "Transaction Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left text-default">
              <div> {moment(cell).format("YYYY-MM-DD HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Amount Sent",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amountRecieve,
                  row.transactionType
                )}
              >
                {`$ ${niceNumberDecimalDisplay(cell, 2)}`} 
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "coins",
        text: `Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-default">
              { cell && niceNumberDecimalDisplay(cell, 2) }
              <span className="pl-2">
                { row.issuerId && row.issuerId.listedTokenSymbol }
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "depositeAddress",
        text: "Deposit Address",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.transactionType !== "MANUAL" && (
                <span className="custom-tooltip" tooltip-title={cell}>
                  {ethAddressDisplay(cell)}
                </span>
              )}
              {row.transactionType === "MANUAL" && <span>N/A</span>}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "detail",
        text: "Detail",
        style: {
          minWidth: 200
        },
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.paymentFileUrl && (
                <div>
                  <a href={row.paymentFileUrl} target="_blank">
                    View Payment Proof
                  </a>
                </div>
              )}
              {row.subscriptionAgreementFileUrl && (
                <div>
                  <a href={row.subscriptionAgreementFileUrl} target="_blank">
                    View Subscription Agreement
                  </a>
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.tokenSentStatus === true) {
            label = "Completed";
            className = "badge-success";
          } else {
            label = "Pending";
            className = "badge-warning";
          }
          return (
            <div>
              <div className="text-left text-default">
                <div className="d-inline-block">
                  <span className={"badge "+className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    return (
      <div className="adminDashboardContainer transactioncontainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <h6 className="element-header">Subscription Transactions</h6>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data || []}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Transactions;
