import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class IndividualFatcaDeclarationAndUndertakings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      buttonLoading: false,
    };
    this.clearSignature = this.clearSignature.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.saveUnderstandingDetails = this.saveUnderstandingDetails.bind(this);
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  async componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    } else {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  async saveUnderstandingDetails(event) {
    event.preventDefault();
    let _this = this;
    let authenticationToken = this.props.authToken;
    let signature = _this.signaturePad.toDataURL();
    const { printName, date, capacity } = this.state;

    if (
      printName !== "" &&
      date !== "" &&
      capacity !== "" &&
      signature !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_fatca_crs/save/declaration_undertakings", {
          printName: printName,
          date: date,
          capacity: capacity,
          signature: signature,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/my_account");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  handleDateChange(date) {
    this.setState({
      date: date,
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h4 className="text-center color-white identification-header">
            Section 5: Declaration and Undertakings
          </h4>
          <div className="kyc-container mt-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <p>
                      I declare that the information provided in this form is,
                      to the best of my knowledge and belief, accurate and
                      complete.
                    </p>
                    <p>
                      I acknowledge that the information contained in this form
                      and information regarding the Subscriber may be reported
                      to the tax authorities of the country in which this
                      account(s) is/are maintained and exchanged with tax
                      authorities of another country or countries in which the
                      Subscriber may be tax resident where those countries (or
                      tax authorities in those countries) have entered into
                      agreements to exchange financial account information.
                    </p>
                    <p>
                      I undertake to advise the recipient promptly and provide
                      an updated Self-Certification Form where any change in
                      circumstances occurs which causes any of the information
                      contained in this form to be incorrect.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="element-wrapper mt-2">
              <div className="element-box custom-element-box">
                <h5 className="color-white">Details</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        {" "}
                        Print Name<span className="required-sign">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="printName"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        {" "}
                        Date (dd/mm/yyyy)
                        <span className="required-sign">*</span>
                      </label>
                      <Datetime
                        dateFormat="DD-MM-YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: "dd-mm-yyyy" }}
                        utc={true}
                        onChange={this.handleDateChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        {" "}
                        Capacity<span className="required-sign">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="capacity"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <h5 className="color-white">
                      Signature{" "}
                      <span
                        className="color-white clear-sign-action ml-2 text-underline"
                        onClick={() => this.clearSignature()}
                      >
                        Clear
                      </span>
                    </h5>
                    <div className="signature-pad-container">
                      <SignaturePad
                        ref={(ref) => (this.signaturePad = ref)}
                        height={150}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Link
                  to="/individual_fatca_crs/controlling_person"
                  className="color-white text-underline"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveUnderstandingDetails}
                  disabled={buttonDisabled}
                >
                  Submit{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualFatcaDeclarationAndUndertakings;
