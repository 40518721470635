import React, { Component } from "react";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import _ from "lodash";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import InstructionModal from "./InstructionModal";
import axios from "axios";
import { API_ROOT } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class PaymentRequestModal extends Component {
  constructor(props, state) {
    super(props);
    this.state = {
      totalTokens: "",
      isOpenOne: false,
      isOpenTwo: false,
      isOpenThree: false,
    };
  }
  onChangeCurrencyType(value) {
    this.setState({
      currency: value,
    });
  }

  async onAmountChange(event) {
    this.setState({
      transactionAmount: event.target.value,
    });
  }
  render() {
    const {
      paymentRequestModalOpen,
      onCloseModal,
      onInputValueChange,
      onAmountChange,
      onChangeCurrencyType,
      saveNrtBuyPaymentRequest,
      paymentRequestButtonLoading,
      transactionAmount,
      userNote,
      displayValidationErrors,
      fileUpdated,
      securityTokenSymbol,
      wireTransferDetails,
      currency,
      totalTokens,
      wireFrameData,
      authToken,
      isFormValid,
      onPaymentFileUpdated
    } = this.props;
    const formValid = isFormValid();
    const buttonDisabled = (paymentRequestButtonLoading === true || !formValid);
    return (
      <Modal
        open={paymentRequestModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-large">
                      Wire Details
                    </h4>
                    <div className="onboarding-text text-left">
                      Submit the below form to request to deposit your
                      subscription in fiat currency.
                    </div>
                    <form className="mt-3">
                      <Button color="primary" onClick={() => this.setState({isOpenOne: !this.state.isOpenOne})} style={{ marginBottom: '1rem' }}>
                        Step 1: Transaction Amount
                      </Button>
                      <Collapse isOpen={this.state.isOpenOne} style={{ marginBottom: '3rem' }}>
                        <Card>
                          <CardBody>
                            <div className="form-group">
                              <label
                                htmlFor="transactionAmount"
                                className="text-default-color"
                              >
                                Transaction Amount
                                <span className="required-field">*</span>
                              </label>
                              <div className="amount-n-dropdown">
                                <input
                                  className="form-control mr-3 "
                                  id="transactionAmount"
                                  placeholder="Enter transaction amount"
                                  type="text"
                                  value={transactionAmount}
                                  name="transactionAmount"
                                  onChange={onAmountChange}
                                />
                                <div
                                  className="btn-group mr-1 mb-1"
                                  style={{ width: "151px" }}
                                >
                                  <button
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    className="btn default-border-btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    id="dropdownMenuButton6"
                                    type="button"
                                  >
                                    {currency || <span>Currency</span>}
                                  </button>
                                  <div
                                    aria-labelledby="dropdownMenuButton6"
                                    className="dropdown-menu"
                                  >
                                    <button
                                      className="mr-2 mb-2 dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        onChangeCurrencyType("CAD");
                                      }}
                                    >
                                      CAD
                                    </button>

                                    <button
                                      className="mr-2 mb-2 dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        onChangeCurrencyType("USD");
                                      }}
                                    >
                                      USD
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center mt-4 receive-text text-default-color">
                                {transactionAmount &&
                                  displayValidationErrors("transactionAmount")}
                              </div>
                            </div>

                            {totalTokens && (
                              <div className="form-group">
                                <div className="text-center mt-4 receive-text text-default-color">
                                  You will Receive{" "}
                                  <span className="highligher">
                                    { Math.floor(Number(totalTokens)) }
                                  </span>{" "}
                                  {securityTokenSymbol}
                                </div>
                              </div>
                            )}

                            <div class="form-group">
                              <label
                                htmlFor="userNote"
                                className="text-default-color"
                              >
                                Wire transfer details to subscribe token
                              </label>
                              <div
                                style={{
                                  border: "1px solid #ebebeb",
                                  boxSizing: "border-box",
                                  padding: "10px 30px",
                                  borderRadius: "5px",
                                }}
                              >
                                {wireTransferDetails &&
                                  wireTransferDetails.map((data, index) => {
                                    const dataArr = data.split(":");
                                    return (
                                      <div
                                        class="row justify-content-center "
                                        style={{
                                          padding: "10px 20px",
                                        }}
                                        key={index}
                                      >
                                        <div>
                                          <span className="text-bold text-black">
                                            {dataArr[0]} :{" "} {/* this line is showing the Bank field or wiretransfer field like Bank name which we get from from split funcion*/}  
                                          </span>
                                          <span className="text-default-color text-black">
                                            {dataArr[1]}  {/* this line is showing the value of the Bank field which we get from from split funcion */}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {(wireFrameData && _.isEmpty(wireTransferDetails)) && (
                                  <div>
                                    <div
                                      class="row justify-content-center "
                                      style={{
                                        padding: "10px 20px",
                                      }}
                                    >
                                      <div>
                                        <span className="text-bold text-black">
                                          Bank Name :
                                        </span>
                                        <span className="text-default-color text-black ml-1">
                                          {wireFrameData.bankName}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="row justify-content-center "
                                      style={{
                                        padding: "10px 20px",
                                      }}
                                    >
                                      <div>
                                        <span className="text-bold text-black">
                                          Bank Account Number :
                                        </span>
                                        <span className="text-default-color text-black ml-1">
                                          {wireFrameData.bankAccountNumber}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="row justify-content-center "
                                      style={{
                                        padding: "10px 20px",
                                      }}
                                    >
                                      <div>
                                        <span className="text-bold text-black">
                                          Bank Swift Code :
                                        </span>
                                        <span className="text-default-color text-black ml-1">
                                          {wireFrameData.bankSwiftCode}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="row justify-content-center "
                                      style={{
                                        padding: "10px 20px",
                                      }}
                                    >
                                      <div>
                                        <span className="text-bold text-black">
                                          BIC Code :
                                        </span>
                                        <span className="text-default-color text-black ml-1">
                                          {wireFrameData.bankBicCode}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="userNote"
                                className="text-default-color"
                              >
                                Note
                              </label>
                              <textarea
                                className="form-control"
                                placeholder="Enter note"
                                name="userNote"
                                id="userNote"
                                value={userNote}
                                onChange={onInputValueChange}
                              ></textarea>
                            </div>

                          </CardBody>
                        </Card>
                      </Collapse>

                      <p></p>

                      <Button color="primary" onClick={() => this.setState({isOpenTwo: !this.state.isOpenTwo})} style={{ marginBottom: '1rem' }}>
                        Step 2: Subscription Agreement
                      </Button>
                      <Collapse isOpen={this.state.isOpenTwo} style={{ marginBottom: '3rem' }}>
                        <Card>
                          <CardBody>
                            <InstructionModal 
                              {...this.props}
                              {...this.state}
                            />
                          </CardBody>
                        </Card>
                      </Collapse>
                      <p></p>
                      <Button color="primary" onClick={() => this.setState({isOpenThree: !this.state.isOpenThree})} style={{ marginBottom: '1rem' }}>
                      Step 3: Subscription Payment
                      </Button>
                      <Collapse isOpen={this.state.isOpenThree} style={{ marginBottom: '3rem' }}>
                        <Card>
                          <CardBody>
                            <label className="text-default-color">
                              {" "}
                              Proof of Payment (pdf, jpg, jpeg)
                              <span className="required-field">*</span>
                            </label>
                            <div className="resource-continaer payment-proof-container dropzoneIdContainer">
                              <div className="row">
                                <div className="col-md-12">
                                  <FilePond
                                    allowMultiple={false}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={[
                                      "application/pdf",
                                      "image/jpg",
                                      "image/jpeg",
                                    ]}
                                    server={{
                                      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const config = {
                                          headers: {
                                            "Content-Type": "multipart/form-data",
                                            Authorization: authToken,
                                          },
                                        };
                                        axios
                                          .post(
                                            API_ROOT + "/user/token/contribution/proof/upload",
                                            formData,
                                            config
                                          )
                                          .then(({ data }) => {
                                            const { data: fileKeys } = data;
                                            if (data.code === 200 && fileKeys && fileKeys.length > 0) {
                                              const fileKey = fileKeys[0];
                                              onPaymentFileUpdated(fileKey);
                                              load(fileKey);
                                            } else {
                                              error(data.message);
                                              toast.error(data.message);
                                            }
                                          })
                                          .catch(function (err) {
                                            error(messageConstants.SOMETHING_WENT_WRONG);
                                            toast.error(messageConstants.SOMETHING_WENT_WRONG);
                                          });
                                        return {
                                          abort: () => {
                                            abort();
                                          }
                                        };
                                      },
                                      revert: (source, load, error) => {
                                        onPaymentFileUpdated(null);
                                        load();
                                        const config = {
                                          headers: {
                                            Authorization: authToken,
                                          },
                                        };
                                        // ignore the response result
                                        axios
                                          .post(
                                            API_ROOT + "/user/token/contribution/proof/revert",
                                            { key: source },
                                            config
                                          );
                                      },
                                    }}
                                    instantUpload={true}
                                    onaddfile={(error, file) => {
                                      onPaymentFileUpdated(null);
                                      if (file) {
                                        const allowdFileExtension = ["pdf", "jpg", "jpeg"];
                                        const { fileExtension, fileSize } = file;
                                        if (!_.includes(allowdFileExtension, fileExtension)) {
                                          file.abortProcessing();
                                          file.abortLoad();
                                          return toast.error("Please make sure the file extension is correct");
                                        }
                                        if (fileExtension === "pdf" && fileSize > 1024 * 1024 * 5) {
                                          file.abortProcessing();
                                          file.abortLoad();
                                          return toast.error("Please make sure pdf size is under 5mb");
                                        }
                                        if ((fileExtension === "jpeg" ||
                                            fileExtension === "jpg") &&
                                            fileSize > 1024 * 500) {
                                          file.abortProcessing();
                                          file.abortLoad();
                                          return toast.error("Please make sure image size is under 500kb");
                                        }
                                        fileUpdated([file]);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>



                      <div className="d-flex justify-content-end mt-4">
                        <button
                          className="btn modal-button cancel-button"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn modal-button update-button"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={saveNrtBuyPaymentRequest}
                        >
                          {" "}
                          Submit{" "}
                          {paymentRequestButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default PaymentRequestModal;
