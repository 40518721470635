import "icheck/skins/all.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "react-datetime/css/react-datetime.css";
import React from "react";
import _ from "lodash";
import { Checkbox } from "react-icheck";
import { toast } from "react-toastify";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import validators from "../../validators";
import Api from "../../services/api";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import * as utils from "../../utils/Util";

const LOCAL_CACHE_KEY = "_kyc";

class Kyc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName:
        !_.isUndefined(this.props.authUserInfo) &&
        this.props.authUserInfo !== null
          ? this.props.authUserInfo.fullName
          : "",
      gender: "m",
      dob: "",
      countryId: "",
      countryIdValue: null,
      countryName: "Select country",
      state: "",
      city: "",
      postalCode: "",
      email:
        !_.isUndefined(this.props.authUserInfo) &&
        this.props.authUserInfo !== null
          ? this.props.authUserInfo.email
          : "",
      contactNo: "",
      error: null,
      buttonLoading: false,
      apiError: "",
      recaptchaResponse: "",
      agreeCheck: false,
      countriesList: [{ value: "", label: "Select Country" }],
      socialSecurityNumber: "",
      insiderOrReportingIssuer: "",
      addressLine1: "",
      addressLine2: "",
      role:
        !_.isUndefined(this.props.authUserInfo) &&
        this.props.authUserInfo !== null
          ? this.props.authUserInfo.role
          : "",
      natureOfBusiness: "",
      hidePassword: "password",
    };
    this.optionRef = React.createRef();
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.saveKycBasicDetails = this.saveKycBasicDetails.bind(this);
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
    this.setConteactNo = this.setConteactNo.bind(this);
    this.displaySecurityNumberToggle =
      this.displaySecurityNumberToggle.bind(this);
  }

  unload() {
    alert("Please click next button to save details.");
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_BASIC_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    window.addEventListener("beforeunload", this.unload);

    var body = document.body;
    body.classList.remove("full-screen");
    body.classList.remove("with-content-panel");
    body.classList.remove("user-dashboard-panel");

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;

    try {
      const api = new Api();
      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", {emdId});
      if (userResponse.code === 200) {
        if (userResponse.data.isEmdSelected === true) {
          if (
            userResponse.data.kycStatus === "u" ||
            (userResponse.data.kycStatus === "a" &&
              userResponse.data.isKycVerified === true)
          ) {
            let redirectUrl =
              userResponse.data.kycStatus === "u"
                ? `/kyc_status/pending/${emdId}`
                : `/kyc_status/approved/${emdId}`;
            this.props.history.push(redirectUrl);
          } else {
            const localCache = utils.getKycLocalCache()[LOCAL_CACHE_KEY];
            if (userResponse.data.userKycDetail) {
              let dob = moment(userResponse.data.userKycDetail.dob);
              this.setState({
                fullName:
                  userResponse.data.userKycDetail &&
                  userResponse.data.userKycDetail.fullName
                    ? userResponse.data.userKycDetail.fullName
                    : this.props.authUserInfo.fullName,
                contactNo:
                  userResponse.data.userKycDetail &&
                  userResponse.data.userKycDetail.contactNo
                    ? userResponse.data.userKycDetail.contactNo
                    : "",
                postalCode: userResponse.data.userKycDetail.postalCode,
                city: userResponse.data.userKycDetail.city,
                state: userResponse.data.userKycDetail.state,
                gender: userResponse.data.userKycDetail.gender,
                dob: dob,
                countryId: userResponse.data.userKycDetail.countryId
                  ? userResponse.data.userKycDetail.countryId._id
                  : "",
                countryIdValue: userResponse.data.userKycDetail.countryId
                  ? userResponse.data.userKycDetail.countryId._id
                  : "",
                countryName: userResponse.data.userKycDetail.countryId
                  ? userResponse.data.userKycDetail.countryId.name
                  : "",
                addressLine1: userResponse.data.userKycDetail.addressLine1
                  ? userResponse.data.userKycDetail.addressLine1
                  : "",
                addressLine2: userResponse.data.userKycDetail.addressLine2
                  ? userResponse.data.userKycDetail.addressLine2
                  : "",
                agreeCheck: true,
                termsOfEngagementDataAdded:
                  userResponse.data.termsOfEngagementDataAdded,
                socialSecurityNumber: userResponse.data.socialSecurityNumber
                  ? userResponse.data.socialSecurityNumber
                  : "",
                insiderOrReportingIssuer: userResponse.data.userKycDetail
                  .insiderOrReportingIssuer
                  ? userResponse.data.userKycDetail.insiderOrReportingIssuer
                  : "",
                natureOfBusiness: userResponse.data.natureOfBusiness
                  ? userResponse.data.natureOfBusiness
                  : "",
              });
            } else if (userResponse.data.termsOfEngagementDataAdded) {
              this.setState({
                termsOfEngagementDataAdded:
                  userResponse.data.termsOfEngagementDataAdded,
              });
            } else if (!_.isEmpty(localCache)) {
              this.setState(localCache);
            }
            const response = await api.get("user/getCountries");
            let countriesList = [];
            if (response.code === 200) {
              if (!_.isUndefined(response.data.countries)) {
                response.data.countries.forEach((country) => {
                  let obj = { value: country._id, label: country.name };
                  countriesList.push(obj);
                });
                if (!_.isUndefined(countriesList)) {
                  this.setState({
                    countriesList: countriesList,
                  });
                }
              }
            } else {
              toast.error(userResponse.message);
            }
          }
        } else {
          this.props.history.push("/emd");
        }
      } else {
        toast.error(userResponse.message);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      //   console.log("error", error);
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleGenderChange(event, value) {
    this.setState({
      gender: value,
    });
  }

  handleAgreeChange(e, checked) {
    this.setState({
      agreeCheck: checked,
    });
  }

  handleDobChange(date) {
    this.setState({
      dob: date,
    });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
      countryId: selectedOption.value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  setConteactNo(value) {
    this.setState({
      contactNo: value,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "contactNo"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            *{info} <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 "> {errors} </div>{" "}
          </div>{" "}
        </div>
      );
    }
    return result;
  }

  displaySecurityNumberToggle() {
    let { hidePassword } = this.state;
    this.setState({
      hidePassword: hidePassword === "password" ? "text" : "password",
    });
  }

  onMenuOpen = () => {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  };

  async saveKycBasicDetails(event) {
    event.preventDefault();
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    const {
      fullName,
      dob,
      countryId,
      countryIdValue,
      countryName,
      state,
      city,
      postalCode,
      contactNo,
      email,
      agreeCheck,
      address,
      socialSecurityNumber,
      insiderOrReportingIssuer,
      addressLine1,
      addressLine2,
      natureOfBusiness,
    } = this.state;
    const isFormValid = this.isFormValid();
    if (!isFormValid || !countryId) {
      toast.error("Please enter valid details for required fields.");
    }
    if (!agreeCheck) {
      toast.error("Please accept terms & conditions to proceed.");
    }
    this.setState({
      buttonLoading: true,
    });
    const formData = {
      fullName: fullName,
      dob: dob,
      countryId: countryId,
      state: state,
      city: city,
      postalCode: postalCode,
      contactNo: contactNo,
      email: email,
      address,
      addressLine1,
      addressLine2,
      socialSecurityNumber,
      insiderOrReportingIssuer,
      natureOfBusiness,
    }
    const localCache = utils.getKycLocalCache();
    localCache[LOCAL_CACHE_KEY] = {
      ...formData,
      countryIdValue,
      countryName
    }
    utils.saveKycLocalCache(localCache);
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .create("user/emd/saveKycBasicDetails", {...formData, emdId });
    if (response) {
      this.setState({
        buttonLoading: false,
      });
      if (response.code === 200) {
        this.props.history.push(`/kyc/upload/${emdId}`);
        toast.success(response.message);
      } else {
        toast.error(response.message);
        if (response.data.isEmdSelected === false) {
          this.props.history.push("/emd");
        }
      }
    }
  }

  render() {
    let {
      fullName,
      countriesList,
      contactNo,
      email,
      postalCode,
      city,
      state,
      buttonLoading,
      agreeCheck,
      countryName,
      countryId,
      countryIdValue,
      termsOfEngagementDataAdded,
      socialSecurityNumber,
      insiderOrReportingIssuer,
      addressLine1,
      addressLine2,
      role,
      natureOfBusiness,
      hidePassword,
    } = this.state;
    const formValid = this.isFormValid() && agreeCheck && countryId;
    const buttonDisabled = (buttonLoading === true) || !formValid;
    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header"> Submit KYC </h3>{" "}
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-2"> </div>{" "}
              <div className="col-md-8">
                <ul className="progressbar d-flex p-0 justify-content-center">
                  <li className="active"> Basic Details </li> <li> Upload </li>{" "}
                  <li> Suitability Analysis </li>{" "}
                  <li> Relationship Disclosure </li>{" "}
                  {termsOfEngagementDataAdded && <li> Terms Of Engagement </li>}{" "}
                </ul>{" "}
              </div>{" "}
              <div className="col-md-2"> </div>{" "}
            </div>{" "}
            <div className="row row-eq-height card-horizontal-padding">
              <div className="col-lg-6 col-md-12">
                <div className="element-wrapper custom-element-box h-100 card-inner-padding">
                  <form>
                    <h5 className="form-header"> Identity Details </h5>{" "}
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-default-color">
                            Full Name <span className="required-sign"> * </span>{" "}
                          </label>{" "}
                          <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={fullName}
                            onChange={this.onchange}
                          />{" "}
                          {fullName && this.displayValidationErrors("fullName")}{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-default-color">
                            {" "}
                            Email Address
                          </label>{" "}
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            id="email"
                            value={email}
                            disabled={true}
                            onChange={this.onchange}
                          />{" "}
                          {email && this.displayValidationErrors("email")}{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="text-default-color">
                            Confirm whether you are an insider of a reporting
                            issuer or any other issuer whose securities are
                            publicly traded:
                          </label>{" "}
                          <input
                            className="form-control"
                            type="text"
                            name="insiderOrReportingIssuer"
                            id="insiderOrReportingIssuer"
                            onChange={this.onchange}
                            value={insiderOrReportingIssuer}
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>
                    {role === "ci" && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label> Nature of Business </label>{" "}
                            <input
                              className="form-control"
                              type="text"
                              name="natureOfBusiness"
                              id="natureOfBusiness"
                              onChange={this.onchange}
                              value={natureOfBusiness}
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    )}{" "}
                  </form>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-6 col-md-12 mgtp-md-4 mgtp-sm-4">
                <div className="element-wrapper custom-element-box h-100 card-inner-padding">
                  <h5 className="form-header"> Contact Information </h5>{" "}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Country <span className="required-sign"> * </span>{" "}
                        </label>{" "}
                        <Select
                          autoFocus
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countriesList}
                          placeholder="Select Country"
                          name="countryId"
                          id="country"
                          ref={this.optionRef}
                          onChange={this.handleCountryChange}
                          value={{
                            label: countryName,
                            value: countryIdValue,
                          }}
                          onMenuOpen={this.onMenuOpen}
                        />{" "}
                      </div>{" "}
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          {" "}
                          State / Province{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          id="state"
                          onChange={this.onchange}
                          value={state}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color"> City </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          id="city"
                          onChange={this.onchange}
                          value={city}
                        />{" "}
                      </div>{" "}
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          {" "}
                          Postal Code / Zip Code{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          onChange={this.onchange}
                          value={postalCode}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Address Line 1{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          id="addressLine1"
                          name="addressLine1"
                          onChange={this.onchange}
                          placeholder="Address Line 1"
                          value={addressLine1}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Address Line 2{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          id="addressLine2"
                          name="addressLine2"
                          onChange={this.onchange}
                          placeholder="Address Line 2"
                          value={addressLine2}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Contact Number{" "}
                          <span className="required-sign"> * </span>{" "}
                        </label>{" "}
                        <br />
                        <IntlTelInput
                          preferredCountries={["tw"]}
                          onPhoneNumberChange={(validate, value, countryData) =>
                            this.setConteactNo(value)
                          }
                          numberType="MOBILE"
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          value={contactNo}
                          style={{ width: "100%" }}
                        />{" "}
                        {contactNo && this.displayValidationErrors("contactNo")}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  {_.toLower(countryName) === "canada" && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group dob-container">
                          <label className="text-default-color">
                            Social Insurance / Security Number{" "}
                            <span className="required-sign"> * </span>&nbsp;{" "}
                            <i
                              className="fa fa-info-circle"
                              id="socialSecurityNumberInformation"
                            ></i>{" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="bottom"
                              target="socialSecurityNumberInformation"
                            >
                              <PopoverBody>
                                Your Social Insurance / Security Number is
                                encrypted and can only be seen by your Exempt
                                market Dealer{" "}
                              </PopoverBody>{" "}
                            </UncontrolledPopover>{" "}
                          </label>{" "}
                          <input
                            className="form-control"
                            type={hidePassword}
                            name="socialSecurityNumber"
                            id="socialSecurityNumber"
                            onChange={this.onchange}
                            value={socialSecurityNumber}
                          />{" "}
                          <span
                            style={{
                              position: "absolute",
                              top: 40,
                              right: 26,
                            }}
                            onClick={() => this.displaySecurityNumberToggle()}
                          >
                            <i
                              className={`fa cursor-pointer ${
                                hidePassword === "text"
                                  ? "fa-eye-slash"
                                  : "fa-eye"
                              }`}
                            ></i>{" "}
                          </span>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>
                  )}{" "}
                  <div className="form-check mt-1 kyc-agree-container">
                    <Checkbox
                      id="agreeCheck"
                      checkboxClass="icheckbox_square-blue"
                      increaseArea="20%"
                      name="agreeCheck"
                      onChange={this.handleAgreeChange}
                      checked={agreeCheck}
                    />{" "}
                    <label
                      htmlFor="agreeCheck"
                      className="ml-2 text-default-color m-0"
                    >
                      I agree to{" "}
                      <Link
                        to="/terms_conditions"
                        className="text-underline"
                        target="_blank"
                      >
                        Terms & Conditions{" "}
                      </Link>{" "}
                    </label>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div className="row card-inner-padding">
              <div className="col-md-12 text-right d-flex justify-content-end">
                <Link className="btn cancel-button" to="/dashboard">
                  Do it later{" "}
                </Link>{" "}
                <button
                  className="btn update-button"
                  type="submit"
                  onClick={this.saveKycBasicDetails}
                  disabled={buttonDisabled}
                >
                  {" "}
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white ml-1" />
                  )}{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}
export default Kyc;
