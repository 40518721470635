import _ from "lodash";
import { ethers } from "ethers";

export const niceNumberDisplay = (val) => {
  if (val > 0) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
      val = val.toString().replace(/(\d+)(\d{3})/, "$1,$2");
    }
  } else {
    val = 0;
  }
  return val;
};

export const niceNumberDecimalDisplay = (value, decimalPoint) => {
  let niceNumber = 0;
  if (!_.isUndefined(value)) {
    niceNumber = parseFloat(value);
    let decimal = !_.isUndefined(decimalPoint) ? decimalPoint : 3;
    if (decimal > 0) {
      niceNumber =
        niceNumber > 0
          ? niceNumber.toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : 0;
    } else {
      niceNumber =
        niceNumber > 0
          ? niceNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
    }
  }
  return niceNumber;
};

export const tooltipNumberDisplay = (value, symbol, action = "append") => {
  let finalNumber = "";
  if (!_.isUndefined(value) && !_.isNull(value)) {
    let niceNumber = parseFloat(value);
    let numberAsString = value.toString();
    if (
      numberAsString.indexOf(".") === -1 ||
      numberAsString.split(".")[1].length < 3
    ) {
      niceNumber =
        niceNumber > 0
          ? niceNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : 0;
    } else {
      niceNumber =
        niceNumber > 0 ? numberAsString.replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0;
    }
    if (!_.isUndefined(symbol)) {
      finalNumber =
        action === "append"
          ? niceNumber + " " + symbol
          : symbol + " " + niceNumber;
    } else {
      finalNumber = niceNumber;
    }
  } else {
    finalNumber = 0;
  }
  return finalNumber;
};

export const ethAddressDisplay = (ethAddress) => {
  let ETHAddressDisplay = "";
  if (!_.isUndefined(ethAddress)) {
    ETHAddressDisplay = ethAddress.substr(0, 6) + "..." + ethAddress.substr(-4);
  }
  return ETHAddressDisplay;
};

export function getWeb3NetworkName(networkId) {
  let networkName;
  switch (networkId) {
    case 1:
      networkName = "Main";
      break;
    case 2:
      networkName = "Morden";
      break;
    case 3:
      networkName = "Ropsten";
      break;
    case 4:
      networkName = "Rinkeby";
      break;
    case 42:
      networkName = "Kovan";
      break;
    default:
      networkName = "Unknown";
  }
  return networkName;
}

export function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1], 10);
    if (e) {
      x *= 10 ** (e - 1);
      x = `0.${new Array(e).join("0")}${x.toString().substring(2)}`;
    }
  } else {
    let e = parseInt(x.toString().split("+")[1], 10);
    if (e > 20) {
      e -= 20;
      x /= 10 ** e;
      x += new Array(e + 1).join("0");
    }
  }
  return x.toString();
}

export const fetchTokenDetails = async (tokenContractAddress) => {
  try {
    if (!tokenContractAddress) {
      return false;
    }
    const network =
      process.env.REACT_APP_NODE_ENV === "test" ||
      process.env.REACT_APP_NODE_ENV === "dev"
        ? "kovan"
        : "mainnet";

    if (tokenContractAddress) {
      const contractABI = await getABIOfContact(tokenContractAddress);
      const provider = ethers.getDefaultProvider(network);

      if (!contractABI) {
        const responseAr = {
          totalSupply: "",
        };
        return responseAr;
      }

      const contract1 = new ethers.Contract(
        tokenContractAddress,
        contractABI,
        provider
      );

      let totalSupply = await contract1.totalSupply();
      const decimals = await contract1.decimals();
      totalSupply = totalSupply / Math.pow(10, decimals);
      return totalSupply;
    }
  } catch (error) {
    const errorMessage = error.message;
    return errorMessage;
  }
};

const getABIOfContact = async (contractAddress) => {
  try {
    const apiUrl =
      process.env.REACT_APP_NODE_ENV === "test" ||
      process.env.REACT_APP_NODE_ENV === "dev"
        ? "https://api-kovan.etherscan.io/api"
        : "https://api.etherscan.io/api";
    const url = `${apiUrl}?module=contract&action=getabi&address=${contractAddress}&apikey=${"APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V"}`;
    return fetch(url)
      .then((response) => response.json())
      .then(async (data) => {
        if (data && data.status === "1") {
          return data.result || null;
        }
        return null;
      })
      .catch((error) => {
        console.error("error: ", error);
        return null;
      });
  } catch (error) {
    console.error("error: ", error);
  }
};

const LOCALSTORAGE_KYC_KEY = "_kycSubmitDetails";
export const saveKycLocalCache  = (data) => {
  if (!_.isEmpty(data)) {
    const json = JSON.stringify(data);
    localStorage.setItem(LOCALSTORAGE_KYC_KEY, json);
  }
}

export const getKycLocalCache = () => {
  const json = localStorage.getItem(LOCALSTORAGE_KYC_KEY);
  if (json) {
    return JSON.parse(json);
  }
  return {};
}

export const clearKycLocalCache = () => {
  localStorage.removeItem(LOCALSTORAGE_KYC_KEY);
}
