import React, { Component } from "react";
import _ from "lodash";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import Select from "react-select";
import CardElement from "./CardElement";
import { title } from "process";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourcesList: [],
      requestProcessing: true,
      totalResources: 0,
      categoryList: [],
      selectedCategory: "",
      title: "",
      selectedCategoryLabel: "",
      clearLoading: false,
      filterLoading: false,
    };
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.getFileExtension = this.getFileExtension.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearRecords = this.clearRecords.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
  }

  changeTitle(e) {
    this.setState({ title: e.target.value });
  }
  filterRecords() {
    this.setState({ filterLoading: true });
    this.getRecords();
  }
  clearRecords() {
    this.setState(
      {
        clearLoading: true,
        title: "",
        selectedCategoryLabel: "",
        selectedCategory: "",
      },
      () => {
        this.getRecords();
        // this.setState({clearLoading:false})
      }
    );
  }
  async componentWillMount() {
    document.title =
      messageConstants.RESOURCES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
    this.getCategory();
  }

  async getRecords() {
    const { selectedCategory, title } = this.state;
    const api = new Api();
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/resources/list", {
          filterByTitle: title,
          category: selectedCategory,
          panel: "i",
        });
      if (response.code === 200) {
        this.setState({
          resourcesList: response.data.resources,
          requestProcessing: false,
          totalResources: response.data.totalResources,
          clearLoading: false,
          filterLoading: false,
        });
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      this.setState({
        requestProcessing: false,
        totalResources: 0,
      });
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }
  handleChangeCategory(e) {
    this.setState({
      selectedCategory: e.value,
      selectedCategoryLabel: e.label,
    });
  }
  getFileExtension(filename) {
    const extension = filename.split(".").pop();
    return extension;
  }
  async getCategory() {
    const api = new Api();

    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("user/viewCategories");
    if (response.code === 200) {
      if (response.data) {
        if (response.data.resourceCategories) {
          let categoryArr = [];
          response.data.resourceCategories.map((data) => {
            let category = {};
            category.value = data.title;
            category.label = data.title;
            categoryArr.push(category);
          });
          this.setState({ categoryList: categoryArr });
        }
      }
    }
  }
  render() {
    const {
      resourcesList,
      totalResources,
      requestProcessing,
      selectedCategoryLabel,
      selectedCategory,
      categoryList,
      title,
      clearLoading,
      filterLoading,
    } = this.state;

    let displayResourceList = "";

    if (
      !_.isUndefined(resourcesList) &&
      !_.isEmpty(resourcesList) &&
      totalResources > 0
    ) {
      displayResourceList = resourcesList.map((resource, mainIndex) => {
        const extension = this.getFileExtension(resource.resourceFile);
        if (
          extension === "pdf" ||
          extension === "doc" ||
          extension === "docx"
        ) {
          return (
            <CardElement
              title={resource.resourceTitle}
              description={resource.resourceDescription}
              link={resource.resourceFile}
              category={resource.category}
              document={true}
              key={mainIndex}
              filetype={resource.type}
            />
          );
        } else if (
          extension === "JPEG" ||
          extension === "JPG" ||
          extension === "PNG"
        ) {
          return (
            <CardElement
              title={resource.resourceTitle}
              description={resource.resourceDescription}
              link={resource.resourceFile}
              category={resource.category}
              document={false}
              key={mainIndex}
              filetype={resource.type}
            />
          );
        }

        return (
          <CardElement
            title={resource.resourceTitle}
            description={resource.resourceDescription}
            link={resource.resourceFile}
            category={resource.category}
            document={false}
            key={mainIndex}
            filetype={resource.type}
          />
        );
      });
    }

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="dark-blue-theme-color card-inner-padding mb-3 ">
              <h5 className="form-header">Filter Resources</h5>
              <form className="form-inline">
                <div className="col-sm-12 mb-2 col-md-6 col-lg-6">
                  <Select
                    className="text-black"
                    value={{
                      value: selectedCategory,
                      label: selectedCategoryLabel || "Select Category",
                    }}
                    options={categoryList}
                    isMulti={false}
                    onChange={this.handleChangeCategory}
                  />
                </div>
                <div className="col-sm-12 mb-2 col-md-6 col-lg-6">
                  <input
                    className="form-control w-100"
                    style={{
                      padding: "10px",
                      borderColor: "hsl(0,0%,80%)",
                      fontSize: "14px",
                    }}
                    value={title}
                    placeholder="Keyword"
                    type="text"
                    onChange={this.changeTitle}
                  />
                </div>

                <div className="col-sm-12 mt-2 col-md-12 col-lg-12">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.filterRecords}
                    // onClick={() => {
                    //   this.filterRecords(selectedMonth, selectedYear);
                    // }}
                  >
                    {" "}
                    Filter
                    {filterLoading && (
                      <i className="fa-spin fa fa-spinner text-white ml-2" />
                    )}
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearRecords}
                  >
                    {" "}
                    Clear
                    {clearLoading && (
                      <i className="fa-spin fa fa-spinner text-white ml-2" />
                    )}
                  </button>
                </div>
              </form>
            </div>
            {requestProcessing === false && totalResources > 0 && (
              <div className="dark-blue-theme-color card-inner-padding">
                <h4 className="mb-4">Resources</h4>
                <div className="row">{displayResourceList}</div>
              </div>
            )}

            {requestProcessing === false && totalResources === 0 && (
              <div className="dark-blue-theme-color card-inner-padding">
                There are no resources to display.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Resources;
