import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class CorporateFatcaCrsDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coySpecifiedUsPerson: "specifiedusperson",
      buttonDisabled: false,
    };
    this.onChange = this.onChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.saveDeclarationDetails = this.saveDeclarationDetails.bind(this);
  }

  async componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.CORPORATE_FATCA_CRS_DECLARATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    const api = new Api();
    let authenticationToken = this.props.authToken;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/company/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = ["coySpecifiedUsPerson", "coyUsTinNo"];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleRadioChange(event, value, name) {
    this.setState({
      [name]: value,
    });
  }

  async saveDeclarationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const { coySpecifiedUsPerson, coyUsTinNo } = this.state;

    if (coySpecifiedUsPerson !== "") {
      this.setState({
        buttonLoading: true,
      });

      if (coySpecifiedUsPerson === "specifiedusperson" && coyUsTinNo === "") {
        this.setState({
          buttonLoading: false,
        });
        toast.error(messageConstants.ERROR_US_TIN_REQUIRED);
      } else {
        const api = new Api();
        const apiResponse = await api
          .setToken(authenticationToken)
          .create("user/company_fatca_crs/save/declaration_details", {
            coySpecifiedUsPerson: coySpecifiedUsPerson,
            coyUsTinNo: coyUsTinNo,
          });
        if (apiResponse) {
          this.setState({
            buttonLoading: false,
          });
          if (apiResponse.code === 200) {
            this.props.history.push("/corporate_fatca_crs/classification");
            toast.success(apiResponse.message);
          } else {
            toast.error(apiResponse.message);
          }
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    const { coySpecifiedUsPerson, buttonLoading, coyUsTinNo } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h4 className="text-center color-white identification-header">Section 2: FATCA Declaration Specified U.S. Person</h4> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <h5 className="text-xl text-default-color">
                      Please tick either (a), (b) or (c) below and complete as
                      appropriate.
                    </h5>
                    <RadioGroup
                      className="understanding-split-radio-container"
                      value={coySpecifiedUsPerson}
                      onChange={(e, value) =>
                        this.handleRadioChange(e, value, "coySpecifiedUsPerson")
                      }
                    >
                      <Radio
                        value="specifiedusperson"
                        radioClass="iradio_square-blue pr-2"
                        increaseArea="20%"
                        label={
                          " (a) " +
                          messageConstants
                            .LABEL_CORPORATE_FATCA_CRS_DECLARATION[
                            "SPECIFIEDUSPERSON"
                          ]
                        }
                      />
                      <Radio
                        value="notspecifiedusperson"
                        radioClass="iradio_square-blue pr-2"
                        increaseArea="20%"
                        label={
                          " (b) " +
                          messageConstants
                            .LABEL_CORPORATE_FATCA_CRS_DECLARATION[
                            "NOTSPECIFIEDUSPERSON"
                          ]
                        }
                      />
                      <Radio
                        value="entityusperson"
                        radioClass="iradio_square-blue pr-2"
                        increaseArea="20%"
                        label={
                          " (c) " +
                          messageConstants
                            .LABEL_CORPORATE_FATCA_CRS_DECLARATION[
                            "ENTITYUSPERSON"
                          ]
                        }
                      />
                    </RadioGroup>

                    {coySpecifiedUsPerson === "specifiedusperson" && (
                      <div className="row mt-3 mb-3">
                        <div className="col-md-6">
                          <label className="text-default text-default-color">
                            U.S. TIN
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="U.S. TIN"
                            name="coyUsTinNo"
                            onChange={this.onChange}
                            value={coyUsTinNo}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/identification"
                className="color-white text-underline"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>

              <button
                className="btn update-button ml-3"
                type="button"
                onClick={this.saveDeclarationDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaCrsDeclaration;
