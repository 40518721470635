import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class CorporateFatcaTaxDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyTaxResidencyCountryValue: null,
      coyTaxResidencyCountry2Value: null,
      coyTaxResidencyCountry3Value: null,
      coyTaxResidencyCountryName: "Select country",
      coyTaxResidencyCountry2Name: "Select country",
      coyTaxResidencyCountry3Name: "Select country",
    };
    this.saveTaxDeclarationDetails = this.saveTaxDeclarationDetails.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentWillMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.CORPORATE_FATCA_CRS_TAX_DECLARATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    let authenticationToken = this.props.authToken;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/company/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "coyTaxPayerIdentificationNo",
          "coyTaxPayerIdentificationNo2",
          "coyTaxPayerIdentificationNo3",
        ];
        let setStateCountryVariables = [
          "coyTaxResidencyCountry",
          "coyTaxResidencyCountry2",
          "coyTaxResidencyCountry3",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  async saveTaxDeclarationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyTaxResidencyCountryValue,
      coyTaxPayerIdentificationNo,
      coyTaxResidencyCountry2Value,
      coyTaxPayerIdentificationNo2,
      coyTaxResidencyCountry3Value,
      coyTaxPayerIdentificationNo3,
    } = this.state;

    if (
      coyTaxResidencyCountryValue !== "" &&
      coyTaxPayerIdentificationNo !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/company_fatca_crs/save/tax_residency_details", {
          coyTaxResidencyCountryValue: coyTaxResidencyCountryValue,
          coyTaxPayerIdentificationNo: coyTaxPayerIdentificationNo,
          coyTaxResidencyCountry2Value: coyTaxResidencyCountry2Value,
          coyTaxPayerIdentificationNo2: coyTaxPayerIdentificationNo2,
          coyTaxResidencyCountry3Value: coyTaxResidencyCountry3Value,
          coyTaxPayerIdentificationNo3: coyTaxPayerIdentificationNo3,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/corporate_fatca_crs/entity_crs_classification"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  render() {
    let {
      countriesList,
      coyTaxResidencyCountryName,
      coyTaxResidencyCountryValue,
      coyTaxResidencyCountry2Name,
      coyTaxResidencyCountry2Value,
      coyTaxResidencyCountry3Name,
      coyTaxResidencyCountry3Value,
      buttonLoading,
      coyTaxPayerIdentificationNo,
      coyTaxPayerIdentificationNo2,
      coyTaxPayerIdentificationNo3,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h4 className="text-center color-white identification-header">Section 4: CRS Declaration of Tax Residency</h4> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <p className="text-default text-default-color">
                      Please indicate the Subscriber’s country of tax residence
                      for CRS purposes (if resident in more than one country
                      please detail all countries of tax residence and
                      associated tax identification numbers). If the Subscriber
                      is not tax resident in any jurisdiction (e.g. because it
                      is fiscally transparent), please indicate that below and
                      provide its place of effective management or country in
                      which its principal office is located.
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Country of Tax Residency
                            <span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Tax Residency Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyTaxResidencyCountry"
                              )
                            }
                            value={{
                              label: coyTaxResidencyCountryName,
                              value: coyTaxResidencyCountryValue,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Tax ID Number
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Tax ID Number"
                            name="coyTaxPayerIdentificationNo"
                            onChange={this.onChange}
                            value={coyTaxPayerIdentificationNo}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Country of Tax Residency
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Tax Residency Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyTaxResidencyCountry2"
                              )
                            }
                            value={{
                              label: coyTaxResidencyCountry2Name,
                              value: coyTaxResidencyCountry2Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Tax ID Number
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Tax ID Number"
                            name="coyTaxPayerIdentificationNo2"
                            onChange={this.onChange}
                            value={coyTaxPayerIdentificationNo2}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Country of Tax Residency
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Tax Residency Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyTaxResidencyCountry3"
                              )
                            }
                            value={{
                              label: coyTaxResidencyCountry3Name,
                              value: coyTaxResidencyCountry3Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Tax ID Number
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Tax ID Number"
                            name="coyTaxPayerIdentificationNo3"
                            onChange={this.onChange}
                            value={coyTaxPayerIdentificationNo3}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-12 ">
                        <span className="text-default text-default-color">
                          NOTE: Provision of a taxpayer identification number is
                          required unless you are tax resident in a jurisdiction
                          that does not issue a taxpayer identification number.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/classification"
                className="color-white text-underline"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-3"
                type="button"
                onClick={this.saveTaxDeclarationDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaTaxDeclaration;
