import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import _ from "lodash";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class ListingCategoryProgressDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progressReports: [],
      progressReportFiles: [],
      requestProcessing: true,
      totalProgressReports: 0,
    };
  }

  async componentDidMount() {
    const api = new Api();
    document.title =
      messageConstants.LISTING_PROGRESS_REPORTS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    let authenticationToken = this.props.authToken;
    let listingId = this.props.match.params.id;
    let categoryId = this.props.match.params.categoryid;

    let parsedQueryParams = {
      listingId: listingId,
    };

    if (categoryId && categoryId !== "" && categoryId !== null) {
      parsedQueryParams.categoryId = categoryId;
    }

    if (!_.isUndefined(listingId)) {
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("user/listing/progress_reports", parsedQueryParams);
        if (response.code === 200) {
          this.setState({
            progressReports: response.data.listingProgressReports,
            progressReportFiles: response.data.progressReportFiles,
            requestProcessing: false,
            totalProgressReports: response.data.totalProgressReports,
          });
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
        this.setState({
          progressReports: [],
          progressReportFiles: [],
          requestProcessing: false,
          totalProgressReports: 0,
        });
      }
    }
    this.props.pageProgress("remove");
  }

  render() {
    const {
      progressReports,
      totalProgressReports,
      requestProcessing,
      progressReportFiles,
    } = this.state;
    let displayProgressReportsList = "";
    let listingId = this.props.match.params.id;
    let categoryId = this.props.match.params.categoryid;
    if (
      !_.isUndefined(progressReports) &&
      !_.isEmpty(progressReports) &&
      totalProgressReports > 0
    ) {
      displayProgressReportsList = progressReports.map(
        (progressReport, mainIndex) => (
          <div className="post-box">
            <div className="post-content">
              <h6 className="post-title">{progressReport.title}</h6>
              <div className="post-text">
                {progressReport.shortDescription &&
                  progressReport.shortDescription !== "" && (
                    <div>{progressReport.shortDescription}</div>
                  )}
                <div className="mt-1">
                  {progressReport.categoryId &&
                    progressReport.categoryId.title !== "" &&
                    (!categoryId ||
                      categoryId === "" ||
                      categoryId === null) && (
                      <span>
                        Category :{" "}
                        <Link
                          to={`/progress_reports/${listingId}/category/${progressReport.categoryId._id}`}
                        >
                          {progressReport.categoryId.title}
                        </Link>
                      </span>
                    )}
                  <a
                    className="post-link pull-right"
                    href={progressReportFiles[progressReport._id]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>View Details</span>{" "}
                    <i className="os-icon os-icon-arrow-right7"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      );
    }

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <h2 className="color-white text-center">
              Progress Reports
              {progressReports &&
                !_.isEmpty(progressReports[0]) &&
                progressReports[0].categoryId &&
                progressReports[0].categoryId.title && (
                  <span> For {progressReports[0].categoryId.title}</span>
                )}
            </h2>
            <div className="row">
              <div className="col-md-12">
                {requestProcessing === false && totalProgressReports > 0 && (
                  <div className="element-box-tp mt-4">
                    {displayProgressReportsList}
                  </div>
                )}
                {requestProcessing === false && totalProgressReports === 0 && (
                  <div className="alert alert-warning mt-3 alert-dark-blue">
                    {messageConstants.PROGRESS_REPORTS_RECORD_NOT_FOUND}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ListingCategoryProgressDetails;
