import React, { Component } from "react";
import Modal from "react-responsive-modal";
import PropTypes from 'prop-types'
import EnhancedSwitch from 'react-icheck/lib/EnhancedSwitch'
import { Checkbox } from "react-icheck";

// Workaround for https://github.com/luqin/react-icheck/issues/43
EnhancedSwitch.propTypes = {
  ...EnhancedSwitch.propTypes,
  cursor: PropTypes.string
}

class InstructionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      checkboxAgree,
      listedTokenSubscriptionAgreement,
      handleUploadFile,
    } = this.props;

    return (
      <div>
        <h4 className="onboarding-title text-large">
          Subscription Agreement
        </h4>
        <div>
          <a
            href={listedTokenSubscriptionAgreement}
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer" }}
          >
            {listedTokenSubscriptionAgreement? "Download Document": "No Subscription Agreement In Your Country"}
          </a>
        </div>

        <div className="form-group mt-2">
          <label className="text-default text-default-color">
            Upload signed copy of Subscription Agreement
            <span className="required-field">*</span>
          </label>
          <br />
          <input
            type="file"
            name="emdAvatar"
            onChange={(event) => handleUploadFile(event)}
          />
        </div>

        <div className="row mt-4">
          <div className="col-sm-12">
            <div className="mb-3">
              <Checkbox
                id="ppmCheck"
                checkboxClass="icheckbox_square-blue"
                increaseArea="20%"
                onChange={(e, checked) =>
                  checkboxAgree(e, checked, "ppmCheck")
                }
                cursor="pointer"
              />
              <label
                htmlFor="ppmCheck"
                className="instructionCheck text-default text-default-color"
              >
                I certify that I have read the contents of subscription
                agreement.
              </label>
              <span className="required-field">*</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InstructionModal;
