import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
class IndividualIdentificationContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesList: [
        { value: "", label: messageConstants.LABEL_SELECT_COUNTRY },
      ],
      professionalStatus: "e",
      jobTitle: "",
      jobSectorIndustry: "",
      employerName: "",
      politicallyExposedPerson: "n",
      politicalName: "",
      politicalFunction: "",
      politicalName2: "",
      politicalFunction2: "",
      businessCountryValue: "",
      businessCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handlePoliticallyExposedChange =
      this.handlePoliticallyExposedChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.saveProfessionalDetails = this.saveProfessionalDetails.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_PROFESSIONAL_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    let authenticationToken = this.props.authToken;
    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "professionalStatus",
          "jobTitle",
          "jobSectorIndustry",
          "employerName",
          "politicallyExposedPerson",
          "politicalName",
          "politicalFunction",
          "politicalName2",
          "politicalFunction2",
        ];
        let setStateCountryVariables = ["businessCountry"];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleStatusChange(event, value) {
    this.setState({
      professionalStatus: value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      businessCountryValue: selectedOption.value,
      businessCountryName: selectedOption.label,
    });
  }

  handlePoliticallyExposedChange(event, value) {
    this.setState({
      politicallyExposedPerson: value,
    });
  }

  async saveProfessionalDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      professionalStatus,
      jobTitle,
      jobSectorIndustry,
      employerName,
      politicallyExposedPerson,
      politicalName,
      politicalFunction,
      politicalName2,
      politicalFunction2,
      businessCountryValue,
    } = this.state;

    if (professionalStatus !== "") {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/professional_details", {
          professionalStatus: professionalStatus,
          jobTitle: jobTitle,
          jobSectorIndustry: jobSectorIndustry,
          employerName: employerName,
          politicallyExposedPerson: politicallyExposedPerson,
          politicalName: politicalName,
          politicalFunction: politicalFunction,
          politicalName2: politicalName2,
          politicalFunction2: politicalFunction2,
          businessCountry: businessCountryValue,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push(
            "/individual_identification/business_intentions"
          );
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      countriesList,
      professionalStatus,
      jobTitle,
      jobSectorIndustry,
      employerName,
      politicallyExposedPerson,
      buttonLoading,
      businessCountryValue,
      businessCountryName,
      politicalName,
      politicalFunction,
      politicalName2,
      politicalFunction2,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h2 className="color-white text-center identification-header">
            Professional Details
          </h2>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Status</label>
                          <div className="gender-container">
                            <RadioGroup
                              name="professionalStatus"
                              value={professionalStatus}
                              onChange={this.handleStatusChange}
                            >
                              <Radio
                                value="e"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Employed"
                                name="professionalStatus"
                              />
                              <Radio
                                value="se"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Self Employed"
                                name="professionalStatus"
                              />
                              <Radio
                                value="r"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Retired"
                                name="professionalStatus"
                              />
                              <Radio
                                value="ne"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Not Employed"
                                name="professionalStatus"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {professionalStatus !== "ne" && (
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Job Title / Function</label>
                              <input
                                className="form-control"
                                type="text"
                                name="jobTitle"
                                id="jobTitle"
                                value={jobTitle}
                                onChange={this.onchange}
                                placeholder="Job Title / Function"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Sector / Industry</label>
                              <input
                                className="form-control"
                                type="text"
                                name="jobSectorIndustry"
                                id="jobSectorIndustry"
                                value={jobSectorIndustry}
                                onChange={this.onchange}
                                placeholder="Sector / Industry"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Name of employer</label>
                              <input
                                className="form-control"
                                type="text"
                                name="employerName"
                                id="employerName"
                                value={employerName}
                                onChange={this.onchange}
                                placeholder="Name of employer"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Country of business</label>
                              <Select
                                className="country-select2"
                                classNamePrefix="cntry"
                                options={countriesList}
                                placeholder="Select Country of business"
                                onChange={(selectedOption) =>
                                  this.handleCountryChange(selectedOption)
                                }
                                value={{
                                  label: businessCountryName,
                                  value: businessCountryValue,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="color-white">
                          Are you / is a member of your family / a close
                          associate a Politically Exposed Person?
                        </h6>
                        <div className="form-group">
                          <label>Status</label>
                          <div className="gender-container">
                            <RadioGroup
                              name="politicallyExposedPerson"
                              value={politicallyExposedPerson}
                              onChange={this.handlePoliticallyExposedChange}
                            >
                              <Radio
                                value="y"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Yes"
                                name="politicallyExposedPerson"
                              />
                              <Radio
                                value="n"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="No"
                                name="politicallyExposedPerson"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {politicallyExposedPerson === "y" && (
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="politicalName"
                                id="politicalName"
                                onChange={this.onchange}
                                placeholder="Name"
                                value={politicalName}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Function</label>
                              <input
                                className="form-control"
                                type="text"
                                name="politicalFunction"
                                id="politicalFunction"
                                onChange={this.onchange}
                                placeholder="Function"
                                value={politicalFunction}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="politicalName2"
                                id="politicalName2"
                                onChange={this.onchange}
                                placeholder="Name"
                                value={politicalName2}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Function</label>
                              <input
                                className="form-control"
                                type="text"
                                name="politicalFunction2"
                                id="politicalFunction2"
                                onChange={this.onchange}
                                placeholder="Function"
                                value={politicalFunction2}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        Note : A Politically exposed person is a person with
                        prominent public function e.g. head of state or of
                        government, senior politician, senior government,
                        judicial or military official, ambassador, senior
                        executive of a state owned co-operation or an important
                        political party official.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link
                  to="/individual_identification/contact_details"
                  className="color-white"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveProfessionalDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationContactDetails;
