import React, { PureComponent } from "react";
import ModelViewer from "metamask-logo";
import cx from "classnames";
import styles from "./Logo.css";

export default class Logo extends PureComponent {
  static defaultProps = {
    animated: true,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.child = null;
  }

  componentDidMount() {
    const { animated } = this.props;

    if (!animated) {
      return;
    }

    const fox = ModelViewer({
      pxNotRatio: true,
      width: 47,
      height: 47,
      followMouse: true,
    });
    this.child = fox.container;
    // add fox to DOM
    this.ref.current.appendChild(this.child);
  }

  componentDidUpdate(prevProps) {
    const { animated } = this.props;

    if (prevProps.animated !== animated && animated) {
      const fox = ModelViewer({
        pxNotRatio: true,
        width: 47,
        height: 47,
        followMouse: true,
      });
      //  this.ref.current.html('')
      // add fox to DOM
      const oldChild = this.child;
      this.child = fox.container;

      this.ref.current.removeChild(oldChild);
      this.ref.current.appendChild(this.child);
    }
  }

  render() {
    const { className, animated } = this.props;
    if (animated) {
      return <span ref={this.ref} className={className} />;
    }
    return <span className={cx(className, styles.fox)} />;
  }
}
