import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class IndividualFatcaTaxDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxResidencyCountryValue: null,
      taxResidencyCountry2Value: null,
      taxResidencyCountry3Value: null,
      taxResidencyCountryName: "Select country",
      taxResidencyCountry2Name: "Select country",
      taxResidencyCountry3Name: "Select country",
    };
    this.saveTaxDeclarationDetails = this.saveTaxDeclarationDetails.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentWillMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.INDIVIDUAL_FATCA_CRS_TAX_RESIDENCY_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    const api = new Api();
    const response = await api.get("user/getCountries");
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/individual/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "taxPayerIdentificationNo",
          "taxPayerIdentificationNo2",
          "taxPayerIdentificationNo3",
        ];
        let setStateCountryVariables = [
          "taxResidencyCountry",
          "taxResidencyCountry2",
          "taxResidencyCountry3",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  async saveTaxDeclarationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      taxResidencyCountryValue,
      taxPayerIdentificationNo,
      taxResidencyCountry2Value,
      taxPayerIdentificationNo2,
      taxResidencyCountry3Value,
      taxPayerIdentificationNo3,
    } = this.state;

    if (taxResidencyCountryValue !== "" && taxPayerIdentificationNo !== "") {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_fatca_crs/save/tax_residency", {
          taxResidencyCountryValue: taxResidencyCountryValue,
          taxPayerIdentificationNo: taxPayerIdentificationNo,
          taxResidencyCountry2Value: taxResidencyCountry2Value,
          taxPayerIdentificationNo2: taxPayerIdentificationNo2,
          taxResidencyCountry3Value: taxResidencyCountry3Value,
          taxPayerIdentificationNo3: taxPayerIdentificationNo3,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/individual_fatca_crs/controlling_person");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  render() {
    let {
      countriesList,
      taxResidencyCountryName,
      taxResidencyCountryValue,
      taxPayerIdentificationNo,
      taxResidencyCountry2Name,
      taxResidencyCountry2Value,
      taxResidencyCountry3Name,
      taxResidencyCountry3Value,
      taxPayerIdentificationNo3,
      buttonLoading,
      taxPayerIdentificationNo2,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h4 className="text-center color-white identification-header">
            Section 3: CRS Declaration of Tax Residency
          </h4>
          <div className="kyc-container mt-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <p>
                      Please indicate your/the Subscriber’s country of tax
                      residence (if resident in more than one country please
                      detail all countries of tax residence and associated
                      taxpayer identification numbers. Please see the CRS Portal
                      for more information on tax residency.
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Country of Tax Residency
                            <span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Tax Residency Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "taxResidencyCountry"
                              )
                            }
                            value={{
                              label: taxResidencyCountryName,
                              value: taxResidencyCountryValue,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Taxpayer Identification Number
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Identification Number"
                            name="taxPayerIdentificationNo"
                            onChange={this.onChange}
                            value={taxPayerIdentificationNo}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country of Tax Residency</label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Tax Residency Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "taxResidencyCountry2"
                              )
                            }
                            value={{
                              label: taxResidencyCountry2Name,
                              value: taxResidencyCountry2Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Taxpayer Identification Number</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Identification Number"
                            name="taxPayerIdentificationNo2"
                            onChange={this.onChange}
                            value={taxPayerIdentificationNo2}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country of Tax Residency</label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Tax Residency Country"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "taxResidencyCountry3"
                              )
                            }
                            value={{
                              label: taxResidencyCountry3Name,
                              value: taxResidencyCountry3Value,
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Taxpayer Identification Number</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Identification Number"
                            name="taxPayerIdentificationNo3"
                            onChange={this.onChange}
                            value={taxPayerIdentificationNo3}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-12">
                        NOTE: Provision of a taxpayer identification number is
                        required unless you are tax resident in a jurisdiction
                        that does not issue a taxpayer identification number.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Link
                  to="/individual_fatca_crs/declaration"
                  className="color-white text-underline"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveTaxDeclarationDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualFatcaTaxDeclaration;
