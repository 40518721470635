import React, { Component } from "react";
import _ from "lodash";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";

class IndividualFatcaCrsInformations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }
  }

  componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    document.title =
      messageConstants.INDIVIDUAL_FATCA_CRS_INFORMATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  render() {
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h4 className="text-center color-white identification-header">
            Self-Certification for FATCA and CRS
          </h4>
          <div className="kyc-container mt-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <h5 className="color-white">Instructions for completion</h5>
                    <p>
                      We are obliged under the Foreign Account Tax Compliance
                      Act (“FATCA”), related intergovernmental agreements
                      (“IGAs”) and regulations based on the OECD Common
                      Reporting Standard (“CRS”) to collect certain information
                      about each investor’s tax arrangements. Please complete
                      the sections below as directed and provide any additional
                      information that is requested. Please note that in certain
                      circumstances we may be legally obliged to share this
                      information, and other financial information with respect
                      to an investor’s interests in the Company with relevant
                      tax authorities. This form is intended to request
                      information only where such request is not prohibited by
                      local law.
                    </p>
                    <p className="mt-2">
                      If you have any questions about this form or defining the
                      investor’s tax residency status, please refer to the OECD
                      CRS Portal or speak to a tax adviser.
                    </p>
                    <p className="mt-2">
                      For further information on FATCA or CRS please refer to
                      the US Department of the Treasury’s website at:{" "}
                      <a
                        className="color-white text-underline"
                        rel="noopener noreferrer"
                        href="http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA"
                        target="_blank"
                      >
                        http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA
                      </a>{" "}
                      or in the case of CRS only the following link to the OECD
                      CRS Information Portal at:{" "}
                      <a
                        className="color-white text-underline"
                        rel="noopener noreferrer"
                        href="http://www.oecd.org/tax/automatic-exchange/"
                        target="_blank"
                      >
                        http://www.oecd.org/tax/automatic-exchange/
                      </a>
                      .
                    </p>
                    <p className="mt-2">
                      If any of the information below about the investor’s tax
                      residence or FATCA/CRS classification changes in the
                      future, please advise of these changes promptly.
                    </p>
                    <p className="mt-2">
                      Please note that where there are joint or multiple account
                      holders each investor is required to complete a separate
                      Self-Certification form.
                    </p>
                    <p className="mt-2">
                      Sections 1, 2, 3 and 5 must be completed by all investors.
                    </p>
                    <p className="mt-2">
                      Section 4 should only be completed by any individual who
                      is a Controlling Person of an entity investor which is a
                      Passive Non-Financial Entity.
                    </p>
                    <p className="mt-2">
                      For further guidance see:{" "}
                      <a
                        className="color-white text-underline"
                        rel="noopener noreferrer"
                        href="http://www.oecd.org/tax/automatic-exchange/common-reporting-standard/common-reporting-standard-and-related-commentaries/#d.en.345314"
                        target="_blank"
                      >
                        Visit Link
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Link
                  to="/individual_fatca_crs/identification"
                  className="color-white text-underline"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Next
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualFatcaCrsInformations;
