import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as messageConstants from "../../utils/Messages";
import { Radio, RadioGroup } from "react-icheck";
import Api from "../../services/api";

class CorporateFatcaCrsClassification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyFinancialInsitution: "",
      unableToProvideGIIN: "",
      financialInsitution: "",
      nonFinancialEntity: "",
      buttonLoading: false,
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveClassificationDetails = this.saveClassificationDetails.bind(this);
  }

  async componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }

    document.title =
      messageConstants.CORPORATE_FATCA_CRS_CLASSIFICATION_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    let authenticationToken = this.props.authToken;
    const api = new Api();

    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/company/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "coyFinancialInsitution",
          "coySubscriberGiinNo",
          "coyFinancialInsitutionWithoutGiin",
          "coySponsorName",
          "coySponsorGiinNo",
          "coyNonFinancialInsitution",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleRadioChange(event, value, name) {
    this.setState({
      coyFinancialInsitution: "",
      coySubscriberGiinNo: "",
      coyFinancialInsitutionWithoutGiin: "",
      coySponsorName: "",
      coySponsorGiinNo: "",
      coyNonFinancialInsitution: "",
      [name]: value,
    });
  }

  async saveClassificationDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyFinancialInsitution,
      coySubscriberGiinNo,
      coyFinancialInsitutionWithoutGiin,
      coySponsorName,
      coySponsorGiinNo,
      coyNonFinancialInsitution,
    } = this.state;

    if (
      coyFinancialInsitution !== "" ||
      coyFinancialInsitutionWithoutGiin !== "" ||
      coyNonFinancialInsitution !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/company_fatca_crs/save/classification_details", {
          coyFinancialInsitution: coyFinancialInsitution,
          coySubscriberGiinNo: coySubscriberGiinNo,
          coyFinancialInsitutionWithoutGiin: coyFinancialInsitutionWithoutGiin,
          coySponsorName: coySponsorName,
          coySponsorGiinNo: coySponsorGiinNo,
          coyNonFinancialInsitution: coyNonFinancialInsitution,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/corporate_fatca_crs/tax_declaration");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    const {
      coyFinancialInsitution,
      coyFinancialInsitutionWithoutGiin,
      coyNonFinancialInsitution,
      buttonLoading,
      coySponsorGiinNo,
      coySponsorName,
      coySubscriberGiinNo,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h4 className="text-center color-white identification-header">Section 3:  Entity’s FATCA Classification</h4> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <p className="text-default text-default-color">
                      Note : The information provided in this section is for
                      FATCA, please note your classification may differ from
                      your CRS classification in Section 5
                    </p>
                    <div className="form-group">
                      <label className="text-default text-default-color">
                        {" "}
                        If the Subscriber is a Financial Institution, please
                        tick one of the below categories and provide the
                        Subscriber’s Global Intermediary Identification Number
                        (GIIN).
                      </label>
                      <RadioGroup
                        className="radio-half-width-container"
                        value={coyFinancialInsitution}
                        onChange={(e, value) =>
                          this.handleRadioChange(
                            e,
                            value,
                            "coyFinancialInsitution"
                          )
                        }
                      >
                        <Radio
                          value="jfi"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION[
                              "PARTNER"
                            ]
                          }
                        />
                        <Radio
                          value="financial"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION[
                              "DEEMEDCOMPLIANT"
                            ]
                          }
                        />
                        <Radio
                          value="foreign_institution"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION[
                              "PARTICIPATING"
                            ]
                          }
                        />
                      </RadioGroup>
                    </div>

                    {coyFinancialInsitution !== "" && (
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label className="text-default text-default-color">
                            Subscriber’s GIIN:{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="coySubscriberGiinNo"
                            onChange={this.onChange}
                            value={coySubscriberGiinNo}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group mt-3">
                      <label className="text-default text-default-color">
                        {" "}
                        If the Subscriber is a Financial Institution but unable
                        to provide a GIIN, please tick one of the below reasons.
                      </label>

                      <RadioGroup
                        value={coyFinancialInsitutionWithoutGiin}
                        onChange={(e, value) =>
                          this.handleRadioChange(
                            e,
                            value,
                            "coyFinancialInsitutionWithoutGiin"
                          )
                        }
                      >
                        <Radio
                          value="sponsoredby"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN[
                              "SPONSOREDBY"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="exemptbeneficialowner"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN[
                              "EXEMPTBENEFICIALOWNER"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="certifieddeemedcompliant"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN[
                              "CERTIFIEDDEEMEDCOMPLIANT"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="nonparticipating"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN[
                              "NONPARTICIPATING"
                            ]
                          }
                        />
                        <br />
                        <Radio
                          value="excepted"
                          radioClass="iradio_square-blue pr-2"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN[
                              "EXCEPTED"
                            ]
                          }
                        />
                      </RadioGroup>
                    </div>

                    {coyFinancialInsitutionWithoutGiin === "sponsoredby" && (
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label className="text-default text-default-color">
                            Sponsor’s Name:{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Sponsor’s Name"
                            name="coySponsorName"
                            onChange={this.onChange}
                            value={coySponsorName}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="text-default text-default-color">
                            Sponsor’s GIIN:{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Sponsor’s GIIN"
                            name="coySponsorGiinNo"
                            onChange={this.onChange}
                            value={coySponsorGiinNo}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group mt-3 understanding2-split-radio-container">
                      <label className="text-default text-default-color">
                        If the Subscriber is not a Financial Institution, please
                        tick one of the below categories.
                      </label>
                      <RadioGroup
                        className="understanding-split-radio-container"
                        value={coyNonFinancialInsitution}
                        onChange={(e, value) =>
                          this.handleRadioChange(
                            e,
                            value,
                            "coyNonFinancialInsitution"
                          )
                        }
                      >
                        <Radio
                          value="active"
                          radioClass="iradio_square-blue"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_NON_FINANCIAL_INSTITUTION[
                              "ACTIVE"
                            ]
                          }
                        />
                        <Radio
                          value="passive"
                          radioClass="iradio_square-blue pr-2 ml-3"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_NON_FINANCIAL_INSTITUTION[
                              "PASSIVE"
                            ]
                          }
                        />
                        <Radio
                          value="excepted"
                          radioClass="iradio_square-blue pr-2 ml-3"
                          increaseArea="20%"
                          label={
                            " " +
                            messageConstants
                              .LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_NON_FINANCIAL_INSTITUTION[
                              "EXCEPTED"
                            ]
                          }
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/declaration"
                className="color-white text-underline"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-3"
                type="button"
                onClick={this.saveClassificationDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaCrsClassification;
