import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";

import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import PaymentRequestModal from "./PaymentRequestModal";
import TokenPaymentModal from "./TokenPaymentModal";
import CurrencyAddressModal from "./CurrencyAddressModal";
import AddWalletAddressModal from "./AddWalletAddressModal";
import { ethAddressDisplay } from "../../utils/Util";
import { tokenSymbol } from "../../services/api-config";
import { API_ROOT } from "../../services/api-config";

let decimalPoints = 8;
// let usdDecimalPoints = 2;

class Exchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiveXpr: 0,
      transactionFeeInPer: "",
      transactionVatTax: "",
      subTotal: 0,
      transactionFeeAmount: 0,
      transactionVatTaxAmount: 0,
      finalTotal: 0,
      submitBuyMandateLoading: false,
      submitSellMandateLoading: false,
      currencyType: "USD",
      sellSubTotal: 0,
      sellFinalTotal: 0,
      sellTransactionFeeAmount: 0,
      sellTransactionVatTaxAmount: 0,
      xprUnitPrice: "",
      buyAmount: "",
      sellAmount: "",
      sellQuantity: 0,
      myMandates: [],
      myMandatesLoading: true,
      mandateBookLoading: true,
      currentXprBalance: 0,
      xprUnitUsdPrice: "",
      xprUnitBtcPrice: "",
      xprUnitEthPrice: "",
      tradeHistory: [],
      walletAddresses: [],
      currentWalletBalance: 0,
      paymentRequestModalOpen: false,
      transactionAmount: 0,
      transactionNote: "",
      paymentRequestButtonLoading: false,
      totalExchangeUSDBalance: 0,
      currencyTransactionModalOpen: false,
      tokenPaymentModalOpen: false,
      walletAddressModalOpen: false,
      totalTokenPayment: 0,
      userWalletAddressList: [],
      selectedOptionIdValue: null,
      walletAddress: "Select Wallet Address",
      userWalletAddress: "",
      ethUnitUsdPrice: 0,
      btcUnitUsdPrice: 0,
      userReceivingEthAddress: "",
    };
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    this.onChange = this.onChange.bind(this);
    this.mandateInputChange = this.mandateInputChange.bind(this);
    this.submitMandate = this.submitMandate.bind(this);
    this.changeBaseCurrency = this.changeBaseCurrency.bind(this);
    this.getMyMandates = this.getMyMandates.bind(this);
    this.makeTrade = this.makeTrade.bind(this);
    this.saveUSDPaymentRequest = this.saveUSDPaymentRequest.bind(this);
    this.addWalletBalance = this.addWalletBalance.bind(this);
    this.onPaymentRequestCloseModal =
      this.onPaymentRequestCloseModal.bind(this);
    this.onCurrencyRequestCloseModal =
      this.onCurrencyRequestCloseModal.bind(this);
    this.sendTokenToCustody = this.sendTokenToCustody.bind(this);
    this.handleMetaMaskError = this.handleMetaMaskError.bind(this);
    this.handleMetaMaskSuccess = this.handleMetaMaskSuccess.bind(this);
    this.onTokenPaymentCloseModal = this.onTokenPaymentCloseModal.bind(this);
    this.handleWalletAddressChange = this.handleWalletAddressChange.bind(this);
    this.openAddWalletModal = this.openAddWalletModal.bind(this);
    this.onWalletAddressCloseModal = this.onWalletAddressCloseModal.bind(this);
    this.addWalletAddress = this.addWalletAddress.bind(this);
    this.getUserWalletAddresses = this.getUserWalletAddresses.bind(this);
    this.refreshMandateBook = this.refreshMandateBook.bind(this);
    this.currencyObject = {
      ETH: "ethereum",
      BTC: "bitcoin",
      LTC: "litecoin",
      USDC: "usd-coin",
    };
    this.fileUpdated = this.fileUpdated.bind(this);
    this.uploadImageArray = {};
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  initHeightSet() {
    var elmnt = document.getElementById("buy-sell-mandate-container");
    document.getElementById("sell-mandate-book").style.height =
      elmnt.offsetHeight + "px";
    var recentOrderElmnt = document.getElementById("recent-order-container");
    var recentOrderElmntHeight = recentOrderElmnt.scrollHeight;
    var buyMandateElmnt = document.getElementById("buy-mandate-container");
    var buyMandateElmntHeight = buyMandateElmnt.offsetHeight;
    if (buyMandateElmntHeight > recentOrderElmntHeight) {
      let considerHeight =
        elmnt.offsetHeight > buyMandateElmntHeight
          ? elmnt.offsetHeight
          : buyMandateElmntHeight;
      document.getElementById("recent-order-container").style.minHeight =
        considerHeight + "px";
    } else if (recentOrderElmntHeight > buyMandateElmntHeight) {
      let considerHeight =
        elmnt.offsetHeight > recentOrderElmntHeight
          ? elmnt.offsetHeight
          : recentOrderElmntHeight;
      document.getElementById("buy-mandate-container").style.minHeight =
        considerHeight + "px";
      document.getElementById("recent-order-container").style.minHeight =
        considerHeight + "px";
    }
    var tradeElmnt = document.getElementById("sell-recent-orders");
    var finalPx = tradeElmnt.offsetHeight - 24;
    document.getElementById("trade-history").style.minHeight = finalPx + "px";
  }

  componentDidMount() {
    this.initHeightSet();
    this.updateUserWalletBalance();
    this.getUserWalletAddresses();
  }

  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }

  async getUserWalletAddresses() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const userWalletAddressedResponse = await api
      .setToken(authenticationToken)
      .get("user/whitelisted/address");
    if (userWalletAddressedResponse.code === 200) {
      this.setState({
        userReceivingEthAddress:
          userWalletAddressedResponse.data.receivingEthAddress,
      });
    }
  }

  async updateUserWalletBalance(returnFlag = false) {
    const api = new Api();
    const { currencyType } = this.state;
    let authenticationToken = this.props.authToken;
    const stateSetObject = {};
    const userWalletBalanceResponse = await api
      .setToken(authenticationToken)
      .get("user/exchange/wallet/balance");
    if (userWalletBalanceResponse.code === 200) {
      if (currencyType === "USD") {
        stateSetObject.currentWalletBalance =
          userWalletBalanceResponse.data.totalExchangeUSDBalance;
      }
      stateSetObject.totalExchangeUSDBalance =
        userWalletBalanceResponse.data.totalExchangeUSDBalance;
      if (!_.isUndefined(userWalletBalanceResponse.data.addresses)) {
        _.forEach(
          userWalletBalanceResponse.data.addresses,
          function (value, key) {
            stateSetObject[key + "walletAddress"] = value.walletAddress;
            stateSetObject[key + "walletBalance"] = value.walletBalance;
          }
        );
      }
    }
    if (stateSetObject && !_.isEmpty(stateSetObject) && returnFlag === false) {
      this.setState(stateSetObject);
    }
    return stateSetObject;
  }

  async componentWillMount() {
    let authenticationToken = this.props.authToken;
    let authenticationUserDetails = this.props.authUserInfo;
    let stateSetObject = {};
    document.title =
      messageConstants.EXCHANGE_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .get("user/getGlobalStatistics");
    if (response.code === 200) {
      stateSetObject.xprPrice = response.data.GlobalDetails.xprPrice;
      stateSetObject.transactionFeeInPer =
        response.data.GlobalDetails.transactionFeeInPer;
      stateSetObject.transactionVatTax =
        response.data.GlobalDetails.transactionVatTax;
      stateSetObject.xprUnitUsdPrice = response.data.GlobalDetails.xprPrice;
      if (authenticationUserDetails && authenticationUserDetails.tokens > 0) {
        stateSetObject.currentXprBalance = niceNumberDecimalDisplay(
          authenticationUserDetails.tokens,
          2
        );
      }
    }
    this.setState(stateSetObject);
    this.getRecords();
    this.getMyMandates();
    this.setXprUnitPrice();
  }

  async getRecords() {
    const api = new Api();
    const { currencyType } = this.state;
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/mandate/list", { currencyType: currencyType });
      if (response.code === 200) {
        this.setState({
          mandateBookLoading: false,
          sellMandateData: response.data.sellMandateRequests,
          sellMandateTotalSize: response.data.sellRequestNumber,
          buyMandateData: response.data.buyMandateRequests,
          buyMandateTotalSize: response.data.buyRequestNumber,
          tradeHistory: response.data.tradeRequests,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        sellMandateData: [],
        buyMandateData: [],
        sellMandateTotalSize: 0,
        buyMandateTotalSize: 0,
      });
    }
  }

  async calculateInputChange(type) {
    if (type !== "") {
      const {
        transactionFeeInPer,
        transactionVatTax,
        xprPrice,
        currencyType,
        buyAmount,
        sellAmount,
      } = this.state;
      let transactionFeeAmount = 0;
      let transactionVatTaxAmount = 0;
      let totalTokenReceive = "";
      let stateObj = {};
      let priceUsd = "";
      let amount = type === "buy" ? buyAmount : sellAmount;
      const currencyId = this.currencyObject[currencyType];
      const decimalPoints = currencyType === "USD" ? 2 : 8;

      if (currencyId && !_.isUndefined(currencyId) && currencyId !== "") {
        const api = new Api(
          "https://api.coinmarketcap.com/v1/ticker/" + currencyId
        );
        const response = await api.get("");
        priceUsd = response[0].price_usd;
      }

      if (type === "buy") {
        if (transactionFeeInPer && transactionFeeInPer > 0) {
          transactionFeeAmount =
            (parseFloat(amount) * transactionFeeInPer) / 100;
        }
        if (transactionVatTax && transactionVatTax > 0) {
          transactionVatTaxAmount =
            (parseFloat(amount) * transactionVatTax) / 100;
        }
        let finalTotal =
          parseFloat(amount) +
          parseFloat(transactionFeeAmount) +
          parseFloat(transactionVatTaxAmount);

        if (currencyType === "USD") {
          totalTokenReceive = parseFloat(amount) / xprPrice;
        } else if (priceUsd) {
          let amountInUsd = parseFloat(amount) * parseFloat(priceUsd);
          totalTokenReceive = amountInUsd / xprPrice;
        }
        stateObj.receiveXpr = niceNumberDecimalDisplay(
          totalTokenReceive,
          decimalPoints
        );
        stateObj.subTotal = niceNumberDecimalDisplay(amount, decimalPoints);
        stateObj.transactionFeeAmount = niceNumberDecimalDisplay(
          transactionFeeAmount,
          decimalPoints
        );
        stateObj.transactionVatTaxAmount = niceNumberDecimalDisplay(
          transactionVatTaxAmount,
          decimalPoints
        );
        stateObj.finalTotal = niceNumberDecimalDisplay(
          finalTotal,
          decimalPoints
        );
      } else if (type === "sell") {
        let receiveAmountInUsd = amount * parseFloat(xprPrice);

        if (currencyType !== "USD") {
          receiveAmountInUsd =
            parseFloat(receiveAmountInUsd) / parseFloat(priceUsd);
        }
        stateObj.sellQuantity = niceNumberDecimalDisplay(amount, decimalPoints);
        stateObj.sellSubTotal = niceNumberDecimalDisplay(
          receiveAmountInUsd,
          decimalPoints
        );

        if (transactionFeeInPer && transactionFeeInPer > 0) {
          transactionFeeAmount =
            (parseFloat(receiveAmountInUsd) * transactionFeeInPer) / 100;
          stateObj.sellTransactionFeeAmount = niceNumberDecimalDisplay(
            transactionFeeAmount,
            decimalPoints
          );
        }
        if (transactionVatTax && transactionVatTax > 0) {
          transactionVatTaxAmount =
            (parseFloat(receiveAmountInUsd) * transactionVatTax) / 100;
          stateObj.sellTransactionVatTaxAmount = niceNumberDecimalDisplay(
            transactionVatTaxAmount,
            decimalPoints
          );
        }
        let finalTotal =
          parseFloat(receiveAmountInUsd) -
          parseFloat(transactionFeeAmount) -
          parseFloat(transactionVatTaxAmount);
        stateObj.sellFinalTotal = niceNumberDecimalDisplay(
          finalTotal,
          decimalPoints
        );
      }
      this.setState(stateObj);
    }
  }

  async mandateInputChange(event, type) {
    let stateName = type === "buy" ? "buyAmount" : "sellAmount";
    let mandateStateName =
      type === "buy" ? "buyOnMandateId" : "sellOnMandateId";
    this.setState(
      {
        [stateName]: event.target.value,
        [mandateStateName]: "",
      },
      async () => {
        await this.calculateInputChange(type);
      }
    );
  }

  async submitMandate(e, type) {
    e.preventDefault();
    if (type && type !== "") {
      let authenticationToken = this.props.authToken;
      const {
        subTotal,
        transactionFeeAmount,
        receiveXpr,
        finalTotal,
        currencyType,
        transactionVatTax,
        transactionFeeInPer,
        sellSubTotal,
        sellFinalTotal,
        sellQuantity,
        totalExchangeUSDBalance,
        sellTransactionFeeAmount,
        sellTransactionVatTaxAmount,
        buyAmount,
      } = this.state;
      if (
        type &&
        currencyType &&
        ((type === "buy" && subTotal && transactionFeeAmount) ||
          (type === "sell" && sellSubTotal))
      ) {
        const finalOrderAmount = type === "buy" ? finalTotal : sellFinalTotal;
        const userCurrentBalance =
          currencyType === "USD"
            ? totalExchangeUSDBalance
            : this.state[currencyType + "walletBalance"];
        if (type === "buy" && userCurrentBalance < finalOrderAmount) {
          toast.error(
            `You do not have sufficient ${currencyType} balance for creating buy mandate.`
          );
          return false;
        }

        let loadingVariable =
          type === "buy"
            ? "submitBuyMandateLoading"
            : "submitSellMandateLoading";
        this.setState({ [loadingVariable]: true });

        let params = {
          currencyType,
          type,
          taxPercentage: transactionVatTax,
          feePercentage: transactionFeeInPer,
        };
        params.quantity = type === "buy" ? receiveXpr : sellQuantity;
        params.subTotal = type === "buy" ? subTotal : sellSubTotal;
        params.totalOrderAmount = type === "buy" ? finalTotal : sellFinalTotal;

        if (type === "buy" && buyAmount > 0) {
          params.buyAmount = buyAmount;
        }

        if (type === "sell") {
          params.sellTransactionFeeAmount = sellTransactionFeeAmount;
          params.sellTransactionVatTaxAmount = sellTransactionVatTaxAmount;
        }
        const submitUrl =
          type === "buy"
            ? "user/mandate/create/new"
            : "user/sell/mandate/create";
        const api = new Api();
        const response = await api
          .setToken(authenticationToken)
          .create(submitUrl, params);
        if (response) {
          this.setState({
            [loadingVariable]: false,
          });
          if (response.code === 200) {
            let newStateObj = {};
            if (type === "buy") {
              newStateObj = {
                subTotal: 0,
                transactionFeeAmount: 0,
                transactionVatTaxAmount: 0,
                finalTotal: 0,
                receiveXpr: 0,
                buyAmount: 0,
              };
            } else {
              newStateObj = {
                sellSubTotal: 0,
                sellAmount: 0,
                sellFinalTotal: 0,
                sellTransactionFeeAmount: 0,
                sellTransactionVatTaxAmount: 0,
              };
            }
            this.setState(newStateObj);
            this.getMyMandates();
            this.getRecords();
            this.updateUserWalletBalance();
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        }
      } else {
        let msgValue =
          type === "buy"
            ? messageConstants.PLEASE_ENTER_VALID_AMOUNT
            : messageConstants.PLEASE_ENTER_VALID_QUANTITY;
        let msg =
          !subTotal && !sellFinalTotal
            ? msgValue
            : messageConstants.SOMETHING_WENT_WRONG;
        toast.error(msg);
      }
    } else {
      toast.error(messageConstants.INVALID_REQUEST);
    }
  }

  async setXprUnitPrice() {
    const { xprPrice, buyAmount, sellAmount } = this.state;
    let { xprUnitEthPrice, xprUnitBtcPrice } = this.state;
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
    );
    let priceUsd = 0;
    let btcPriceUsd = 0;
    if (response) {
      priceUsd = response.data["ethereum"]["usd"];
      xprUnitEthPrice = parseFloat(xprPrice) / parseFloat(priceUsd);
      xprUnitEthPrice = niceNumberDecimalDisplay(
        xprUnitEthPrice,
        decimalPoints
      );
    }
    const btcResponse = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd"
    );
    if (btcResponse) {
      btcPriceUsd = btcResponse.data["bitcoin"]["usd"];
      xprUnitBtcPrice = parseFloat(xprPrice) / parseFloat(btcPriceUsd);
      xprUnitBtcPrice = niceNumberDecimalDisplay(
        xprUnitBtcPrice,
        decimalPoints
      );
    }
    this.setState({
      xprUnitEthPrice,
      xprUnitBtcPrice,
      ethUnitUsdPrice: niceNumberDecimalDisplay(priceUsd, 2),
      btcUnitUsdPrice: niceNumberDecimalDisplay(btcPriceUsd, 2),
    });
    if (buyAmount > 0) {
      this.calculateInputChange("buy");
    }
    if (sellAmount > 0) {
      this.calculateInputChange("sell");
    }
  }

  async changeBaseCurrency(event) {
    const { totalExchangeUSDBalance } = this.state;
    let stateObj = {};
    let currencyValue = event.target.value;
    stateObj.currencyType = currencyValue;
    if (currencyValue === "USD") {
      stateObj.currentWalletBalance = totalExchangeUSDBalance;
    } else {
      const cryptoCurrencyBalance = this.state[currencyValue + "walletBalance"];
      stateObj.currentWalletBalance = cryptoCurrencyBalance
        ? cryptoCurrencyBalance
        : 0;
    }
    this.setState(stateObj, async () => {
      await this.setXprUnitPrice();
      this.getRecords();
    });
  }

  async getMyMandates() {
    let authenticationToken = this.props.authToken;
    if (authenticationToken) {
      try {
        const api = new Api();
        const myMandatesResponse = await api
          .setToken(authenticationToken)
          .get("user/my/mandates");
        if (myMandatesResponse.code === 200) {
          this.setState({
            myMandatesLoading: false,
            myMandates: myMandatesResponse.data.myMandates,
          });
        }
      } catch (err) {}
    }
  }

  async makeTrade(event, type, details) {
    event.preventDefault();
    if (type && !_.isEmpty(details)) {
      let stateObject = {};
      if (type === "buy") {
        stateObject.buyAmount = niceNumberDecimalDisplay(details.subTotal, 2);
        stateObject.receiveXpr = niceNumberDecimalDisplay(details.quantity, 2);
        stateObject.subTotal = niceNumberDecimalDisplay(details.subTotal, 2);
        stateObject.transactionFeeAmount = niceNumberDecimalDisplay(
          details.feeAmount,
          2
        );
        stateObject.transactionVatTaxAmount = niceNumberDecimalDisplay(
          details.taxAmount,
          2
        );
        stateObject.finalTotal = niceNumberDecimalDisplay(
          details.totalOrderAmount,
          2
        );
      } else if (type === "sell") {
        stateObject.sellQuantity = niceNumberDecimalDisplay(
          details.quantity,
          2
        );
        stateObject.sellAmount = details.totalOrderAmount;
        stateObject.sellSubTotal = niceNumberDecimalDisplay(
          details.subTotal,
          2
        );
        stateObject.sellFinalTotal = niceNumberDecimalDisplay(
          details.totalOrderAmount,
          2
        );
      }
      this.setState(stateObject);
    }
  }

  async onPaymentRequestCloseModal() {
    this.setState({ paymentRequestModalOpen: false });
  }

  async onCurrencyRequestCloseModal() {
    this.setState({ currencyTransactionModalOpen: false });
  }

  openBuyXprPayRequest() {
    this.setState({
      paymentRequestModalOpen: true,
    });
  }

  async saveUSDPaymentRequestBkk() {
    let authenticationToken = this.props.authToken;
    const { transactionAmount, transactionNote } = this.state;
    if (!_.isUndefined(transactionAmount) && transactionAmount > 0) {
      this.setState({ paymentRequestButtonLoading: true });

      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/exchange/create/request", {
          transactionAmount,
          transactionNote,
        });
      if (response) {
        this.setState({ paymentRequestButtonLoading: false });
      }
      if (response.code === 200) {
        this.setState({
          transactionAmount: "",
          transactionNote: "",
        });
        toast.success(response.message);
        this.onPaymentRequestCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  }

  async saveUSDPaymentRequest() {
    let _this = this;
    let authenticationToken = this.props.authToken;
    const { transactionAmount, transactionNote } = this.state;
    if (
      !_.isEmpty(this.uploadImageArray) &&
      transactionAmount !== "" &&
      transactionAmount > 0
    ) {
      this.setState({
        paymentRequestButtonLoading: true,
      });
      const data = new FormData();
      data.append("image", this.uploadImageArray["file"]);
      data.append("transactionAmount", transactionAmount);
      data.append("transactionNote", transactionNote);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authenticationToken,
        },
      };
      axios
        .post(API_ROOT + "/user/exchange/create/request", data, config)
        .then(function (response) {
          if (!_.isUndefined(response)) {
            _this.setState({ paymentRequestButtonLoading: false });
            if (response.data.code === 200) {
              this.setState({
                transactionAmount: "",
                transactionNote: "",
              });
              toast.success(response.message);
              this.onPaymentRequestCloseModal();
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch(function (error) {
          toast.error(messageConstants.SOMETHING_WENT_WRONG);
        });
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  }

  async addWalletBalance(e, currencyType) {
    e.preventDefault();
    if (currencyType !== "") {
      if (currencyType === "USD") {
        this.setState({ paymentRequestModalOpen: true });
      } else {
        this.setState({ currencyTransactionModalOpen: true });
      }
    }
  }

  walletAddressCopied() {
    toast.success(messageConstants.WALLET_ADDRESS_COPIED_SUCCESSFULLY);
  }

  async openAddWalletModal() {
    this.setState({
      tokenPaymentModalOpen: false,
      walletAddressModalOpen: true,
    });
  }

  async onWalletAddressCloseModal() {
    this.setState({
      walletAddressModalOpen: false,
    });
  }

  async deleteOperation(mandateId) {
    if (mandateId && mandateId !== "") {
      let authenticationToken = this.props.authToken;
      const api = new Api();
      const mandateCancelResponse = await api
        .setToken(authenticationToken)
        .create("user/exchange/mandate/cancel", { mandateId });
      if (mandateCancelResponse.code === 200) {
        this.updateUserWalletBalance();
        this.getMyMandates();
        this.getRecords();
        toast.success(messageConstants.MANDATE_CANCELLED_SUCCESSFULLY);
      } else {
        toast.error(messageConstants.SOMETHING_WENT_WRONG);
      }
    } else {
      toast.error(messageConstants.MANDATE_NOT_PROVIDED_INVALID_REQUEST);
    }
  }

  eventOperation(mandateId) {
    if (mandateId) {
      let _self = this;
      let buttons = "";
      buttons = [
        {
          label: "Yes",
          onClick: () => _self.deleteOperation(mandateId),
        },
        {
          label: "No",
        },
      ];
      confirmAlert({
        title: "Are you sure want to cancel mandate?",
        buttons: buttons,
      });
    }
  }

  sendTokenToCustody(requiredTokens) {
    this.setState({
      tokenPaymentModalOpen: true,
      totalTokenPayment: requiredTokens,
    });
  }

  onTokenPaymentCloseModal() {
    this.setState({
      tokenPaymentModalOpen: false,
      totalTokenPayment: 0,
    });
  }

  handleMetaMaskError = (error) => {
    const { userReceivingEthAddress } = this.state;
    const ethAddressDisp = ethAddressDisplay(userReceivingEthAddress);
    let errMessage = messageConstants.METAMASK_DEFAULT_MSG;
    if (error.message.includes("User denied transaction signature")) {
      errMessage = messageConstants.METAMASK_USER_REJECTED_MSG;
    } else if (error.message.includes("Please select wallet address")) {
      errMessage = messageConstants.METAMASK_SELECT_WALLET_ADDRESS_MSG;
    } else if (error.message.includes("Sending address mismatch")) {
      errMessage =
        messageConstants.METAMASK_SENDING_ADDRESS_MISMATCH_MSG +
        " " +
        ethAddressDisp +
        " on metamask account.";
    } else if (error.message.includes("Please enter amount")) {
      errMessage = messageConstants.METAMASK_INVALID_AMOUNT_MSG;
    } else if (error.message.includes("Minimum")) {
      errMessage = error.message;
    } else if (error.message.includes("Maximum")) {
      errMessage = error.message;
    } else if (error.message.includes("Wrong Metamask network")) {
      errMessage = error.message;
    }
    toast.error(errMessage);
  };

  async handleMetaMaskSuccess(txHash) {
    const { totalTokenPayment, userReceivingEthAddress } = this.state;
    if (txHash) {
      let authenticationToken = this.props.authToken;
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/token/tranfer/transaction/create", {
          txHash: txHash,
          tokenSendingWalletAdress: userReceivingEthAddress,
          totalTokenPayment: totalTokenPayment,
        });
      if (response.code === 200) {
        this.getMyMandates();
        this.getRecords();
        this.setState({
          tokenPaymentModalOpen: false,
        });
        toast.success(response.message);
      } else {
        toast.success(response.message);
      }
    }
  }

  handleWalletAddressChange(selectedOption) {
    if (selectedOption) {
      this.setState({
        selectedOptionIdValue: selectedOption.value,
        walletAddress: selectedOption.label,
      });
    }
  }

  async addWalletAddress() {
    const { userWalletAddress } = this.state;
    if (userWalletAddress !== "") {
      let authenticationToken = this.props.authToken;
      this.setState({ walletAddressButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/wallet/add", {
          address: userWalletAddress,
        });
      if (response) {
        this.setState({ walletAddressButtonLoading: false });
      }
      if (response.code === 200) {
        this.getUserWalletAddresses();
        this.setState({
          walletAddressModalOpen: false,
          tokenPaymentModalOpen: true,
        });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }

  refreshMandateBook() {
    this.getRecords();
  }

  render() {
    const {
      receiveXpr,
      subTotal,
      transactionFeeAmount,
      transactionVatTaxAmount,
      finalTotal,
      sellFinalTotal,
      currencyType,
      submitBuyMandateLoading,
      submitSellMandateLoading,
      buyAmount,
      sellAmount,
      myMandates,
      myMandatesLoading,
      sellMandateData,
      mandateBookLoading,
      buyMandateData,
      currentXprBalance,
      xprUnitUsdPrice,
      xprUnitBtcPrice,
      xprUnitEthPrice,
      tradeHistory,
      currentWalletBalance,
      sellSubTotal,
      sellTransactionFeeAmount,
      sellTransactionVatTaxAmount,
      ethUnitUsdPrice,
      btcUnitUsdPrice,
    } = this.state;
    let buyMandateButtonDisabled =
      submitBuyMandateLoading === true ? true : false;
    let sellMandateButtonDisabled =
      submitSellMandateLoading === true ? true : false;
    let myMandatesHtml = "";
    let sellMandateRequestsHtml = "";
    let buyMandateRequestsHtml = "";
    let tradeHistoryHtml = "";

    /* My Mandates */
    if (myMandates && !_.isEmpty(myMandates)) {
      myMandatesHtml = myMandates.map((mandateDetails) => (
        <tr key={Math.random()}>
          <td>
            <span>{mandateDetails.currencyType}</span>
            <i className="os-icon os-icon-repeat icon-separator"></i>
            <span>{tokenSymbol}</span>
          </td>
          <td
            className={mandateDetails.type === "BUY" ? "buy-text" : "sell-text"}
          >
            {niceNumberDecimalDisplay(mandateDetails.mandateQuantity, 2)}
          </td>
          <td>
            {mandateDetails.currencyType === "USD"
              ? "$" +
                niceNumberDecimalDisplay(mandateDetails.totalOrderAmount, 2)
              : niceNumberDecimalDisplay(mandateDetails.totalOrderAmount, 2) +
                " " +
                mandateDetails.currencyType}
          </td>
          <td>
            <span className={`${_.toLower(mandateDetails.status)}-text`}>
              {mandateDetails.status}
            </span>
          </td>
          <td
            className={`${
              mandateDetails.status !== "ACCEPTED" ? "text-center" : ""
            }`}
          >
            <span
              className={`${_.toLower(mandateDetails.executedStatus)}-text`}
            >
              {mandateDetails.status === "ACCEPTED"
                ? mandateDetails.quantity !== mandateDetails.mandateQuantity &&
                  mandateDetails.quantity > 0
                  ? "PARTIAL"
                  : mandateDetails.executedStatus
                : "-"}
            </span>
          </td>
          <td>{moment(mandateDetails.createdOnUTC).format("DD MMM YYYY")}</td>
          <td>
            {mandateDetails.executedStatus !== "EXECUTED" &&
            mandateDetails.status !== "CANCELLED" &&
            mandateDetails.quantity === mandateDetails.mandateQuantity ? (
              <i
                className="fa fa-times cursor-pointer sell-text mr-2"
                title="Cancel Mandate"
                onClick={() => this.eventOperation(mandateDetails._id)}
              ></i>
            ) : mandateDetails.tokenTransferredStatus === "TRANSFERRED" ||
              mandateDetails.executedStatus === "EXECUTED" ||
              mandateDetails.status === "CANCELLED" ? (
              <span className="text-center">N/A</span>
            ) : (
              ""
            )}{" "}
            {mandateDetails.type === "SELL" &&
            mandateDetails.tokenTransferredStatus === "PENDING" &&
            mandateDetails.adminVerifiedStatus === "ACCEPTED" &&
            mandateDetails.verifierVerifiedStatus === "ACCEPTED" &&
            mandateDetails.status === "PENDING" ? (
              <a
                href="Javascript:"
                onClick={() => this.sendTokenToCustody(mandateDetails.quantity)}
              >
                Send {tokenSymbol}
              </a>
            ) : (
              ""
            )}{" "}
          </td>
        </tr>
      ));
      this.initHeightSet();
    } else if (!myMandatesLoading && _.isEmpty(myMandates)) {
      myMandatesHtml = (
        <tr className="text-center" key={Math.random()}>
          <td colSpan="6">No records found.</td>
        </tr>
      );
    }
    /* My Mandates */

    /* Sell mandates */
    if (sellMandateData && !_.isEmpty(sellMandateData)) {
      sellMandateRequestsHtml = sellMandateData.map((details) => (
        <tr
          key={Math.random()}
          className=""
          onClick={(e) => this.makeTrade(e, "buy", details)}
        >
          <td className="sell-text">
            {niceNumberDecimalDisplay(details.quantity, 2)}
          </td>
          <td>
            {details.currencyType === "USD"
              ? "$" + details.totalOrderAmount
              : details.totalOrderAmount + " " + details.currencyType}
          </td>
          <td>{moment(details.createdOnUTC).format("DD MMM YYYY HH:mm")}</td>
        </tr>
      ));
    } else if (!mandateBookLoading && _.isEmpty(sellMandateData)) {
      sellMandateRequestsHtml = (
        <tr className="text-center" key={Math.random()}>
          <td colSpan="3">{messageConstants.NO_RECORDS_FOUND}</td>
        </tr>
      );
    }
    /* Sell mandates */

    /* Buy Mandates */
    if (buyMandateData && !_.isEmpty(buyMandateData)) {
      buyMandateRequestsHtml = buyMandateData.map((details) => (
        <tr
          key={Math.random()}
          className=""
          onClick={(e) => this.makeTrade(e, "sell", details)}
        >
          <td className="buy-text">
            {niceNumberDecimalDisplay(details.quantity, 2)}
          </td>
          <td>
            {details.currencyType === "USD"
              ? "$" + details.totalOrderAmount
              : details.totalOrderAmount + " " + details.currencyType}
          </td>
          <td>{moment(details.createdOnUTC).format("DD MMM YYYY HH:mm")}</td>
        </tr>
      ));
    } else if (!mandateBookLoading && _.isEmpty(buyMandateData)) {
      buyMandateRequestsHtml = (
        <tr className="text-center" key={Math.random()}>
          <td colSpan="3">{messageConstants.NO_RECORDS_FOUND}</td>
        </tr>
      );
    }
    /* Buy Mandates */

    /* Trade History */
    if (tradeHistory && !_.isEmpty(tradeHistory)) {
      tradeHistoryHtml = tradeHistory.map((details) => (
        <tr key={Math.random()}>
          <td>{niceNumberDecimalDisplay(details.quantity, 2)}</td>
          <td className="text-center">
            {details.currencyType === "USD"
              ? "$" + niceNumberDecimalDisplay(details.totalOrderAmount, 2)
              : niceNumberDecimalDisplay(details.totalOrderAmount, 2) +
                " " +
                details.currencyType}
          </td>
          <td className="text-right">
            {moment(details.executedOnUTC).format("Do MMMM YYYY, h:mm:ss a")}
          </td>
        </tr>
      ));
    } else if (!mandateBookLoading && _.isEmpty(tradeHistory)) {
      tradeHistoryHtml = (
        <tr className="text-center" key={Math.random()}>
          <td colSpan="3">{messageConstants.NO_RECORDS_FOUND}</td>
        </tr>
      );
    }
    /* Trade History */

    const oneXprCurrencyPrice =
      currencyType === "BTC"
        ? xprUnitBtcPrice
        : currencyType === "ETH"
        ? xprUnitEthPrice
        : xprUnitUsdPrice;

    return (
      <div className="buy-xpr-container permian-exchange-container">
        <div className="content-i">
          <div className="content-box permian-exchange-content-box">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <div className="exchange-content-container">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <div
                        className="color-white p-3 exchange-header-block"
                        id="martek-selector"
                      >
                        <div className="row">
                          <div className="col-md-3 col-xs-12">
                            <span>
                              Select Market:
                              <select
                                className="exchange-currnecy cursor-pointer"
                                onChange={this.changeBaseCurrency}
                                value={currencyType}
                              >
                                <option value="BTC">{tokenSymbol} - BTC</option>
                                <option value="ETH">{tokenSymbol} - ETH</option>
                                <option value="USD">{tokenSymbol} - USD</option>
                              </select>
                            </span>
                          </div>
                          <div className="col-md-9 col-xs-12 xpr-unit-display-container">
                            <div className="row market-other-details-container">
                              <div className="col-md-6">
                                <div className="xpr-unit-display-text text-center">
                                  <span>
                                    {(currencyType === "ETH" ||
                                      currencyType === "BTC") && (
                                      <span>
                                        <span className="ml-2 cryptounitpricetext">
                                          1 {currencyType} = $
                                          {currencyType === "ETH"
                                            ? ethUnitUsdPrice
                                            : btcUnitUsdPrice}
                                        </span>
                                        <span className="pl-2 pr-2 unit-price-seperator">
                                          |
                                        </span>
                                      </span>
                                    )}
                                    1 {tokenSymbol} ={" "}
                                    <span>
                                      {" "}
                                      {currencyType === "USD"
                                        ? "$" + oneXprCurrencyPrice
                                        : oneXprCurrencyPrice +
                                          " " +
                                          currencyType}
                                    </span>
                                    {/* <span className="pl-2 unit-price-seperator">|</span>
                                                                        <span className="pl-2">{xprUnitBtcPrice} BTC</span>
                                                                        <span className="pl-2 unit-price-seperator">|</span>
                                                                        <span className="pl-2">{xprUnitEthPrice} ETH</span> */}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 wallet-balance-display-container">
                                <div className="pull-right wallet-balance-display-text">
                                  <span className="mr-2">
                                    Current Wallet Balance:{" "}
                                    {currencyType === "USD"
                                      ? "$" +
                                        niceNumberDecimalDisplay(
                                          currentWalletBalance,
                                          2
                                        )
                                      : currentWalletBalance +
                                        " " +
                                        currencyType}
                                  </span>
                                  <button
                                    className="exchange-btn2 cursor-pointer"
                                    onClick={(e) =>
                                      this.addWalletBalance(e, currencyType)
                                    }
                                  >
                                    Add {currencyType}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div
                        className="mt-4 color-white content-panel compact color-scheme-dark mandate-book-container permian-exchange-content-panel"
                        id="sell-mandate-book"
                      >
                        <h6 className="color-white mandate-header sell-text">
                          Sell Mandate Book{" "}
                          <span onClick={() => this.refreshMandateBook()}>
                            <i
                              className="fa fa-refresh ml-2 cursor-pointer"
                              title="Refresh Sell Mandate Book"
                            ></i>
                          </span>
                        </h6>
                        <div className="element-box-tp">
                          <table className="table table-compact smaller text-faded mb-0">
                            <thead>
                              <tr>
                                <th>{tokenSymbol} Qty.</th>
                                <th>Amount</th>
                                <th className="text-right">Date</th>
                              </tr>
                            </thead>
                            <tbody>{sellMandateRequestsHtml}</tbody>
                          </table>
                          <a
                            className="centered-load-more-link smaller hide d-none"
                            href="Javascript:void(0)"
                          >
                            <span className="sell-text">
                              Load More Sell Mandates
                            </span>
                          </a>
                        </div>
                      </div>

                      <div
                        className="mt-4 color-white content-panel compact color-scheme-dark mandate-book-container permian-exchange-content-panel"
                        id="buy-mandate-container"
                      >
                        <h6 className="color-white mandate-header buy-text">
                          Buy Mandate Book{" "}
                          <span onClick={() => this.refreshMandateBook()}>
                            <i
                              className="fa fa-refresh ml-2 cursor-pointer"
                              title="Refresh Buy Mandate Book"
                            ></i>
                          </span>
                        </h6>
                        <div className="element-box-tp">
                          <table className="table table-compact smaller text-faded mb-0">
                            <thead>
                              <tr>
                                <th>{tokenSymbol} Qty.</th>
                                <th>Amount</th>
                                <th className="text-right">Date</th>
                              </tr>
                            </thead>
                            <tbody>{buyMandateRequestsHtml}</tbody>
                          </table>
                          <a
                            className="centered-load-more-link smaller hide d-none"
                            href="Javascript:void(0)"
                          >
                            <span className="buy-text">
                              Load More Buy Mandates
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-7" id="sell-recent-orders">
                          <div
                            className="mt-4 color-white content-panel compact color-scheme-dark mandate-book-container permian-exchange-content-panel"
                            id="buy-sell-mandate-container"
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="border-right-seperator pr-4">
                                  <h6 className="color-white mandate-header">
                                    Buy Mandate
                                  </h6>
                                  <div className="mt-2">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <span className="exchange-xpr-label">
                                          Amount:
                                        </span>
                                      </div>
                                      <div className="col-md-9">
                                        <input
                                          className="pl-2 exchange-input2 dark-blue-font-color"
                                          type="number"
                                          id="buyXprAmount"
                                          name="buyXprAmount"
                                          onChange={(e) =>
                                            this.mandateInputChange(e, "buy")
                                          }
                                          placeholder={`Enter ${currencyType} amount`}
                                          value={buyAmount}
                                          step="1"
                                        />
                                      </div>
                                    </div>
                                    <div className="text-right mt-1 xpr-receive-text">
                                      <span>
                                        You will receive {receiveXpr}{" "}
                                        {tokenSymbol}
                                      </span>
                                    </div>
                                    <div className="mt-3 text-right">
                                      <div className="mt-1 exchange-label">
                                        Sub Total:{" "}
                                        {currencyType === "USD"
                                          ? "$" + subTotal
                                          : subTotal + " " + currencyType}
                                      </div>
                                      <div className="mt-1 exchange-label">
                                        Fees:{" "}
                                        {currencyType === "USD"
                                          ? "$" + transactionFeeAmount
                                          : transactionFeeAmount +
                                            " " +
                                            currencyType}{" "}
                                      </div>
                                      <div className="mt-1 exchange-label">
                                        Tax:{" "}
                                        {currencyType === "USD"
                                          ? "$" + transactionVatTaxAmount
                                          : transactionVatTaxAmount +
                                            " " +
                                            currencyType}
                                      </div>
                                      <div className="mt-1 exchange-label">
                                        Final Total:{" "}
                                        {currencyType === "USD"
                                          ? "$" + finalTotal
                                          : finalTotal + " " + currencyType}
                                      </div>
                                    </div>
                                    <div className="text-right mt-3">
                                      <button
                                        className="exchange-btn2 cursor-pointer"
                                        onClick={(e) =>
                                          this.submitMandate(e, "buy")
                                        }
                                        disabled={buyMandateButtonDisabled}
                                      >
                                        Buy {tokenSymbol}{" "}
                                        {submitBuyMandateLoading && (
                                          <i className="fa-spin fa fa-spinner text-white m-1" />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 create-sell-mandate-container">
                                <div className="pr-2">
                                  <h6 className="color-white mandate-header">
                                    Sell Mandate
                                  </h6>
                                  <div className="mt-2">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <span className="exchange-xpr-label">
                                          Amount:
                                        </span>
                                      </div>
                                      <div className="col-md-9">
                                        <input
                                          className="pl-2 exchange-input2 dark-blue-font-color"
                                          type="number"
                                          id="sellXprAmount"
                                          name="sellXprAmount"
                                          onChange={(e) =>
                                            this.mandateInputChange(e, "sell")
                                          }
                                          placeholder={`Enter ${tokenSymbol} Quantity`}
                                          value={sellAmount}
                                          step={1}
                                        />
                                      </div>
                                    </div>
                                    <div className="text-right mt-1 xpr-receive-text hide d-none">
                                      <span>
                                        Current balance {currentXprBalance}{" "}
                                        {tokenSymbol}
                                      </span>
                                    </div>
                                    <div className="text-right mt-1 xpr-receive-text">
                                      <div className="mt-1 exchange-label">
                                        You will receive:{" "}
                                        {currencyType === "USD"
                                          ? "$" + sellFinalTotal
                                          : sellFinalTotal + " " + currencyType}
                                      </div>
                                    </div>
                                    <div className="mt-3 text-right">
                                      <div className="mt-1 exchange-label">
                                        Sub Total:{" "}
                                        {currencyType === "USD"
                                          ? "$" + sellSubTotal
                                          : sellSubTotal + " " + currencyType}
                                      </div>
                                      <div className="mt-1 exchange-label">
                                        Fees: -
                                        {currencyType === "USD"
                                          ? "$" + sellTransactionFeeAmount
                                          : sellTransactionFeeAmount +
                                            " " +
                                            currencyType}{" "}
                                      </div>
                                      <div className="mt-1 exchange-label">
                                        Tax: -
                                        {currencyType === "USD"
                                          ? "$" + sellTransactionVatTaxAmount
                                          : sellTransactionVatTaxAmount +
                                            " " +
                                            currencyType}
                                      </div>
                                      <div className="mt-1 exchange-label">
                                        Final Total:{" "}
                                        {currencyType === "USD"
                                          ? "$" + sellFinalTotal
                                          : sellFinalTotal + " " + currencyType}
                                      </div>
                                    </div>
                                    <div className="text-right mt-3">
                                      <button
                                        className="exchange-btn2 cursor-pointer"
                                        onClick={(e) =>
                                          this.submitMandate(e, "sell")
                                        }
                                        disabled={sellMandateButtonDisabled}
                                      >
                                        Sell {tokenSymbol}{" "}
                                        {submitSellMandateLoading && (
                                          <i className="fa-spin fa fa-spinner text-white m-1" />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="mt-4 color-white content-panel compact color-scheme-dark mandate-book-container permian-exchange-content-panel"
                            id="recent-order-container"
                          >
                            <div>
                              <h6 className="color-white mandate-header">
                                My Recent Orders
                              </h6>
                              <div className="element-box-tp my-recent-orders-container">
                                <table className="table table-compact smaller text-faded mb-0">
                                  <thead>
                                    <tr>
                                      <th>Pair</th>
                                      <th>{tokenSymbol} Qty.</th>
                                      <th>Amount</th>
                                      <th>Status</th>
                                      <th>Executed</th>
                                      <th>Created On</th>
                                      <th>Operations</th>
                                    </tr>
                                  </thead>
                                  <tbody>{myMandatesHtml}</tbody>
                                </table>
                                <a
                                  className="centered-load-more-link smaller text-right hide d-none"
                                  href="Javascript:void(0)"
                                >
                                  <span className="buy-text">View More</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div
                            className="mt-4 color-white content-panel compact color-scheme-dark mandate-book-container permian-exchange-content-panel"
                            id="trade-history"
                          >
                            <h6 className="color-white mandate-header">
                              Trade History
                            </h6>
                            <div className="element-box-tp">
                              <table className="table table-compact smaller text-faded mb-0">
                                <thead>
                                  <tr>
                                    <th>{tokenSymbol} QTY.</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-right">Date</th>
                                  </tr>
                                </thead>
                                <tbody>{tradeHistoryHtml}</tbody>
                              </table>
                              <a
                                className="centered-load-more-link smaller text-right hide d-none"
                                href="Javascript:void(0)"
                              >
                                <span>View More</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentRequestModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onPaymentRequestCloseModal}
          onInputValueChange={this.onChange}
          saveUSDPaymentRequest={this.saveUSDPaymentRequest}
          fileUpdated={this.fileUpdated}
        />

        <CurrencyAddressModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCurrencyRequestCloseModal}
          walletAddressCopied={this.walletAddressCopied}
        />

        <TokenPaymentModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onTokenPaymentCloseModal}
          handleMetaMaskSuccess={this.handleMetaMaskSuccess}
          handleMetaMaskError={this.handleMetaMaskError}
          handleWalletAddressChange={this.handleWalletAddressChange}
          openAddWalletModal={this.openAddWalletModal}
          walletAddressCopied={this.walletAddressCopied}
        />

        <AddWalletAddressModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onWalletAddressCloseModal}
          onInputValueChange={this.onChange}
          addWalletAddress={this.addWalletAddress}
        />
      </div>
    );
  }
}
export default Exchange;
