import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class TokenHolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
      tokenSymbol: this.props.match.params.id
        ? this.props.match.params.id
        : this.props.activeTokenName,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.TOKEN_HOLDERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    this.getRecords();
  }

  componentDidUpdate(prevProps) {
    const { activeTokenName } = this.props;
    if (prevProps.activeTokenName !== activeTokenName) {
      this.setActiveToken(activeTokenName);
    }
  }

  setActiveToken(tokenSymbol = "") {
    if (tokenSymbol) {
      this.setState(
        {
          tokenSymbol,
        },
        () => {
          this.getRecords();
        }
      );
    }
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, tokenSymbol } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    // let issuerTokenSymbol = this.props.match.params.id;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/holdings/", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
          issuerTokenSymbol: tokenSymbol,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.tokenHolders,
          totalSize: response.data.totaltokenHolders,
        });
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag, tokenSymbol } = this.state;
    //let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "address",
        text: "ETH Address",
      },
      {
        headerClasses: "text-bold",
        dataField: "tokens",
        text: "Tokens",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>
                {cell.toLocaleString(undefined, { maximumFractionDigits: 0 })} {tokenSymbol}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "share",
        text: "Share",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}%</div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="element-box">
                <h5 className="form-header">
                  <span>{tokenSymbol} Token Holders</span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                      // onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TokenHolders;
