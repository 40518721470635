import React from "react";
import { toast } from "react-toastify";
import MetaTags from "react-meta-tags";

import Api from "../../services/api";

import logoImg from "../../assets/img/investor-w.png?v3";
import enhancing from "./assets/images/enhancing.svg";
import brokerage from "./assets/images/brokerage.svg";
import blockchain from "./assets/images/blockchain.svg";

import capitalize from "./assets/images/capitalize_1.svg";
import conserve from "./assets/images/conserve_2.svg";
import consult from "./assets/images/consult_3.svg";
import convert from "./assets/images/convert_4.svg";
import lockerImg from "./assets/images/locker.svg?v3";

import "./assets/css/icomoon.css";
import "./assets/css/stylecustom.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      buttonLoading: false,
      modalOpen: false,
      recaptchaResponse: "",
      addInterestLoading: false,
      otherInterestShow: false,
      otherinterest: "",
      termsAccepted: false,
    };
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    this.onChange = this.onChange.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
    this.addInterest = this.addInterest.bind(this);
    this.handleCaptchaResponseChange =
      this.handleCaptchaResponseChange.bind(this);
    this.changeInterestOptions = this.changeInterestOptions.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  acceptTerms(event) {
    this.setState({
      termsAccepted: event.target.checked,
    });
  }

  async subscribeNow(event) {
    event.preventDefault();
    const { email } = this.state;
    if (email && email !== "") {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api.create("user/subscribe", {
        email,
      });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
        if (response.code === 200) {
          this.setState({
            email: "",
          });
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error("Please enter valid email.");
    }
  }

  async addInterest() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      interestedoption,
      country,
      companyName,
      website,
      otherinterest,
      // recaptchaResponse,
      termsAccepted,
    } = this.state;
    if (
      firstName !== "" &&
      email !== "" &&
      interestedoption &&
      termsAccepted === true
    ) {
      this.setState({
        addInterestLoading: true,
      });
      const api = new Api();
      const response = await api.create("user/interest/add", {
        firstName,
        lastName,
        email,
        phoneNumber,
        interestedoption,
        country,
        companyName,
        website,
        otherinterest,
      });
      if (response) {
        this.setState({
          addInterestLoading: false,
        });
        if (response.code === 200) {
          this.setState({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            country: "",
            companyName: "",
            website: "",
            interestedoption: [],
            modalOpen: false,
          });
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      let msg =
        termsAccepted === false
          ? "Please accept terms & conditions."
          : "Please enter required details.";
      toast.error(msg);
    }
  }

  changeInterestOptions(e) {
    var options = e.target.options;
    var value = [];
    var otherinterestFlag = false;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
        if (options[i].value === "other") {
          otherinterestFlag = true;
        }
      }
    }
    let stateObj = {
      interestedoption: value,
    };
    if (otherinterestFlag && otherinterestFlag === true) {
      stateObj.otherInterestShow = true;
    }
    this.setState(stateObj);
  }

  render() {
    const { email, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="home-page-container home-page-main-container">
        <MetaTags>
          <title>
            Digital Securities issuance platform for exploration & production
            companies
          </title>
        </MetaTags>
        <div id="page" className="homepage">
          <nav className="colorlib-nav" role="navigation">
            <div className="top-menu">
              <div className="container">
                <div className="row">
                  <div className="col-md-2">
                    <div id="colorlib-logo">
                      <img src={logoImg} height={70} alt="Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <section
            id="home"
            className="video-hero home-background-image"
            data-section="home"
          >
            <div className="overlay"></div>
            <div className="display-t">
              <div className="display-tc">
                <div className="container">
                  <div className="col-md-12 col-md-offset-0">
                    <div className="animate-box mt-3">
                      <h2>
                        PermianChain Investor: Digital Securities issuance
                        platform for exploration & production companies
                      </h2>
                      <h3 className="home-heading-text">
                        Making it simple for exploration & production (E&P)
                        companies and registered market dealers to work together
                        to achieve sustainable resource financing
                      </h3>
                      <p className="slider-text">
                        The PermianChain platform uses the blockchain to enable
                        exploration and production (E&amp;P) companies to
                        catalogue, manage and create value from their proven oil
                        and gas reserves.
                      </p>
                      <p>
                        {/*  <Link to="/signin" className="btn btn-primary btn-lg btn-custom home-link-button mr-4" rel="noopener noreferrer">Sign In</Link> */}
                        <a
                          href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                          className="btn btn-primary btn-lg btn-custom home-link-button"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="colorlib-services colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img src={enhancing} width={84} alt="Enhance" />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Enhancing the way exempt market dealers raise capital
                      </h3>
                      <p className="body-text">
                        PermianChain Investor platform enables financial
                        services companies to sponsor and offer debt and equity
                        investment opportunities as compliant digital securities
                        in the form of smart contacts on the blockchain to
                        accredited investors under available prospectus
                        exemptions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img src={blockchain} width={84} alt="BlockChain" />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Bringing efficiency and transparency through the
                        blockchain
                      </h3>
                      <p className="body-text">
                        PermianChain establishes an open, secure, transparent
                        and direct private investment platform. The digital
                        infrastructure which PermianChain is built on allows the
                        overall efficiency and transparency of the investment
                        process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img src={brokerage} width={84} alt="Brokerage" />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Reducing administrative and opportunity costs
                      </h3>
                      <p className="body-text">
                        Conventional direct investments in natural resources
                        projects has become exceptionally convoluted. The
                        PermainChain platform makes the investment process
                        efficient, removing costs every step of the way by
                        digitally streamlining compliance requirements, client
                        onboarding processes, launching offering documents and
                        more...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="colorlib-featured">
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="cubewrap">
                    <div className="cube">
                      <div className="front">
                        <img
                          src={lockerImg}
                          height={45}
                          className="lockerimage"
                          alt="LockerImage"
                        />
                      </div>
                      <div className="back"></div>
                      <div className="top"></div>
                      <div className="bottom"></div>
                      <div className="left"></div>
                      <div className="right"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <p className="mt-5 cube-details text-center colorlib-heading animate-box mb-3">
                    PermianChain Investor provides a seamless panel which allows
                    direct investments into natural resources projects to be
                    streamlined on a digital platform as digital securities. The
                    dashboard allows investors [E&P company shareholders] to
                    track the progress of underlying assets, to ascertain key
                    performance indicators, to make additional investments, to
                    request for share transfers, participate in buy-backs,
                    request a sell and/or buy mandate, receive dividends, and
                    more.
                  </p>
                  <p className="cube-details text-center cube-register-text">
                    Register today for a live demo with a PermianChain
                    representative.
                  </p>
                  <p className="text-center">
                    <a
                      href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                      className="btn btn-primary btn-lg btn-custom live-demo-button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Request Live Demo
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="colorlib-work-featured colorlib-bg-white detailed-description">
            <div className="container homecontainer">
              <div className="row mobile-wrap">
                <div className="col-md-12 animate-box pull-left">
                  <div className="desc">
                    <h3>PermianChain’s 4-C’s for Oil & Gas 4.0</h3>
                    <div className="features">
                      <span className="icon">
                        <img src={consult} width={50} alt="Consult" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Consult</strong>
                          <br />
                          EMD’s using the PermianChain technology can provide
                          exploration &amp; production companies ongoing
                          consulting on efficient digital frameworks to solve
                          asset owner communication, strengthen investor
                          relations and optimize cash-flows by adopting the
                          PermianChain pillars.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            className="btn btn-primary btn-custom learn-more-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img src={conserve} width={50} alt="Conserve" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Conserve</strong>
                          <br />
                          Through the adoption of PermianChain’s Oil &amp; Gas
                          4.0 framework, we deploy power generation
                          infrastructure allowing E&amp;Ps to create an onsite
                          market for excess, stranded and/or wasted natural gas,
                          generating a higher netback per mcf.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            className="btn btn-primary btn-custom learn-more-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img src={convert} width={50} alt="Convert" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Convert</strong>
                          <br />
                          When adopting PermianChain’s Oil &amp; Gas 4.0
                          framework we guide upstream companies through the
                          development stages of converting gas-to-bitcoin, which
                          translates into increased cash-flow and an auxiliary
                          source of revenue to fund drilling campaigns.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            className="btn btn-primary btn-custom learn-more-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img
                          src={capitalize}
                          width={50}
                          alt="Capitalize Icon"
                        />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Capitalize</strong>
                          <br />
                          As part of the Oil &amp; Gas 4.0 integration, our team
                          facilitates the potential to access new sources of
                          capital by adopting our technology with registered
                          EMDs, digital asset brokers and authorized placement
                          agents to structure and distribute digital securities
                          for improved liquidity and cash-flow.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            className="btn btn-primary btn-custom learn-more-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="colorlib-subscribe"
            className="colorlib-subscribe"
            data-stellar-background-ratio="0.5"
          >
            <div className="overlay"></div>
            <div className="homecontainer marginleftrightauto">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 text-center colorlib-heading animate-box">
                  <h2>Subscribe Newsletter</h2>
                  <p className="font-size-16">
                    If you’d like to hear more about how PermianChain is
                    boosting oil and gas trading and investment, join our
                    mailing list.
                  </p>
                </div>
              </div>
              <div className="row animate-box">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <form className="form-inline qbstp-header-subscribe">
                        <div className="col-three-forth">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Enter your email"
                              onChange={this.onChange}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-one-third">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => this.subscribeNow(e)}
                              disabled={buttonDisabled}
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer id="colorlib-footer">
            <div className="copy">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>
                      Copyright © 2020 PermianChain Technologies, Inc. All right
                      reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>

        <div className="gototop js-top">
          <a href="#" className="js-gotop">
            <i className="icon-arrow-up2"></i>
          </a>
        </div>
      </div>
    );
  }
}
export default Home;
