import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import CustomHeader from "./CustomHeader";
import Footer from "./Footer";
class ExchangeBasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    document.body.classList.add("full-screen");
    document.body.classList.add("with-content-panel");
    document.body.classList.add("user-dashboard-panel");
  }

  render() {
    return (
      <div className="all-wrapper with-side-panel solid-bg-all user-dashboard-container">
        <ToastContainer hideProgressBar={true} />
        <div>
          <div className="user-dashboard-header-bg-color header-fixed-menu">
            <CustomHeader
              authUserInfo={this.props.authUserInfo}
              isExchange={true}
            />
          </div>
          <div className="layout-w">
            <div className="content-w exchange-dashboard-content">
              {this.props.children}
              <div className="base-footer exchange-base-footer">
                <Footer isExchange={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExchangeBasePage;
