import React, { Component } from "react";
import _ from "lodash";
import * as messageConstants from "../../utils/Messages";
import { Checkbox } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../services/api";

class IndividualFatcaControllingPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changeControllingPerson = this.changeControllingPerson.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveControllingPersonDetails =
      this.saveControllingPersonDetails.bind(this);
  }

  async componentWillMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    document.title =
      messageConstants.INDIVIDUAL_FATCA_CRS_TAX_RESIDENCY_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    const api = new Api();
    try {
      const crsResponse = await api
        .setToken(authenticationToken)
        .get("user/individual/crs/details");
      if (crsResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = crsResponse.data;
        let setStateVariables = [
          "legalPersonOwnership",
          "legalPersonOwnerEntityName",
          "legalPersonOther",
          "legalPersonOtherEntityName",
          "legalPersonSeniorManagingOfficial",
          "legalPersonSeniorManagingOfficialEntityName",
          "trustSettlor",
          "trustSettlorEntityName",
          "trustTrustee",
          "trustTrusteeEntityName",
          "trustProtector",
          "trustProtectorEntityName",
          "trustBeneficiary",
          "trustBeneficiaryEntityName",
          "trustOther",
          "trustOtherEntityName",
          "legalArrangementSettlor",
          "legalArrangementSettlorEntityName",
          "legalArrangementTrustee",
          "legalArrangementTrusteeEntityName",
          "legalArrangementProtector",
          "legalArrangementProtectorEntityName",
          "legalArrangementBeneficiary",
          "legalArrangementBeneficiaryEntityName",
          "legalArrangementOther",
          "legalArrangementOtherEntityName",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  changeControllingPerson(e, checked, stateName) {
    this.setState({
      [stateName]: checked,
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async saveControllingPersonDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      legalPersonOwnership,
      legalPersonOwnerEntityName,
      legalPersonOther,
      legalPersonOtherEntityName,
      legalPersonSeniorManagingOfficial,
      legalPersonSeniorManagingOfficialEntityName,
      trustSettlor,
      trustSettlorEntityName,
      trustTrustee,
      trustTrusteeEntityName,
      trustProtector,
      trustProtectorEntityName,
      trustBeneficiary,
      trustBeneficiaryEntityName,
      trustOther,
      trustOtherEntityName,
      legalArrangementSettlor,
      legalArrangementSettlorEntityName,
      legalArrangementTrustee,
      legalArrangementTrusteeEntityName,
      legalArrangementProtector,
      legalArrangementProtectorEntityName,
      legalArrangementBeneficiary,
      legalArrangementBeneficiaryEntityName,
      legalArrangementOther,
      legalArrangementOtherEntityName,
    } = this.state;

    this.setState({
      buttonLoading: true,
    });
    const api = new Api();
    const apiResponse = await api
      .setToken(authenticationToken)
      .create("user/individual_fatca_crs/save/controlling_person", {
        legalPersonOwnership: legalPersonOwnership,
        legalPersonOwnerEntityName: legalPersonOwnerEntityName,
        legalPersonOther: legalPersonOther,
        legalPersonOtherEntityName: legalPersonOtherEntityName,
        legalPersonSeniorManagingOfficial: legalPersonSeniorManagingOfficial,
        legalPersonSeniorManagingOfficialEntityName:
          legalPersonSeniorManagingOfficialEntityName,
        trustSettlor: trustSettlor,
        trustSettlorEntityName: trustSettlorEntityName,
        trustTrustee: trustTrustee,
        trustTrusteeEntityName: trustTrusteeEntityName,
        trustProtector: trustProtector,
        trustProtectorEntityName: trustProtectorEntityName,
        trustBeneficiary: trustBeneficiary,
        trustBeneficiaryEntityName: trustBeneficiaryEntityName,
        trustOther: trustOther,
        trustOtherEntityName: trustOtherEntityName,
        legalArrangementSettlor: legalArrangementSettlor,
        legalArrangementSettlorEntityName: legalArrangementSettlorEntityName,
        legalArrangementTrustee: legalArrangementTrustee,
        legalArrangementTrusteeEntityName: legalArrangementTrusteeEntityName,
        legalArrangementProtector: legalArrangementProtector,
        legalArrangementProtectorEntityName:
          legalArrangementProtectorEntityName,
        legalArrangementBeneficiary: legalArrangementBeneficiary,
        legalArrangementBeneficiaryEntityName:
          legalArrangementBeneficiaryEntityName,
        legalArrangementOther: legalArrangementOther,
        legalArrangementOtherEntityName: legalArrangementOtherEntityName,
      });
    if (apiResponse) {
      this.setState({
        buttonLoading: false,
      });
      if (apiResponse.code === 200) {
        this.props.history.push(
          "/individual_fatca_crs/declaration_and_undertakings"
        );
        toast.success(apiResponse.message);
      } else {
        toast.error(apiResponse.message);
      }
    }
  }

  render() {
    const {
      legalPersonOwnership,
      legalPersonOwnerEntityName,
      legalPersonOther,
      legalPersonOtherEntityName,
      legalPersonSeniorManagingOfficial,
      legalPersonSeniorManagingOfficialEntityName,
      trustSettlor,
      trustSettlorEntityName,
      trustTrustee,
      trustTrusteeEntityName,
      trustProtector,
      trustProtectorEntityName,
      trustBeneficiary,
      trustBeneficiaryEntityName,
      trustOther,
      trustOtherEntityName,
      legalArrangementSettlor,
      legalArrangementSettlorEntityName,
      legalArrangementTrustee,
      legalArrangementTrusteeEntityName,
      legalArrangementProtector,
      legalArrangementProtectorEntityName,
      legalArrangementBeneficiary,
      legalArrangementBeneficiaryEntityName,
      legalArrangementOther,
      legalArrangementOtherEntityName,
      buttonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h4 className="text-center color-white identification-header">
            Section 4: Type of Controlling Person
          </h4>
          <div className="kyc-container mt-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <p>
                      (ONLY to be completed by any individual who is a
                      Controlling Person of an entity investor which is a
                      Passive Non-Financial Entity or an Investment Entity
                      located in a Non-Participating Jurisdiction and managed by
                      another Financial Institution)
                    </p>

                    <p>
                      For joint or multiple Controlling Persons please complete
                      a separate Self-Certification Form for each Controlling
                      Person
                    </p>

                    <label>
                      Please Confirm what type of Controlling Person applicable
                      under CRS that applies to you/the investor by ticking the
                      appropriate checkbox.
                    </label>

                    <div className="form-group mt-3">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalPersonOwnership"
                          )
                        }
                        checked={legalPersonOwnership}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal person – control by
                        ownership
                      </label>
                    </div>

                    {legalPersonOwnership && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalPersonOwnerEntityName"
                            onChange={this.onChange}
                            value={legalPersonOwnerEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalPersonOther"
                          )
                        }
                        checked={legalPersonOther}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal person – control by other
                        means
                      </label>
                    </div>

                    {legalPersonOther && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalPersonOtherEntityName"
                            onChange={this.onChange}
                            value={legalPersonOtherEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalPersonSeniorManagingOfficial"
                          )
                        }
                        checked={legalPersonSeniorManagingOfficial}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal person – senior managing
                        official
                      </label>
                    </div>

                    {legalPersonSeniorManagingOfficial && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalPersonSeniorManagingOfficialEntityName"
                            onChange={this.onChange}
                            value={legalPersonSeniorManagingOfficialEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "trustSettlor"
                          )
                        }
                        checked={trustSettlor}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a trust - settlor
                      </label>
                    </div>

                    {trustSettlor && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="trustSettlorEntityName"
                            onChange={this.onChange}
                            value={trustSettlorEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "trustTrustee"
                          )
                        }
                        checked={trustTrustee}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a trust – trustee
                      </label>
                    </div>

                    {trustTrustee && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="trustTrusteeEntityName"
                            onChange={this.onChange}
                            value={trustTrusteeEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "trustProtector"
                          )
                        }
                        checked={trustProtector}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a trust – protector
                      </label>
                    </div>

                    {trustProtector && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="trustProtectorEntityName"
                            onChange={this.onChange}
                            value={trustProtectorEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "trustBeneficiary"
                          )
                        }
                        checked={trustBeneficiary}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a trust – beneficiary
                      </label>
                    </div>

                    {trustBeneficiary && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="trustBeneficiaryEntityName"
                            onChange={this.onChange}
                            value={trustBeneficiaryEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(e, checked, "trustOther")
                        }
                        checked={trustOther}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a trust – other
                      </label>
                    </div>

                    {trustOther && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="trustOtherEntityName"
                            onChange={this.onChange}
                            value={trustOtherEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalArrangementSettlor"
                          )
                        }
                        checked={legalArrangementSettlor}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal arrangement (non-trust) –
                        settlor-equivalent
                      </label>
                    </div>

                    {legalArrangementSettlor && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalArrangementSettlorEntityName"
                            onChange={this.onChange}
                            value={legalArrangementSettlorEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalArrangementTrustee"
                          )
                        }
                        checked={legalArrangementTrustee}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal arrangement (non-trust) –
                        trustee-equivalent
                      </label>
                    </div>

                    {legalArrangementTrustee && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalArrangementTrusteeEntityName"
                            onChange={this.onChange}
                            value={legalArrangementTrusteeEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalArrangementProtector"
                          )
                        }
                        checked={legalArrangementProtector}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal arrangement (non-trust) –
                        protector-equivalent
                      </label>
                    </div>

                    {legalArrangementProtector && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalArrangementProtectorEntityName"
                            onChange={this.onChange}
                            value={legalArrangementProtectorEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalArrangementBeneficiary"
                          )
                        }
                        checked={legalArrangementBeneficiary}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal arrangement (non-trust) –
                        beneficiary-equivalent
                      </label>
                    </div>

                    {legalArrangementBeneficiary && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalArrangementBeneficiaryEntityName"
                            onChange={this.onChange}
                            value={legalArrangementBeneficiaryEntityName}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue declarationCheck"
                        increaseArea="20%"
                        onChange={(e, checked) =>
                          this.changeControllingPerson(
                            e,
                            checked,
                            "legalArrangementOther"
                          )
                        }
                        checked={legalArrangementOther}
                      />
                      <label className="declarationLabel">
                        Controlling Person of a legal arrangement (non-trust) –
                        other-equivalent
                      </label>
                    </div>

                    {legalArrangementOther && (
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Entity Name"
                            name="legalArrangementOtherEntityName"
                            onChange={this.onChange}
                            value={legalArrangementOtherEntityName}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Link
                  to="/individual_fatca_crs/tax_declaration"
                  className="color-white text-underline"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button mr-3"
                  type="button"
                  onClick={this.saveControllingPersonDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualFatcaControllingPerson;
