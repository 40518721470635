import React, { Component } from "react";
import _ from "lodash";
import { Checkbox, Radio, RadioGroup } from "react-icheck";
import Select from "react-select";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import validators from "../../validators";
class CorporateIdentificationCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyRegisteredName: "",
      coyLegalForm: "",
      coyOtherUsedTradingName: "",
      coyOtherUsedTradingName2: "",
      coyIncorportionDate: "",
      coyPlaceOfIncorporation: "",
      coyRegistrationNumber: "",
      coyFiscalResidenceCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      coyFiscalResidenceCountryValue: "",
      coyBusinessActivities: [],
      coyOtherActivities1: "",
      coyOtherActivities2: "",
      coyGroupName: "",
      coyGroupWebsite: "",
      coySupervisedByName: "",
      coyPoliticalName: "",
      coyPoliticalFunction: "",
      coyPoliticalName2: "",
      coyPoliticalFunction2: "",
      countriesList: [
        { value: "", label: messageConstants.LABEL_SELECT_COUNTRY },
      ],
      coyPoliticallyExposedPerson: "n",
      otherActivitiesChecked: false,
      coySupervisedBy: "n",
      coyPartOfGroup: "n",
      coyGroupCountryName: messageConstants.LABEL_SELECT_COUNTRY,
      coyExternalAuditor: "",
      buttonLoading: false,
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.handleDoiChange = this.handleDoiChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.changeBusinessActivities = this.changeBusinessActivities.bind(this);
    this.saveCompanyDetails = this.saveCompanyDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkBusinessActivitiesIfSelected =
      this.checkBusinessActivitiesIfSelected.bind(this);
  }

  async componentWillMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const api = new Api();
    const response = await api.get("user/getCountries");
    let authenticationToken = this.props.authToken;
    let countriesList = [];
    if (response.code === 200) {
      if (!_.isUndefined(response.data.countries)) {
        response.data.countries.forEach((country) => {
          let obj = { value: country._id, label: country.name };
          countriesList.push(obj);
        });
        if (!_.isUndefined(countriesList)) {
          this.setState({
            countriesList: countriesList,
          });
        }
      }
    }

    try {
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/company/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let identificationData = identificationResponse.data;
        let setStateVariables = [
          "coyRegisteredName",
          "coyLegalForm",
          "coyOtherUsedTradingName",
          "coyOtherUsedTradingName2",
          "coyIncorportionDate",
          "coyPlaceOfIncorporation",
          "coyRegistrationNumber",
          "coyBusinessActivities",
          "coyOtherActivities1",
          "coyOtherActivities2",
          "coyPartOfGroup",
          "coyGroupName",
          "coyGroupWebsite",
          "coySupervisedBy",
          "coySupervisedByName",
          "coyExternalAuditor",
          "coyPoliticallyExposedPerson",
          "coyPoliticalName",
          "coyPoliticalFunction",
          "coyPoliticalName2",
          "coyPoliticalFunction2",
        ];
        let setStateCountryVariables = [
          "coyGroupCountry",
          "coyFiscalResidenceCountry",
        ];
        if (!_.isUndefined(identificationData)) {
          _.forEach(identificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
            if (setStateCountryVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key + "Value"] = value._id;
                stateObj[key + "Name"] = value.name;
              }
            }
          });
          if (
            (!_.isUndefined(identificationData.coyOtherActivities1) &&
              identificationData.coyOtherActivities1 !== "") ||
            (!_.isUndefined(identificationData.coyOtherActivities2) &&
              identificationData.coyOtherActivities2 !== "")
          ) {
            stateObj["otherActivitiesChecked"] = true;
          }
        }
        this.setState(stateObj);
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleDoiChange(date) {
    this.setState({
      coyIncorportionDate: date,
    });
  }

  handleCountryChange(selectedOption, fieldName) {
    let countryIdValue = fieldName + "Value";
    let countryName = fieldName + "Name";
    let stateobj = {};
    stateobj[countryIdValue] = selectedOption.value;
    stateobj[countryName] = selectedOption.label;
    this.setState(stateobj);
  }

  handleChange(e, value, stateName) {
    let stateObj = {};
    stateObj[stateName] = value;
    if (stateName === "otherActivitiesChecked" && value === false) {
      stateObj.coyOtherActivities1 = "";
      stateObj.coyOtherActivities2 = "";
    }
    this.setState(stateObj);
  }

  changeBusinessActivities(e, checked, value) {
    let { coyBusinessActivities } = this.state;
    if (checked) {
      coyBusinessActivities.push(value);
      this.setState({
        coyBusinessActivities: coyBusinessActivities,
      });
    } else {
      var removeBusinessActivities = {};
      removeBusinessActivities = _.remove(coyBusinessActivities, function (n) {
        return n !== value;
      });
      this.setState({
        coyBusinessActivities: removeBusinessActivities,
      });
    }
  }

  isFormValid() {
    let status = true;
    const validationFields = ["coyRegisteredName", "coyPlaceOfIncorporation"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async saveCompanyDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      coyRegisteredName,
      coyLegalForm,
      coyOtherUsedTradingName,
      coyOtherUsedTradingName2,
      coyIncorportionDate,
      coyPlaceOfIncorporation,
      coyRegistrationNumber,
      coyBusinessActivities,
      coyOtherActivities1,
      coyOtherActivities2,
      coyPartOfGroup,
      coyGroupName,
      coyGroupWebsite,
      coySupervisedBy,
      coyExternalAuditor,
      coySupervisedByName,
      coyPoliticallyExposedPerson,
      coyPoliticalName,
      coyPoliticalFunction,
      coyPoliticalName2,
      coyPoliticalFunction2,
      coyGroupCountryValue,
      coyFiscalResidenceCountryValue,
    } = this.state;

    const isFormValid = this.isFormValid();
    if (
      isFormValid &&
      coyRegisteredName !== "" &&
      coyIncorportionDate !== "" &&
      coyPlaceOfIncorporation !== "" &&
      coyRegistrationNumber !== "" &&
      coyFiscalResidenceCountryValue !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/corporate_identification/save/company_details", {
          coyRegisteredName: coyRegisteredName,
          coyLegalForm: coyLegalForm,
          coyOtherUsedTradingName: coyOtherUsedTradingName,
          coyOtherUsedTradingName2: coyOtherUsedTradingName2,
          coyIncorportionDate: coyIncorportionDate,
          coyPlaceOfIncorporation: coyPlaceOfIncorporation,
          coyRegistrationNumber: coyRegistrationNumber,
          coyFiscalResidenceCountryValue: coyFiscalResidenceCountryValue,
          coyBusinessActivities: coyBusinessActivities,
          coyOtherActivities1: coyOtherActivities1,
          coyOtherActivities2: coyOtherActivities2,
          coyPartOfGroup: coyPartOfGroup,
          coyGroupName: coyGroupName,
          coyGroupWebsite: coyGroupWebsite,
          coySupervisedBy: coySupervisedBy,
          coyExternalAuditor: coyExternalAuditor,
          coySupervisedByName: coySupervisedByName,
          coyPoliticallyExposedPerson: coyPoliticallyExposedPerson,
          coyPoliticalName: coyPoliticalName,
          coyPoliticalFunction: coyPoliticalFunction,
          coyPoliticalName2: coyPoliticalName2,
          coyPoliticalFunction2: coyPoliticalFunction2,
          coyGroupCountryValue: coyGroupCountryValue,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/corporate_identification/contact_details");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  checkBusinessActivitiesIfSelected(fieldName) {
    let { coyBusinessActivities } = this.state;
    let checked =
      !_.isUndefined(coyBusinessActivities) &&
      coyBusinessActivities.indexOf(fieldName) > -1
        ? true
        : false;
    return checked;
  }

  render() {
    let {
      otherActivitiesChecked,
      coyPartOfGroup,
      coySupervisedBy,
      coyPoliticallyExposedPerson,
      coyRegisteredName,
      coyLegalForm,
      coyOtherUsedTradingName,
      coyOtherUsedTradingName2,
      coyPlaceOfIncorporation,
      coyRegistrationNumber,
      coyGroupName,
      countriesList,
      coyGroupWebsite,
      coyGroupCountryName,
      coyGroupCountryValue,
      coyExternalAuditor,
      coyFiscalResidenceCountryName,
      coyFiscalResidenceCountryValue,
      buttonLoading,
      coySupervisedByName,
      coyPoliticalName,
      coyPoliticalFunction,
      coyPoliticalName2,
      coyPoliticalFunction2,
      coyOtherActivities1,
      coyOtherActivities2,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    let coyIncorportionDate = moment(this.state.coyIncorportionDate);
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            Registered name
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyRegisteredName"
                            id="coyRegisteredName"
                            onChange={this.onchange}
                            placeholder="Registered Name"
                            value={coyRegisteredName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Legal form
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyLegalForm"
                            id="coyLegalForm"
                            placeholder="Legal form"
                            onChange={this.onchange}
                            value={coyLegalForm}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label className="text-default-color text-default">
                          Other used trading names
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyOtherUsedTradingName"
                            id="coyOtherUsedTradingName"
                            onChange={this.onchange}
                            placeholder="Other used trading name"
                            value={coyOtherUsedTradingName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="coyOtherUsedTradingName2"
                            id="coyOtherUsedTradingName2"
                            onChange={this.onchange}
                            placeholder="Other used trading name"
                            value={coyOtherUsedTradingName2}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group dob-container">
                          <label className="text-default-color text-default">
                            Date of incorporation
                            <span className="required-sign">*</span>
                          </label>
                          <Datetime
                            dateFormat="DD-MM-YYYY"
                            timeFormat={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: "dd-mm-yyyy" }}
                            utc={true}
                            onChange={this.handleDoiChange}
                            value={coyIncorportionDate}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            Place of incorporation
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyPlaceOfIncorporation"
                            id="coyPlaceOfIncorporation"
                            onChange={this.onchange}
                            placeholder="Place of incorporation"
                            value={coyPlaceOfIncorporation}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Registration number of the company
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyRegistrationNumber"
                            id="coyRegistrationNumber"
                            onChange={this.onchange}
                            placeholder="Registration number of the company"
                            value={coyRegistrationNumber}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Country of fiscal residence
                            <span className="required-sign">*</span>
                          </label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Country of fiscal residence"
                            name="companyCountryId"
                            onChange={(selectedOption) =>
                              this.handleCountryChange(
                                selectedOption,
                                "coyFiscalResidenceCountry"
                              )
                            }
                            value={{
                              label: coyFiscalResidenceCountryName,
                              value: coyFiscalResidenceCountryValue,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            {" "}
                            Business activities{" "}
                          </label>
                          <div className="financial-products-invested">
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_CORPORATE_BUSINESS_ACTIVITIES[
                                  "COMMERCIAL_ACTIVITIES"
                                ]
                              }
                              onChange={(e, checked) =>
                                this.changeBusinessActivities(
                                  e,
                                  checked,
                                  "commercial_activities"
                                )
                              }
                              checked={this.checkBusinessActivitiesIfSelected(
                                "commercial_activities"
                              )}
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_CORPORATE_BUSINESS_ACTIVITIES[
                                  "INVESTMENT_ACTIVITIES"
                                ]
                              }
                              onChange={(e, checked) =>
                                this.changeBusinessActivities(
                                  e,
                                  checked,
                                  "investment_activities"
                                )
                              }
                              checked={this.checkBusinessActivitiesIfSelected(
                                "investment_activities"
                              )}
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_CORPORATE_BUSINESS_ACTIVITIES[
                                  "PERSONAL_INVESTMENT_VEHICLE"
                                ]
                              }
                              onChange={(e, checked) =>
                                this.changeBusinessActivities(
                                  e,
                                  checked,
                                  "personal_investment_vehicle"
                                )
                              }
                              checked={this.checkBusinessActivitiesIfSelected(
                                "personal_investment_vehicle"
                              )}
                            />
                            <Checkbox
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              label={
                                " " +
                                messageConstants
                                  .LABEL_CORPORATE_BUSINESS_ACTIVITIES[
                                  "ANY_OTHER_ACTIVITIES"
                                ]
                              }
                              name="otherService"
                              onChange={(e, checked) =>
                                this.handleChange(
                                  e,
                                  checked,
                                  "otherActivitiesChecked"
                                )
                              }
                              checked={this.state.otherActivitiesChecked}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {otherActivitiesChecked === true && (
                      <div>
                        <div className="row">
                          <div className="col-md-12">Other Services</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                name="coyOtherActivities1"
                                onChange={this.onchange}
                                placeholder="Service"
                                value={coyOtherActivities1}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                name="coyOtherActivities2"
                                onChange={this.onchange}
                                placeholder="Service"
                                value={coyOtherActivities2}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-large">
                          Is the Company part of a group?
                        </h6>
                        <div className="form-group">
                          <div className="gender-container">
                            <RadioGroup
                              name="coyPartOfGroup"
                              value={coyPartOfGroup}
                              onChange={(e, value) =>
                                this.handleChange(e, value, "coyPartOfGroup")
                              }
                            >
                              <Radio
                                value="y"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Yes"
                                name="coyPartOfGroup"
                              />
                              <Radio
                                value="n"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="No"
                                name="coyPartOfGroup"
                                selected
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyPartOfGroup === "y" && (
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="coyGroupName"
                                onChange={this.onchange}
                                placeholder="Name"
                                value={coyGroupName}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Country
                              </label>
                              <Select
                                className="country-select2"
                                classNamePrefix="cntry"
                                options={countriesList}
                                onChange={(selectedOption) =>
                                  this.handleCountryChange(
                                    selectedOption,
                                    "coyGroupCountry"
                                  )
                                }
                                value={{
                                  label: coyGroupCountryName,
                                  value: coyGroupCountryValue,
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Website
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="coyGroupWebsite"
                                onChange={this.onchange}
                                placeholder="Website"
                                value={coyGroupWebsite}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-6">
                        <h6 className="text-default-color text-large">
                          Is the company supervised by a Financial Services
                          Regulator?
                        </h6>
                        <div className="form-group">
                          <div className="gender-container">
                            <RadioGroup
                              name="coySupervisedBy"
                              value={coySupervisedBy}
                              onChange={(e, value) =>
                                this.handleChange(e, value, "coySupervisedBy")
                              }
                            >
                              <Radio
                                value="y"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Yes"
                                name="coySupervisedBy"
                              />
                              <Radio
                                value="n"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="No"
                                name="coySupervisedBy"
                                selected
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>

                      {coySupervisedBy === "y" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color text-default">
                              Superviser Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="coySupervisedByName"
                              onChange={this.onchange}
                              placeholder="By Name"
                              value={coySupervisedByName}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Name of external Auditor
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="coyExternalAuditor"
                            placeholder="Name of external Auditor"
                            onChange={this.onchange}
                            value={coyExternalAuditor}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div
                  className="element-wrapper custom-element-box"
                  style={{ margin: "0" }}
                >
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-default-color text-large">
                          Are any of the directors /signatories /shareholders or
                          a member of their family / a close associate, a
                          Politically Exposed Person?
                        </h6>
                        <div className="form-group">
                          <label className="text-default-color text-default">
                            Status
                          </label>
                          <div className="gender-container">
                            <RadioGroup
                              name="coyPoliticallyExposedPerson"
                              value={coyPoliticallyExposedPerson}
                              onChange={(e, value) =>
                                this.handleChange(
                                  e,
                                  value,
                                  "coyPoliticallyExposedPerson"
                                )
                              }
                            >
                              <Radio
                                value="y"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="Yes"
                                name="coyPoliticallyExposedPerson"
                              />
                              <Radio
                                value="n"
                                radioClass="iradio_square-blue pr-2"
                                increaseArea="20%"
                                label="No"
                                name="coyPoliticallyExposedPerson"
                                selected
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>

                    {coyPoliticallyExposedPerson === "y" && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="coyPoliticalName"
                                onChange={this.onchange}
                                placeholder="Name"
                                value={coyPoliticalName}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Function
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="coyPoliticalFunction"
                                onChange={this.onchange}
                                placeholder="Function"
                                value={coyPoliticalFunction}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="coyPoliticalName2"
                                onChange={this.onchange}
                                placeholder="Name"
                                value={coyPoliticalName2}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-default-color text-default">
                                Function
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="coyPoliticalFunction2"
                                onChange={this.onchange}
                                placeholder="Function"
                                value={coyPoliticalFunction2}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <span className="text-default-color text-default">
                      Note : A Politically exposed person is a person with
                      prominent public function e.g. head of state or of
                      government, senior politician, senior government, judicial
                      or military official, ambassador, senior executive of a
                      state owned co-operation or an important political party
                      official
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link to="/my_account">
                <button className="btn cancel-button" type="button">
                  Cancel
                </button>
              </Link>
              <button
                className="btn update-button ml-2"
                type="button"
                onClick={this.saveCompanyDetails}
                disabled={buttonDisabled}
              >
                Next{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateIdentificationCompanyDetails;
