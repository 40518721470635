import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { tokenSymbol } from "../../services/api-config";

class AddWalletAddressModal extends Component {
  render() {
    const {
      walletAddressModalOpen,
      onCloseModal,
      addWalletAddress,
      onInputValueChange,
    } = this.props;
    return (
      <Modal
        open={walletAddressModalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container token-payment-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">Add Wallet Address</h4>
                    <div className="onboarding-text">
                      Add your {tokenSymbol} token sending ERC-20 address.
                    </div>
                    <form>
                      <div className="form-group">
                        <label htmlFor="transactionAmount">
                          Address<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="walletAddress"
                          placeholder="Enter wallet address"
                          type="text"
                          name="userWalletAddress"
                          onChange={onInputValueChange}
                        />
                      </div>
                      <div>
                        <button
                          className="btn btn-primary permian-button exchange-usd-submit-btn"
                          type="button"
                          onClick={addWalletAddress}
                        >
                          {" "}
                          Submit{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddWalletAddressModal;
