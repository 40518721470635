import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

class OtpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      otpModalOpen,
      onCloseModal,
      onInputValueChange,
      sendOtp,
      copySecretKey,
      otpCode,
      whilelistedWalletAddressSecretKey,
    } = this.props;
    return (
      <Modal
        open={otpModalOpen}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-large">
                      Import Wallet
                    </h4>
                    <form>
                      <div className="form-group">
                        <label
                          htmlFor="fullName"
                          className="text-default-color"
                        >
                          OTP<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Enter otp"
                          type="text"
                          name="otpCode"
                          id="otpCode"
                          value={otpCode}
                          onChange={onInputValueChange}
                        />
                      </div>

                      {whilelistedWalletAddressSecretKey && (
                        <div>
                          <div className="mb-2 break-all">
                            Please use below mentioned secret key to import
                            wallet.
                          </div>
                          <div className="secret-container">
                            <span className="break-all">
                              <b>{whilelistedWalletAddressSecretKey}</b>
                            </span>
                            <span>
                              <CopyToClipboard
                                text={whilelistedWalletAddressSecretKey}
                                onCopy={() => {
                                  copySecretKey();
                                }}
                              >
                                <span className="btn btn-outline buy-xpr-button">
                                  Copy
                                </span>
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="d-flex justify-content-end mt-4">
                        <button
                          className="btn modal-button cancel-button"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn modal-button update-button"
                          type="button"
                          onClick={sendOtp}
                        >
                          {" "}
                          Verify{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default OtpModal;
