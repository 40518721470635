import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import Api from "../../services/api";
import { Link } from "react-router-dom";
import * as messageConstants from "../../utils/Messages";

class KycSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      buttonLoading: false,
      apiError: "",
    };
    this.saveKycDetails = this.saveKycDetails.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.KYC_SUBMIT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
  }

  componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  async saveKycDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    this.setState({
      buttonLoading: true,
    });
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .create("user/kycSubmit");
    if (!_.isUndefined(response) && response.code === 200) {
      this.props.history.push("/kyc/agreement");
      toast.success(response.message);
    } else {
      this.setState({
        buttonLoading: false,
      });
      toast.error(response.message);
    }
  }

  render() {
    const { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <ul className="progressbar d-flex">
                  <li>Fill Details</li>
                  <li>Upload</li>
                  <li className="active">Submit</li>
                  <li>Agreement</li>
                </ul>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-4 card-horizontal-padding">
              <div className="col-md-12">
                <div className="element-wrapper card-horizontal-padding">
                  <div className="element-box custom-element-box">
                    <p>Note:</p>
                    <p>
                      KYC seeks a process of collecting pertinent, identifying
                      information about the users of a service. In applying KYC,
                      the platform or company providing the service will compel
                      all users to submit (internationally recognizable)
                      identify documents.
                    </p>

                    <p>
                      Although KYC is seen as “soft” or civil regulation, the
                      protocols are in fact laws, enforced by industry bodies
                      and law enforcement alike. The core purpose of KYC is to
                      make sure people are disqualified to partake of a service
                      – either through being underage, illegal resident or
                      criminally motivated – remain precluded from
                      participation.
                    </p>

                    <p>
                      KYC also generates a database that is useful to both
                      parties. Law enforcement can identify and pursue criminals
                      or terminate the service provision to minors, but users
                      can also employ the database to validate their presence
                      and participation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row card-inner-padding kyc-btn-group">
              <div className="col-md-12 text-right d-flex justify-content-end">
                <Link to="/kyc/upload" className="color-white">
                  <button className="btn cancel-button" type="button">
                    Previous
                  </button>
                </Link>

                <button
                  className="btn update-button ml-2"
                  type="submit"
                  onClick={this.saveKycDetails}
                  disabled={buttonDisabled}
                >
                  Submit{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white ml-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    );
  }
}
export default KycSubmit;
