import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import * as messageConstants from "../../utils/Messages";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  ethAddressDisplay
} from "../../utils/Util";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

class Issuers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
    };
  }

  copyWhiteListAddress = () =>{
    let msg = "Address successfully copied!";
    toast.success(msg);
  }
  async componentDidMount() {
    document.title =
      messageConstants.ISSUERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    await this.props.checkEmdSelected();
    this.getRecords();
  }

  getRecords = async () => {
    const api = new Api();
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/issuers/list", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
        });
      if (response.code === 200) {
        let users = response.data.users;
        users = users.map(user => ({...user, issuerId: user._id + user.emdId._id}));
        this.setState({
          renderFlag: true,
          data: users,
          totalSize: response.data.totalUsers,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag } = this.state;
    const _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "listedTokenCompanyName",
        text: "Issuer",
        formatter: function (cell) {
          return <div>{cell}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "listedTokenName",
        text: "Token Name",
        formatter: function (cell, row) {
          return <div>{cell ? cell : ""}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "listedTokenSymbol",
        text: "Ticker",
        formatter: function (cell, row) {
          return (
            <a
              href={`http://etherscan.io/address/${row.listedTokenContractAddress}`}
              target="_blank"
            >
              {cell ? cell : ""}
            </a>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "listedTokenPrice",
        text: "Price (USD)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.listedTokenPrice,
                  "USD"  // TODO: currency symbol/tag should be a row field
                )}
              >
                {`$ ${niceNumberDecimalDisplay(cell, 2)}`}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "campaigns",
        text: "Campaigns",
        formatter: function (cell) {
          return <div style={{ textAlign: "center" }}>{cell ? cell : 0}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "listedTokenContractAddress",
        text: "Contract Address",
        formatter: function (cell) {
          if(cell){
            const displayEth = ethAddressDisplay(cell);
            return <div>
              <span style={{ color: "gray" }}>{displayEth}</span>
              <CopyToClipboard
                text={cell}
                onCopy={() => _this.copyWhiteListAddress()}
              >
                <i
                  className="fa fa-clipboard cursor-pointer ml-2 standardColorIcon"
                  aria-hidden="true"
                ></i>
              </CopyToClipboard>
            </div>

          }
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "emdFullName",
        text: "Dealer",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <Link to="/emd">
              {row && row.emdId && row.emdId.companyName
                ? row.emdId.companyName
                : ""}
            </Link>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "dealerCountry",
        text: "Dealer country",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div style={{ textAlign: "center" }}>
              {row && row.emdId.country
                ? row.emdId.country
                : ""}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold width-12",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          let redirectLink = "/campaigns/" + row._id;
          return (
            <div className="text-left">
              <div className="d-inline-block">
                <div className="btn-group mr-1 mb-1">
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="btn default-border-btn dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdownMenuButton6"
                    type="button"
                  >
                    {/* {r1} */}
                    Action
                  </button>
                  <div
                    aria-labelledby="dropdownMenuButton6"
                    className="dropdown-menu"
                  >
                    <Link to={redirectLink}>
                      <button className="dropdown-item" type="button">
                        Campaigns
                      </button>
                    </Link>
                    {row.subscriptionAllowd && (
                      <Link
                        to={{
                          pathname: `/subscribe/${row._id}`,
                          state: { supplierData: row },
                        }}
                      >
                        <button className="dropdown-item" type="button">
                          Subscribe
                        </button>
                      </Link>
                    )}
                    <Link to={`token_holders/${row.listedTokenSymbol}`}>
                      <button className="dropdown-item" type="button">
                        Token Holders
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="issuerId"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box overflow-auto">
            <div>
              <div className="element-box overflow-auto">
                <h5 className="form-header">
                  <span className="pull-left">Issuers</span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data || []}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Issuers;
