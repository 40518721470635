import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import Api from "../../services/api";
import { niceNumberDisplay, niceNumberDecimalDisplay } from "../../utils/Util";
import PaymentRequestModal from "./PaymentRequestModal";
import * as messageConstants from "../../utils/Messages";
import validators from "../../validators";
import { API_ROOT } from "../../services/api-config";

class SubscribeSecurityToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      instructionModalOpen: false,
      paymentRequestModalOpen: false,
      buyRequestModalOpen: false,
      totalUnlockedTokens: 0,
      NRTPrice: 0,
      userNote: "",
      buttonLoading: false,
      paymentRequestButtonLoading: false,
      addTransactionButtonLoading: false,
      contributionCurrency: "usd",
      contributionAmount: 0.01,
      totalNRTTokens: 0,
      buyContributionAddress: "",
      buyContributionCurrency: "usd",
      buyContributionAmount: "",
      buyContributionTokens: "",
      ethQRCodeImg: "",
      btcQRCodeImg: "",
      buyModalQRCodeImg: "",
      walletAddresses: [],
      ppmCheck: false,
      scheduleCheck: false,
      newWireTransferDetails: [],
      warrantsCheck: false,
      tokenData: {},
      totalTokens: "",
      currency: "USD",
      transactionAmount: "",
      exchangeRate: "",
      wireFrameData: "",
      selectedSupplierRecord: "",
      paymentProofFileKey: ""
    };

    this.currencyObject = {
      eth: "ethereum",
      btc: "bitcoin",
    };
    this.validators = validators;
  }

  handleUploadFile = (event) => {
    if (event.target.files[0]) {
      this.setState({
        subscriptionAgrrementDocument: event.target.files[0],
      });
    }
  };

  onchange = (event) => {
    event.preventDefault();

    this.setState({
      [event.target.name]: event.target.value,
    });
    if ([event.target.name] == "transactionAmount") {
      this.setState({ transactionAmount: event.target.value }, () => {
        this.fiatTokens(this.state.transactionAmount, this.state.currency);
      });
    }
  };

  checkboxAgree = (e, checked, name) => {
    this.setState({
      [name]: checked,
    });
  };

  onBuyContributionAmountChange = (event) => {
    const { buyContributionCurrency } = this.state;
    this.setState(
      {
        buyContributionAmount: event.target.value,
      },
      async () => {
        await this.getLivePriceCalculations(
          true,
          this.currencyObject[buyContributionCurrency]
        );
      }
    );
  };

  fiatTokens = async (amount, currency) => {
    if (!!amount && !!currency && amount > 0) {
      if (currency === "CAD") {
        this.setState({
          totalTokens:
            amount /
            this.state.exchangeRate /
            this.state.tokenData.listedTokenPrice,
        });
      }
      if (currency === "USD") {
        this.setState({
          totalTokens: amount / this.state.tokenData.listedTokenPrice,
        });
      }
    }
  };

  onNRTTokenChange = (event) => {
    this.setState(
      {
        totalNRTTokens: event.target.value,
      },
      async () => {
        await this.getLivePriceCalculations(false, "", true);
      }
    );
  };

  async componentDidMount() {
    document.title =
      messageConstants.SUBSCRIBE_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { supplierData: data } = this.props.location.state;
    this.setState({ selectedSupplierRecord: data });
    this.setState({
      wireFrameData: data ? data.wireTransferDetail_subscribe_token : '',
      newWireTransferDetails:
        data ? data.wireTransferDetails && data.wireTransferDetails.split(",") : '',
    });

    const issuerTokenSymbol = data ? data.listedTokenSymbol : '';
    const authenticationToken = this.props.authToken;
    const api = new Api();
    const conversionData = await api
      .setToken(this.props.authToken)
      .get("user/rateConversion");

    this.setState({ exchangeRate: conversionData.message[0].exchangerate });

    await this.props.checkEmdSelected();

    const kycVerifiedResponse = await this.props.checkKycVerified();
    if (kycVerifiedResponse.status === true) {
      const userDetailsResponse = kycVerifiedResponse.userDetailsResponse.data;

      if (
        userDetailsResponse.fatcaCrsDataFilled === true &&
        userDetailsResponse.identificationDataFilled
      ) {
        const api = new Api();
        this.getTokenDetails(issuerTokenSymbol);
        const walletResponse = await api
          .setToken(authenticationToken)
          .create("user/subscribe/addresses", {
            issuerTokenSymbol,
          });
        if (walletResponse.code === 200 && walletResponse.data) {
          let walletaddressarr = [];
          _.forEach(walletResponse.data.addresses, function (value, key) {
            const temp = {};
            temp.type = value.addressType;
            temp.value = value.address;
            walletaddressarr.push(temp);
          });
          walletaddressarr = _.sortBy(walletaddressarr, [
            function (o) {
              return o.type;
            },
          ]);
          this.setState(
            {
              walletAddresses: walletaddressarr,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        } else {

          toast.error(walletResponse.message)
        }
        this.getLivePriceCalculations();
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      } else {
        this.props.history.push("/my_account");
      }
    } else {
      this.props.history.replace("/", this.props.history.location.pathname);
      this.props.history.push(kycVerifiedResponse.redirectUrl);
    }
  }

  onContributionAmountChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      async () => {
        await this.getLivePriceCalculations();
      }
    );
  };

  getLivePriceCalculations = async (
    flag,
    buyContributionCurrency,
    reverseFlag = false
  ) => {
    const {
      contributionCurrency,
      contributionAmount,
      buyContributionAmount,
      securityTokenPrice,
    } = this.state;
    const selectedContributionCurrency =
      flag === true && buyContributionCurrency !== ""
        ? buyContributionCurrency
        : contributionCurrency;
    let finalContributionAmount =
      flag === true && buyContributionCurrency !== ""
        ? buyContributionAmount
        : contributionAmount;
    finalContributionAmount =
      finalContributionAmount > 0 ? finalContributionAmount : 0;
    let priceUsd = "";

    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson && parseJson[selectedContributionCurrency]) {
        priceUsd = parseJson[selectedContributionCurrency];
      } else if (selectedContributionCurrency.toLowerCase() === 'usd') {
        priceUsd = 1;
      }
    }
    if (!geminiPricingResponse || !priceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/${selectedContributionCurrency}usd`
      );
      if (response) {
        const priceDetails = response.data;
        priceUsd = priceDetails.last;
      }
    }
    if (priceUsd > 0) {
      const stateKey =
        flag === true && buyContributionCurrency !== ""
          ? "buyContributionTokens"
          : "totalNRTTokens";
      if (reverseFlag === true) {
        const totalNRTTokens = this.state[stateKey];
        const usdAmount =
          parseFloat(totalNRTTokens) * parseFloat(securityTokenPrice);
        if (usdAmount > 0) {
          const latestContributionAmount =
            parseFloat(usdAmount) / parseFloat(priceUsd);
          this.setState({
            contributionAmount: latestContributionAmount,
          });
        }
      } else {
        const contriutionAmountInUsd =
          parseFloat(finalContributionAmount) * parseFloat(priceUsd);
        let totalNRTTokens = contriutionAmountInUsd / securityTokenPrice;
        totalNRTTokens = niceNumberDecimalDisplay(totalNRTTokens, 2);
        this.setState({
          [stateKey]: totalNRTTokens,
        });
      }
    } else {
      toast.error("Somthing went wrong. Please refresh page.");
    }
  };

  onCurrencyChange = async (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      async () => {
        await this.getLivePriceCalculations();
      }
    );
  }

  onAmountChange = async (event) => {
    this.setState(
      {
        transactionAmount: event.target.value,
      },
      () => {
        this.fiatTokens(this.state.transactionAmount, this.state.currency);
      }
    );
  }

  onChangeCurrencyType = (value) => {
    this.setState(
      {
        currency: value,
      },
      () => {
        this.fiatTokens(this.state.transactionAmount, this.state.currency);
      }
    );
  }

  onPaymentFileUpdated = (value) => {
    this.setState({
      paymentProofFileKey: value
    });
  }

  saveNrtBuyPaymentRequest = async () => {
    const { transactionAmount, userNote, currency, subscriptionAgrrementDocument, paymentProofFileKey } = this.state;
    const _this = this;
    const authenticationToken = this.props.authToken;
    const issuerId = this.props.match.params.id;
    const { supplierData } = this.props.location.state;
    const isFormValid = this.isFormValid();
    if (
      !!transactionAmount &&
      transactionAmount > 0 &&
      paymentProofFileKey &&
      subscriptionAgrrementDocument &&
      !!currency &&
      !!issuerId &&
      !!this.state.tokenData.email
    ) {
        if (subscriptionAgrrementDocument) {
          this.setState({
            paymentRequestButtonLoading: true
          });
          const data = new FormData();
          data.append("paymentProofFileKey", paymentProofFileKey);
          data.append("transactionAmount", transactionAmount);
          data.append("transactionType", 'MANUAL');
          data.append("userNote", userNote);
          data.append("currency", currency);
          data.append("issuerId", issuerId);
          data.append("dealerId", supplierData.emdId._id);
          data.append("email", this.state.tokenData.email);
          data.append("ticker", supplierData.listedTokenSymbol);
          data.append("subscriptionAgreement", subscriptionAgrrementDocument);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: authenticationToken,
            },
          };
          axios
            .post(
              API_ROOT + "/user/token/contribution/manual/request",
              data,
              config
            )
            .then(function (response) {
              if (response && response.data && response.data.code === 200) {
                _this.setState({
                  paymentRequestButtonLoading: false,
                });
                _this.setState({
                  transactionAmount: "",
                  userNote: "",
                  paymentProofFileKey: null,
                  subscriptionAgrrementDocument: null,
                  paymentRequestModalOpen: false,
                });
                toast.success(response.data.message);
              } else {
                toast.error(response.data.message);
              }
            })
            .catch(function (error) {
              console.log(error)
              _this.setState({
                paymentRequestButtonLoading: false,
              });
              toast.error(messageConstants.SOMETHING_WENT_WRONG);
            });
          const configuration = {
            headers: {
              Authorization: this.props.authToken,
            },
            body: {
              email: this.state.tokenData.email,
            },
          };
        } else {
          toast.error('Please sign and upload the subsciption agreement.');
        }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      this.setState({
        paymentRequestButtonLoading: false,
      });
    }
  }

  setNRTReadModalCookie = async () => {
    const { ppmCheck, subscriptionAgrrementDocument } = this.state;
    if (ppmCheck) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);

      const authenticationToken = this.props.authToken;

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authenticationToken,
        },
      };

      const issuerTokenSymbol = this.state.securityTokenSymbol || '';

      if (subscriptionAgrrementDocument) {
        const data = new FormData();
        data.append("ticker", issuerTokenSymbol);
        data.append("subscriptionAgreement", subscriptionAgrrementDocument);

        await axios
          .post(API_ROOT + "/user/token/subscription/agreement", data, config)
          .then(function (response) {
            if (response && response.data && response.data.code === 200) {
              toast.success(response.data.message);
              const nrtCookies = new Cookies();
              nrtCookies.set("subscribeNRTTermsRead", true, {
                path: "/",
                expires: nextYear,
              });

            } else {
              toast.error(response.data.message);
            }
          })
          .catch(function (error) {
            toast.error(messageConstants.SOMETHING_WENT_WRONG);
          });
        this.setState({ instructionModalOpen: false });
        toast.error('Please sign and upload the subsciption agreement.');
      }
    } else {
      toast.error(messageConstants.ACCEPT_SUBSCRIBE_TERMS);
    }
  };

  onInsructionCloseModal = () => {
    this.setState({ instructionModalOpen: false });
  }

  onPaymentRequestCloseModal = () => {
    this.setState({
      paymentProofFileKey: null,
      subscriptionAgrrementDocument: null,
      paymentRequestModalOpen: false
    });
  }

  openBuyXprPayRequest = () => {
    this.setState({
      paymentRequestModalOpen: true,
      instructionModalOpen: true
    });
  }

  onUpdateTransactionAmount = (amount) => {
    this.setState({ transactionAmount: amount });
  }

  permianAddressCopied = (type) => {
    type = _.toLower(type);
    const msg =
      type === "eth"
        ? messageConstants.PERMIANCHAIN_ETHEREUM_CONTRIBUTION_ADDRESS_COPIED
        : type === "btc"
          ? messageConstants.PERMIANCHAIN_BITCOIN_CONTRIBUTION_ADDRESS_COPIED
          : type === "usdc"
            ? messageConstants.PERMIANCHAIN_USDC_CONTRIBUTION_ADDRESS_COPIED
            : messageConstants.PERMIANCHAIN_CONTRIBUTION_ADDRESS_COPIED;
    toast.success(msg);
  }

  updateValidators = (fieldName, value) => {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  };

  isFormValid = () => {
    let status = true;
    const validationFields = ["transactionAmount"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    const { paymentProofFileKey, subscriptionAgrrementDocument, ppmCheck } = this.state;
    if (!paymentProofFileKey) {
      return false;
    }
    if (!subscriptionAgrrementDocument) {
      return false;
    }
    if (!ppmCheck) {
      return false;
    }
    return status;
  }

  displayValidationErrors = (fieldName) => {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="validation-red-error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  getTokenDetails = async (tokenSymbol = "") => {
    if (tokenSymbol) {
      const response = this.state.selectedSupplierRecord;
      if (response) {
        const responseData = response;
        this.setState({ tokenData: responseData });
        const tokenPrice = parseFloat(responseData.listedTokenPrice);
        const listedTokenFloating = niceNumberDecimalDisplay(responseData.listedTokenFloating);
        let authorizedToken = parseFloat(responseData.listedTokenAuthorized);

        let authorizedTokenValue = parseFloat(authorizedToken);
        authorizedTokenValue = authorizedTokenValue * tokenPrice;
        authorizedTokenValue = niceNumberDecimalDisplay(
          authorizedTokenValue,
          2
        );

        let floatingToken = responseData.listedTokenFloating;
        floatingToken = parseFloat(floatingToken);

        let floatingTokenValue = parseFloat(floatingToken);
        floatingTokenValue = floatingTokenValue * tokenPrice;
        floatingTokenValue = niceNumberDecimalDisplay(floatingTokenValue, 2);

        let unissuedToken = responseData.listedTokenUnIssued;
        unissuedToken = parseFloat(unissuedToken);

        let unissuedTokenValue = parseFloat(unissuedToken);
        unissuedTokenValue = unissuedTokenValue * tokenPrice;
        unissuedTokenValue = niceNumberDecimalDisplay(unissuedTokenValue, 2);

        let outstandingToken = responseData.listedTokenOutstanding;
        outstandingToken = parseFloat(outstandingToken);

        let outstandingTokenValue = parseFloat(outstandingToken);
        outstandingTokenValue = outstandingTokenValue * tokenPrice;
        outstandingTokenValue = niceNumberDecimalDisplay(
          outstandingTokenValue,
          2
        );
        authorizedToken = niceNumberDecimalDisplay(authorizedToken, 0);
        floatingToken = niceNumberDecimalDisplay(floatingToken, 0);
        unissuedToken = niceNumberDecimalDisplay(unissuedToken, 0);
        outstandingToken = niceNumberDecimalDisplay(outstandingToken, 0);

        const cookies = new Cookies();

        const stateObj = {
          listedTokenFloating,
          authorizedToken,
          authorizedTokenValue,
          floatingToken,
          floatingTokenValue,
          unissuedToken,
          unissuedTokenValue,
          outstandingToken,
          outstandingTokenValue,
          listedTokenForecastDetails: responseData.listedTokenForecastDetails,
          securityTokenPrice: responseData.listedTokenPrice,
          securityTokenSymbol: responseData.listedTokenSymbol,
          listedTokenTotalUnlocked: responseData.listedTokenTotalOfferings,
          wireTransferDetails: responseData.wireTransferDetails,
          listedTokenSubscriptionAgreement:
            responseData.listedTokenSubscriptionAgreement,
        };
        if (stateObj) {
          this.setState(stateObj);
        }
      }
    }
  }

  render() {
    const {
      contributionAmount,
      totalNRTTokens,
      walletAddresses,
      securityTokenSymbol,
      listedTokenTotalUnlocked,
      wireFrameData,
      newWireTransferDetails,
    } = this.state;
    let displayUserWallet = "";
    if (!_.isUndefined(walletAddresses) && !_.isEmpty(walletAddresses)) {
      displayUserWallet = walletAddresses.map((walletAddress, walletKey) => (
        <div className="row mt-4" key={walletKey}>
          <div className="col-sm-12">
            <div className="dark-blue-theme-color">
              <div className="row">
                {/* temporary disabled */}
                {/* <div className="col-sm-12 col-md-12">
                  <div className="card-inner-padding buy-xpr-block">
                    <span className="text-default-color">
                      PermianChain {walletAddress.type} Wallet
                    </span>
                    <h6 className="pt-3 text-default-color">
                      Send your contribution to this {walletAddress.type} wallet
                      to subscribe {securityTokenSymbol} Tokens with{" "}
                      {walletAddress.type}.
                    </h6>
                    <input
                      className="mt-3 eth-contribution-input"
                      type="text"
                      name="fullName"
                      id="fullName"
                      defaultValue={walletAddress.value}
                    />
                    <CopyToClipboard
                      text={walletAddress.value}
                      onCopy={() =>
                        this.permianAddressCopied(walletAddress.type)
                      }
                    >
                      <button className="btn btn-outline buy-xpr-button">
                        Copy
                      </button>
                    </CopyToClipboard>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ));
    }

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-center p-3 dark-blue-theme-color buy-xpr-header-block">
                  Total Unlocked Tokens:
                  <span className="pl-4">
                    {niceNumberDisplay(listedTokenTotalUnlocked)}{" "}
                    {securityTokenSymbol}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="card-inner-padding dark-blue-theme-color buy-xpr-block">
                <span className="text-default-color">
                  PermianChain Converter
                </span>
                <div className="row mt-3">
                  <div className="col-md-5 col-sm-12 converter-container">
                    <span className="pr-3 buy-xpr-label text-default-color">
                      Amount:
                    </span>
                    <div className="token-input-container full-width">
                      <input
                        className="buy-xpr-input"
                        type="text"
                        id="fullName"
                        name="contributionAmount"
                        value={contributionAmount}
                        onChange={this.onContributionAmountChange}
                      />
                      {/* temporary disabled until eth and btc are added */}
                      {/* <select
                        className="buy-xpr-currencey-select"
                        onChange={this.onCurrencyChange}
                        name="contributionCurrency"
                      >
                        <option value="eth">ETH</option>
                        <option value="btc">BTC</option>
                        <option value="usd">USD</option>
                      </select> */}
                      <input
                        onChange={this.onCurrencyChange}
                        className="token-symbol-input"
                        type="text"
                        value="USD"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 converter-sign-container">
                    <span className="converter-sign text-default-color">
                      &#10230;
                    </span>
                  </div>
                  <div className="col-md-5 col-sm-12 converter-container">
                    <span className="pr-2 xpr-tokens-label buy-xpr-label text-default-color">
                      {securityTokenSymbol} Tokens:
                    </span>
                    <div className="token-input-container">
                      <input
                        className="buy-xpr-input"
                        type="text"
                        name="tokens"
                        id="tokens"
                        value={totalNRTTokens || ''}
                        onChange={this.onNRTTokenChange}
                      />
                      <input
                        className="token-symbol-input"
                        type="text"
                        name="tokens"
                        id="tokens"
                        defaultValue={securityTokenSymbol || ''}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4 receive-text text-default-color">
                  You will Receive{" "}
                  <span className="highligher">{totalNRTTokens}</span>{" "}
                  {securityTokenSymbol}
                </div>
              </div>
            </div>

            {displayUserWallet}

            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="dark-blue-theme-color">
                  <div className="row">
                    <div className="col-sm-12 element-wrapper">
                      <div className="card-inner-padding buy-xpr-block">
                        <h4 className="text-default-color element-header">
                          Bank Wire Transfer
                        </h4>
                        <h6 className="pt-3 text-default">
                          Click the Subscribe button to submit your subscription for the {securityTokenSymbol} Token offering. 
                        </h6>
                        <p className="text-default">
                          Once your subscription is submitted, it will be reviewed by your PermianChain Dealer before receiving
                          approval by the Issuer. Once approved, your {securityTokenSymbol} Tokens will be issued to your Whitelisted Wallet Address.
                        </p>
                        <button
                          className="ml-0 mt-3 btn btn-outline buy-online-payment-buttons"
                          onClick={() => this.openBuyXprPayRequest()}
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PaymentRequestModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onPaymentRequestCloseModal}
          onChangeCurrencyType={this.onChangeCurrencyType}
          wireFrameData={wireFrameData}
          onAmountChange={this.onAmountChange}
          onInputValueChange={this.onchange}
          onUpdateAmount={this.onUpdateTransactionAmount}
          fiatTokens={this.fiatTokens}
          saveNrtBuyPaymentRequest={this.saveNrtBuyPaymentRequest}
          isFormValid={this.isFormValid}
          wireTransferDetails={newWireTransferDetails}
          displayValidationErrors={this.displayValidationErrors}
          onPaymentFileUpdated={this.onPaymentFileUpdated}
          setNRTReadModalCookie={this.setNRTReadModalCookie}
          handleUploadFile={this.handleUploadFile}
          checkboxAgree={this.checkboxAgree}
        />
      </div>
    );
  }
}
export default SubscribeSecurityToken;
