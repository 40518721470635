// https://github.com/pierregoutheraud/metamask-transaction-button
import React, { Component, Fragment } from "react";
import { ethAddressDisplay, getWeb3NetworkName } from "../../utils/Util";
import Logo from "./Logo";
import { formatCryptocurrency } from "./lib/format";
import eth from "./lib/Eth";

const STATUS = {
  INITIAL: "initial",
  NOT_LOGGED: "not-logged",
  ERROR: "error",
  SUCCESS: "success",
  LOADING: "loading",
  PROCESSING: "processing",
  MISSING_METAMASK: "missing-metamask",
  MISSING_CONTRACT_ADDRESS: "missing-contract-address",
  MISMATCH_FROM_ADDRESS: "mimatch-from-address",
};

export default class Button extends Component {
  static defaultProps = {
    text: "Send",
    buttonDisabled: true,
    toAddress: null,
    fromAddress: null,
    amount: 0,
    amountSymbol: "",
    amountWalletCurrency: "",
    amountWalletCurrencySymbol: "",
    logoAnimated: true,
    successText: "Thank you!",
    min: undefined,
    max: undefined,
    successCallback: () => {},
    errorCallback: () => {},
    status: STATUS.INITIAL,
    web3Network: 1,
  };

  state = {
    status: STATUS.LOADING,
  };

  componentDidMount() {
    // const { status } = this.props;
    // const { status: stateStatus } = this.state;
    this.interval = setInterval(() => {
      if (eth.reload) {
        eth.reloadDone();
        this.setUpContract();
      }
    }, 1000);
    this.setUpContract();
    // if (status !== stateStatus && status) {
    //   this.updateStatus(status)
    // }
  }

  componentDidUpdate(prevProps) {
    // const { contractAddress } = this.props;
    // if (contractAddress !== prevProps.contractAddress) {
    //   this.setUpContract();
    // }

    if (prevProps !== this.props) {
      this.setUpContract();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async setUpContract() {
    const { contractAddress, contractAbiUrl } = this.props;
    this.updateStatus(STATUS.LOADING);
    if (!eth.isAvailable()) {
      this.updateStatus(STATUS.MISSING_METAMASK);
      window.open("https://metamask.io");
    }
    // if (!eth.isLoggedin) {
    //   this.updateStatus(STATUS.NOT_LOGGED)
    // } else {
    await eth.setContractAddress(contractAddress, contractAbiUrl);
    if (!eth.isTokenContractAvailable()) {
      this.updateStatus(STATUS.MISSING_CONTRACT_ADDRESS);
    }
    // else if (fromAddress !== '' && fromAddress !== eth.currentAccount) {
    //   this.updateStatus(STATUS.MISMATCH_FROM_ADDRESS)
    // }
    else {
      this.updateStatus(STATUS.INITIAL);
    }
    // }
  }

  updateStatus = (status) => {
    this.setState({
      status,
    });
  };

  handleClick = async () => {
    const { toAddress, amount, fromAddress, web3Network } = this.props;
    const { status } = this.state;
    if (status !== STATUS.INITIAL) {
      return;
    }
    if (!fromAddress) {
      this.onError({
        message: "Please select whitelisted address",
      });
      return;
    }
    if (!toAddress) {
      this.onError({
        message: "Please enter receiver address",
      });
      return;
    }
    if (!amount) {
      this.onError({
        message: "Please enter amount",
      });
      return;
    }
    if (eth.networkId !== web3Network) {
      this.onError({
        message: `Wrong Metamask network. Please connect to ${getWeb3NetworkName(
          web3Network
        )} network.`,
      });
      return;
    }
    if (!eth.isLoggedin) {
      await eth.login();
    }

    if (
      eth.currentAccount !== "" &&
      fromAddress !== "" &&
      fromAddress.toLowerCase() !== eth.currentAccount.toLowerCase()
    ) {
      this.onError({
        message: "Sending address mismatch",
      });
      return;
    }
    this.updateStatus(STATUS.PROCESSING);
    eth
      .transferTokens(toAddress, amount)
      .then(this.onSuccess)
      .catch((e) => {
        // Display error during 3s than back to idle state
        this.updateStatus(STATUS.ERROR);
        setTimeout(() => this.updateStatus(STATUS.INITIAL), 3000);
        this.onError(e); // continue to onError
      });
  };

  onSuccess = (transactionHash) => {
    const { successCallback } = this.props;
    this.updateStatus(STATUS.SUCCESS);
    // Call success callback
    if (successCallback && typeof successCallback === "function") {
      successCallback(transactionHash);
    }
  };

  onError = (e) => {
    console.error(e);
    const { errorCallback } = this.props;
    // Call error callback
    if (errorCallback && typeof errorCallback === "function") {
      errorCallback(e);
    }
  };

  renderNotLogged = () => {
    const { status } = this.state;
    return status === STATUS.NOT_LOGGED ? (
      <span className="status">
        You must login
        <br />
        to metamask first.
      </span>
    ) : null;
  };

  renderMissing = () => {
    const { status } = this.state;
    return status === STATUS.MISSING_METAMASK ? (
      <span className="status">
        You need metamask!
        <br />
        https://metamask.io
      </span>
    ) : null;
  };

  renderMissingContract = () => {
    const { status } = this.state;
    return status === STATUS.MISSING_CONTRACT_ADDRESS ? (
      <span className="status">
        {eth.errorMessage || "Token contract error"}
      </span>
    ) : null;
  };

  renderError = () => {
    const { status } = this.state;
    return status === STATUS.ERROR ? (
      <span className="status">
        Transaction
        <br />
        not confirmed.
      </span>
    ) : null;
  };

  renderMisMatchFromAddress = () => {
    const { status } = this.state;
    return status === STATUS.MISMATCH_FROM_ADDRESS ? (
      <span className="status">Mismatch token transfer address</span>
    ) : null;
  };

  renderAccountDetails = () => {
    return (
      <div>
        {eth.currentAccount ? (
          <p className="mb-1 mt-2">{ethAddressDisplay(eth.currentAccount)} </p>
        ) : null}
        {eth.currentAccount != null && eth.currentAccountBalance != null ? (
          <p className="mb-1">
            Balance : {formatCryptocurrency(eth.currentAccountBalance)}{" "}
            {eth.tokenSymbol}{" "}
          </p>
        ) : null}
        {eth.currentAccount && eth.networkId ? (
          <p className="mb-0 mt-1 text-capitalize">
            {getWeb3NetworkName(eth.networkId)} Network
          </p>
        ) : null}
      </div>
    );
  };

  renderInitial = () => {
    const { text, amount, amountWalletCurrency, amountWalletCurrencySymbol } =
      this.props;
    const amountSymbol = eth.tokenSymbol;
    const { status } = this.state;
    const amountTokenFormatted = (
      <Fragment>
        {formatCryptocurrency(amount)}
        <span className="symbolEthLogo">Ξ</span>
        <span className="symbolEth">{amountSymbol}</span>
      </Fragment>
    );
    const amountWalletCurrencyFormatted = amountWalletCurrency
      ? formatCryptocurrency(amountWalletCurrency)
      : undefined;

    const amountBig = amountTokenFormatted;
    const amountSmall = amountWalletCurrencyFormatted;

    return status === STATUS.INITIAL ? (
      <Fragment>
        <span className="text">
          {text}
          <span className="withMetamask">via metamask</span>
        </span>
        <span className="amounts">
          <span className="amountBig">{amountBig}</span>
          {amountWalletCurrencyFormatted && (
            <span className="conversion">
              <span className="approx">≈</span>
              <span className="amountSmall">
                {amountSmall} {amountWalletCurrencySymbol}
              </span>
            </span>
          )}
        </span>
      </Fragment>
    ) : null;
  };

  renderLoading = () => {
    const { status } = this.state;
    return status === STATUS.LOADING ? (
      <span className="status">Fetching contract detail</span>
    ) : null;
  };

  renderProcessing = () => {
    const { status } = this.state;
    return status === STATUS.PROCESSING ? (
      <span className="status">
        Waiting for
        <br />
        confirmation...
      </span>
    ) : null;
  };

  renderSuccess = () => {
    const { successText } = this.props;
    const { status } = this.state;
    return status === STATUS.SUCCESS ? (
      <span className="status">{successText}</span>
    ) : null;
  };

  render() {
    const { logoAnimated } = this.props;
    return (
      <div className="text-center metamask-payment-container">
        <button
          type="button"
          onClick={this.handleClick}
          className="metamaskbutton"
        >
          <Logo className="logo" animated={logoAnimated} />
          {this.renderInitial()}
          {this.renderNotLogged()}
          {this.renderMissing()}
          {this.renderMissingContract()}
          {this.renderMisMatchFromAddress()}
          {this.renderError()}
          {this.renderLoading()}
          {this.renderProcessing()}
          {this.renderSuccess()}
        </button>
        {this.renderAccountDetails()}
      </div>
    );
  }
}
