import React, { Component } from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

class IndividualIdentificationBusinessIntentions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnedAboutOurService: "own",
      referralByName: "",
      assetAmountExpectedToServiced: "",
      assetPercentageExpectedToServiced: "",
      expectedInOutTurnoverFlow: "l500000USD",
      buttonLoading: false,
    };
    this.onchange = this.onchange.bind(this);
    this.saveBusinessIntensionDetails =
      this.saveBusinessIntensionDetails.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.INDIVIDUAL_BUSINESS_INTENSIONS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    try {
      const api = new Api();
      const identificationResponse = await api
        .setToken(authenticationToken)
        .get("user/identification/details");
      if (identificationResponse.code === 200) {
        let stateObj = {};
        let idnetificationData = identificationResponse.data;
        let setStateVariables = [
          "learnedAboutOurService",
          "referralByName",
          "assetAmountExpectedToServiced",
          "assetPercentageExpectedToServiced",
          "expectedInOutTurnoverFlow",
        ];
        if (!_.isUndefined(idnetificationData)) {
          _.forEach(idnetificationData, function (value, key) {
            if (setStateVariables.indexOf(key) > -1) {
              if (!_.isUndefined(value) && value !== "") {
                stateObj[key] = value;
              }
            }
          });
        }
        this.setState(stateObj);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleRadioChange(event, value, fieldName) {
    this.setState({
      [fieldName]: value,
    });
  }

  async saveBusinessIntensionDetails(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    const {
      learnedAboutOurService,
      referralByName,
      assetAmountExpectedToServiced,
      assetPercentageExpectedToServiced,
      expectedInOutTurnoverFlow,
    } = this.state;

    if (
      learnedAboutOurService !== "" &&
      assetAmountExpectedToServiced !== "" &&
      assetPercentageExpectedToServiced !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/individual_identification/save/business_intensions", {
          learnedAboutOurService: learnedAboutOurService,
          referralByName: referralByName,
          assetAmountExpectedToServiced: assetAmountExpectedToServiced,
          assetPercentageExpectedToServiced: assetPercentageExpectedToServiced,
          expectedInOutTurnoverFlow: expectedInOutTurnoverFlow,
        });

      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/individual_identification/wealth");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  render() {
    let {
      learnedAboutOurService,
      referralByName,
      assetAmountExpectedToServiced,
      assetPercentageExpectedToServiced,
      expectedInOutTurnoverFlow,
      buttonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          <h2 className="text-center color-white identification-header">
            About your business intentions with us
          </h2>
          <div className="kyc-container mt-4">
            <div className="row mt-2 p-2">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box">
                  <div className="element-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            How did you learn about us and our services?
                          </label>
                          <div className="our-service-container">
                            <div className="row">
                              <div className="col-md-12">
                                <RadioGroup
                                  name="learnedAboutOurService"
                                  className="split-radio-container"
                                  value={learnedAboutOurService}
                                  onChange={(event, value) =>
                                    this.handleRadioChange(
                                      event,
                                      value,
                                      "learnedAboutOurService"
                                    )
                                  }
                                >
                                  <Radio
                                    value="oe"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE[
                                        "OE"
                                      ]
                                    }
                                    name="learnedAboutOurService"
                                  />

                                  <Radio
                                    value="own"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE[
                                        "OWN"
                                      ]
                                    }
                                    name="learnedAboutOurService"
                                  />
                                  <br />

                                  <Radio
                                    value="r"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE[
                                        "R"
                                      ]
                                    }
                                    name="learnedAboutOurService"
                                  />

                                  <Radio
                                    value="roub"
                                    radioClass="iradio_square-blue pr-2"
                                    increaseArea="20%"
                                    label={
                                      " " +
                                      messageConstants
                                        .LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE[
                                        "ROUB"
                                      ]
                                    }
                                    name="learnedAboutOurService"
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          </div>
                        </div>

                        {learnedAboutOurService !== "own" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Name</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="referralByName"
                                  id="referralByName"
                                  onChange={this.onchange}
                                  placeholder="Name"
                                  value={referralByName}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Which amount of assets do you expect to be serviced by
                          us?<span className="required-sign">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="assetAmountExpectedToServiced"
                            id="assetAmountExpectedToServiced"
                            onChange={this.onchange}
                            placeholder="Assets in USD"
                            value={assetAmountExpectedToServiced}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="assetPercentageExpectedToServiced"
                            id="assetPercentageExpectedToServiced"
                            onChange={this.onchange}
                            placeholder="% of total net wealth"
                            value={assetPercentageExpectedToServiced}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            What is the expected annual in/out flow turnover of
                            assets?
                          </label>
                          <div className="our-service-container">
                            <RadioGroup
                              name="expectedInOutTurnoverFlow"
                              value={expectedInOutTurnoverFlow}
                              onChange={(event, value) =>
                                this.handleRadioChange(
                                  event,
                                  value,
                                  "expectedInOutTurnoverFlow"
                                )
                              }
                            >
                              <Radio
                                value="l500000USD"
                                radioClass="iradio_square-blue"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_EXPECTED_IN_OUT_FLOW[
                                    "L500000USD"
                                  ]
                                }
                                name="expectedInOutTurnoverFlow"
                              />

                              <Radio
                                value="500000to1000000USD"
                                radioClass="iradio_square-blue ml-3"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_EXPECTED_IN_OUT_FLOW[
                                    "500000TO1000000USD"
                                  ]
                                }
                                name="expectedInOutTurnoverFlow"
                              />

                              <Radio
                                value="m1000000USD"
                                radioClass="iradio_square-blue ml-3"
                                increaseArea="20%"
                                label={
                                  " " +
                                  messageConstants
                                    .LABEL_INDIVIDUAL_EXPECTED_IN_OUT_FLOW[
                                    "M1000000USD"
                                  ]
                                }
                                name="expectedInOutTurnoverFlow"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link
                  to="/individual_identification/professional_details"
                  className="color-white"
                >
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>

                <button
                  className="btn btn-primary permian-button"
                  type="button"
                  onClick={this.saveBusinessIntensionDetails}
                  disabled={buttonDisabled}
                >
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IndividualIdentificationBusinessIntentions;
