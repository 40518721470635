import "icheck/skins/all.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "react-datetime/css/react-datetime.css";
import React from "react";
import _ from "lodash";
import { Radio, RadioGroup } from "react-icheck";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import validators from "../../validators";
import Api from "../../services/api";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";
import * as utils from "../../utils/Util";

const LOCAL_CACHE_KEY = "_kycAnalysis";

class KycSuitabilityAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grossAnnualIncome: "Under $200,000",
      netLiquidAssets: "Under $1 million",
      investmentTimeFrame: "3–10 Years",
      investmentKnowledge: "Good",
      investmentObjective: "Capital Appreciation",
      toleranceForRisk: "High",
    };

    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.saveKycBasicDetails = this.saveKycBasicDetails.bind(this);
    this.displaySecurityNumberToggle =
      this.displaySecurityNumberToggle.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_BASIC_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    var body = document.body;
    body.classList.remove("full-screen");
    body.classList.remove("with-content-panel");
    body.classList.remove("user-dashboard-panel");

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    try {
      const api = new Api();
      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", {emdId});
      if (userResponse.code === 200) {
        if (userResponse.data.isEmdSelected === true) {
          if (
            userResponse.data.kycStatus === "u" ||
            (userResponse.data.kycStatus === "a" &&
              userResponse.data.isKycVerified === true)
          ) {
            let redirectUrl =
              userResponse.data.kycStatus === "u"
                ? `/kyc_status/pending/${emdId}`
                : `/kyc_status/approved/${emdId}`;
            this.props.history.push(redirectUrl);
          } else {
            const localCache = utils.getKycLocalCache()[LOCAL_CACHE_KEY];
            if (userResponse.data.userKycDetail && userResponse.data.userKycDetail.grossAnnualIncome) {
              let stateObj = {};
              stateObj = {
                grossAnnualIncome:
                  userResponse.data.userKycDetail.grossAnnualIncome,
                netLiquidAssets:
                  userResponse.data.userKycDetail.netLiquidAssets,
                investmentTimeFrame:
                  userResponse.data.userKycDetail.investmentTimeFrame,
                investmentKnowledge:
                  userResponse.data.userKycDetail.investmentKnowledge,
                investmentObjective:
                  userResponse.data.userKycDetail.investmentObjective,
                toleranceForRisk:
                  userResponse.data.userKycDetail.toleranceForRisk,
              };
              if (userResponse.data.termsOfEngagementDataAdded) {
                stateObj["termsOfEngagementDataAdded"] =
                  userResponse.data.termsOfEngagementDataAdded;
              }
              this.setState(stateObj);
            } else if (!_.isEmpty(localCache)) {
              this.setState(localCache);
            }
            const response = await api.get("user/getCountries");
            let countriesList = [];
            if (response.code === 200) {
              if (!_.isUndefined(response.data.countries)) {
                response.data.countries.forEach((country) => {
                  let obj = { value: country._id, label: country.name };
                  countriesList.push(obj);
                });
                if (!_.isUndefined(countriesList)) {
                  this.setState({
                    countriesList: countriesList,
                  });
                }
              }
            } else {
              toast.error(userResponse.message);
            }
          }
        } else {
          this.props.history.push("/emd");
        }
      } else {
        toast.error(userResponse.message);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  updateValidators(fieldName, value) {
    //const { password, confirmPassword } = this.state;
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "contactNo", "email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  displaySecurityNumberToggle() {
    const currentType = document.getElementById("socialSecurityNumber")
      .attributes["type"].value;
    document.getElementById("socialSecurityNumber").attributes["type"].value =
      currentType === "password" ? "text" : "password";
  }

  async saveKycBasicDetails(event) {
    event.preventDefault();
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    const {
      grossAnnualIncome,
      netLiquidAssets,
      toleranceForRisk,
      investmentKnowledge,
      investmentObjective,
      investmentTimeFrame,
    } = this.state;
    this.setState({ buttonLoading: true });
    const formData = {
      grossAnnualIncome,
      netLiquidAssets,
      toleranceForRisk,
      investmentKnowledge,
      investmentObjective,
      investmentTimeFrame,
      suitabilityAnalysis: true,
    };
    const localCache = utils.getKycLocalCache();
    localCache[LOCAL_CACHE_KEY] = {
      ...formData
    }
    utils.saveKycLocalCache(localCache);
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .create("user/emd/saveKycBasicDetails", {...formData, emdId});

    if (response) {
      this.setState({ buttonLoading: false });
      if (response.code === 200) {
        this.props.history.push(`/kyc/relationship/disclosure/${emdId}`);
        toast.success(response.message);
      } else {
        toast.error(response.message);
        if (response.data.isEmdSelected === false) {
          this.props.history.push("/emd");
        }
      }
    }
  }

  async handleRadioChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value,
    });
  }

  render() {
    let {
      buttonLoading,
      termsOfEngagementDataAdded,
      grossAnnualIncome,
      netLiquidAssets,
      toleranceForRisk,
      investmentKnowledge,
      investmentObjective,
      investmentTimeFrame,
    } = this.state;
    const { emdId } = this.props.match.params;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <ul className="progressbar d-flex p-0 justify-content-center">
                  <li>Basic Details</li>
                  <li>Upload</li>
                  <li className="active">Suitability Analysis</li>
                  <li>Relationship Disclosure</li>
                  {termsOfEngagementDataAdded && <li>Terms Of Engagement</li>}
                </ul>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div>
              <div className="row mt-3 row-eq-height card-horizontal-padding">
                <div className="col-md-12">
                  <div className="custom-element-box">
                    <div className="element-box">
                      <form>
                        <h5 className="form-header">
                          To be completed by decision marker on behalf of the
                          individual(s) or entity as applicable
                        </h5>
                        <div className="form-group row mt-3">
                          <div className="col-md-3">
                            <label className="h6 pt-0 text-default font-400">
                              Gross Annual Income
                            </label>
                          </div>
                          <div className="col-md-9 col-sm-9">
                            <RadioGroup
                              className="kyc-button-group"
                              name="grossAnnualIncome"
                              value={grossAnnualIncome}
                              onChange={(e) =>
                                this.handleRadioChange(e, "grossAnnualIncome")
                              }
                              id="grossAnnualIncome"
                            >
                              <Radio
                                value="Under $200,000"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Under $200,000"}
                              />
                              <Radio
                                value="$200,000 - $400,000"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "$200,000 - $400,000"}
                              />
                              <Radio
                                value="Over $400,000"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Over $400,000"}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="form-group row mt-md-3 mt-sm-4">
                          <div className="col-md-3">
                            <label className="h6 pt-0 text-default font-400">
                              Net Liquid Assets
                            </label>
                          </div>
                          <div className="col-md-9 col-sm-9">
                            <RadioGroup
                              className="kyc-button-group"
                              name="netLiquidAssets"
                              value={netLiquidAssets}
                              onChange={(e) =>
                                this.handleRadioChange(e, "netLiquidAssets")
                              }
                              id="netLiquidAssets"
                            >
                              <Radio
                                value="Under $1 million"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Under $1 million"}
                              />
                              <Radio
                                value="$1 - $5 Million"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "$1 - $5 Million"}
                              />
                              <Radio
                                value="Over $5 million"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Over $5 million"}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="form-group row  mt-md-3 mt-sm-4">
                          <div className="col-md-3">
                            <label className="h6 pt-0 text-default font-400">
                              Investment Time Frame
                            </label>
                          </div>
                          <div className="col-md-9 col-sm-9">
                            <RadioGroup
                              className="kyc-button-group"
                              name="investmentTimeFrame"
                              value={investmentTimeFrame}
                              onChange={(e) =>
                                this.handleRadioChange(e, "investmentTimeFrame")
                              }
                            >
                              <Radio
                                value="10+ Years"
                                radioClass="iradio_square-blue pr-2 text-default"
                                increaseArea="20%"
                                label={" " + "10 + Years"}
                              />
                              <Radio
                                value="3–10 Years"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "3–10 Years"}
                              />
                              <Radio
                                value="1-3 Years"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "1-3 Years"}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="form-group row  mt-md-3 mt-sm-4">
                          <div className="col-md-3">
                            <label className="h6 pt-0 text-default font-400">
                              Investment Knowledge
                            </label>
                          </div>
                          <div className="col-md-9">
                            <RadioGroup
                              className="kyc-button-group"
                              name="investmentKnowledge"
                              value={investmentKnowledge}
                              onChange={(e) =>
                                this.handleRadioChange(e, "investmentKnowledge")
                              }
                            >
                              <Radio
                                value="Good"
                                radioClass="iradio_square-blue pr-2 text-default"
                                increaseArea="20%"
                                label={" " + "Good"}
                              />
                              <Radio
                                value="Fair"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Fair"}
                              />
                              <Radio
                                value="Nil"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Nil"}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="form-group row  mt-md-3 mt-sm-4">
                          <div className="col-md-3">
                            <label className="h6 pt-0 text-default font-400">
                              Investment Objective
                            </label>
                          </div>
                          <div className="col-md-9">
                            <RadioGroup
                              className="kyc-button-group"
                              name="investmentObjective"
                              value={investmentObjective}
                              onChange={(e) =>
                                this.handleRadioChange(e, "investmentObjective")
                              }
                            >
                              <Radio
                                value="Capital Appreciation"
                                radioClass="iradio_square-blue pr-2 text-default"
                                increaseArea="20%"
                                label={" " + "Capital Appreciation"}
                              />
                              <Radio
                                value="Income"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Income"}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="form-group row mt-md-3 mt-sm-4">
                          <div className="col-md-3  ">
                            <label className="h6 pt-0 text-default font-400">
                              Tolerance for Risk
                            </label>
                          </div>

                          <div className="col-md-9">
                            <RadioGroup
                              className="kyc-button-group"
                              name="toleranceForRisk"
                              value={toleranceForRisk}
                              onChange={(e) =>
                                this.handleRadioChange(e, "toleranceForRisk")
                              }
                            >
                              <Radio
                                value="High"
                                radioClass="iradio_square-blue pr-2 text-default"
                                increaseArea="20%"
                                label={" " + "High"}
                              />
                              <Radio
                                value="Moderate"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Moderate"}
                              />
                              <Radio
                                value="Low"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Low"}
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end card-inner-padding">
              <Link className="btn cancel-button" to="/dashboard">
                Do it later
              </Link>
              <Link to={`/kyc/upload/${emdId}`} className="color-white">
                <button className="btn update-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-0"
                type="submit"
                onClick={this.saveKycBasicDetails}
                disabled={buttonDisabled}
              >
                {" "}
                Next{" "}
                {buttonLoading && (
                  <i className="fa-spin fa fa-spinner text-white ml-1" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default KycSuitabilityAnalysis;
