import React from "react";
class EmailVerifyBasePages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="wrapper">{this.props.children}</div>;
  }
}
export default EmailVerifyBasePages;
