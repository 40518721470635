import React from "react";
import { Link } from "react-router-dom";
import { RECAPTCHASITEKEY } from "../../services/api-config";
import logoImg from "../../assets/img/investor-w.png?v3";
import ReCAPTCHA from "react-google-recaptcha";
import validators from "../../validators";
import Api from "../../services/api";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      recaptchaResponse: "",
      buttonLoading: false,
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.forgotSubmit = this.forgotSubmit.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleCaptchaResponseChange =
      this.handleCaptchaResponseChange.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.FORGOT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
  }

  componentDidMount() {
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async forgotSubmit(event) {
    event.preventDefault();
    const { email, recaptchaResponse } = this.state;
    const isFormValid = this.isFormValid();
    if (email !== "" && isFormValid) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api.create("user/forgotPassword", {
        email: email,
        recaptchaResponse: recaptchaResponse,
      });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }

      if (response.code === 200) {
        this.props.history.push("/signin");
        toast.success(response.message);
      } else {
        toast.error(response.message);
        this.recaptcha.reset();
      }
    } else {
      toast.error("Please enter valid email.");
      this.recaptcha.reset();
    }
  }

  render() {
    const { email, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    const isFormValid = this.isFormValid();
    return (
      <div className="login-container min-width-50">
        <div className="auth-box-w wider login-box">
          <div className="logo-w">
            <Link to="/">
              <img alt="Logo" src={logoImg} width="250" />
            </Link>
          </div>
          <form action="" className="custom-form">
            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                <input
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Email ID*"
                  type="text"
                  onChange={this.onchange}
                  value={this.state.email}
                />
              </div>
              {email && this.displayValidationErrors("email")}
            </div>
            <div className="form-check terms-block">
              <ReCAPTCHA
                ref={(el) => {
                  this.recaptcha = el;
                }}
                sitekey={RECAPTCHASITEKEY}
                onChange={this.handleCaptchaResponseChange}
              />
            </div>
            <div>
              <div className="buttons-w pull-left width-35 width-100 pl-2 pl-none">
                <button
                  className={`btn btn-primary custom-submit-button ${
                    isFormValid ? "" : "disabled"
                  }`}
                  onClick={this.forgotSubmit}
                  disabled={buttonDisabled}
                >
                  SUBMIT{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
              <div className="pull-right signuppage-info-bottom-link signinpt">
                <Link to="/signin" className="color-white">
                  Back to sign in
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </form>
        </div>
      </div>
    );
  }
}
export default Forgot;
