import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class CorporateFatcaDeclarationAndUndertakings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coyDate: "",
      buttonLoading: false,
    };
    this.clearSignature = this.clearSignature.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.saveUnderstandingDetails = this.saveUnderstandingDetails.bind(this);
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  componentDidMount() {
    let authUserInfo = this.props.authUserInfo;
    if (
      !_.isUndefined(authUserInfo.fatcaCrsDataFilled) &&
      authUserInfo.fatcaCrsDataFilled === true
    ) {
      this.props.history.push("/my_account");
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    document.title =
      messageConstants.CORPORATE_FATCA_CRS_DECLARATION_AND_UNDERSTANDING_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  async saveUnderstandingDetails(event) {
    event.preventDefault();
    let _this = this;
    let authenticationToken = this.props.authToken;
    let coySignature = _this.signaturePad.toDataURL();
    const { coyPrintName, coyDate, coyCapacity } = this.state;

    if (
      coyPrintName !== "" &&
      coyDate !== "" &&
      coyCapacity !== "" &&
      coySignature !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const apiResponse = await api
        .setToken(authenticationToken)
        .create("user/company_fatca_crs/save/declaration_undertakings", {
          coyPrintName: coyPrintName,
          coyDate: coyDate,
          coyCapacity: coyCapacity,
          coySignature: coySignature,
        });
      if (apiResponse) {
        this.setState({
          buttonLoading: false,
        });
        if (apiResponse.code === 200) {
          this.props.history.push("/my_account");
          toast.success(apiResponse.message);
        } else {
          toast.error(apiResponse.message);
        }
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  handleDateChange(date) {
    this.setState({
      coyDate: date,
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const { coyDate, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="content-i identification-block">
        <div className="content-box">
          {/* <h4 className="text-center color-white identification-header">Section 6: Declarations and Undertakings</h4> */}
          <div className="kyc-container">
            <div className="row">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <p className="text-default-color text-default">
                      I/We declare (as an authorized signatory of the
                      Subscriber) that the information provided in this form is,
                      to the best of my/our knowledge and belief, accurate and
                      complete.
                    </p>
                    <p className="text-default-color text-default">
                      I acknowledge that the information contained in this form
                      and information regarding the Subscriber may be reported
                      to the tax authorities of the country in which this
                      account(s) is/are maintained and exchanged with tax
                      authorities of another country or countries in which the
                      Subscriber may be tax resident where those countries (or
                      tax authorities in those countries) have entered into
                      agreements to exchange financial account information.
                    </p>
                    <p className="text-default-color text-default">
                      I/We undertake to advise the recipient promptly and
                      provide an updated Self-Certification where any change in
                      circumstance occurs which causes any of the information
                      contained in this form to be incorrect.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="element-wrapper mt-3">
              <div
                className="element-box custom-element-box"
                style={{ margin: "0" }}
              >
                <h5 className="text-default-color text-xl">Details</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="text-default-color text-default">
                        {" "}
                        Print Name<span className="required-sign">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="coyPrintName"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="text-default-color text-default">
                        {" "}
                        Date (dd/mm/yyyy)
                        <span className="required-sign">*</span>
                      </label>
                      <Datetime
                        dateFormat="DD-MM-YYYY"
                        onChange={this.handleDateChange}
                        timeFormat={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: "dd-mm-yyyy" }}
                        utc={true}
                        value={coyDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="text-default-color text-default">
                        Capacity<span className="required-sign">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="coyCapacity"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div
                    className="element-box custom-element-box"
                    style={{ margin: "0" }}
                  >
                    <h5 className="text-default-color text-xl">
                      Signature{" "}
                      <span
                        className="clear-sign-action ml-2 text-underline"
                        onClick={() => this.clearSignature()}
                      >
                        Clear
                      </span>
                    </h5>
                    <div className="signature-pad-container">
                      <SignaturePad
                        ref={(ref) => (this.signaturePad = ref)}
                        height={150}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-end">
              <Link
                to="/corporate_fatca_crs/entity_crs_classification"
                className="color-white text-underline"
              >
                <button className="btn cancel-button" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-3"
                type="button"
                onClick={this.saveUnderstandingDetails}
                disabled={buttonDisabled}
              >
                Submit{" "}
                {buttonLoading && <i className="fa-spin fa fa-spinner m-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateFatcaDeclarationAndUndertakings;
